import { IContact } from './IContact';

export enum ISendDocumentDocumentType {
  Record = 'Urkunde',
  Invoice = 'Rechnung',
  Drafts = 'Entwürfe',
  DocumentsFromRequirements = 'Dokumente aus Maßnahmen',
  EmailsFromRequirements = 'E-Mails aus Maßnahmen',
  SideFile = 'Digitale Nebenakte',
}

export enum ISendDocumentSendOption {
  Email = 'EMail',
  Documentportal = 'Dokumentenportal',
}

export interface ISendDocument {
  contactArray: IContact[];
  documentTypeArray: ISendDocumentDocumentType[];
  emailText: string | undefined;
  subject: string;
  sendOption: ISendDocumentSendOption;
  idLegalTransaction: number;
}
