import React, { useState } from 'react'
import { ILegalPhaseTemplateFullObject } from '../Interfaces/ILegalPhaseTemplate'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { TextModuleSelector } from '../TextModule/TextModuleSelector'
import { useFetch } from '../hooks/useFetch'
import { IAdminstrativeUnit, IFederalState } from '../Interfaces/IAemter'

interface IProps {
  currentPhase: ILegalPhaseTemplateFullObject
  currentPhaseEdit: ILegalPhaseTemplateFullObject
  setCurrentPhaseEdit: Function
  legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[]
  setLegalPhaseTemplateArray: Function
}

export const LegalPhaseEdit: React.FC<IProps> = (props) => {
  const handleSavePhase = () => {
    props.setLegalPhaseTemplateArray([
      ...props.legalPhaseTemplateArray.map((x) =>
        x.idLegalPhaseTemplate === props.currentPhaseEdit.idLegalPhaseTemplate ? props.currentPhaseEdit : x
      )
    ])
    setIsOpenEdit(false)
  }
  const [isOpenEdit, setIsOpenEdit] = useState(
    props.currentPhase.idLegalPhaseTemplate < 0 && props.currentPhase.LegalPhaseTemplate === ''
  )
  const [isOpenAddPrecondition, setIsOpenAddPrecondition] = useState(false)

  //
  const [precIdLegalReq, setPrecIdLegalReq] = useState<number | null>(null)

  const hanldeCloseEdit = () => {
    props.setCurrentPhaseEdit(props.currentPhase)
    setIsOpenEdit(false)
  }

  const handleAddPredcondigiont = () => {
    if (precIdLegalReq !== null) {
      let testObject = props.legalPhaseTemplateArray.find((x) => x.idLegalPhaseTemplate === precIdLegalReq)

      if (testObject !== undefined) {
        props.setCurrentPhaseEdit({
          ...props.currentPhaseEdit,
          PreconditionArray: [...props.currentPhaseEdit.PreconditionArray, testObject]
        } as ILegalPhaseTemplateFullObject)
        setIsOpenAddPrecondition(false)
      }
    }
  }

  const handleRemovePrecondition = (idLegalPhaseTemplate: number) => {
    props.setCurrentPhaseEdit({
      ...props.currentPhaseEdit,
      PreconditionArray: [
        ...props.currentPhaseEdit.PreconditionArray.filter((x) => x.idLegalPhaseTemplate !== idLegalPhaseTemplate)
      ]
    } as ILegalPhaseTemplateFullObject)
  }

  return (
    <>
      <Dialog open={isOpenAddPrecondition} onClose={() => setIsOpenAddPrecondition(false)} maxWidth='md'>
        <DialogTitle>Vorgänger hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            label='Vorgänger'
            value={precIdLegalReq === null ? '' : precIdLegalReq}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrecIdLegalReq(Number(event.target.value))}
            size='small'
            fullWidth
            select
          >
            {props.legalPhaseTemplateArray
              .filter((y) => y.idLegalPhaseTemplate !== props.currentPhase.idLegalPhaseTemplate)
              .map((x) => (
                <MenuItem
                  key={`idLegalPhaseTemplate-${x.idLegalPhaseTemplate}`}
                  disabled={
                    props.currentPhaseEdit.PreconditionArray.filter(
                      (y) => y.idLegalPhaseTemplate !== props.currentPhase.idLegalPhaseTemplate
                    )
                      .map((y) => y.idLegalPhaseTemplate)
                      .indexOf(x.idLegalPhaseTemplate) !== -1
                  }
                  value={x.idLegalPhaseTemplate}
                >
                  {x.LegalPhaseTemplate}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenAddPrecondition(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleAddPredcondigiont}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenEdit} onClose={() => {}} maxWidth='lg' fullWidth>
        <DialogTitle>Phase anpassen</DialogTitle>
        <DialogContent>
          <TextField
            label='Phase'
            sx={{ mt: 2 }}
            value={props.currentPhaseEdit.LegalPhaseTemplate}
            error={props.currentPhaseEdit.LegalPhaseTemplate === ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentPhaseEdit({ ...props.currentPhaseEdit, LegalPhaseTemplate: event.target.value })
            }
            size='small'
            fullWidth
          />
          <TextModuleSelector
            key={`type-legalphasedialogedit`}
            floatRight
            idTextModuleCategory={4}
            txt={props.currentPhaseEdit.InfoText}
            setTxt={(txt: string) => props.setCurrentPhaseEdit({ ...props.currentPhaseEdit, InfoText: txt })}
          />
          <TextField
            label='Infotext'
            //sx={{mt: 2}}
            value={props.currentPhaseEdit.InfoText === null ? '' : props.currentPhaseEdit.InfoText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              event.target.value === ''
                ? props.setCurrentPhaseEdit({ ...props.currentPhaseEdit, InfoText: null })
                : props.setCurrentPhaseEdit({ ...props.currentPhaseEdit, InfoText: event.target.value })
            }
            size='small'
            fullWidth
            multiline
            rows={3}
          />

          <Typography variant='h6' sx={{ mt: 3 }}>
            Vorgänger
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setPrecIdLegalReq(null)
                setIsOpenAddPrecondition(true)
              }}
            >
              <Add />
            </IconButton>
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vorgänger</TableCell>
                <TableCell sx={{ maxWidth: 30 }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.currentPhaseEdit.PreconditionArray.map((x) => (
                <TableRow>
                  <TableCell>{x.LegalPhaseTemplate}</TableCell>
                  <TableCell>
                    <IconButton size='small' onClick={() => handleRemovePrecondition(x.idLegalPhaseTemplate)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={hanldeCloseEdit}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={handleSavePhase}
            disabled={props.currentPhaseEdit.LegalPhaseTemplate === ''}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title='Phase bearbeiten'>
        <IconButton
          size='small'
          onClick={() => {
            props.setCurrentPhaseEdit(props.currentPhase)
            setIsOpenEdit(true)
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    </>
  )
}
