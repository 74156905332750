import React, { useState } from "react";
import { IDocument } from "../Interfaces/IDocument";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TableCell, TableRow } from "@mui/material";
import { Add, FindInPage, Preview, Search } from "@mui/icons-material";


interface IProps {
    resultElement: {FileName : string; Data: string;};
    documentArray: IDocument[];
    setDocumentArray: Function;
    searchResult: {FileName : string; Data: string;}[];
    setSearchResults: Function;
}


export const FSDirectorySearchRow:React.FC<IProps> = ({resultElement, documentArray, setDocumentArray, searchResult, setSearchResults}) => {
    const [isOpenPreview,setIsOpenPreview] = useState(false);

    const handleAdd = () => {
        let tmpId = -1;

        if (documentArray.length > 0) {
            let tmpIdArray = Math.min(...documentArray.map(x => x.idDocument)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray -1;
            }
        }

        let newDocument:IDocument = {
            idDocument: tmpId,
            idMIMEType: 1,
            Data: resultElement.Data,
            FileName: resultElement.FileName,
            Created_at: "",
            idDocumentDirectory: null,
            idLegalTransaction: -1
        }
        setDocumentArray([
            ...documentArray,
            newDocument
        ])
        setSearchResults([
            ...searchResult.filter(x => x.FileName !== resultElement.FileName)
        ])
    }

    return(
        <>
            <Dialog open={isOpenPreview} onClose={() => setIsOpenPreview(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <iframe name={resultElement.FileName} src={`data:application/pdf;headers=filename%3D${resultElement.FileName};base64,${resultElement.Data}`} width={850} height={600}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpenPreview(false)}>Okay</Button>
                </DialogActions>
            </Dialog>

            <TableRow>
                <TableCell>{resultElement.FileName}</TableCell>
                <TableCell>
                    <IconButton onClick={() => setIsOpenPreview(true)} title="Vorschau"><FindInPage/></IconButton>
                    <IconButton onClick={handleAdd} title="Hinzufügen"><Add/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}