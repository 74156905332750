import React, { useState } from "react";
import { LegalRoleOverview } from "./LegalRoleOverview";
import { LegalRoleCanInviteTable } from "./LegalRoleCanInviteTable";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { ILegalRole_canInvite } from "../../../Interfaces/ILegalRole_canInvite";
import { FullScreenDialog } from "../../../core/FullScreenDialog";
import { Button } from "@mui/material";
import { ILegalTransactionHasContact } from "../../../Interfaces/IContact";



interface IProps {
    contactArray: ILegalTransactionHasContact[];
    chosenContactArray: ILegalTransactionHasContact[];
    legalRoleArray: ILegalRole[];
    setLegalRoleArray: Function;
    legalRoleCanInviteArray: ILegalRole_canInvite[];
    setLegalRoleCanInviteArray: Function;
}



export const LegalRoleEditDialog:React.FC<IProps> = (props) => {
    const [editLegalRoleArray, setEditLegalRoleArray] = useState(props.legalRoleArray);
    const [editLegalRoleCanInviteArray, setEditLegalRoleCanInviteArray] = useState(props.legalRoleCanInviteArray);
    //
    const [isOpenDialog,setIsOpenDialog] = useState(false);


    const handleOpenDialog = () => {
        setEditLegalRoleArray(props.legalRoleArray);
        setEditLegalRoleCanInviteArray(props.legalRoleCanInviteArray);
        setIsOpenDialog(true);
    }

    const handleSave = () => {
        props.setLegalRoleArray(editLegalRoleArray);
        props.setLegalRoleCanInviteArray(editLegalRoleCanInviteArray);
        setIsOpenDialog(false);
    }


    return(
        <>
            <Button sx={{float: "right"}} variant="outlined" onClick={handleOpenDialog}>Rollen anpassen</Button>

            <FullScreenDialog
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                title="Rollen bearbeiten"
            >
                <>
                    <Button sx={{float: "right"}} variant="contained" onClick={handleSave}>Übernehmen</Button>
                    <LegalRoleOverview
                            contactArray={props.contactArray}
                            newContactArray={props.chosenContactArray}
                            legalRoleArray={editLegalRoleArray}
                            setLegalRoleArray={setEditLegalRoleArray}
                        />

                    <LegalRoleCanInviteTable
                        legalRoleArray={editLegalRoleArray}
                        legalRoleCanInviteArray={editLegalRoleCanInviteArray}
                        setLegalRoleCanInviteArray={setEditLegalRoleCanInviteArray}
                    />
                </>
            </FullScreenDialog>

        </>
    )
}