import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ILegalPhaseFullObject } from '../../../Interfaces/ILegalPhase';
import { ILegalRequirementFullObject } from '../../../Interfaces/ILegalRequirement';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';
import { ILegalTransaction } from '../../../Interfaces/ILegalTransaction';
import { PopupAlert } from '../../../core/PopupAlert';
import { getFetch } from '../../../hooks/useFetch';

interface AddPrerequisiteDeadlineProps {
  currentLegalRequirement: ILegalRequirementFullObject;
  setDeadline: Function;
  open: boolean;
  onClose: () => void;
  legaltransactionId: number;
}

const newDeadline = (): ILegalRequirementDeadline => ({
  idDeadline: -1,
  idLegalRequirement: undefined,
  idLegalPhase: undefined,
  idLegalRequirementState: undefined,
  idLegalTransactionState: undefined,
  LegalPhase: '',
  LegalRequirement: '',
  LegalRequirementState: '',
  LegalTransactionState: '',
  LegalTransactionTitle: '',
  Startdate: undefined,
  Weighting: 0,
  idLegalTransaction: undefined,
});

export const AddPrerequisiteDeadline: React.FC<
  AddPrerequisiteDeadlineProps
> = ({
  currentLegalRequirement,
  setDeadline,
  open,
  onClose,
  legaltransactionId,
}) => {
  const [selectionType, setSelectionType] = useState<
    'phase' | 'requirement' | 'state' | 'startdate' | 'undefined'
  >('undefined');

  const [legalPhaseArray, setLegalPhaseArray] = useState<
    ILegalPhaseFullObject[]
  >([]);

  const [legalTransactionLoaded, setLegalTransactionLoaded] =
    useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const [allLegalTransactions, setAllLegalTransactions] = useState<
    ILegalTransaction[]
  >([]);

  const [legalPhasePosition, setLegalPhasePosition] = useState<number>(0);
  const [deadlineEdit, setDeadlineEdit] =
    useState<ILegalRequirementDeadline>(newDeadline());

  const [currentLegalTransaction, setCurrentLegalTransaction] = useState<
    ILegalTransaction | undefined
  >(undefined);

  const handleFailedToLoadAllLegalTransactions = useCallback(
    (success: boolean) => {
      setShowError(!success);
    },
    [setShowError]
  );

  const handleClose = useCallback(() => {
    setSelectionType('undefined');
    setDeadlineEdit(newDeadline());
    onClose();
  }, [onClose]);

  const loadLegalTransactionsPhaseAndRequirement = useCallback(
    (idLegalTransaction: number) => {
      getFetch<ILegalPhaseFullObject[]>(
        `/legalPhase/getbylegaltransaction/`,
        idLegalTransaction,
        (data: ILegalPhaseFullObject[]) => {
          setLegalPhasePosition(
            data.find(
              legalPhaseTemplate =>
                legalPhaseTemplate.idLegalPhase ===
                currentLegalRequirement.idLegalPhase
            )?.Position || 0
          );
          setLegalPhaseArray(data);
        },
        handleFailedToLoadAllLegalTransactions
      );
    },
    [
      currentLegalRequirement.idLegalPhase,
      handleFailedToLoadAllLegalTransactions,
    ]
  );

  const getLegalRequirementName = useCallback(
    (idLegalRequirement: number) => {
      return legalPhaseArray
        ? legalPhaseArray
            .map(
              legalPhaseTemplate =>
                legalPhaseTemplate.LegalRequirementArray &&
                legalPhaseTemplate.LegalRequirementArray.find(
                  legalRequirementTemplate =>
                    legalRequirementTemplate.idLegalRequirement ===
                    idLegalRequirement
                )
            )
            .filter(x => x)[0]?.LegalRequirement || ''
        : '';
    },
    [legalPhaseArray]
  );

  const getLegalPhaseName = useCallback(
    (idLegalPhase: number) => {
      return legalPhaseArray?.find(
        legalPhaseTemplate => legalPhaseTemplate.idLegalPhase === idLegalPhase
      )?.LegalPhase;
    },
    [legalPhaseArray]
  );

  const getLegalStateName = useCallback((idLegalRequirementState: number) => {
    switch (idLegalRequirementState) {
      case 10:
        return 'Offen';
      case 15:
        return 'Prüfung Mitarbeiter';
      case 20:
        return 'Prüfung Notar';
      case 40:
        return 'Erledigt';
      default:
        return '';
    }
  }, []);

  const getLegalTransactionStateName = useCallback(
    (idLegalTransactionState: number) => {
      switch (idLegalTransactionState) {
        case 1:
          return 'Entwurf';
        case 5:
          return 'Laufend';
        default:
          return '';
      }
    },
    []
  );

  const onSave = useCallback(() => {
    setDeadline({
      idDeadline: -1,
      idLegalRequirement:
        selectionType === 'requirement'
          ? deadlineEdit.idLegalRequirement
          : null,
      idLegalPhase:
        selectionType === 'phase' ? deadlineEdit.idLegalPhase : null,
      idLegalRequirementState:
        selectionType === 'requirement'
          ? deadlineEdit.idLegalRequirementState
          : null,
      LegalPhase:
        selectionType === 'phase'
          ? getLegalPhaseName(deadlineEdit.idLegalPhase || -1)
          : '',
      LegalRequirement:
        selectionType === 'requirement'
          ? getLegalRequirementName(deadlineEdit.idLegalRequirement || -1)
          : '',
      LegalRequirementState:
        selectionType === 'requirement'
          ? getLegalStateName(deadlineEdit.idLegalRequirementState || -1)
          : '',
      Weighting: deadlineEdit.Weighting || 0,
      Startdate: selectionType === 'startdate' ? deadlineEdit.Startdate : null,
      idLegalTransaction: currentLegalTransaction?.idLegalTransaction,
      LegalTransactionTitle: currentLegalTransaction?.Title,
      idLegalTransactionState:
        selectionType === 'state' ? deadlineEdit.idLegalTransactionState : null,
      LegalTransactionState:
        selectionType === 'state'
          ? getLegalTransactionStateName(
              deadlineEdit.idLegalTransactionState || -1
            )
          : '',
    } as ILegalRequirementDeadline);
    handleClose();
  }, [
    deadlineEdit,
    setDeadline,
    selectionType,
    getLegalPhaseName,
    getLegalRequirementName,
    getLegalStateName,
    currentLegalTransaction?.idLegalTransaction,
    currentLegalTransaction?.Title,
    getLegalTransactionStateName,
    handleClose,
  ]);

  const loadAllLegalTransactions = useCallback(() => {
    if (allLegalTransactions.length === 0) {
      getFetch<ILegalTransaction[]>(
        '/legaltransaction',
        null,
        (data: ILegalTransaction[]) => {
          setAllLegalTransactions(data);
          setCurrentLegalTransaction(
            data.find(x => x.idLegalTransaction === legaltransactionId)
          );
          loadLegalTransactionsPhaseAndRequirement(legaltransactionId);
        },
        handleFailedToLoadAllLegalTransactions
      );
    }
  }, [
    allLegalTransactions.length,
    handleFailedToLoadAllLegalTransactions,
    legaltransactionId,
    loadLegalTransactionsPhaseAndRequirement,
  ]);

  useEffect(() => {
    if (!legalTransactionLoaded && open) {
      loadAllLegalTransactions();
      setLegalTransactionLoaded(true);
    }
  }, [legalTransactionLoaded, loadAllLegalTransactions, open]);

  const currentLegalRequirementDontHasLegalRequirementPhaseAsPrerequisite = (
    legalRequirement: ILegalRequirementFullObject
  ) => {
    return !currentLegalRequirement.DeadlineArray?.find(
      x => x.idLegalPhase === legalRequirement.idLegalPhase
    );
  };

  const legalRequirementDontHasCurrentAsPrerequisite = (
    legalRequirement: ILegalRequirementFullObject
  ) => {
    return !legalRequirement.DeadlineArray?.map(
      x => x.idLegalRequirement
    ).includes(currentLegalRequirement.idLegalRequirement);
  };

  const legalRequirementAfterCurrent = (
    legalRequirement: ILegalRequirementFullObject
  ) => {
    return (
      legalPhaseArray
        .map(x => {
          if (
            x.idLegalTransaction === legaltransactionId &&
            x.idLegalPhase === legalRequirement.idLegalPhase &&
            x.Position > legalPhasePosition
          ) {
            return x;
          } else {
            return null;
          }
        })
        .filter(x => x).length > 0 ||
      (legalRequirement.idLegalPhase === currentLegalRequirement.idLegalPhase &&
        legalRequirement.Position > currentLegalRequirement.Position)
    );
  };

  const showRequirement = (legalRequirement: ILegalRequirementFullObject) => {
    return (
      currentLegalRequirement.DeadlineArray?.find(
        x => x.idLegalRequirement === legalRequirement.idLegalRequirement
      ) === undefined &&
      !legalRequirementAfterCurrent(legalRequirement) &&
      legalRequirementDontHasCurrentAsPrerequisite(legalRequirement) &&
      currentLegalRequirementDontHasLegalRequirementPhaseAsPrerequisite(
        legalRequirement
      ) &&
      legalRequirement.idLegalRequirement !==
        currentLegalRequirement.idLegalRequirement
    );
  };

  const showRequirementSelect = () => {
    if (legalPhaseArray) {
      return legalPhaseArray.find(legalPhaseTemplate =>
        legalPhaseTemplate.LegalRequirementArray?.find(legalRequirement =>
          showRequirement(legalRequirement)
        )
      )
        ? true
        : false;
    }
    return false;
  };

  const currentLegalRequirementDontHasLegaLRequirementFromPhaseAsPrerequisite =
    (legalPhase: ILegalPhaseFullObject) => {
      return !currentLegalRequirement.DeadlineArray?.find(x =>
        legalPhase.LegalRequirementArray?.find(
          y => y.idLegalRequirement === x.idLegalRequirement
        )
      );
    };

  const phaseDontHasCurrentAsPrerequisite = (
    legalPhase: ILegalPhaseFullObject
  ) => {
    return !legalPhase.LegalRequirementArray?.map(
      x => x.idLegalRequirement
    ).includes(currentLegalRequirement.idLegalRequirement);
  };

  const phaseAfterCurrent = (legalPhase: ILegalPhaseFullObject) => {
    return (
      legalPhase.idLegalTransaction ===
        currentLegalRequirement.idLegalTransaction &&
      legalPhase.Position > legalPhasePosition
    );
  };

  const showPhase = (legalPhase: ILegalPhaseFullObject) => {
    return (
      !currentLegalRequirement.DeadlineArray?.map(x => x.idLegalPhase).includes(
        legalPhase.idLegalPhase
      ) &&
      !phaseAfterCurrent(legalPhase) &&
      phaseDontHasCurrentAsPrerequisite(legalPhase) &&
      currentLegalRequirementDontHasLegaLRequirementFromPhaseAsPrerequisite(
        legalPhase
      ) &&
      legalPhase.idLegalPhase !== currentLegalRequirement.idLegalPhase
    );
  };

  const showPhaseSelect = () => {
    if (legalPhaseArray) {
      return legalPhaseArray.some(legalPhaseTemplate =>
        showPhase(legalPhaseTemplate)
      );
    }
    return false;
  };

  const showStateSelect = () => {
    return !currentLegalRequirement.DeadlineArray?.some(
      x => x.idLegalTransactionState
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <PopupAlert
        show={showError}
        setShow={setShowError}
        severity='error'
        text='Beim Laden der Vorgänge ist ein Fehler aufgetreten'
      />
      <DialogTitle>Startbedingung für die Frist hinzufügen</DialogTitle>
      <DialogContent>
        <Divider>
          <Typography variant='body1'>Bedinungsoptionen:</Typography>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
              }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label='Vorgang'
                  value={currentLegalTransaction?.idLegalTransaction || ''}
                  onChange={e => {
                    setCurrentLegalTransaction(
                      allLegalTransactions.find(
                        x => x.idLegalTransaction === Number(e.target.value)
                      )
                    );
                    loadLegalTransactionsPhaseAndRequirement(
                      Number(e.target.value)
                    );
                    setSelectionType('undefined');
                    setDeadlineEdit(newDeadline());
                  }}
                >
                  {allLegalTransactions.map(legalTransaction => (
                    <MenuItem
                      key={legalTransaction.idLegalTransaction}
                      value={legalTransaction.idLegalTransaction}
                    >
                      {legalTransaction.Title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={selectionType || ''}
                onChange={e =>
                  setSelectionType(
                    e.target.value as
                      | 'phase'
                      | 'requirement'
                      | 'state'
                      | 'startdate'
                      | 'undefined'
                  )
                }
                fullWidth
                select
                label='Bedinungstyp'
              >
                {showRequirementSelect() && (
                  <MenuItem value='requirement'>Maßnahme</MenuItem>
                )}
                {showPhaseSelect() && <MenuItem value='phase'>Phase</MenuItem>}
                {showStateSelect() && <MenuItem value='state'>Status</MenuItem>}
                {!currentLegalRequirement.DeadlineArray?.some(
                  x => x.Startdate
                ) && <MenuItem value='startdate'>Startdatum</MenuItem>}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider>
              <Typography variant='body1'>Bedingung:</Typography>
            </Divider>
          </Grid>

          {selectionType === 'requirement' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalRequirementLabel'>Maßnahme</InputLabel>
                  <Select
                    labelId='idLegalRequirementLabel'
                    id='idLegalRequirement'
                    value={deadlineEdit.idLegalRequirement || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalRequirement: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Maßnahme'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {legalPhaseArray &&
                      legalPhaseArray.map(legalPhaseTemplate =>
                        legalPhaseTemplate.LegalRequirementArray?.map(
                          legalRequirementTemplate =>
                            showRequirement(legalRequirementTemplate) && (
                              <MenuItem
                                key={
                                  legalRequirementTemplate.idLegalRequirement
                                }
                                value={
                                  legalRequirementTemplate.idLegalRequirement
                                }
                              >
                                {legalRequirementTemplate.LegalRequirement}
                              </MenuItem>
                            )
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalRequirementStateLabel'>
                    Bei Status
                  </InputLabel>
                  <Select
                    id='idLegalRequirementState'
                    labelId='idLegalRequirementStateLabel'
                    value={deadlineEdit.idLegalRequirementState || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalRequirementState: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Maßnahmenstatus'
                  >
                    <MenuItem value={15}>Prüfung Mitarbeiter</MenuItem>
                    <MenuItem value={20}>Prüfung Notar</MenuItem>
                    <MenuItem value={40}>Erledigt</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {legalPhaseArray && selectionType === 'phase' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalPhasePrerequisite'>
                    Legal Phase Template Prerequisite
                  </InputLabel>
                  <Select
                    labelId='idLegalPhasePrerequisite'
                    id='idLegalPhasePrerequisite'
                    value={deadlineEdit.idLegalPhase || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalPhase: e.target.value as number | undefined,
                      })
                    }
                    label='Phase'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {legalPhaseArray?.map(
                      legalPhase =>
                        showPhase(legalPhase) && (
                          <MenuItem
                            key={legalPhase.idLegalPhase}
                            value={legalPhase.idLegalPhase}
                          >
                            {legalPhase.LegalPhase}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {selectionType === 'state' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='state'>Vorgangsstatus</InputLabel>
                  <Select
                    labelId='state'
                    id='state'
                    value={deadlineEdit.idLegalTransactionState || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalTransactionState: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Status'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Entwurf</MenuItem>
                    <MenuItem value={5}>Laufend</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {selectionType === 'startdate' && (
            <Grid item xs={12}>
              <TextField
                type='date'
                value={deadlineEdit.Startdate || ''}
                onChange={e =>
                  setDeadlineEdit({
                    ...deadlineEdit,
                    Startdate: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          )}
          {selectionType && (
            <Grid item xs={12}>
              <TextField
                label='Gewichtung'
                type='number'
                value={deadlineEdit.Weighting || ''}
                onChange={e => {
                  setDeadlineEdit({
                    ...deadlineEdit,
                    Weighting: Number(e.target.value),
                  });
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant='outlined'
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Schließen
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={onSave}
              disabled={
                (selectionType === 'requirement' &&
                  !deadlineEdit.idLegalRequirement &&
                  !deadlineEdit.idLegalRequirementState) ||
                (selectionType === 'phase' && !deadlineEdit.idLegalPhase) ||
                (selectionType === 'state' &&
                  !deadlineEdit.idLegalRequirementState) ||
                (selectionType === 'startdate' && !deadlineEdit.Startdate) ||
                !deadlineEdit.Weighting
              }
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
