import React, {useEffect, useState} from "react";
import {ISystemMail} from "../Interfaces/ISystemMail";
import {Alert, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {$generateHtmlFromNodes} from '@lexical/html';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Box} from "@mui/system";
import {uploadFetch, useFetch} from "../hooks/useFetch";
import {CustomEditor} from "../core/CustomEditor";
import {ISetting} from "../Interfaces/ISignature";
import { ISystemMail_has_Dictionary } from "../Interfaces/ISystemMail_has_Dictionary";
import { IDictionaryEntry } from "../Interfaces/IDictionaryEntry";

interface IProps {
    currentId: number;
    setCurrentId: Function;
    currentArray: ISystemMail[];
    setCurrentArray: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
    systemMailHasDictionaryArray: ISystemMail_has_Dictionary[];
}

export const SystemMailEdit: React.FC<IProps> = (props) => {
    const [systemMailOrg, setSystemMailOrg] = useState(props.currentArray.find(x => x.idSystemMail === props.currentId));
    const [systemMailObject, setSystemMailObject] = useState(systemMailOrg);

    const [settingObject, setSettingObject, wasSuccessfullySetting] = useFetch<ISetting>("/setting/", 40);

    useEffect(() => {
        setSettingObject({... settingObject, SettingValue : systemMailObject?.Body})
    }, []);
    const setText = (txt: string) => {
        setSystemMailObject({... systemMailObject!, Body : txt});
        /*
        setSettingObject({
            ...settingObject,
            SettingValue: txt
        })
        if(settingObject?.SettingValue !== undefined && systemMailObject !== undefined)
        {
            setSystemMailObject({... systemMailObject, Body : settingObject.SettingValue})
        }
        */
    }

    useEffect(() => {

    }, [settingObject]);

    const isDisabled = () => {
        if (systemMailObject !== undefined) {
            return (
                systemMailObject.Title === ""
                || systemMailObject.Subject === ""
                || systemMailObject.Body === ""
            )
        } else {
            return true;
        }
    }

    const wrapperSaved = (localObject: ISystemMail) => {
        if (props.currentId < 0) {
            props.setCurrentArray([
                ...props.currentArray,
                localObject
            ])
        } else {
            props.setCurrentArray([
                ...props.currentArray.map(x => x.idSystemMail === localObject.idSystemMail ? localObject : x)
            ])
        }
        props.setCurrentId(null);
    }


    const handleSave = () => {
        if (systemMailObject !== undefined) {
            let uploadObeject: ISystemMail = {
                ...systemMailObject,
                isPublic: Boolean(systemMailObject.isPublic),
                isHidden: Boolean(systemMailObject.isHidden),
            }
            uploadFetch(`/systemmail`,
                (uploadObeject.idSystemMail < 0),
                uploadObeject,
                wrapperSaved,
                props.setWasSavedSuccesfully,
                props.setWasSaved
            );
        }
    }

    if (!wasSuccessfullySetting) {
        return <Alert severity="error">Bitte kontaktieren Sie einen Administrator!</Alert>
    }
    else if (systemMailOrg === undefined || systemMailObject === undefined || settingObject === undefined) {
        return <>Bitte warten...</>
    }
    else {
        return (
            <>
                <Typography variant="h5">
                    {`${systemMailOrg.Title}`}
                </Typography>

                <Box sx={{mt: 2}}/>

                <Grid container spacing={2}>
                    {/*
                    <Grid item sm={4}>
                        <TextField
                            label="Öffentlich"
                            size="small"
                            select
                            value={(systemMailObject.isPublic) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, isPublic: (event.target.value === "true")})}
                            error={systemMailObject.Title === ""}
                            fullWidth
                        >
                            <MenuItem key="public-true" value="true">Ja</MenuItem>
                            <MenuItem key="public-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                    */}

                    <Grid item sm={6}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={systemMailObject.Title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, Title: event.target.value})}
                            error={systemMailObject.Title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            label="Betreff"
                            size="small"
                            value={systemMailObject.Subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, Subject: event.target.value})}
                            error={systemMailObject.Subject === ""}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Box sx={{mt: 5}}/>

                <Grid container spacing={2}>
                    <CustomEditor 
                        idTextModuleCategory={1} 
                        txt={systemMailObject.Body} 
                        setTxt={setText}
                        dictonaryIdsArray={props.systemMailHasDictionaryArray.filter(x => x.idSystemMail === props.currentId).map(x => x.idDictionary)}
                    />
                </Grid>


                <Box sx={{mt: 2}}/>
                <Button onClick={handleSave} sx={{float: "right", ml: 2}} variant="contained"
                        disabled={isDisabled()}>Speichern</Button>
                <Button onClick={() => props.setCurrentId(null)} sx={{float: "right"}}
                        variant="outlined">Abbruch</Button>
            </>
        )
    }

}