import { Box, Checkbox, Grid } from "@mui/material";
import React, { useState } from "react";
import { DocumentAssistentCell } from "./DocumentAssistentCell";


interface IProps {
    ownIndex: number;
    selectArray: boolean[][];
    setSelectArray: Function;
    cellArray: string[];
}


export const DocumentAssistentRow:React.FC<IProps> = ({ownIndex,selectArray,setSelectArray,cellArray}) => {

    return(
        <>
            <Grid container>
                {cellArray.map((x,idx) =>
                    <DocumentAssistentCell
                        key={`dw-cell-${ownIndex}-${idx}`}
                        parentIndex={ownIndex}
                        ownIndex={idx}
                        selectArray={selectArray}
                        setSelectArray={setSelectArray}
                        cellArray={cellArray}
                        currentCell={x}
                    />

                )}
            </Grid>
        </>
    )
} 