import React, { useState } from 'react'
import { IUser } from '../Interfaces/IUser'
import { IRole } from '../Interfaces/IRole'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { UserSetPassword } from './UserSetPassword'
import { getFetch } from '../hooks/useFetch'
import { useSelector } from 'react-redux'
import { State } from '../redux/mainReducer'
import { ILegalTransaction } from '../Interfaces/ILegalTransaction'
import { UserReassign } from './UserReassign'
import { PopupAlert } from '../core/PopupAlert'
import { IUserGroupFullObject } from '../Interfaces/IUserGroup'

interface IProps {
  userEdit: IUser
  setUserEdit: Function
  roleArray: IRole[]
  setIsPwValidated: Function
  userArray: IUser[]
  setRoleChange: Function
  setUserDeactivated: Function
  roleChange: boolean
  currentUserId: number | undefined
  userGroups: IUserGroupFullObject[]
}

const genEmptyUser = () => {
  return {
    Email: '',
    FirstName: '',
    idRole: 3,
    idUser: -1,
    isBlocked: false,
    isNotary: false,
    LastName: '',
    Login: '',
    GenerateNewPassword: true,
    idGender: 1,
    // Erweiterung MA
    Birthplace: null,
    Birthdate: null,
    Street: null,
    StreetNr: null,
    idPostcode: null,
    IBAN: null,
    idBank: null,
    InsuranceNumber: null,
    TaxIdentificationNumber: null,
    idHealthInsurance: null
  } as IUser
}

export const UserCoreEdit: React.FC<IProps> = ({
  userEdit,
  setUserEdit,
  roleArray,
  setIsPwValidated,
  userArray,
  setRoleChange,
  setUserDeactivated,
  roleChange,
  currentUserId,
  userGroups
}) => {
  //
  const genderArray = useSelector((state: State) => state.genderArray.storeGender)
  const [showPreventUserRoleChange, setShowPreventUserRoleChange] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>('')

  const checkNotaryAndAdminExists = () => {
    //check if at least one other user with isNotary = true and/or an user with roleId = 1 exists, that is not blocked
    return checkNotaryExists() && checkAdminExists()
  }

  const checkNotaryExists = () => {
    //check if at least one other user with isNotary = true exists, that is not blocked

    let isNotary = false

    userArray.forEach((user) => {
      if (user.isNotary && !user.isBlocked && user.idUser !== userEdit.idUser) {
        isNotary = true
      }
    })

    return !(userEdit.isNotary && !isNotary)
  }

  const checkAdminExists = () => {
    //check if at least one other user with roleId = 1 exists, that is not blocked

    let isAdmin = false

    userArray.forEach((user) => {
      if (user.idRole === 1 && !user.isBlocked && user.idUser !== userEdit.idUser) {
        isAdmin = true
      }
    })

    return !(userEdit.idRole === 1 && !isAdmin)
  }

  const checkIsGroupAdmin = () => {
    //check if the user is a group admin
    let isGroupAdmin = false

    userGroups.forEach((group) => {
      for (let i = 0; i < group.UserHasUserGroupArray.length; i++) {
        if (group.UserHasUserGroupArray[i].idUser === userEdit.idUser && group.UserHasUserGroupArray[i].isGroupAdmin) {
          isGroupAdmin = true
        }
      }
    })

    return isGroupAdmin
  }

  const checkIfLastUserInGroup = () => {
    //check if the user is the last user in a group
    let isLastUserInGroup = false

    userGroups.forEach((group) => {
      let count = 0

      if (group.UserHasUserGroupArray.length === 1) {
        isLastUserInGroup = true
      }
    })

    return isLastUserInGroup
  }

  const showAlert = (text: string) => {
    setAlertText(text)
    setShowPreventUserRoleChange(true)
  }

  //
  //const [isPwValidated, setIsPwValidated] = useState(false);

  /*
    const isDisabled = () => {
        if (userEdit !== undefined) {
            return (
                userEdit.FirstName === ""
                || userEdit.LastName === ""
                || userEdit.Email === ""
                || userEdit.Login === ""
                || (
                    !isPwValidated 
                    && userEdit.Password !== undefined
                    && userEdit.Password !== ""
                    && (
                        userEdit.GenerateNewPassword === undefined
                        ||!userEdit.GenerateNewPassword
                    )
                    
                )
                //|| (!isPwValidated && userEdit.GenerateNewPassword !== undefined && !userEdit.GenerateNewPassword)
            )
        } else {
            return true;
        }

    }*/

  return (
    <>
      <PopupAlert
        show={showPreventUserRoleChange}
        setShow={setShowPreventUserRoleChange}
        severity='error'
        text={alertText}
      />

      <Box sx={{ mt: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            label='Rolle'
            size='small'
            value={userEdit.idRole}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (userEdit.isBlocked) {
                showAlert('Bitte reaktivieren Sie den User bevor Sie einen Rollenwechsel vornehmen.')
              } else {
                if (checkAdminExists()) {
                  setUserEdit({ ...userEdit, idRole: Number(event.target.value) })
                } else {
                  showAlert('Es muss mindestens ein Admin existieren.')
                }
              }
            }}
            fullWidth
          >
            {roleArray.map((x) => (
              <MenuItem key={`idRole-${x.idRole}`} value={x.idRole}>
                {x.Role}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label='Benutzername'
            size='small'
            value={userEdit.Login}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUserEdit({ ...userEdit, Login: event.target.value })
            }
            error={userEdit.Login === ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            label='Deaktiviert'
            control={
              <Checkbox
                checked={userEdit.isBlocked}
                onChange={() => {
                  if (userEdit.idUser === currentUserId) {
                    showAlert('Sie können sich nicht selbst deaktivieren.')
                  } else if (roleChange && !userEdit.isBlocked) {
                    
                    showAlert(
                      'Die Rolle des Users wurde geändert. Bitte speichern Sie die Änderungen bevor Sie den User deaktivieren.'
                    )
                  } else {
                    if (!checkNotaryAndAdminExists() && !userEdit.isBlocked) {
                      showAlert('Es muss mindestens ein Notar und ein Admin aktiv.')
                    } else if (checkIsGroupAdmin() && !userEdit.isBlocked) {
                      showAlert('Der User ist Gruppenadmin einer Nutzergruppe und kann nicht deaktiviert werden.')
                    } else if (checkIfLastUserInGroup() && !userEdit.isBlocked) {
                      showAlert(
                        'Der User ist der letzte User in einer Gruppe und kann nicht deaktiviert werden. Bitte löschen Sie vorher die Nutzergruppe.'
                      )
                    } else {
                      setUserDeactivated(!userEdit.isBlocked)
                      setUserEdit({ ...userEdit, isBlocked: !userEdit.isBlocked })
                    }
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />

          <FormControlLabel
            label='Notar'
            control={
              <Checkbox
                checked={userEdit.isNotary}
                onChange={() => {
                  if (userEdit.isBlocked) {
                    showAlert('Bitte reaktivieren Sie den User bevor Sie einen Rollenwechsel vornehmen.')
                  } else {
                    if (checkNotaryExists()) {
                      setRoleChange()
                      setUserEdit({ ...userEdit, isNotary: !userEdit.isNotary })
                    } else {
                      showAlert('Es muss mindestens ein Notar exisitieren.')
                    }
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />

          <FormControlLabel
            label='Darf einmalig eigene Stammdaten verändern'
            control={
              <Checkbox
                checked={userEdit.isUserEditEnabled}
                onChange={() => setUserEdit({ ...userEdit, isUserEditEnabled: !userEdit.isUserEditEnabled })}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            type='email'
            label='E-Mail-Adresse'
            size='small'
            value={userEdit.Email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUserEdit({ ...userEdit, Email: event.target.value })
            }
            error={userEdit.Email === ''}
            fullWidth
          />
        </Grid>
      </Grid>

      <UserSetPassword
        key={`pw-idUser-${userEdit.idUser}`}
        userObject={userEdit}
        setUserObject={setUserEdit}
        enableSetGenPw={userEdit.idUser < 0}
        setIsValid={setIsPwValidated}
      />
    </>
  )
}
