import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { FederalStatesTab } from './FederalStates/FederalStatesTab';
import { CourtRolesTab } from './CourtRoles/CourtRolesTab';
import { CourtsTab } from './Courts/CourtsTab';
import { AdminUnitsTab } from './AdminUnits/AdminUnitsTab';

export const AemterMain: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: 'Gerichte',
      component: <CourtsTab />,
    },
    {
      label: 'Verwaltungseinheiten',
      component: <AdminUnitsTab />,
    },
    {
      label: 'Gerichtsrollen',
      component: <CourtRolesTab />,
    },
    {
      label: 'Bundesländer',
      component: <FederalStatesTab />,
    },
  ];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newTabIndex: number) => setTabIndex(newTabIndex)}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabs[tabIndex].component}
    </>
  );
};
