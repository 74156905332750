import { Add, ArrowDownward, ArrowUpward, Gavel } from '@mui/icons-material';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  IAdminstrativeUnit,
  ICourtAdminstrativeUnitAssignment,
  ICourtCourtRole,
  IFederalState,
} from '../../../Interfaces/IAemter';
import { ILegalPhaseFullObject } from '../../../Interfaces/ILegalPhase';
import {
  ILegalRequirement,
  ILegalRequirementFullObject,
} from '../../../Interfaces/ILegalRequirement';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../../../Interfaces/ILegalRequirementTemplate';
import { LegalPhaseCopyRequirement } from '../../../LegalTransactionType/LegalPhaseCopyRequirement';
import { ConfirmDelete } from '../../../core/ConfirmDelete';
import { PopupAlert } from '../../../core/PopupAlert';
import { getFetch } from '../../../hooks/useFetch';
import { LegalPhaseAddAdminUnit } from '../LegalPhaseAddAdminUnit';
import { LegalPhaseDialogEdit } from './LegalPhaseDialogEdit';
import { LegalRequirementRow } from './LegalRequirementRowEdit';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';

interface IProps {
  currentPhase: ILegalPhaseFullObject;
  legalPhaseArray: ILegalPhaseFullObject[];
  setLegalPhaseArray: Function;
  legalRequirementTemplateArray: ILegalRequirementTemplateFullObject[];
  adminUnitArray: IAdminstrativeUnit[];
  federalStateArray: IFederalState[];
  courtcourtroleArray: ICourtCourtRole[];
  legaltransactionId: number;
}

export const LegalPhaseRowEdit: React.FC<IProps> = props => {
  const [currentPhaseEdit, setCurrentPhaseEdit] = useState(props.currentPhase);
  const [currentPreconditionArray, setCurrentPreconditionArray] = useState(
    props.currentPhase.PreconditionArray
  );

  const [openAdminUnitDialog, setOpenAdminUnitDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [
    courtAdminstrativeUnitAssignmentArray,
    setCourtAdminstrativeUnitAssignmentArray,
  ] = useState<ICourtAdminstrativeUnitAssignment[]>([]);
  //
  //const [isOpenEdit, setIsOpenEdit] = useState(props.currentPhase.idLegalPhase < 0 && props.currentPhase.LegalPhase === "");

  const handleAddNewReq = () => {
    let tmpId = -1;

    if (props.currentPhase.LegalRequirementArray.length > 0) {
      let tmpIdArry = Math.min(
        ...props.currentPhase.LegalRequirementArray.map(
          x => x.idLegalRequirement
        )
      );

      if (tmpIdArry <= tmpId) {
        tmpId = tmpIdArry - 1;
      }
    }

    let tmpObject = { ...props.currentPhase };
    tmpObject.LegalRequirementArray.push({
      idLegalPhase: -1,
      idLegalRequirement: tmpId,
      canUploadFilesClient: false,
      canUploadFilesEmployees: false,
      hasError: false,
      hasErrorFreeText: false,
      LegalRequirement: '',
      sendMail: false,
      EmailBody: null,
      EmailSubject: null,
      Predecessor_idLegalRequirement: null,
      TextOfError: null,
      Position: props.currentPhase.LegalRequirementArray.length === 0 ? 1 : Math.max(...props.currentPhase.LegalRequirementArray.map(x => x.Position))+1,
      enableError: false,
      enableFreeTextError: false,
      isComplete: false,
      idLegalRequirementState: 10,
      User_idUser: null,
      InfoText: null,
      initialProcessingTime: 14,
      ManualProcessingTime: false,
      UserDeadlineNotified: false,
    } as ILegalRequirementFullObject);

    props.setLegalPhaseArray([
      ...props.legalPhaseArray.map(x =>
        x.idLegalPhase === tmpObject.idLegalPhase ? tmpObject : x
      ),
    ]);
  };

  const updateArry = (localLegalReqArray: ILegalRequirement[]) => {
    let tmpObject = { ...props.currentPhase };
    tmpObject.LegalRequirementArray = localLegalReqArray;
    props.setLegalPhaseArray([
      ...props.legalPhaseArray.map(x =>
        x.idLegalPhase === tmpObject.idLegalPhase ? tmpObject : x
      ),
    ]);
  };

  const handleTemplateToRequirement = (
    localLegalReqArray: ILegalRequirementTemplateFullObject[]
  ) => {
    let tmpArray: ILegalRequirementFullObject[] = [];
    let maxPosition =
      Math.max(
        ...props.currentPhase.LegalRequirementArray.map(x => x.Position)
      ) + 1;
    localLegalReqArray.map((x, i) => {
      let newRequirement = {
        ...x,
        idLegalPhase: props.currentPhase.idLegalPhase,
        idLegalRequirement: x.idLegalRequirementTemplate,
        LegalRequirement: x.LegalRequirementTemplate,
        enableError: x.hasError,
        enableFreeTextError: x.hasErrorFreeText,
        isComplete: false,
        User_idUser: null,
        Position: maxPosition + i,
        ManualProcessingTime: false,
        idLegalRequirementTemplate: x.currentProcessingTime,
        initialProcessingTime: x.initialProcessingTime,
        UserDeadlineNotified: false,
      } as ILegalRequirementFullObject;
      const newDeadlineArray =
        x.DeadlineArray?.map(y => {
          if (y.idLegalRequirementTemplate) {
            return (
              props.legalPhaseArray
                .filter(
                  legalPhase =>
                    legalPhase.Position <= props.currentPhase.Position
                )
                .map(legalPhase =>
                  legalPhase.LegalRequirementArray.filter(
                    z =>
                      z.idLegalRequirementTemplate ===
                      y.idLegalRequirementTemplate
                  ).map(z => {
                    return {
                      idLegalRequirement: z.idLegalRequirement,
                      idLegalRequirementState: y.idLegalRequirementState,
                      idLegalTransaction: props.legaltransactionId,
                    } as ILegalRequirementDeadline;
                  })
                )
                .flat() || null
            ).flat();
          } else if (y.idLegalTransactionState) {
            return {
              idLegalRequirementState: y.idLegalRequirementState,
              idLegalTransaction: props.legaltransactionId,
            } as ILegalRequirementDeadline;
          } else {
            return null;
          }
        }) || [];
      //remove null values from array
      
      newRequirement.DeadlineArray = newDeadlineArray
        .flat()
        .filter(x => x) as ILegalRequirementDeadline[];
      tmpArray.push(newRequirement);
    });

    updateArry([...props.currentPhase.LegalRequirementArray, ...tmpArray]);
  };

  const isFirstDisabled = () => {
    let currentIndex: number = props.legalPhaseArray
      .map(x => x.idLegalPhase)
      .indexOf(props.currentPhase.idLegalPhase);
    return currentIndex === 0;
  };
  const isLastDisabled = () => {
    let currentIndex: number = props.legalPhaseArray
      .map(x => x.idLegalPhase)
      .indexOf(props.currentPhase.idLegalPhase);
    return currentIndex === props.legalPhaseArray.length - 1;
  };

  /*
    const getUp = () => {
        let copyOfArray = [...props.legalPhaseArray];
        let targetPositionn = currentPhaseEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position-1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(currentPhaseEdit.idLegalPhase);
            let targetIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(toChangeObject.idLegalPhase);
            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position-1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position+1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseArray(copyOfArray)
        }        
    }
    */
  const getUp = () => {
    let targetObject = props.legalPhaseArray.find(
      x => x.Position === currentPhaseEdit.Position - 1
    );

    if (targetObject === undefined) {
    } else {
      targetObject.Position = targetObject.Position + 1;
      currentPhaseEdit.Position = currentPhaseEdit.Position - 1;

      props.setLegalPhaseArray([
        ...props.legalPhaseArray.map(x =>
          x.idLegalPhase === targetObject?.idLegalPhase
            ? targetObject
            : x.idLegalPhase === currentPhaseEdit.idLegalPhase
              ? currentPhaseEdit
              : x
        ),
      ]);
    }
  };

  const getDown = () => {
    let targetObject = props.legalPhaseArray.find(
      x => x.Position === currentPhaseEdit.Position + 1
    );

    if (targetObject === undefined) {
    } else {
      targetObject.Position = targetObject.Position - 1;
      currentPhaseEdit.Position = currentPhaseEdit.Position + 1;

      props.setLegalPhaseArray([
        ...props.legalPhaseArray.map(x =>
          x.idLegalPhase === targetObject?.idLegalPhase
            ? targetObject
            : x.idLegalPhase === currentPhaseEdit.idLegalPhase
              ? currentPhaseEdit
              : x
        ),
      ]);
    }
  };

  const handleAdminUnitChange = useCallback(
    (newAdminUnit: IAdminstrativeUnit | null) => {
      if (newAdminUnit === null) {
        let tmpObject = props.currentPhase;
        tmpObject.idAdministrativeUnit = null;
        setCurrentPhaseEdit(tmpObject);
      } else {
        if (courtAdminstrativeUnitAssignmentArray.length > 0) {
          handleAdminUnitAssignmentAfterChange(
            courtAdminstrativeUnitAssignmentArray,
            newAdminUnit
          );
        } else {
          getFetch(
            '/courtadminstrativeunitassignment/getbyadminunit/',
            newAdminUnit.idAdministrativeUnit,
            (adminUnitAssignment: ICourtAdminstrativeUnitAssignment[]) => {
              setCourtAdminstrativeUnitAssignmentArray(adminUnitAssignment);
              handleAdminUnitAssignmentAfterChange(
                adminUnitAssignment,
                newAdminUnit
              );
            },
            (sucess: boolean) => setShowAlert(!sucess)
          );
        }
      }
    },
    [props]
  );

  const handleAdminUnitAssignmentAfterChange = useCallback(
    (
      adminUnitAssignment: ICourtAdminstrativeUnitAssignment[],
      newAdminUnit: IAdminstrativeUnit
    ) => {
      let tmpObject = props.currentPhase;
      tmpObject.idAdministrativeUnit = newAdminUnit.idAdministrativeUnit;
      tmpObject.AdministrativeUnit = newAdminUnit.AdministrativeUnit;
      tmpObject.LegalRequirementArray.forEach(x => {
        let tmpCourt = adminUnitAssignment.find(
          y => y.idCourtRole === x.idCourtRole
        );
        if (tmpCourt !== undefined) {
          x.idCourt = tmpCourt.idCourt;
          x.Court = tmpCourt.Court;
        }
      });
      setCurrentPhaseEdit(tmpObject);
    },
    [props]
  );

  /*
        let copyOfArray = [...props.legalPhaseArray];
        let targetPositionn = currentPhaseEdit.Position+1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position+1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(currentPhaseEdit.idLegalPhase);
            let targetIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(toChangeObject.idLegalPhase);

            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position+1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position-1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseArray(copyOfArray)
        }
        */

  const handleDelete = () => {
    props.setLegalPhaseArray([
      ...props.legalPhaseArray.filter(
        x => x.idLegalPhase !== props.currentPhase.idLegalPhase
      ),
    ]);
  };

  useEffect(() => {
    props.setLegalPhaseArray([
      ...props.legalPhaseArray.map(x =>
        x.idLegalPhase === currentPhaseEdit.idLegalPhase ? currentPhaseEdit : x
      ),
    ]);
  }, [currentPhaseEdit]);

  return (
    <>
      <PopupAlert
        text='Fehler beim hinterlegen der Gemarkung. Bitte versuchen Sie es erneut.'
        show={showAlert}
        setShow={setShowAlert}
        severity='error'
      />
      <TableRow>
        <TableCell colSpan={2}>{props.currentPhase.LegalPhase}</TableCell>
        <TableCell>
          {props.currentPhase.PreconditionArray.map(x =>
            props.legalPhaseArray
              .filter(y => y.idLegalPhase === x.idLegalPhase)
              .map(y => (
                <>
                  <Typography sx={{ mr: 3 }} variant='caption'>
                    {y.LegalPhase}
                  </Typography>
                  <br />
                </>
              ))
          )}
        </TableCell>
        <TableCell>
          <Typography sx={{ mr: 3 }} variant='caption'>
            {props.currentPhase.AdministrativeUnit}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ float: 'right' }}
            size='small'
            disabled={isLastDisabled()}
            onClick={getDown}
          >
            <ArrowDownward />
          </IconButton>

          <IconButton
            sx={{ float: 'right' }}
            size='small'
            disabled={isFirstDisabled()}
            onClick={getUp}
          >
            <ArrowUpward />
          </IconButton>

          <IconButton
            sx={{ float: 'right' }}
            size='small'
            onClick={handleAddNewReq}
          >
            <Add />
          </IconButton>

          <LegalPhaseCopyRequirement
            idLegalPhaseTemplate={props.currentPhase.idLegalPhase}
            startId={Math.min(
              ...props.currentPhase.LegalRequirementArray.map(
                x => x.idLegalRequirement
              )
            )}
            setArray={handleTemplateToRequirement}
            legalRequirementTemplateArray={props.legalRequirementTemplateArray}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 150 }}>
          <LegalPhaseDialogEdit
            currentPhase={props.currentPhase}
            currentPhaseEdit={currentPhaseEdit}
            setCurrentPhaseEdit={setCurrentPhaseEdit}
            legalPhaseArray={props.legalPhaseArray}
            setLegalPhaseArray={props.setLegalPhaseArray}
          />
          <Tooltip title='Gemarkung zur Phase hinzufügen. Dies weißt alle Ämter, welche in den Maßnahmen der Phase definiert sind, automatisch den zuständigen Ämtern der Phase zu.'>
            <IconButton
              size='small'
              onClick={() => setOpenAdminUnitDialog(true)}
            >
              <Gavel />
            </IconButton>
          </Tooltip>
          <ConfirmDelete
            itemText='die aktulle Phase und deren Bedingungen'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>

      {props.currentPhase.LegalRequirementArray.sort((a, b) =>
        a.Position < b.Position ? -1 : 1
      ).map(x => (
        <LegalRequirementRow
          key={`idLegalRequirement-${x.idLegalRequirement}`}
          currentRequirement={x}
          legalRequirementArray={props.currentPhase.LegalRequirementArray}
          setLegalRequirementArray={updateArry}
          courtcourtroleArray={props.courtcourtroleArray}
          legaltransactionId={props.legaltransactionId}
        />
      ))}
      <LegalPhaseAddAdminUnit
        open={openAdminUnitDialog}
        currentAdminUnit={props.adminUnitArray.find(
          x =>
            x.idAdministrativeUnit === props.currentPhase.idAdministrativeUnit
        )}
        adminUnitArray={props.adminUnitArray}
        federalStateArray={props.federalStateArray}
        onClose={() => setOpenAdminUnitDialog(false)}
        onSave={handleAdminUnitChange}
      />
    </>
  );
};
