import React, { useEffect, useMemo, useRef } from "react";
import { IAlkisResult } from "../CustomeWMSLayer";
import { LatLngExpression } from "leaflet";
import { Marker, Popup, useMap } from "react-leaflet";
import { Grid } from "@mui/material";


export const MPMarker:React.FC<{posMarker: LatLngExpression, triggerMarkerPopup:number, alkisRestultObject: IAlkisResult | null}> = ({ posMarker, triggerMarkerPopup, alkisRestultObject}) => {
    let popupRef = useRef<L.Popup|null>(null);
    let markerRef = useRef<L.Marker|null>(null);
    const map = useMap();


    useEffect(() => {
        if (triggerMarkerPopup > 0) {
            popupRef.current?.openOn(map);
            //popupRef.current?.openPopup();
            //
        }
    },[triggerMarkerPopup])

    const eventHandlers = useMemo(
        () => ({
          mouseover() {
            if (markerRef) markerRef.current?.openPopup();
          },
          /*mouseout() {
            if (markerRef) markerRef.current?.closePopup();
          }*/
        }),
        []
    );


    return(
        <Marker position={posMarker} key="main-marker" ref={markerRef}   eventHandlers={eventHandlers}>
            <Popup minWidth={500} maxWidth={500} ref={popupRef}>
                <Grid container>
                    <Grid item xs={3}>
                        Gemarkung
                    </Grid>
                    <Grid item xs={9}>
                        {alkisRestultObject?.Marking}
                    </Grid>

                    <Grid item xs={3}>
                        Flur
                    </Grid>
                    <Grid item xs={9}>
                        {alkisRestultObject?.CadastralDistrict}
                    </Grid>

                    <Grid item xs={3}>
                        Flurstück
                    </Grid>
                    <Grid item xs={9}>
                        {alkisRestultObject?.CadastralUnit}
                    </Grid>

                    <Grid item xs={3}>
                        Lage
                    </Grid>
                    <Grid item xs={9}>
                        {alkisRestultObject?.Location}
                    </Grid>

                    <Grid item xs={3}>
                        Amt. Größe
                    </Grid>
                    <Grid item xs={9}>
                        {alkisRestultObject?.Area}
                    </Grid>
                </Grid>
            </Popup>
        </Marker>
    )
}
