import React, { useEffect, useState } from 'react';
import './App.css';
import { Alert, ThemeProvider, createTheme } from '@mui/material';
import SecurityDriver from './core/SecurityDriver';
import { PageRouter } from './core/PageRouter';
import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import { useFetch } from './hooks/useFetch';
import { IConfig } from './Interfaces/IConfig';
import { CustomCircularProgress } from './generic/CustomCircularProgress';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlertProvider } from './context/AlertContext';

function App() {
  const queryClient = new QueryClient();
  const [config, setConfig, wasSuccessfullyConfig] = useFetch<
    IConfig | undefined
  >('/config/public');
  //
  const [theme, setTheme] = useState(
    createTheme(
      {
        palette: {
          primary: {
            main: '#AF9F66',
          },
          secondary: {
            main: '#504C48',
          },
          text: {
            primary: '#504C48',
          },
        },
      },
      deDE, // x-date-pickers translations
      dataGridDeDE, // x-data-grid translations
      coreDeDE // core translations
    )
  );

  useEffect(() => {
    if (config !== undefined) {
      setTheme(
        createTheme(
          {
            palette: {
              primary: {
                main: config.ci_color_primary,
              },
              secondary: {
                main: config.ci_color_secondary,
              },
              text: {
                primary: config.ci_color_text,
              },
            },
          },
          deDE, // x-date-pickers translations
          dataGridDeDE, // x-data-grid translations
          coreDeDE // core translations
        )
      );

      var link = document.createElement('link');

      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = `data:image/x-icon;base64,${config.favicon}`;

      var oldLink = document.getElementById('dynamic-favicon');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
      document.title = `DiNo - ${config.company_name}`;
    }
  }, [config]);

  if (!wasSuccessfullyConfig) {
    return (
      <Alert severity='warning'>
        Die Lizenz von DiNo konnte nicht überprüft werden!
      </Alert>
    );
  } else if (config === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <SecurityDriver configObject={config}>
              <PageRouter configObject={config} />
            </SecurityDriver>
          </AlertProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
