import React, { useState, useMemo } from 'react';
import {
  IconButton,
  TextField,
  Select,
  MenuItem,
  type SelectChangeEvent,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { Clear, Delete } from '@mui/icons-material';
import { ICourt } from '../../Interfaces/IAemter';
import {
  DataGrid,
  GridActionsCellItem,
  type GridColDef,
} from '@mui/x-data-grid';

interface CourtsListProps {
  courts: ICourt[];
  onCourtClick: (court: ICourt) => void;
  onDelete: (court: ICourt) => void;
}

export const CourtsList: React.FC<CourtsListProps> = ({
  courts,
  onCourtClick,
  onDelete,
}) => {
  const federalStates = useMemo(
    () =>
      [...Array.from(new Set(courts.map(court => court.FederalState)))].sort(),
    [courts]
  );

  const [federalState, setFederalState] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCourts = useMemo(() => {
    let result: ICourt[] = courts;
    if (federalState !== '') {
      result = result.filter(court => court.FederalState === federalState);
    }
    if (searchTerm !== '') {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      result = result.filter(court =>
        court.Court.toLowerCase().includes(lowerCaseSearchTerm)
      );
    }
    return result;
  }, [courts, federalState, searchTerm]);

  const columns: GridColDef<ICourt>[] = [
    { field: 'Court', headerName: 'Gericht', width: 250 },
    { field: 'FederalState', headerName: 'Bundesland', width: 200 },
    { field: 'XJustizID', headerName: 'ID', width: 90 },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          icon={<Delete />}
          label='Löschen'
          onClick={() =>
            onDelete(courts.find(court => court.idCourt === params.id)!)
          }
        />,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          sx={{ flexGrow: 1, mr: 2 }}
          label='Nach Gericht suchen'
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {searchTerm && (
                  <IconButton
                    aria-label='clear search'
                    onClick={() => setSearchTerm('')}
                    edge='end'
                  >
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        <FormControl
          variant='outlined'
          sx={{ flexBasis: '300px', flexGrow: 0, flexShrink: 0 }}
        >
          <InputLabel id='federal-state-label'>Bundesland wählen</InputLabel>
          <Select
            labelId='federal-state-label'
            label='Bundesland wählen'
            value={federalState || ''}
            onChange={(event: SelectChangeEvent<string>) =>
              setFederalState(event.target.value)
            }
          >
            <MenuItem value=''>Alle Bundesländer</MenuItem>
            {federalStates.map(state => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={filteredCourts}
        columns={columns}
        getRowId={row => row.idCourt}
        onRowClick={row => {
          onCourtClick(courts.find(court => court.idCourt === row.id)!);
        }}
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
      />
    </>
  );
};
