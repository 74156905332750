import React, { useMemo, useState } from 'react';
import { Clear, Delete } from '@mui/icons-material';
import { ICourtRole } from '../../Interfaces/IAemter';
import {
  DataGrid,
  GridActionsCellItem,
  type GridColDef,
} from '@mui/x-data-grid';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface CourtsListProps {
  courtRoles: ICourtRole[];
  onDelete: (court: ICourtRole) => void;
}

export const CourtRoleList: React.FC<CourtsListProps> = ({
  courtRoles,
  onDelete,
}) => {
  const sortedCourtRoles = [...courtRoles].sort((a, b) =>
    a.CourtRole.localeCompare(b.CourtRole)
  );

  const [searchTerm, setSearchTerm] = useState('');

  const filteredCourtRoles = useMemo(() => {
    let result: ICourtRole[] = sortedCourtRoles;

    if (searchTerm) {
      result = result.filter(courtRole =>
        courtRole.CourtRole.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return result;
  }, [sortedCourtRoles, searchTerm]);

  const columns: GridColDef<ICourtRole>[] = [
    { field: 'CourtRole', headerName: 'Rolle', width: 250 },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          icon={<Delete />}
          label='Löschen'
          onClick={() =>
            onDelete(
              sortedCourtRoles.find(court => court.idCourtRole === params.id)!
            )
          }
        />,
      ],
    },
  ];

  return (
    <>
      <TextField
        sx={{ mb: 2 }}
        label='Nach Rolle suchen'
        value={searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(event.target.value)
        }
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {searchTerm && (
                <IconButton
                  aria-label='clear search'
                  onClick={() => setSearchTerm('')}
                  edge='end'
                >
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <DataGrid
        rows={filteredCourtRoles}
        columns={columns}
        getRowId={row => row.idCourtRole}
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </>
  );
};
