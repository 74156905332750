import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { ArrowDownward, ArrowUpward, Edit } from '@mui/icons-material';
import {
  ILegalRequirement,
  ILegalRequirementFullObject,
} from '../../../Interfaces/ILegalRequirement';
import { ConfirmDelete } from '../../../core/ConfirmDelete';
import { LegalRequirementEdit } from './LegalRequirementEdit';
import { ICourtCourtRole } from '../../../Interfaces/IAemter';

interface IProps {
  currentRequirement: ILegalRequirementFullObject;
  legalRequirementArray: ILegalRequirement[];
  setLegalRequirementArray: Function;
  courtcourtroleArray: ICourtCourtRole[];
  legaltransactionId: number;
}

const IcButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}));

export const LegalRequirementRow: React.FC<IProps> = props => {
  const [currentRequirementEdit, setCurrentRequirementEdit] = useState(
    props.currentRequirement
  );
  //
  const [isOpenEdit, setIsOpenEdit] = useState(
    props.currentRequirement.idLegalRequirement < 0 &&
      props.currentRequirement.LegalRequirement === ''
  );

  const saveReq = () => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.map(x =>
        x.idLegalRequirement === currentRequirementEdit.idLegalRequirement
          ? currentRequirementEdit
          : x
      ),
    ]);
    setIsOpenEdit(false);
  };

  const isFirstDisabled = () => {
    let currentIndex: number = props.legalRequirementArray
      .map(x => x.idLegalRequirement)
      .indexOf(props.currentRequirement.idLegalRequirement);

    return currentIndex === 0;
  };
  const isLastDisabled = () => {
    let currentIndex: number = props.legalRequirementArray
      .map(x => x.idLegalRequirement)
      .indexOf(props.currentRequirement.idLegalRequirement);

    return currentIndex === props.legalRequirementArray.length - 1;
  };
  const isDeadlinePrequisiteAbove = useCallback(() => {
    const filteredRequirements =
      props.currentRequirement.DeadlineArray?.filter(x => {
        return (
          x.idLegalTransaction === props.legaltransactionId &&
          x.idLegalRequirement != null &&
          props.legalRequirementArray.some(
            y =>
              y.idLegalRequirement === x.idLegalRequirement &&
              y.Position === props.currentRequirement.Position - 1 &&
              y.idLegalPhase === props.currentRequirement.idLegalPhase
          )
        );
      }) || [];
    return filteredRequirements.length !== 0;
  }, [
    props.currentRequirement,
    props.legalRequirementArray,
    props.legaltransactionId,
  ]);

  const isDeadlinePrequisiteBelow = useCallback(() => {
    const filteredRequirements =
      props.currentRequirement.DeadlineArray?.filter(x => {
        return (
          x.idLegalTransaction === props.legaltransactionId &&
          x.idLegalRequirement != null &&
          props.legalRequirementArray.some(
            y =>
              y.idLegalRequirement === x.idLegalRequirement &&
              y.Position === props.currentRequirement.Position + 1 &&
              y.idLegalPhase === props.currentRequirement.idLegalPhase
          )
        );
      }) || [];

    return filteredRequirements.length !== 0;
  }, [
    props.currentRequirement.DeadlineArray,
    props.currentRequirement.Position,
    props.currentRequirement.idLegalPhase,
    props.legalRequirementArray,
    props.legaltransactionId,
  ]);

  /*
    const getUp = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let
            
            toChangeObject.Position = currentRequirementEdit.Position;
            currentObject.Position = currentRequirementEdit.Position-1;
            //copyOfArray.map(x => x.idLegalRequirement === toChangeObject!.idLegalRequirement ? toChangeObject : x );
            copyOfArray.map(x => 
                x.idLegalRequirement === currentObject!.idLegalRequirement ? currentObject :
                (x.idLegalRequirement === toChangeObject?.idLegalRequirement) ? toChangeObject : x );
            
            props.setLegalRequirementArray(copyOfArray)
        }
        
        
    }
    */

  const getUp = () => {
    let copyOfArray = [...props.legalRequirementArray];
    let targetPositionn = currentRequirementEdit.Position - 1;
    let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
    let currentObject = { ...currentRequirementEdit };

    if (toChangeObject !== undefined) {
      let currentIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirement)
        .indexOf(currentObject.idLegalRequirement);
      let targetIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirement)
        .indexOf(toChangeObject.idLegalRequirement);

      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position - 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position + 1;

      props.setLegalRequirementArray(copyOfArray);
    }
  };

  const getDown = () => {
    let copyOfArray = [...props.legalRequirementArray];
    let targetPositionn = currentRequirementEdit.Position + 1;
    let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
    let currentObject = { ...currentRequirementEdit };

    if (toChangeObject !== undefined) {
      let currentIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirement)
        .indexOf(currentObject.idLegalRequirement);
      let targetIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirement)
        .indexOf(toChangeObject.idLegalRequirement);

      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position + 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position - 1;

      props.setLegalRequirementArray(copyOfArray);
    }
  };

  const handleDelete = () => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.filter(
        x =>
          x.idLegalRequirement !== props.currentRequirement.idLegalRequirement
      ),
    ]);
  };

  return (
    <>
      <Dialog open={isOpenEdit} onClose={() => {}} maxWidth='lg' fullWidth>
        <DialogTitle>Voraussetzung anpassen</DialogTitle>
        <DialogContent>
          <LegalRequirementEdit
            currentRequirement={currentRequirementEdit}
            setCurrentRequirement={setCurrentRequirementEdit}
            legalRequirements={props.legalRequirementArray}
            courtcourtroleArray={props.courtcourtroleArray}
            legaltransactionId={props.legaltransactionId}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenEdit(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={saveReq}
            disabled={currentRequirementEdit.LegalRequirement === ''}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell sx={{ width: 20 }}></TableCell>
        <TableCell>{props.currentRequirement.LegalRequirement}</TableCell>

        <TableCell></TableCell>
        <TableCell></TableCell>

        {/*
                <TableCell>
                    {props.legalRequirementArray
                        .filter(x => x.idLegalRequirement === currentRequirementEdit.P)
                        .map(x =>
                            <Typography sx={{mr: 3}} variant="caption">{x.LegalRequirement}</Typography>
                        )
                    }
                </TableCell>
                */}

        <TableCell>
          <Tooltip
            title={
              isLastDisabled()
                ? 'Die Maßnahme ist die letzte Maßnahme dieser Phase und kann nicht weiter nach unten verschoben werden.'
                : isDeadlinePrequisiteBelow()
                  ? 'Die Maßnahme unter dieser Maßnahme hat diese als Startbedinung, daher kann diese Maßnahme nicht weiter nach unten verschoben werden.'
                  : 'Verschiebt die Maßnahme eine Position nach unten.'
            }
          >
            <span>
              <IcButton
                sx={{ float: 'right' }}
                size='small'
                disabled={isLastDisabled() || isDeadlinePrequisiteBelow()}
                onClick={getDown}
              >
                <ArrowDownward />
              </IcButton>
            </span>
          </Tooltip>
          <Tooltip
            disableInteractive
            title={
              isFirstDisabled()
                ? 'Die Maßnahme ist die erste Maßnahme dieser Phase und kann nicht weiter nach oben verschoben werden.'
                : isDeadlinePrequisiteAbove()
                  ? 'Die Maßnahme hat eine Maßnahme als Startbedinung, die vor dieser Maßnahme liegt und kann daher nicht weiter nach oben verschoben werden.'
                  : 'Verschiebt die Maßnahme eine Position nach oben.'
            }
          >
            <span>
              <IcButton
                sx={{ float: 'right' }}
                size='small'
                disabled={isFirstDisabled() || isDeadlinePrequisiteAbove()}
                onClick={getUp}
              >
                <ArrowUpward />
              </IcButton>
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesClient ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesEmployees ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.sendMail ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          <IconButton
            size='small'
            onClick={() => {
              setCurrentRequirementEdit(props.currentRequirement);
              setIsOpenEdit(true);
            }}
          >
            <Edit />
          </IconButton>
          <ConfirmDelete
            itemText='die aktulle Bedingung'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
