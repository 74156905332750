import { useCallback, useEffect, useState } from 'react';
import { IAdminstrativeUnit, IFederalState } from '../../Interfaces/IAemter';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface LegalPhaseAddAdminUnitProps {
  currentAdminUnit?: IAdminstrativeUnit;
  adminUnitArray: IAdminstrativeUnit[];
  federalStateArray: IFederalState[];
  onSave: (newAdminUnit: IAdminstrativeUnit | null) => void;
  open: boolean;
  onClose: () => void;
}

export const LegalPhaseAddAdminUnit: React.FC<LegalPhaseAddAdminUnitProps> = ({
  currentAdminUnit,
  adminUnitArray,
  federalStateArray,
  onSave,
  open,
  onClose,
}) => {
  const [adminUnitId, setAdminUnitId] = useState<number | ''>('');
  const [federalStateId, setFederalStateId] = useState<number | ''>('');

  useEffect(() => {
    if (open) {
      if (currentAdminUnit) {
        setAdminUnitId(currentAdminUnit.idAdministrativeUnit);
        setFederalStateId(currentAdminUnit.idFederalState);
      } else {
        setAdminUnitId('');
        setFederalStateId('');
      }
    } else {
      setAdminUnitId('');
      setFederalStateId('');
    }
  }, [currentAdminUnit, open]);

  const handeFederalStateChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      setFederalStateId(e.target.value === '' ? '' : Number(e.target.value));
      setAdminUnitId('');
    },
    []
  );

  const handleSave = useCallback(() => {
    if (adminUnitId !== '' && federalStateId !== '') {
      const newAdminUnit = adminUnitArray.find(
        adminUnit => adminUnit.idAdministrativeUnit === adminUnitId
      );
      if (!newAdminUnit) return;

      onSave(newAdminUnit);
      onClose();
    } else {
      onSave(null);
      onClose();
    }
  }, [adminUnitId, federalStateId, adminUnitArray, onSave, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Verwaltungseinheit ändern</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} pt={5} pb={5}>
          <FormControl fullWidth>
            <InputLabel>Bundesland</InputLabel>
            <Select
              value={federalStateId}
              onChange={e => handeFederalStateChange(e)}
            >
              <MenuItem value=''>Nichts auswählen</MenuItem>
              {federalStateArray.map(state => (
                <MenuItem
                  key={state.idFederalState}
                  value={state.idFederalState}
                >
                  {state.FederalState}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {federalStateId !== '' && (
            <FormControl fullWidth>
              <InputLabel>Verwaltungseinheit</InputLabel>
              <Select
                value={adminUnitId}
                onChange={e =>
                  setAdminUnitId(
                    e.target.value === '' ? '' : Number(e.target.value)
                  )
                }
              >
                <MenuItem value=''>Nichts auswählen</MenuItem>
                {adminUnitArray
                  .filter(unit => unit.idFederalState === federalStateId)
                  .map(unit => (
                    <MenuItem
                      key={unit.idAdministrativeUnit}
                      value={unit.idAdministrativeUnit}
                    >
                      {unit.AdministrativeUnit}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          onClick={handleSave}
          disabled={adminUnitId === '' && federalStateId !== ''}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};
