import { Box, Checkbox, Grid } from "@mui/material";
import React, { useState } from "react";

interface IProps {
    parentIndex: number;
    ownIndex: number;
    selectArray: boolean[][];
    setSelectArray: Function;
    cellArray: string[];
    currentCell: string;
}



export const DocumentAssistentCell:React.FC<IProps> = ({
    parentIndex,ownIndex,
    selectArray,setSelectArray,
    currentCell,cellArray
})=> {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tmpArray = [...selectArray];
        tmpArray[parentIndex][ownIndex] = event.target.checked;
        setSelectArray(tmpArray); 
    };

    return(
        <Grid item xs={12/cellArray.length} sx={{border: "0.5px solid"}}>
            
                
                <Checkbox
                    sx={{float: "right"}}
                    checked={selectArray[parentIndex][ownIndex]}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            <Box sx={{m: 1}}>
                {currentCell}
            </Box>
            
        </Grid>
    )
}