import React, { useEffect, useRef, useState } from "react";
import { IDocument } from "../../Interfaces/IDocument";
import { Button, Collapse, Dialog, Grid, IconButton } from "@mui/material";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import { getFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { DocumentAssistentRow } from "./DocumentAssistentRow";
import { IDocumentAnalysisType } from "../../Interfaces/IDocumentAnalysisType";
import { IDocumentAnalysisSection } from "../../Interfaces/IDocumentAnalysisSection";
import { IDocumentAnalysisSubsection } from "../../Interfaces/IDocumentAnalysisSubsection";
import { IDocumentAnalysisAttribute } from "../../Interfaces/IDocumentAnalysisAttribute";
import { DocumentAnalysisMain } from "../DocumentAnalysis/DocumentAnalysisMain";
import { IDocumentAnalysisSubsectionEntryFullObject } from "../../Interfaces/IDocumentAnalysisSubsectionEntry";
import { DocumentAssistentValueHelper } from "./DocumentAssistentValueHelper";
import { ArrowBackIos, ArrowForward, ArrowForwardIos } from "@mui/icons-material";

interface IProps {
    isOpen: boolean;
    setIsOpen: Function;
    documentObject: IDocument;
    setDocumentObject: Function;
}

export const DocumentAssistentMain: React.FC<IProps> = ({ isOpen, setIsOpen, documentObject, setDocumentObject }) => {
    const ref = useRef<null | HTMLIFrameElement>(null);
    const [resultArray,setResultArray] = useState<string[][]|null>(null);
    const [wasSuccessfullyUpload,setWasSuccessfullyUpload] = useState(true);
    //
    const [selectArray,setSelectArray] = useState<boolean[][]|null>(null);
    //
    const [documentAnalysisType, setDocumentAnalysisType, wasSuccessfullyDocumentAnalysisType] = useFetch<IDocumentAnalysisType[]>("/documentanalysistype");
    const [documentAnalysisSection, setDocumentAnalysisSection, wasSuccessfullyDocumentAnalysisSection] = useFetch<IDocumentAnalysisSection[]>("/documentanalysissection");
    const [documentAnalysisSubsection, setDocumentAnalysisSubsection, wasSuccessfullyDocumentAnalysisSubsection] = useFetch<IDocumentAnalysisSubsection[]>("/documentanalysissubsection");
    const [documentAnalysisAttribute, setDocumentAnalysisAttribute, wasSuccessfullyDocumentAnalysisAttribute] = useFetch<IDocumentAnalysisAttribute[]>("/documentanalysisattribute");
    //
    const [sectionEntryArray,setSectionEntryArray] = useState<IDocumentAnalysisSubsectionEntryFullObject[]>([]);
    //
    const [isEnableHelper,setIsEnableHelper] = useState(false);
    //
    const [isDataFullView,setIsDataFullView] = useState(true);
    const [isHelpFullView,setIsHelpFullView] = useState(true);
    //
    const [refresh,setRefresh] = useState(0);

    useEffect(() => {
        if (documentObject.Data === undefined) {
            getFetch("/document/", documentObject.idDocument, setDocumentObject);
        }
        else {
            uploadFetch("/document/ocr",true,documentObject, setResultArray,setWasSuccessfullyUpload)
        }
    }, [documentObject.Data]);


    useEffect(() => {
        if (resultArray !== null) {
            let tmpSelectArray:boolean[][] = []
            resultArray.map(currentRow => {
                let tmpRowSelectArray:boolean[] = [];
                currentRow.map(currentCell => tmpRowSelectArray.push(false));
                tmpSelectArray.push(tmpRowSelectArray)
            })
            setSelectArray(tmpSelectArray);
        }
    },[resultArray])


    const handleUploadDocument = () => {
        if (documentObject.Data !== undefined) {
            setResultArray(null);
            uploadFetch("/document/ocr",true,documentObject, setResultArray,setWasSuccessfullyUpload)
        }
    }




    return (
        <>
            <FullScreenDialog
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Dokumentenassistent"
            >
                <Grid container spacing={2}>
                    <Grid item xs={(isDataFullView) ? 4 : 1} style={{ height: '100vh' }}>
                        {documentObject.Data === undefined
                            ? <CustomCircularProgress />
                            : 
                            <>
                                <Collapse orientation="horizontal" in={isDataFullView} collapsedSize={60}>
                                    <IconButton sx={{mb: 2}}  onClick={() => setIsDataFullView(!isDataFullView)}>{isDataFullView ? <ArrowBackIos/> : <ArrowForwardIos/>}</IconButton>

                                </Collapse>
                                {(isDataFullView) &&
                                    <iframe
                                        ref={ref}
                                        src={`data:application/pdf;base64,${documentObject.Data}`}
                                        style={{ width: '100%', height: '100%', border: 'none' }}
                                        title="Document Viewer"
                                    />
                                }
 
                            </>

                        }
                    </Grid>

                    <Grid item xs={(!isHelpFullView) ? 1 : isDataFullView ? 4 : 5 }>
                        {/*<Button onClick={handleUploadDocument}>Upload</Button>*/}
                        { (!wasSuccessfullyUpload)
                            ? <>FEHLER!</>
                            : (resultArray === null || selectArray === null ) ?
                                <>
                                    { (documentObject.Data === undefined)
                                        ? <>Lade Dokument....</>
                                        : <>Interpretiere Dokument....</>

                                    }
                                    <CustomCircularProgress/>
                                </>
                                :
                                <>
                                <Collapse orientation="horizontal" in={isHelpFullView} collapsedSize={60}>
                                    <IconButton sx={{mb: 2}}  onClick={() => setIsHelpFullView(!isHelpFullView)}>{isHelpFullView ? <ArrowBackIos/> : <ArrowForwardIos/>}</IconButton>
                                    <Button sx={{mb: 2, float: "right"}} variant="outlined" onClick={() => setIsEnableHelper(true)}>&gt;&gt;</Button>
                                    <Grid container>
                                        {resultArray.map((x,idx) =>
                                            <Grid item xs={12}>
                                                <DocumentAssistentRow
                                                    key={`ds-row-${idx}-${refresh}`}
                                                    ownIndex={idx}
                                                    selectArray={selectArray}
                                                    setSelectArray={setSelectArray}
                                                    cellArray={x}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Collapse>

                                </>
                        }
                    </Grid>

                    <Grid item xs={(isHelpFullView && isDataFullView) ? 4 : (isHelpFullView || isDataFullView) ? 6 : 10}>
                        { (!wasSuccessfullyDocumentAnalysisType 
                            || !wasSuccessfullyDocumentAnalysisSection
                            || !wasSuccessfullyDocumentAnalysisSubsection
                            || !wasSuccessfullyDocumentAnalysisAttribute
                        )
                            ? <>FEHLER!</>
                            : ( documentAnalysisType === undefined
                                || documentAnalysisSection === undefined
                                || documentAnalysisSubsection === undefined
                                || documentAnalysisAttribute === undefined
                            ) ?
                                <>
                                    <CustomCircularProgress/>
                                </>
                                :
                                <>
                                    <Collapse in={!isEnableHelper}>
                                        <DocumentAnalysisMain
                                            documentAnalysisTypeArray={documentAnalysisType}
                                            documentAnalysisSectionArray={documentAnalysisSection}
                                            documentAnalysisSubsectionArray={documentAnalysisSubsection}
                                            documentAnalysisAttributeArray={documentAnalysisAttribute}
                                            //
                                            sectionEntryArray={sectionEntryArray}
                                            setSectionEntryArray={setSectionEntryArray}
                                        />
                                    </Collapse>
                                    <Collapse in={isEnableHelper}>
                                        {(resultArray !== null && selectArray !== null) &&
                                            <DocumentAssistentValueHelper
                                                isEnableHelper={isEnableHelper}
                                                setIsEnableHelper={setIsEnableHelper}
                                                //
                                                resultArray={resultArray}
                                                selectArray={selectArray}
                                                setSelectArray={setSelectArray}
                                                //
                                                documentAnalysisTypeArray={documentAnalysisType || []}
                                                documentAnalysisSectionArray={documentAnalysisSection || []}
                                                documentAnalysisSubsectionArray={documentAnalysisSubsection || []}
                                                documentAnalysisAttributeArray={documentAnalysisAttribute || []}
                                                //
                                                sectionEntryArray={sectionEntryArray}
                                                setSectionEntryArray={setSectionEntryArray}
                                                //
                                                refreshParent={refresh}
                                                setRefreshParent={setRefresh}
                                            />
                                        }

                                    </Collapse>

                                </>
                        }
                    </Grid>
                </Grid>
            </FullScreenDialog>
        </>
    );
}
