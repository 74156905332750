import React, { useEffect, useState } from "react";
import { IDraftMessage, IDraftMessageFullObject } from "../../Interfaces/IDraftMessage";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IDocument } from "../../Interfaces/IDocument";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { TextModuleSelector } from "../../TextModule/TextModuleSelector";
import { getZeroTimezoneStringFromDate } from "../../core/generic_helper";


interface IProps {
    idDraftMessageParent: number | null;
    setIdDraftMessageParent: Function;
    draftMessageArray: IDraftMessageFullObject[];
    setDraftMessageArray: Function;
}


const getNewMessage = (draftMessageArray:IDraftMessageFullObject[]) => {
    let tmpId = Math.min(...draftMessageArray.map(x => x.idDraftMessage)) -1;

    if (tmpId >= 0) {
        tmpId = - 1;
    }

    return {
        DraftMessage: "",
        idDraftMessage: tmpId,
        idDraftMessageState: 1,
        idLegalTransaction: -1,
        idUser: Number(localStorage.getItem("idUser")),
        idContact: null,
        Partent_idDraftMessage: null,
        DocumentArray: []
    } as IDraftMessageFullObject
}



export const NewDraftMessage:React.FC<IProps> = (props) => {
    const [parentObject, setPrentObject] = useState<IDraftMessageFullObject|undefined>(undefined);
    const [messageObject, setMessageObject] = useState<IDraftMessageFullObject>(getNewMessage(props.draftMessageArray));
    const [documentArray,setDocumentArray] = useState<IDocument[]>([]);
    //
    const [isOpenNew,setIsOpenNew] = useState(false);


    const handleOpen = () => {
        setMessageObject(getNewMessage(props.draftMessageArray));
        (props.idDraftMessageParent !== null) && 
            setPrentObject(props.draftMessageArray.find(x => x.idDraftMessage === props.idDraftMessageParent));
        setIsOpenNew(true);
    }

    const handleClose = () => {
        props.setIdDraftMessageParent(null);
        setPrentObject(undefined);
        setIsOpenNew(false);
    }

    const handleSave = () => {
        let tmpObject = {
            ...messageObject,
            DocumentArray: documentArray,
            Created_at: getZeroTimezoneStringFromDate(new Date()),
            Created_at_locale_time: new Date().toISOString()
        }

        if (parentObject !== undefined) {
            tmpObject.Partent_idDraftMessage = parentObject.idDraftMessage;
        }

        props.setDraftMessageArray([
            ...props.draftMessageArray,
            tmpObject
        ])
        handleClose()
    }


    useEffect(() => {
        if (props.idDraftMessageParent !== null) {
            handleOpen()
        }
    },[props.idDraftMessageParent])



    return(
        <>
            <Dialog
                open={isOpenNew}
                onClose={handleClose}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>{(parentObject === undefined) ? "Neuer Kommentar" : "Antwort zu"}</DialogTitle>
                <DialogContent>
                    { (parentObject !== undefined) && 
                        <>
                        <Paper variant="outlined" sx={{m: 2, backgroundColor: grey[50]}}>
                            <Box sx={{m: 2}}>
                                <i>{parentObject.DraftMessage}</i>
                            </Box>
                        </Paper>
                        </>
                    }

                    <TextModuleSelector 
                        key={`newdraftmessage`}
                        floatRight
                        idTextModuleCategory={2}
                        txt={messageObject.DraftMessage}
                        setTxt={(txt: string) => setMessageObject({...messageObject, DraftMessage: txt})}
                    />
                    <TextField
                        sx={{mt: 2}}
                        label={(parentObject === undefined) ? "Kommentar" : "Antwort"}
                        value={messageObject.DraftMessage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMessageObject({...messageObject, DraftMessage: event.target.value})}
                        size="small"
                        fullWidth
                        rows={5}
                        multiline
                        error={messageObject.DraftMessage === ""}
                    />

                    <Box sx={{mt: 5}}/>
                    <DocumentOverview
                        allowUpload
                        allowRemove
                        documentArray={documentArray}
                        setDocumentArray={setDocumentArray}
                        title="Doumente zum Kommentar"
                    />


                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={messageObject.DraftMessage === ""}>Übernehmen</Button>
                </DialogActions>
            </Dialog>

            <Button variant="outlined" sx={{float: "right"}} onClick={handleOpen}>Kommentar hinzufügen</Button>
        </>
    )
}
