import React, { useEffect, useState } from "react";
import { IDocumentAnalysisSubsectionEntryFullObject } from "../../Interfaces/IDocumentAnalysisSubsectionEntry";
import { IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { DocumentAnalysisAttributeValue } from "./DocumentAnalysisAttributeValue";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    sectionEntry: IDocumentAnalysisSubsectionEntryFullObject;
    sectionEntryArray: IDocumentAnalysisSubsectionEntryFullObject[];
    setSectionEntryArray: Function;
    //
    disableBtn?: boolean;
}

export const DocumentAnalysisSubsectionEntry:React.FC<IProps> = ({sectionEntry,sectionEntryArray,setSectionEntryArray,disableBtn}) => {
    const [editSubsectionEntry,setEditSubsectionEntry] = useState(sectionEntry);
    const [entryValueArray,setEntryValueArray] = useState(sectionEntry.DocumentAnalysisAttributeValueArray);

    useEffect(() => {
        setSectionEntryArray([
            ...sectionEntryArray.map(x => x.idDocumentAnalysisSubsectionEntry === editSubsectionEntry.idDocumentAnalysisSubsectionEntry
                ? {...editSubsectionEntry, DocumentAnalysisAttributeValueArray: entryValueArray}
                : x
            )

        ])
    },[editSubsectionEntry,entryValueArray])

    const handleDelete = () => {
        setSectionEntryArray([
            ...sectionEntryArray.filter(x => x.idDocumentAnalysisSubsectionEntry !== editSubsectionEntry.idDocumentAnalysisSubsectionEntry)
        ])
    }


    return(
        <>
            <TableRow >
                <TableCell sx={{border: "solid", m: 0, p: 0}}>
                    <CustomeTextField
                        attr="isDeleted"
                        label=""
                        object={editSubsectionEntry}
                        setObject={setEditSubsectionEntry}
                        type="boolean"
                    />
                </TableCell>
                {sectionEntry.DocumentAnalysisAttributeValueArray.map(entry =>
                    <DocumentAnalysisAttributeValue
                        key={`subsection-${sectionEntry.idDocumentAnalysisSubsectionEntry}-entryvalue-${entry.idDocumentAnalysisAttributeValue}`}
                        attrValue={entry}
                        attrValueArray={entryValueArray}
                        setAttrValueArray={setEntryValueArray}
                    />
                )}
                <TableCell sx={{border: "solid", m: 0, p: 0}}>
                    {(disableBtn === undefined || disableBtn === false) &&
                        <IconButton onClick={handleDelete}><Delete/></IconButton>
                    }
                </TableCell>
            </TableRow>
        </>
    )
} 
