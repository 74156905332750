import React, { useMemo, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  type SelectChangeEvent,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  type GridColDef,
} from '@mui/x-data-grid';
import { Clear, Delete } from '@mui/icons-material';
import { IAdminstrativeUnit } from '../../Interfaces/IAemter';

interface AdminUnitsListProps {
  adminUnits: IAdminstrativeUnit[];
  onAdminUnitClick: (adminUnit: IAdminstrativeUnit) => void;
  onDelete: (adminUnit: IAdminstrativeUnit) => void;
}

export const AdminUnitsList: React.FC<AdminUnitsListProps> = ({
  adminUnits,
  onAdminUnitClick,
  onDelete,
}) => {
  const federalStates = useMemo(
    () =>
      [
        ...Array.from(new Set(adminUnits.map(unit => unit.FederalState))),
      ].sort(),
    [adminUnits]
  );

  const [federalState, setFederalState] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUnits = useMemo(() => {
    let result: IAdminstrativeUnit[] = adminUnits;
    if (federalState !== '') {
      result = result.filter(court => court.FederalState === federalState);
    }
    if (searchTerm !== '') {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      result = result.filter(unit =>
        unit.AdministrativeUnit.toLowerCase().includes(lowerCaseSearchTerm)
      );
    }
    return result;
  }, [adminUnits, federalState, searchTerm]);

  const columns: GridColDef<IAdminstrativeUnit>[] = [
    {
      field: 'AdministrativeUnit',
      headerName: 'Verwaltungseinheit',
      width: 250,
    },
    { field: 'FederalState', headerName: 'Bundesland', width: 200 },
    { field: 'idAdministrativeUnit', headerName: 'ID', width: 90 },
    {
      field: 'actions',

      type: 'actions',
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          icon={<Delete />}
          label='Löschen'
          onClick={() => onDelete(params.row)}
        />,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          sx={{ flexGrow: 1, mr: 2 }}
          label='Nach Gericht suchen'
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {searchTerm && (
                  <IconButton
                    aria-label='clear search'
                    onClick={() => setSearchTerm('')}
                    edge='end'
                  >
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        <FormControl
          variant='outlined'
          sx={{ flexBasis: '300px', flexGrow: 0, flexShrink: 0 }}
        >
          <InputLabel id='federal-state-label'>Bundesland wählen</InputLabel>
          <Select
            labelId='federal-state-label'
            label='Bundesland wählen'
            value={federalState || ''}
            onChange={(event: SelectChangeEvent<string>) =>
              setFederalState(event.target.value)
            }
          >
            <MenuItem value=''>Alle Bundesländer</MenuItem>
            {federalStates.map(state => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={filteredUnits}
        columns={columns}
        getRowId={row => row.idAdministrativeUnit}
        onRowClick={row => {
          onAdminUnitClick(
            adminUnits.find(unit => unit.idAdministrativeUnit === row.id)!
          );
        }}
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
      />
    </>
  );
};
