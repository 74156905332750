import React, { useState } from "react";
import { IDocumentAnalysisType } from "../../Interfaces/IDocumentAnalysisType";
import { IDocumentAnalysisSection } from "../../Interfaces/IDocumentAnalysisSection";
import { IDocumentAnalysisSubsection } from "../../Interfaces/IDocumentAnalysisSubsection";
import { IDocumentAnalysisAttribute } from "../../Interfaces/IDocumentAnalysisAttribute";
import { Button, Typography } from "@mui/material";
import { DocumentAnalysisSectionView } from "./DocumentAnalysisSectionView";
import { IDocumentAnalysisSubsectionEntryFullObject } from "../../Interfaces/IDocumentAnalysisSubsectionEntry";


interface IProps {
    documentAnalysisTypeArray: IDocumentAnalysisType[];
    documentAnalysisSectionArray: IDocumentAnalysisSection[];
    documentAnalysisSubsectionArray: IDocumentAnalysisSubsection[];
    documentAnalysisAttributeArray: IDocumentAnalysisAttribute[];
    //
    sectionEntryArray: IDocumentAnalysisSubsectionEntryFullObject[];
    setSectionEntryArray: Function;
}


export const DocumentAnalysisMain:React.FC<IProps> = ({
    documentAnalysisTypeArray,documentAnalysisSectionArray,documentAnalysisSubsectionArray,documentAnalysisAttributeArray,
    sectionEntryArray, setSectionEntryArray
})=> {
    const [currentType,setCurrentType] = useState(1);


    return(
        <>
        <Button variant="contained" sx={{float: "right"}}>XML-Export</Button>
        <Typography variant="h4">{documentAnalysisTypeArray.find(x => x.idDocumentAnalysisType === currentType)?.DocumentAnalysisType}</Typography>
        {documentAnalysisSectionArray
            .filter(section => section.idDocumentAnalysisType === currentType)
            .map(section =>
                <DocumentAnalysisSectionView
                    key={`section-view-${section.idDocumentAnalysisSection}`}
                    documentAnalysisSection={section}
                    documentAnalysisSubsectionArray={documentAnalysisSubsectionArray}
                    documentAnalysisAttributeArray={documentAnalysisAttributeArray}
                    //
                    sectionEntryArray={sectionEntryArray}
                    setSectionEntryArray={setSectionEntryArray}
                />
        )}
        </>
    )
}