import React, { useCallback, useEffect, useState } from 'react';
import {
  ILegalPhase,
  ILegalPhaseFullObject,
} from '../../Interfaces/ILegalPhase';
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { LegalPhaseRow } from './LegalPhaseRow';
import { FullScreenDialog } from '../../core/FullScreenDialog';
import { LegalPhaseTableEdit } from './Edit/LegalPhaseTableEdit';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';
import { ILegalRole } from '../../Interfaces/ILegalRole';
import { ILegalRole_canInvite } from '../../Interfaces/ILegalRole_canInvite';
import { IDocumentDownloaded } from '../../Interfaces/IDocumentDownloaded';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../../Interfaces/ILegalRequirementTemplate';
import { getFetch, useFetch } from '../../hooks/useFetch';
import {
  IAdminstrativeUnit,
  ICourtAdminstrativeUnitAssignment,
  ICourtCourtRole,
  IFederalState,
} from '../../Interfaces/IAemter';
import { PopupAlert } from '../../core/PopupAlert';
import { LegalPhaseAddAdminUnit } from './LegalPhaseAddAdminUnit';

interface IProps {
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
  legalReqState: ILegalRequirementState[];
  legalRequirementTemplateArray: ILegalRequirementTemplateFullObject[];
  //
  legalRoleArray: ILegalRole[];
  setLegalRoleArray: Function;
  legalRoleCanInviteArray: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray: Function;
  downloadedArray?: IDocumentDownloaded[];
  legaltransactionId: number;
  startdatedeadline: Date | undefined;
}

export const LegalPhaseMain: React.FC<IProps> = props => {
  const [legalPhaseEditArray, setLegalPhaseEditArray] = useState([
    ...props.legalPhaseFullObjectArray,
  ]);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);

  const [adminUnitArray, setAdminUnitArray, wasSuccessfullyAdminUnitArray] =
    useFetch<IAdminstrativeUnit[]>('/administrativeunit');

  const [
    federalStateArray,
    setFederalStateArray,
    wasSuccessfullyFederalStateArray,
  ] = useFetch<IFederalState[]>('/federalstate');

  const [
    courtcourtRoleArray,
    setCourtCourtRoleArray,
    wasSuccessfullyLoadCourt,
  ] = useFetch<ICourtCourtRole[]>('/courtcourtrole');

  const handleOpenEdit = () => {
    setLegalPhaseEditArray([...props.legalPhaseFullObjectArray]);
    setIsOpenEditDialog(true);
  };

  if (
    !wasSuccessfullyAdminUnitArray ||
    !wasSuccessfullyFederalStateArray ||
    !wasSuccessfullyLoadCourt
  ) {
    return <div>Feher beim Laden der Daten</div>;
  } else if (
    adminUnitArray === undefined ||
    federalStateArray === undefined ||
    courtcourtRoleArray === undefined
  ) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <FullScreenDialog
        isOpen={isOpenEditDialog}
        onClose={() => setIsOpenEditDialog(false)}
        title='Rechtsgeschäftsart'
      >
        <LegalPhaseTableEdit
          legalPhaseArray={legalPhaseEditArray}
          setLegalPhaseArray={setLegalPhaseEditArray}
          setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
          setIsOpenEditDialog={setIsOpenEditDialog}
          legalRoleArray={props.legalRoleArray}
          setLegalRoleArray={props.setLegalRoleArray}
          legalRoleCanInviteArray={props.legalRoleCanInviteArray}
          setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
          legalRequirementTemplateArray={props.legalRequirementTemplateArray}
          adminUnitArray={adminUnitArray}
          federalStateArray={federalStateArray}
          courtcourtroleArray={courtcourtRoleArray}
          legaltransactionId={props.legaltransactionId}
        />
      </FullScreenDialog>

      <Typography variant='h5' sx={{ mb: 3 }}>
        Aktueller Statusverlauf
        <Tooltip title='Maßnahmen und Phasen anpassen'>
          <Button
            sx={{ float: 'right' }}
            onClick={handleOpenEdit}
            variant='outlined'
          >
            Anpassen
          </Button>
        </Tooltip>
      </Typography>

      <Grid container spacing={1}>
        {props.legalPhaseFullObjectArray
          .sort((a, b) => (a.Position < b.Position ? -1 : 1))
          .map(x => (
            <LegalPhaseRow
              key={`idLegalPhase-${x.idLegalPhase}-${x.LegalRequirementArray.length}`}
              legalPhaseFullObject={x}
              legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
              setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
              legalReqState={props.legalReqState}
              legalRequirementTemplateArray={
                props.legalRequirementTemplateArray
              }
              downloadedArray={props.downloadedArray}
              startdatedeadline={props.startdatedeadline}
            />
          ))}
      </Grid>
    </>
  );
};
