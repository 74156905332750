import React, { useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ILegalPhaseFullObject } from '../../../Interfaces/ILegalPhase';
import { LegalPhaseRowEdit } from './LegalPhaseRowEdit';
import { LegalPhaseDialogEdit } from './LegalPhaseDialogEdit';
import { ILegalRole } from '../../../Interfaces/ILegalRole';
import { ILegalRole_canInvite } from '../../../Interfaces/ILegalRole_canInvite';
import { ILegalRequirementTemplateFullObject } from '../../../Interfaces/ILegalRequirementTemplate';
import {
  IAdminstrativeUnit,
  ICourtCourtRole,
  IFederalState,
} from '../../../Interfaces/IAemter';
import { ILegalTransactionType } from '../../../Interfaces/ILegalTransactionType';
import { useFetch } from '../../../hooks/useFetch';
import { CopyFromTransactionType } from './CopyFromTransactionType';

interface IProps {
  legalPhaseArray: ILegalPhaseFullObject[];
  setLegalPhaseArray: Function;
  setLegalPhaseFullObjectArray: Function;
  setIsOpenEditDialog: Function;
  //
  legalRoleArray: ILegalRole[];
  setLegalRoleArray: Function;
  legalRoleCanInviteArray: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray: Function;
  legalRequirementTemplateArray: ILegalRequirementTemplateFullObject[];
  adminUnitArray: IAdminstrativeUnit[];
  federalStateArray: IFederalState[];
  courtcourtroleArray: ICourtCourtRole[];
  legaltransactionId: number;
}

const getNewPhase = (legalPhaseArrayx: ILegalPhaseFullObject[]) => {
  let tmpId = Math.min(...legalPhaseArrayx.map(x => x.idLegalPhase)) - 1;

  if (tmpId >= 0) {
    tmpId = -1;
  }

  return {
    idLegalPhase: tmpId,
    InfoText: null,
    isComplete: false,
    LegalPhase: '',
    LegalRequirementArray: [],
    Position: Math.max(...legalPhaseArrayx.map(x => x.Position)) + 1,
    PreconditionArray: [],
    idAdministrativeUnit: null,
    AdministrativeUnit: null,
  } as ILegalPhaseFullObject;
};

export const LegalPhaseTableEdit: React.FC<IProps> = props => {
  const [editNewPhase, setEditNewPhase] = useState(
    getNewPhase(props.legalPhaseArray)
  );
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [isOpenCopy, setIsOpenCopy] = useState(false);

  const [
    legaltransactionTypeArray,
    setLegaltransactionTypeArray,
    wasSuccessfullyLegalTransactionTypeArray,
  ] = useFetch<ILegalTransactionType[]>('/legaltransactiontype');

  const handleAddNew = () => {
    setEditNewPhase(getNewPhase(props.legalPhaseArray));
    setIsOpenNew(true);
  };

  const handleSave = () => {
    props.setLegalPhaseFullObjectArray([...props.legalPhaseArray]);
    props.setIsOpenEditDialog(false);
  };

  if (!wasSuccessfullyLegalTransactionTypeArray) {
    return (
      <Alert severity='error'>Fehler beim Laden der Rechtsgeschäftsart</Alert>
    );
  }

  if (legaltransactionTypeArray === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      {isOpenNew && (
        <LegalPhaseDialogEdit
          currentPhase={editNewPhase}
          currentPhaseEdit={editNewPhase}
          setCurrentPhaseEdit={setEditNewPhase}
          legalPhaseArray={props.legalPhaseArray}
          setLegalPhaseArray={props.setLegalPhaseArray}
          setIsOpenNew={setIsOpenNew}
        />
      )}

      <Button
        sx={{ float: 'right', ml: 2 }}
        variant='contained'
        onClick={handleSave}
      >
        Übernehmen
      </Button>
      <Button sx={{ float: 'right' }} variant='outlined' onClick={handleAddNew}>
        Neue Phase
      </Button>
      <Button
        sx={{ float: 'right', mr: 2 }}
        variant='outlined'
        onClick={() => setIsOpenCopy(true)}
      >
        Aus Rechtsgeschäftsart kopieren
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>Vorgänger</TableCell>
            <TableCell>Gemarkung</TableCell>
            <TableCell></TableCell>
            <TableCell>Upload Mandanten</TableCell>
            <TableCell>Upload Mitarbeiter</TableCell>
            <TableCell>
              Auto. <span style={{ whiteSpace: 'nowrap' }}>E-Mail</span>
            </TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.legalPhaseArray
            .sort((a, b) => (a.Position < b.Position ? -1 : 1))
            .map(x => (
              <LegalPhaseRowEdit
                key={`idLegalPhase-${x.idLegalPhase}`}
                currentPhase={x}
                legalPhaseArray={props.legalPhaseArray}
                setLegalPhaseArray={props.setLegalPhaseArray}
                legalRequirementTemplateArray={
                  props.legalRequirementTemplateArray
                }
                adminUnitArray={props.adminUnitArray}
                federalStateArray={props.federalStateArray}
                courtcourtroleArray={props.courtcourtroleArray}
                legaltransactionId={props.legaltransactionId}
              />
            ))}
        </TableBody>
      </Table>

      <CopyFromTransactionType
        transactionTypes={legaltransactionTypeArray}
        open={isOpenCopy}
        onClose={() => setIsOpenCopy(false)}
        legalPhaseArray={props.legalPhaseArray}
        setLegalPhaseArray={props.setLegalPhaseArray}
        idLegalTransaction={props.legaltransactionId}
      ></CopyFromTransactionType>
    </>
  );
};
