import React, {useEffect, useState} from "react";
import {
    Alert,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {
    ILegalTransaction_has_LegalTransactionSpecialFunction
} from "../../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction";
import {ChangeLegalPhaseCondition} from "../ChangeLegalPhaseCondition";
import {ILegalPhaseFullObject} from "../../../Interfaces/ILegalPhase";
import {ILegalRequirementState} from "../../../Interfaces/ILegalRequirementState";
import SettingsIcon from '@mui/icons-material/Settings';
import {HandelsregisterSearch} from "../../../generic/Handelsregsiter/HandelsregisterSearch";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from "@mui/system";

interface IProps {
    idLegalTransaction: number;
    legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
    setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    legalReqState: ILegalRequirementState[];

    deleteSpecialFunction: Function;
    handleFieldChangeOnImport: (index: number, updates: { [key: string]: string }) => void;
}

export const HandelsregisterCheck: React.FC<IProps> = (props) => {
    const [hasError, setHasError] = useState(false);

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [wasSuccessfullImportedFromhandelsregister, setWasSuccessfullImportedFromhandelsregister] = useState(false)


    // Zustand für das Öffnen und Schließen des Dialogs
    const [open, setOpen] = useState(false);

    // Funktionen zum Öffnen und Schließen des Dialogs
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const hasValidationError = props.legalTransaction_has_LegalTransactionSpecialFunctionArray.some(item =>
            item.idSF === 1 && (item.Function_Field1.trim() === '' || item.Function_Field2.trim() === '' || item.Function_Field6.trim() === '')
        );
        setHasError(hasValidationError);
    }, [props.legalTransaction_has_LegalTransactionSpecialFunctionArray]);

    const handleChange = (index: number, field: string, value: string) => {
        const updatedArray = props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map((item, i) => {
            if (item.idSF === 1 && i === index) {
                const updatedItem = {...item, [field]: value};
                let followUpState = Number(updatedItem.Function_Field3) === 2 ? 2 : 1;
                updatedItem.Function_Field3 = followUpState.toString();
                return updatedItem;
            }
            return item;
        });
        props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(updatedArray);
    };

    return (
        <Grid container spacing={2} style={{paddingBottom: '20px'}}>
            <Grid item sm={11}>
                <Typography variant="h5">Handelsregister Nachverfolgung</Typography>
            </Grid>

            <Grid item sm={1}>
                <IconButton onClick={handleClickOpen}>
                    <InfoOutlinedIcon/>
                </IconButton>
            </Grid>

            {hasError && (
                <Grid item sm={12}>
                    <Alert severity="error">Ein oder mehrere Einträge haben leere Pflichtfelder.</Alert>
                </Grid>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Handelsregister Nachverfolgung</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wählen sie die Art der Eintragung aus <br/><br/>

                        Für Veränderungen und Löschungen wählen Sie bitte "Suche im Handelsregister" aus, alle
                        benötigten Informationen werden automatisch importiert <br/><br/>
                        1. Neueintragungen: Bitte geben Sie hierfür den exakten Namen an, dass das Unternehmen im
                        Handelsregister haben wird<br/>
                        2. Veränderungen: Bitte geben Sie hierfür den exakten Namen, sowie die Handelsregisternummer
                        an<br/>
                        3. Löschungen: Bitte geben Sie hierfür den exakten Namen, sowie die Handelsregisternummer
                        an<br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map((item, index) => (
                item.idSF === 1 && (
                    <React.Fragment key={index}>
                        {item.Function_Field3 === "0" && (
                            <Grid item sm={12}>
                                <Alert severity="warning">Keine Nachverfolgung aktiviert</Alert>
                            </Grid>
                        )}
                        {item.Function_Field3 === "1" && (
                            <Grid item sm={12}>
                                <Alert severity="info">Nachverfolgung aktiviert, noch nicht eingetragen</Alert>
                            </Grid>
                        )}
                        {item.Function_Field3 === "2" && (
                            <Grid item sm={12}>
                                <Alert severity="success">Im Handelsregister eingetragen</Alert>
                            </Grid>
                        )}

                        <Grid item sm={2}>
                            <FormControl fullWidth size="small">
                                <InputLabel shrink={true}>Eintragungsart</InputLabel>
                                <Select
                                    value={item.Function_Field6 === undefined ? "0" : item.Function_Field6}
                                    onChange={(event: SelectChangeEvent<string>) =>
                                        handleChange(index, 'Function_Field6', event.target.value)
                                    }
                                    displayEmpty
                                    disabled={item.Function_Field3 === "2"}
                                >
                                    <MenuItem value="0">Neueintragung</MenuItem>
                                    <MenuItem value="1">Veränderung</MenuItem>
                                    <MenuItem value="2">Löschung</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item sm={2}>
                            <TextField
                                label="Datum"
                                size="small"
                                type="date"
                                fullWidth
                                disabled={item.Function_Field3 === "2"}
                                required={true}
                                InputLabelProps={{shrink: true}}
                                value={item.Function_Field1} // Setzt das heutige Datum als Standardwert
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(index, 'Function_Field1', event.target.value)
                                }
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <TextField
                                label="Unternehmensname (wie im Handelsregister)"
                                size="small"
                                fullWidth
                                disabled={item.Function_Field3 === "2"}
                                required={true}
                                InputLabelProps={{shrink: true}}
                                value={item.Function_Field2}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(index, 'Function_Field2', event.target.value)
                                }
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <TextField
                                label="Handelsregister Nr."
                                size="small"
                                fullWidth
                                disabled={item.Function_Field3 === "2"}
                                required={false}
                                InputLabelProps={{shrink: true}}
                                value={item.Res_Field1}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(index, 'Res_Field1', event.target.value)
                                }
                            />
                        </Grid>

                        <Grid item sm={1}>
                            <IconButton onClick={() => props.deleteSpecialFunction(index)}>
                                <Delete/>
                            </IconButton>
                        </Grid>

                        <Grid item sm={2}>
                            <ChangeLegalPhaseCondition
                                idLegalTransaction={props.idLegalTransaction}
                                legalTransaction_has_LegalTransactionSpecialFunctionArray={props.legalTransaction_has_LegalTransactionSpecialFunctionArray}
                                setLegalTransaction_has_LegalTransactionSpecialFunctionArray={props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray}
                                legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                                legalReqState={props.legalReqState}
                                specialFunction={item}
                            ></ChangeLegalPhaseCondition>
                        </Grid>

                        <Grid item sm={9}>
                            <Button variant={"outlined"} onClick={() => setIsSearchOpen(true)}>Suche im
                                Handelsregister</Button>
                        </Grid>

                        <HandelsregisterSearch
                            openImportFromHandelsregister={isSearchOpen}
                            setOpenImportFromHandelsregister={setIsSearchOpen}
                            wasSuccessfullImportedFromhandelsregister={wasSuccessfullImportedFromhandelsregister}
                            setWasSuccessfullImportedFromhandelsregister={setWasSuccessfullImportedFromhandelsregister}
                            handleFieldChangeOnImport={props.handleFieldChangeOnImport}
                            index={index}
                            isXMLImport={false}
                        />
                        <Box style={{ marginBottom: 80 }}></Box>
                    </React.Fragment>
                )
            ))}
        </Grid>
    );
};
