import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Owner } from "../Components/OwnerRow";
import { IMortgagePerson } from "../../../../Interfaces/IPerson";
import { IMortgage } from "../../../../Interfaces/IMortgage";
import { GridBaseDate } from "../../../../Person/GridBaseData";



interface IProps {
    mortgageObject: IMortgage;
    personArray: IMortgagePerson[];
    setPersonArray: Function;
}

const newPerson = {
    idPerson: -1,
    idGender: 4,
    idAcademicTitle: 1,
    FirstName: "",
    LastName: "",
    Surname: null,
    Birthday: "",
    Birthplace: "",
    Street: "",
    StreetNr: "",
    StreetAditional: null,
    idPostcode: 1,
    idCountry: 1,
    DateOfDeath: null,
    PlaceOfDeath: null,
    idBusinessForm: 1,
    Email: null,
    PhoneNumber: null,
    TaxNumber: null,
    IBAN: null,
    BIC: null,
    Bank: null
};


export const Owners:React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [ownerId, setOwnerId] = React.useState<number>(-1);

    const [currentPerson, setCurrentPerson] = React.useState<IMortgagePerson>(newPerson);

    const createNew = () => {
        setCurrentPerson(newPerson);
        setIsOpen(true);
    }

    const handleSave = () => {
        props.setPersonArray([
            ...props.personArray.map(x => x.idPerson === currentPerson.idPerson ? currentPerson : x)
        ])

        if (currentPerson.idPerson === -1) {
            props.setPersonArray([
                ...props.personArray,
                currentPerson
            ])
        }

        setIsOpen(false);
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => {}}
                maxWidth="lg" fullWidth
            >
                <DialogContent>
                    <>
                        <Typography variant="h5">Bearbeiten:</Typography>
                        <br />
                        <GridBaseDate
                            personObject={currentPerson}
                            personArray={props.personArray}
                            setPersonObject={setCurrentPerson}
                            showDeathSettings={false}
                            forceDeathSettings={false}
                            titleAdress="Anschrift"
                            setIsDeath={() => {}}
                            disableEdit={false}
                            forceAdress={true}
                            disableDeathDateWarning={true}
                        />
                        
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave}>Übernehmen</Button>
                </DialogActions>
            </Dialog>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.:</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                        <TableCell>Geburtsname</TableCell>
                        <TableCell>Geburtstag</TableCell>
                        <TableCell>Geburtsort</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Owner
                        currentPerson={currentPerson}
                        setCurrentPerson={setCurrentPerson}
                        personArray={props.personArray}
                        setPersonArray={props.setPersonArray}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        ownerId={ownerId}
                        setOwnerId={setOwnerId}
                    ></Owner>
                </TableBody>
                <Button variant="contained" onClick={() => createNew()} sx={{float: "left"}}>Eigentümer Hinzufügen</Button>
            </Table>
        </>
    )
}