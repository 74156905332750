import React, { useReducer, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Divider,
  Grid,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import {
  IAdminstrativeUnit,
  ICourtAdminstrativeUnitAssignment,
  ICourtCourtRole,
  type ICourt,
  type ICourtRole,
} from '../../Interfaces/IAemter';
import { UpdateAdminUnitDialog } from './UpdateAdminUnitDialog';
import { RoleAssignmentSelect } from './RoleAssignmentSelect';
import { useAlert } from '../../context/AlertContext';
import {
  useCourtAdministrativeUnitAssignments,
  useUpdateCourtAdminUnitAssignments,
} from '../hooks';

interface AdminUnitDetailProps {
  adminstrativeUnit: IAdminstrativeUnit;
  assignments: ICourtAdminstrativeUnitAssignment[];
  administrativeUnits: IAdminstrativeUnit[];
  courtCourtRoles: ICourtCourtRole[];
  courtRoles: ICourtRole[];
  courts: ICourt[];
  onClose: () => void;
}

type RoleAssignmentState = Record<string, ICourtAdminstrativeUnitAssignment>;

export const AdminUnitDetails: React.FC<AdminUnitDetailProps> = ({
  adminstrativeUnit,
  assignments,
  courtCourtRoles,
  courtRoles,
  courts,
  administrativeUnits,
  onClose,
}) => {
  const theme = useTheme();
  const { showAlert } = useAlert();

  const { refetchCourtAdministrativeUnitAssignments } =
    useCourtAdministrativeUnitAssignments();

  const {
    updateCourtAdminUnitAssignments,
    isPendingUpdateCourtAdminUnitAssignments,
  } = useUpdateCourtAdminUnitAssignments();

  const [editableAdminUnit, setEditableAdminUnit] =
    useState<IAdminstrativeUnit | null>(null);

  const assignmentsReducer = (
    state: RoleAssignmentState,
    action: {
      type: 'update' | 'create' | 'delete';
      payload: ICourtAdminstrativeUnitAssignment;
    }
  ) => {
    switch (action.type) {
      case 'update':
        return {
          ...state,
          [action.payload.idCourtRole]: action.payload,
        };
      case 'create':
        return {
          ...state,
          [action.payload.idCourtRole]: action.payload,
        };
      case 'delete':
        return {
          ...state,
          [action.payload.idCourtRole]: {
            ...state[action.payload.idCourtRole],
            activeAssignment: false,
          },
        };
      default:
        return state;
    }
  };

  const [updatedAssignments, dispatchAssignments] = useReducer(
    assignmentsReducer,
    assignments.reduce((acc, assignment) => {
      return {
        ...acc,
        [assignment.idCourtRole]: assignment,
      };
    }, {} as RoleAssignmentState)
  );

  const rolesToAssignments = courtRoles.map(role => {
    const assignment = updatedAssignments[role.idCourtRole];

    return { role, assignment };
  });

  const handleUpdateAssignments = async () => {
    try {
      const mappedAssignments = Object.values(updatedAssignments).map(
        assignment => ({
          ...assignment,
          activeAssignment:
            assignment.activeAssignment !== undefined
              ? Boolean(assignment.activeAssignment)
              : true,
        })
      );

      await updateCourtAdminUnitAssignments(mappedAssignments);

      refetchCourtAdministrativeUnitAssignments();

      showAlert({
        text: 'Zuweisungen gespeichert',
        severity: 'success',
      });

      onClose();
    } catch (error) {
      showAlert({
        text: 'Fehler beim Speichern der Zuweisungen',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='md'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle>
          Verwaltungseinheit: {adminstrativeUnit.AdministrativeUnit}
        </DialogTitle>
        <Box
          sx={{
            px: '24px',
          }}
        >
          <IconButton onClick={() => setEditableAdminUnit(adminstrativeUnit)}>
            <Edit />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <DialogContent>
        <Box>
          <Box
            sx={{
              p: 2,
              backgroundColor: theme.palette.grey[100],
              borderRadius: 2,
            }}
          >
            Sollten für eine bestimmte Rolle keine Gerichte zur Verfügung
            stehen, müssen Sie den Gerichten zunächst die entsprechende Rolle
            zuweisen.
          </Box>
          <Grid container columns={12} mt={2} spacing={2}>
            {rolesToAssignments.map(({ role, assignment }, index) => (
              <RoleAssignmentSelect
                key={index}
                role={role}
                assignment={assignment}
                allAssignments={Object.values(updatedAssignments)}
                // Only show courts that are allowed for this role
                courts={courts.filter(court =>
                  courtCourtRoles.some(
                    courtCourtRole =>
                      courtCourtRole.idCourt === court.idCourt &&
                      courtCourtRole.idCourtRole === role.idCourtRole
                  )
                )}
                adminUnit={adminstrativeUnit}
                onUpdateAssignment={dispatchAssignments}
              />
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
        <Button
          onClick={handleUpdateAssignments}
          variant='contained'
          disabled={isPendingUpdateCourtAdminUnitAssignments}
        >
          {isPendingUpdateCourtAdminUnitAssignments ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>

      {editableAdminUnit && (
        <UpdateAdminUnitDialog
          adminUnit={editableAdminUnit}
          currentAdminUnits={administrativeUnits}
          onClose={() => setEditableAdminUnit(null)}
        />
      )}
    </Dialog>
  );
};
