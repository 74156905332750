import React, { useState } from "react";
import { ILegalTransactionDataCollectionArray } from "../Interfaces/ILegalTransaction";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material";
import { CompanyFormationMain } from "./CompanyFormation/CompanyFormationMain";
import { ICompanyFormation, ICompanyFormationFullObject } from "../Interfaces/ICompanyFormation";
import { ArrowDownward } from "@mui/icons-material";
import { InheritMain } from "./Inherit/InheritMain";
import { IInheritFullObject } from "../Interfaces/IInherit";
import { PurchaseContractMain } from "./PurchaseContract/PurchaseContractMain";
import { IPurchaseContractFullObject } from "../Interfaces/IPurchaseContract";
import { MortgageMain } from "./Mortgage/MortgageMain";
import { IMortgage } from "../Interfaces/IMortgage";
import { IInheritHasPerson, IMortgagePerson } from "../Interfaces/IPerson";


interface IProps {
    dataCollection: ILegalTransactionDataCollectionArray;
    setDataCollection: Function;
}


const DataCollectionAccordion:React.FC<{title:string,idx:number,children:React.ReactElement}> = ({title,children,idx}) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id={`datacollection-panal-${idx}`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}


export const LegalTransactionDataCollection:React.FC<IProps> = ({dataCollection,setDataCollection}) => {
    const [isOpenNew,setIsOpenNew] = useState(false);
    const [currentDataCollectionOption,setCurrentDataCollectionOption] = useState("Unternehmensgründung");


    const changeArray = (updatedObject:any,idx:number) => {
        let tmpArray = [...dataCollection];
        tmpArray[idx] = updatedObject;
        setDataCollection(tmpArray);
    }

    const companyFormationTitle = (companyFormation:ICompanyFormation) => {
        let prefix = "Unternehmens";
        let sufix = "änderung";

        if (companyFormation.idBusinessForm === 12) {
            prefix = "Vereins";
        }
        if (companyFormation.isFounding) {
            sufix = "gründung";
        }
        return `${prefix}${sufix}`
    }

    const addNewDataCollection = () => {
        if (currentDataCollectionOption === "Unternehmensgründung" 
            || currentDataCollectionOption === "Unternehmensänderung" 
            || currentDataCollectionOption === "Vereinsgründung"
            || currentDataCollectionOption === "Vereinsänderung"
            || currentDataCollectionOption === "Grundschuld"
        ) {
            let newItem:ICompanyFormationFullObject = {
                AddressStreet: null,
                AddressStreetNr: null,
                AmountShares: 0,
                CompanyName: "",
                DateOfFormation: new Date(),
                idBusinessForm: (currentDataCollectionOption === "Vereinsgründung" || currentDataCollectionOption === "Vereinsänderung") ? 12 : 8,
                idCompanyFormation: dataCollection.length*(-1),
                idLegalTransaction: -1,
                idPostcode: 1,
                isFounding: (currentDataCollectionOption === "Unternehmensgründung" || currentDataCollectionOption === "Vereinsgründung" ),
                Postcode_AddressidPostcode: null,
                PurposeOfTheCompany: "",
                ShareCapital: 25000,
                Street: "",
                StreetNr: "",
                useSampleProtocol: false,
                ValuePerShare: 0,
                CompanyFormationAnswerArray: [],
                CompanyFormationHasShareHolderArray: [],
                CompanyFormationShareArray: [],
                DocumentArray: [],
                ShareHolderArray: [],
            }
            setDataCollection([
                ...dataCollection,
                newItem
            ])
        }
        else if (currentDataCollectionOption === "Erbscheinsantrag") {
            let newItem:IInheritFullObject = {
                ChildrenArray: [],
                hasTestament: false,
                idInherit:dataCollection.length*(-1),
                idLegalTransaction: -1,
                idPerson: -1,
                MarriageArray: [],
                PersonArray: [{
                    isStranger : false,
                    FirstName : "",
                    LastName : "",
                    idGender : 1,
                    idAcademicTitle : 1,
                    idBusinessForm : 1,
                    idCountry : 1,
                    Birthday : "",
                    idPerson : -1,
                    Bank: null,
                    BIC: null,
                    Birthplace: "",
                    Email: null,
                    IBAN: null,
                    idInherit: -1,
                    idPostcode: null,
                    PhoneNumber: null,
                    Street: "",
                    StreetAditional: null,
                    StreetNr: "",
                    Surname: null,
                    TaxNumber: null,
                } as IInheritHasPerson],
                PersonHasDocument: [],
            }
            setDataCollection([
                ...dataCollection,
                newItem
            ])
        }
        else if (currentDataCollectionOption === "Kaufvertrag") {
            let newItem:IPurchaseContractFullObject = {
                DocumentArray: [],
                idLegalTransaction: -1,
                idPurchaseContract: dataCollection.length*(-1),
                idPurchaseContractType: 1,
                PersonArray: [],
                RealEstateArray: [],
                RealEstateHasInventoryOptionArray: [],
                RealEstateHasPropertyTypeArray: [],
                ThirdPartyClauseText: null,
                withThirdPartyClause: false
            }
            setDataCollection([
                ...dataCollection,
                newItem
            ])
        }
        setIsOpenNew(false);
    }


    return(
        <>
            <Dialog open={isOpenNew} onClose={() => setIsOpenNew(false)}>
                <DialogTitle>Neue Datenerfassung hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="Art der Datenerfassung"
                        value={currentDataCollectionOption}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCurrentDataCollectionOption(event.target.value)}
                        select
                        size="small"
                        fullWidth
                        sx={{mt: 2}}
                    >
                        <MenuItem key="data-collection-Unternehmensgründung" value="Unternehmensgründung">Unternehmensgründung</MenuItem>
                        <MenuItem key="data-collection-Unternehmensänderung" value="Unternehmensänderung">Unternehmensänderung</MenuItem>
                        <MenuItem key="data-collection-Vereinsgründung" value="Vereinsgründung">Vereinsgründung</MenuItem>
                        <MenuItem key="data-collection-Vereinsänderung" value="Vereinsänderung">Vereinsänderung</MenuItem>
                        <MenuItem key="data-collection-Erbscheinsantrag" value="Erbscheinsantrag">Erbscheinsantrag</MenuItem>
                        <MenuItem key="data-collection-Kaufvertrag" value="Kaufvertrag">Kaufvertrag</MenuItem>
                        <MenuItem key="data-collection-Grundschuld" value="Grundschuld" disabled>Grundschuld</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={addNewDataCollection}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>


            <Typography variant="h5" sx={{mb: 3}}>
                Datenerfassung
                <Button sx={{float: "right"}} variant="outlined" onClick={() => setIsOpenNew(true)}>Hinzufügen</Button>
            </Typography>

            { (dataCollection.length === 0) && 
                <>
                    <i>Keine Datenerfassung vorhanden </i>
                </>
            }

            { dataCollection.map((x,idx) => {
                if ("idCompanyFormation" in x) {
                    return(
                        <DataCollectionAccordion title={companyFormationTitle(x)} idx={idx}>
                             <CompanyFormationMain
                                key={`datacollection-element-${idx}`}
                                companyFormationObject={x}
                                setCompanyFormationObject={(updatedObject:ICompanyFormation) => changeArray(updatedObject,idx)}
                            />
                        </DataCollectionAccordion>
                    )
                }
                else if ("idInherit" in x) {
                    return(
                        <DataCollectionAccordion title="Erbescheinsantrag" idx={idx}>
                            <InheritMain
                                key={`datacollection-element-${idx}`}
                                inheritObject={x}
                                setInheritObject={(updatedObject:IInheritFullObject) => changeArray(updatedObject,idx)}
                            />
                        </DataCollectionAccordion>
                    )
                }
                else if ("idPurchaseContract" in x) {
                    return(
                        <DataCollectionAccordion title="Kaufvertrag" idx={idx}>
                            <PurchaseContractMain
                                key={`datacollection-element-${idx}`}
                                purchaseContractObject={x}
                                setPurchaseContractObject={(updatedObject:IPurchaseContractFullObject) => changeArray(updatedObject,idx)}
                            />
                        </DataCollectionAccordion>
                    )
                }
                else if ("idMortgage" in x) {
                    return(
                        <DataCollectionAccordion title="Grundschuld" idx={idx}>
                            <MortgageMain
                                key={`datacollection-element-${idx}`}
                                mortgageObject={x}
                                setMortgageObject={(updatedObject:IMortgage) => changeArray(updatedObject,idx)}
                            />
                        </DataCollectionAccordion>
                    )
                }
            })}
        </>
    )
}
