import React, { useEffect, useState } from "react";
import { IResult } from "../LegalTransaction/DocumentDirectory/LandregisterSerach";
import { LatLngTuple } from "leaflet";
import { Polygon, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';

interface IProps {
    index: number;
    resultObject: IResult;
}


interface IRestulBrandenburgViewer {
    results: {
        geometry: string;
        geometryComplex: string;
        title: string;
    }[]
}

// Typdefinitionen
type PolygonCoordinates = LatLngTuple[];


  


export const MasterportalLandRegisterMarker:React.FC<IProps> = ({resultObject,index}) => {
    const map = useMap();
    const [polygon,setPolygron] = useState<PolygonCoordinates|null>(null);

    const parsePolygonString = (str: string, setMapView:boolean): PolygonCoordinates => {
        let wasSet:boolean = false;
    
        // Entferne "POLYGON((" und "))"
        const cleanedString = str.replace('POLYGON((', '').replace('))', '');
        // Splitte die Koordinatenpaare
        const coordinatePairs = cleanedString.split(',');
        // Konvertiere die Koordinatenpaare in ein Array von Arrays
        const coordinates = coordinatePairs.map(pair => {
          const [lng, lat] = pair.trim().split(' ').map(Number);
          if (setMapView && !wasSet) {
            map.setView([lat, lng]);
            wasSet = true;
          }
          return [lat, lng] as LatLngTuple; // Leaflet erwartet [lat, lng]
        });
        return coordinates;
      };


    const getSearch = (query: string) => {
        fetch("https://search.geobasis-bb.de/search/?"
            + `query=${query}&`
            + "limit=5&"
            + "sortOrder=desc&"
            + "sortBy=relevance&"
            + "complex=true&"
            + "filter[category]=gazetteer%7Ckataster%7Cstrassendaten%7Chaltestellen%7Ckartenblatt%7Coebvi&"
            + "lang=de&", {
            headers: {
            'User-Agent': 'DiNoPublic'
            }
        })
        .then(res => res.json())
        .then(res => {
            let testObject:IRestulBrandenburgViewer = res;
            if (testObject.results.length>0) {
                setPolygron(parsePolygonString(testObject.results[0].geometryComplex,index === 0))
            }
            
        })
    }
    useEffect(() => {
        getSearch(`Flurstück ${resultObject.CadastralUnit}, Flur ${resultObject.CadastralDistrict}, Gemarkung ${resultObject.Marking}`)
    },[resultObject])
    

    if (polygon === null) {
        return null;
    }else {
        return <Polygon positions={polygon!} color="green"/>
    }
    
}