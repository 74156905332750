import {
  SelectChangeEvent,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ICourt,
  ICourtCourtRole,
  ICourtRole,
} from '../../../Interfaces/IAemter';
import {
  ILegalRequirement,
  ILegalRequirementFullObject,
} from '../../../Interfaces/ILegalRequirement';

interface LegalRequirementAddCourtProps {
  legalRequirement: ILegalRequirementFullObject;
  courtCourtRoleArray: ICourtCourtRole[];
  open: boolean;
  onSave: (updatedLegalRequirement: ILegalRequirementFullObject) => void;
  onClose: () => void;
}

export const LegalRequirementAddCourt: React.FC<
  LegalRequirementAddCourtProps
> = ({ legalRequirement, courtCourtRoleArray, open, onSave, onClose }) => {
  const [selectedCourtId, setSelectedCourtId] = useState<number | ''>('');
  const [selectedCourtRole, setSelectedCourtRole] = useState<number | ''>('');

  useEffect(() => {
    if (legalRequirement.idCourt && legalRequirement.idCourtRole && open) {
      setSelectedCourtId(legalRequirement.idCourt);
      setSelectedCourtRole(legalRequirement.idCourtRole);
    } else if (
      legalRequirement.idCourt === null &&
      legalRequirement.idCourtRole === null &&
      open
    ) {
      setSelectedCourtId('');
      setSelectedCourtRole('');
    }
  }, [legalRequirement, open]);

  const handleCourtChange = (event: SelectChangeEvent<number>) => {
    setSelectedCourtId(event.target.value as number);
  };

  const handleCourtRoleChange = (event: SelectChangeEvent<number>) => {
    setSelectedCourtRole(event.target.value as number);
  };

  const handleAddCourtCourtRole = () => {
    if (selectedCourtId !== '' && selectedCourtRole !== '') {
      const selectedCourt = courtCourtRoleArray.find(
        court => court.idCourt === selectedCourtId
      );
      if (!selectedCourt) return;

      const selectedRole = courtCourtRoleArray.find(
        role => role.idCourtRole === selectedCourtRole
      );
      if (!selectedRole) return;

      onSave({
        ...legalRequirement,
        idCourt: selectedCourt.idCourt,
        Court: selectedCourt.Court,
        idCourtRole: selectedRole.idCourtRole,
        CourtRole: selectedRole.CourtRole,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Gerichtsrolle hinzufügen</DialogTitle>
      <DialogContent>
        <Box>
          <FormControl fullWidth margin='normal'>
            <InputLabel id='court-select-label'>Gericht</InputLabel>
            <Select
              labelId='court-select-label'
              id='court-select'
              value={selectedCourtId}
              label='Gericht'
              onChange={handleCourtChange}
            >
              {courtCourtRoleArray
                .reduce((acc: ICourt[], court) => {
                  if (!acc.find(c => c.idCourt === court.idCourt)) {
                    acc.push({
                      idCourt: court.idCourt,
                      Court: court.Court,
                    } as ICourt);
                  }
                  return acc;
                }, [])
                .map(court => (
                  <MenuItem key={court.idCourt} value={court.idCourt}>
                    {court.Court}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {selectedCourtId && (
            <FormControl fullWidth margin='normal'>
              <InputLabel id='court-role-select-label'>
                Gerichtsrolle
              </InputLabel>
              <Select
                labelId='court-role-select-label'
                id='court-role-select'
                value={selectedCourtRole}
                label='Gerichtsrolle'
                onChange={handleCourtRoleChange}
              >
                {courtCourtRoleArray
                  .filter(court => court.idCourt === selectedCourtId)
                  .map(court => (
                    <MenuItem key={court.idCourtRole} value={court.idCourtRole}>
                      {court.CourtRole}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={handleAddCourtCourtRole}>Hinzufügen</Button>
      </DialogActions>
    </Dialog>
  );
};
