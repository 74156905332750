import React, { useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { IUser } from '../Interfaces/IUser';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  AddCircle,
  CalendarMonth,
  Celebration,
  TextFields,
} from '@mui/icons-material';
import { checkDate } from '../Kanban/KanbanBody';
import { ILegalRequirement } from '../Interfaces/ILegalRequirement';
import { ILegalTransactionMeeting } from '../Interfaces/ILegalTransactionMeeting';
import { castNoTimeZone, DashboardOwn } from './DashboardOwn';
import { DashboardGlobal } from './DashboardGlobal';
import { ILegalTransactionProgress } from '../Interfaces/ILegalTransactionProgress';
import { IUserGroup } from '../Interfaces/IUserGroup';

export const DashboardMain: React.FC = () => {
  const idUser = Number(localStorage.getItem('idUser'));
  const [currentView, setCurrentView] = useState<
    'view_global' | 'view_own' | string
  >('view_global');

  const [userArray, setUserArray, wasSuccessfullyUserArray] =
    useFetch<IUser[]>('/userlist');
  const [userGroupArray, setUserGroupArray, wasSuccessfullyUserGroup] =
    useFetch<IUserGroup[]>('/usergroup');
  const [
    legalTransactionArray,
    setLegalTransactionArray,
    wasSuccessfullyLegalArray,
  ] = useFetch<ILegalTransaction[]>('/legaltransaction');
  const [legalReqArray, setLegalReqArray, wasSuccessfullyLegalReqArray] =
    useFetch<ILegalRequirement[]>('/legalrequirement');
  const [meetingArray, setMeetingArray, wasSuccessfullyMeetingArray] = useFetch<
    ILegalTransactionMeeting[]
  >('/legaltransactionmeeting');
  const [currentUserLegaLReqDeadline, setCurrentUserLegalReqDeadline] =
    useState<ILegalRequirement[]>([]);
  const [
    legalTransactionProgessArray,
    setLegalTransactionProgessArray,
    wasSuccessfullyLegalTransacitonProgressArray,
  ] = useFetch<ILegalTransactionProgress[]>('/legaltransactionprogress');

  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [currentUserLegalArray, setCurrentUserLegalArray] = useState<
    ILegalTransaction[]
  >([]);
  const [currentUserReqArray, setCurrentUserReqArray] = useState<
    ILegalRequirement[]
  >([]);
  const [currentUserMeetingArray, setCurrentUserMeetingArray] = useState<
    ILegalTransactionMeeting[]
  >([]);

  useEffect(() => {
    if (userArray !== undefined) {
      setCurrentUser(userArray.find(x => x.idUser === idUser));
    }
  }, [userArray]);

  useEffect(() => {
    if (currentUser !== undefined && legalTransactionArray !== undefined) {
      if (Boolean(currentUser.isNotary)) {
        setCurrentUserLegalArray([
          ...legalTransactionArray
            .filter(x => x.Closed_at === null)
            .filter(x => x.Notary_idUser !== null)
            .filter(x => x.Notary_idUser === idUser),
        ]);
      } else {
        setCurrentUserLegalArray([
          ...legalTransactionArray
            .filter(x => x.Closed_at === null)
            .filter(x => x.Employee_idUser !== null)
            .filter(x => x.Employee_idUser === idUser),
        ]);
      }
    } else {
    }
  }, [currentUser, legalTransactionArray]);

  useEffect(() => {
    if (currentUser !== undefined && legalReqArray !== undefined) {
      if (Boolean(currentUser.isNotary)) {
        setCurrentUserReqArray([
          ...legalReqArray
            .filter(x => Number(x.idLegalRequirementState === 20))
            .filter(x =>
              Number(x.Closed_at === undefined || x.Closed_at === null)
            )
            .filter(x => Number(x.Notary_idUser !== null))
            .filter(x => Number(x.Notary_idUser === idUser)),
        ]);
        setCurrentUserLegalReqDeadline([
          ...legalReqArray
            .filter(
              x =>
                x.DeadlineFullfilledAt === null ||
                x.DeadlineFullfilledAt === undefined
            )
            .filter(x => Boolean(x.UserDeadlineNotified) === true)
            .filter(x =>
              Number(x.Closed_at === undefined || x.Closed_at === null)
            )
            .filter(x => Number(x.Notary_idUser !== null))
            .filter(x => Number(x.Notary_idUser === idUser))
            .filter(
              (x, i, a) =>
                a.findIndex(
                  t => t.idLegalTransaction === x.idLegalTransaction
                ) === i
            ),
        ]);
      } else {
        setCurrentUserReqArray([
          ...legalReqArray
            .filter(x => Number(x.idLegalRequirementState === 15))
            .filter(x =>
              Number(x.Closed_at === undefined || x.Closed_at === null)
            )
            .filter(x => Number(x.Employee_idUser !== null))
            .filter(x => Number(x.Employee_idUser === idUser))
            .filter(
              (x, i, a) =>
                a.findIndex(
                  t => t.idLegalTransaction === x.idLegalTransaction
                ) === i
            ),
        ]);

        setCurrentUserLegalReqDeadline([
          ...legalReqArray
            .filter(
              x =>
                x.DeadlineFullfilledAt === null ||
                x.DeadlineFullfilledAt === undefined
            )
            .filter(x => Boolean(x.UserDeadlineNotified) === true)
            .filter(x =>
              Number(x.Closed_at === undefined || x.Closed_at === null)
            )
            .filter(x => Number(x.Employee_idUser !== null))
            .filter(x => Number(x.Employee_idUser === idUser)),
        ]);
      }
    }
  }, [currentUser, legalReqArray]);

  useEffect(() => {
    if (meetingArray !== undefined) {
      let ownLegalTransactions = currentUserLegalArray.map(
        x => x.idLegalTransaction
      );
      setCurrentUserMeetingArray(
        meetingArray
          .filter(x => ownLegalTransactions.indexOf(x.idLegalTransaction) > -1)
          .filter(x => castNoTimeZone(x.Meeting_at) >= new Date())
      );
    }
  }, [meetingArray, currentUserLegalArray]);

  const getRightGreeding = () => {
    let currentHour = new Date().getHours();

    if (currentHour >= 17) {
      return 'Guten Abend, ';
    } else if (currentHour >= 11) {
      return 'Guten Tag, ';
    } else {
      return 'Guten Morgen, ';
    }
  };

  const getGender = (currentUser: IUser) => {
    if (currentUser.idGender === 1) {
      return 'Herr';
    } else if (currentUser.idGender === 2) {
      return 'Frau';
    } else {
      return '';
    }
  };

  if (
    !wasSuccessfullyUserArray ||
    !wasSuccessfullyLegalArray ||
    !wasSuccessfullyLegalReqArray ||
    !wasSuccessfullyMeetingArray ||
    !wasSuccessfullyLegalTransacitonProgressArray ||
    !wasSuccessfullyUserGroup
  ) {
    return <>Fehler!</>;
  } else if (
    currentUser === undefined ||
    legalTransactionArray === undefined ||
    legalReqArray === undefined ||
    meetingArray === undefined ||
    legalTransactionProgessArray === undefined ||
    userGroupArray === undefined
  ) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Typography variant='h4'>
          {getRightGreeding()} {getGender(currentUser)} {currentUser.LastName}!
        </Typography>

        <TextField
          label='Ansicht'
          sx={{ float: 'right' }}
          value={currentView}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentView(event.target.value)
          }
          select
        >
          <MenuItem value='view_global'>Globale Ansicht</MenuItem>
          <MenuItem value='view_own'>Meine Ansicht</MenuItem>
        </TextField>

        <Typography sx={{ mt: 2 }}>
          Heute wurden <AddCircle sx={{ fontSize: 13 }} />{' '}
          <b>
            {
              legalTransactionArray.filter(
                x =>
                  new Date(x.Created_at).toDateString() ===
                  new Date().toDateString()
              ).length
            }
          </b>{' '}
          Vorgänge erstellt und &nbsp;
          <Celebration sx={{ fontSize: 13 }} />{' '}
          <b>
            {
              legalTransactionArray
                .filter(x => x.Closed_at !== null)
                .filter(
                  x =>
                    new Date(String(x.Closed_at)).toDateString() ===
                    new Date().toDateString()
                ).length
            }
          </b>
          &nbsp;Vorgänge abgeschlossen.
          <br />
          Insgesamt liegen{' '}
          <b>
            {legalTransactionArray.filter(x => x.Closed_at === null).length}
          </b>{' '}
          offene Vorgänge vor.
        </Typography>

        <Collapse in={currentView === 'view_global'}>
          <DashboardGlobal
            userArray={userArray || []}
            userGroupArray={userGroupArray}
            legalReqArray={legalReqArray}
            legalTransactionArray={legalTransactionArray}
            legalTransactionProgressArray={legalTransactionProgessArray}
          />
        </Collapse>

        <Collapse in={currentView === 'view_own'}>
          <DashboardOwn
            currentUserLegalArray={currentUserLegalArray}
            currentUserMeetingArray={currentUserMeetingArray}
            currentUserReqArray={currentUserReqArray}
            currentUserlegalReqDeadline={currentUserLegaLReqDeadline}
          />
        </Collapse>
      </>
    );
  }
};
