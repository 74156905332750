import React, { ChangeEvent } from "react";
import { IContact, ILegalTransactionHasContact } from "../Interfaces/IContact";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { Check, Close, Delete, Link } from "@mui/icons-material";
import { LegalTransactionAddClientNew } from "./LegalTransactionAddClientNew";
import { ILegalRole } from "../Interfaces/ILegalRole";
import { ILegalRole_canInvite } from "../Interfaces/ILegalRole_canInvite";
import { LegalRoleEditDialog } from "./Edit/LegalRole/LegalRoleEditDialog";
import { useSelector } from "react-redux";
import { State } from "../redux/mainReducer";


interface IProps {
  allContactArray: IContact[];
  setAllContactArray: Function;
  legalRoleArray: ILegalRole[];
  chosenContactArray: ILegalTransactionHasContact[];
  setChosenContactArray: Function;
  setLegalRoleArray?: Function;
  legalRoleCanInviteArray?: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray?: Function;
}



export const LegalTransactionClientsOverview:React.FC<IProps> = (props) => {

  const contactGroupArray = useSelector((state: State) => state.contactGroup.storeContactGroup);

  // remove client function
  const removeClient = (idContact:string|number) => {
    props.setChosenContactArray([
      ...props.chosenContactArray.filter(x => x.idContact !== Number(idContact))
    ])
  }

  // change rolle function
  const handleChangeSelect = (event:ChangeEvent<HTMLInputElement>,idContact:number) => {
    let testObjectNewContact = props.chosenContactArray.find(x => x.idContact === idContact);

    if (testObjectNewContact !== undefined) {
      testObjectNewContact.idLegalRole = Number(event.target.value);
      props.setChosenContactArray([
        ...props.chosenContactArray.map(x => (x.idContact === idContact) ? testObjectNewContact : x)
      ])
    }
  }

  // Grid
  const columns: GridColDef[] = [
    { field: 'idContact', headerName: 'Nr.', flex: 1},
    {
      field: 'idLegalRole',
      headerName: 'Rolle',
      flex: 1,
      renderCell: (params: GridRenderCellParams) : React.ReactNode => {
        if (props.legalRoleArray.length === 0) {
          return <>-</>
        } else {
          return(
              <>
                <TextField
                    select
                    fullWidth
                    size="small"
                    label="Rolle"
                    onChange={(event:ChangeEvent<HTMLInputElement>)  => handleChangeSelect(event,Number(params.id))}
                    value={(params.row.idLegalRole === null) ? "" : Number(params.row.idLegalRole)}
                >
                  {props.legalRoleArray.map(x =>
                      <MenuItem key={`idLegalRole-${x.idLegalRole}`} value={x.idLegalRole}>{x.LegalRole}</MenuItem>
                  )}
                </TextField>
              </>
          )
        }
      }
    },
    {
      field: 'idContactGroup',
      headerName: 'Gruppe',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
          (params.row.idContactGroup == null)
              ? ""
              : contactGroupArray.find(x => x.idContactGroup == params.row.idContactGroup)?.ContactGroup
      ,
    },
    {
      field: 'loginPossible',
      headerName: 'Login',
      flex: 1,
      renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>

    },
    {
      field: 'isReceivingEmailEnabled',
      headerName: 'E-Mails',
      flex: 1,
      renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>

    },
    { field: 'FirstName', headerName: 'Vorname',  flex: 1, },
    { field: 'LastName', headerName: 'Nachname',  flex: 1, },
    { field: 'Email', headerName: 'E-Mail-Adresse', flex: 1, },
    {
      field: 'Actions',
      headerName: "Aktionen",
      flex: 1,
      renderCell: (params: GridRenderCellParams) : React.ReactNode => {
        return(
            <>
              <IconButton
                  title='Mandanten öffnen'
                  onClick={() =>{
                    window.open(`/clients/${params.row.idContact}`, "_blank")
                  }}
              ><Link/></IconButton>
              <IconButton
                  title='Mandanten Löschen'
                  onClick={() => removeClient(params.row.idContact)}
              ><Delete/></IconButton>
            </>
        )
      }
    }
  ];

  //

  //

  return(
      <>

        {(props.setLegalRoleArray !== undefined && props.legalRoleCanInviteArray !== undefined && props.setLegalRoleCanInviteArray !== undefined) &&
            <Box sx={{mb: 2}}>
              <LegalRoleEditDialog
                  contactArray={[]}
                  chosenContactArray={props.chosenContactArray}
                  legalRoleArray={props.legalRoleArray}
                  setLegalRoleArray={props.setLegalRoleArray}
                  legalRoleCanInviteArray={props.legalRoleCanInviteArray}
                  setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
              />
              <br/>
            </Box>
        }

        <Typography variant="h5">
          Mandanten
          <LegalTransactionAddClientNew
              allContactArray={props.allContactArray}
              legalRoleArray={props.legalRoleArray}
              setContactArray={props.setAllContactArray}
              chosenContactArray={props.chosenContactArray}
              setChosenContactArray={props.setChosenContactArray}
          />
        </Typography>

        <DataGrid
            sx={{mt: 2}}
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={[...props.chosenContactArray]}
            columns={columns}
            getRowId={(row) => row.idContact}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            disableRowSelectionOnClick
        />
      </>
  )
}
