import { Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import rollenbezeichnung from "../jsons/rollenbezeichnung.json";
import währung2 from "../jsons/währung.json";

interface IProps {
  currentPersonWithHafteinlagen: any;
  alleBeteiligungen: any;
  zusatzKG: any;
  setZusatzKG: Function;
}

export const PersonWithhafteinlagenRow: React.FC<IProps> = (props) => {
  //
  // Funktion zum Umwandeln des Arrays in ein Objekt
  const convertToObject = (datenArray: string[][]): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, name]) => {
      result[code] = name;
    });
    return result;
  };
  //
  const rollenbezeichnungObjekt = convertToObject(rollenbezeichnung.daten);
  const währungsObjekt = convertToObject(währung2.daten);
  //
  const rollenbezeichnungMap = new Map(
    rollenbezeichnung.daten.map(([code, wert]) => [code, wert])
  );
  const währungsMap = new Map(
    währung2.daten.map(([code, wert]) => [code, wert])
  );
  //
  const valueToKeyRollenbezeichnungMap = new Map(
    Array.from(rollenbezeichnungMap.entries()).map(([key, value]) => [
      value,
      key,
    ])
  );
  const valueToKeyWährungsMap = new Map(
    Array.from(währungsMap.entries()).map(([key, value]) => [value, key])
  );
  //
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [currentPerson, setCurrentPerson] = useState(
    props.currentPersonWithHafteinlagen
  );
  const [editedPerson, setEditedPerson] = useState(
    JSON.parse(JSON.stringify(currentPerson))
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [hafteinlage, setHafteinlage] = useState<string>(
    currentPerson?.["tns:hafteinlage"]?.["tns:zahl"]
  );
  const [währung, setWährung] = useState(
    currentPerson?.["tns:hafteinlage"]?.["tns:waehrung"]?.["code"]
  );

  useEffect(() => {
    setCurrentPerson(props.currentPersonWithHafteinlagen);
  }, [props.currentPersonWithHafteinlagen]);

  // handleOpen function
  const handleOpen = () => {
    setHafteinlage(currentPerson?.["tns:hafteinlage"]?.["tns:zahl"]);
    setWährung(currentPerson?.["tns:hafteinlage"]?.["tns:waehrung"]?.["code"]);
    setEditedPerson(JSON.parse(JSON.stringify(currentPerson)));
    setIsOpen(true);
  };

  // handle Save function
  const handleSave = () => {
    const updatedPerson = props.zusatzKG["tns:datenKommanditist"];
    const updated = updatedPerson.map((x: any) =>
      x["tns:ref.rollennummer"] === currentPerson["tns:ref.rollennummer"]
        ? editedPerson
        : x
    );
    setSaveButtonDisabled(true);
    const newZusatzKG = {
      ...props.zusatzKG, // Die anderen Eigenschaften von zusatzKG beibehalten
      "tns:datenKommanditist": updated, // Das aktualisierte Array setzen
    };
    // Setzen des aktualisierten Objekts
    props.setZusatzKG(newZusatzKG);
    setCurrentPerson(editedPerson);
    setIsOpen(false);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  //

  //

  /**
   * useEffects
   */

  useEffect(() => {
    if (
      editedPerson?.["tns:hafteinlage"]?.["tns:zahl"] ||
      editedPerson?.["tns:hafteinlage"]?.["tns:zahl"] === ""
    ) {
      editedPerson["tns:hafteinlage"]["tns:zahl"] = hafteinlage;
    }
    if (
      editedPerson?.["tns:hafteinlage"]?.["tns:waehrung"]?.["code"] ||
      editedPerson?.["tns:hafteinlage"]?.["tns:waehrung"]?.["code"] === ""
    ) {
      editedPerson["tns:hafteinlage"]["tns:waehrung"]["code"] = währung;
    }
  }, [hafteinlage, währung]);
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <form ref={formRef}>
            <Grid container spacing={2} mt={2}>
              <Grid item sm={12}>
                <Typography variant="h5">Hafteinlagen</Typography>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Hafteinlage"
                  value={hafteinlage}
                  required
                  onChange={(e) => {
                    setHafteinlage(e.target.value);
                    setSaveButtonDisabled(false);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12}>
                <Autocomplete
                  options={Object.values(währungsObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  value={währungsMap.get(währung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setWährung(valueToKeyWährungsMap.get(newValue));
                      setSaveButtonDisabled(false);
                    } else {
                      setWährung(""); // Optional: handle the case where no value is selected
                      setSaveButtonDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Währung"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              setSaveButtonDisabled(true);
            }}
          >
            Abbruch
          </Button>
          <Button
            variant="contained"
            onClick={handleOnSave}
            disabled={saveButtonDisabled}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow>
        <TableCell>
          {rollenbezeichnungMap.get(
            props.alleBeteiligungen[0]?.find(
              (x: any) =>
                x["tns:rolle"]?.["tns:rollennummer"] ===
                props.currentPersonWithHafteinlagen["tns:ref.rollennummer"]
            )?.["tns:rolle"]?.["tns:rollenbezeichnung"]?.["code"]
          )}
        </TableCell>
        <TableCell>
          {
            props.alleBeteiligungen[0]?.find(
              (x: any) =>
                x["tns:rolle"]?.["tns:rollennummer"] ===
                props.currentPersonWithHafteinlagen["tns:ref.rollennummer"]
            )?.["tns:beteiligter"]["tns:auswahl_beteiligter"][
              "tns:natuerlichePerson"
            ]["tns:vollerName"]["tns:vorname"]
          }{" "}
          {
            props.alleBeteiligungen[0]?.find(
              (x: any) =>
                x["tns:rolle"]?.["tns:rollennummer"] ===
                props.currentPersonWithHafteinlagen["tns:ref.rollennummer"]
            )?.["tns:beteiligter"]["tns:auswahl_beteiligter"][
              "tns:natuerlichePerson"
            ]["tns:vollerName"]["tns:nachname"]
          }
        </TableCell>
        <TableCell>
          {props.currentPersonWithHafteinlagen["tns:hafteinlage"]?.["tns:zahl"]}
        </TableCell>
        <TableCell>
          {währungsMap.get(
            props.currentPersonWithHafteinlagen["tns:hafteinlage"]?.[
              "tns:waehrung"
            ]?.["code"]
          )}
        </TableCell>
        {/*Aktionen */}
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          
        </TableCell>
      </TableRow>
    </>
  );
};
