import { FederalStateDetail } from './FederalStateDetail';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import type { IFederalState } from '../../Interfaces/IAemter';
import { useState } from 'react';

import { useFederalStates } from '../hooks';

export const FederalStatesTab: React.FC = () => {
  const { federalStates, isLoadingFederalStates } = useFederalStates();

  const [selectedFederalState, setSelectedFederalState] =
    useState<IFederalState | null>(null);

  if (isLoadingFederalStates)
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );

  if (!federalStates) return <div>Fehler beim Laden der Daten</div>;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={5}
              >
                <Typography variant='h5'>Bundesländer</Typography>
              </Box>
              <Box>
                {federalStates.map(federalState => (
                  <Box
                    key={federalState.idFederalState}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mb={2}
                  >
                    <Typography>{federalState.FederalState}</Typography>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setSelectedFederalState(federalState)}
                    >
                      Bearbeiten
                    </Button>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {selectedFederalState && (
        <FederalStateDetail
          federalState={selectedFederalState}
          onClose={() => setSelectedFederalState(null)}
        />
      )}
    </>
  );
};
