import React, { useEffect, useState } from "react";
import { IDocumentAnalysisAttributeValue } from "../../Interfaces/IDocumentAnalysisAttributeValue";
import { TableCell } from "@mui/material";
import { CustomeTextField } from "../../generic/CustomeTextField";


interface IProps {
    attrValue: IDocumentAnalysisAttributeValue;
    attrValueArray: IDocumentAnalysisAttributeValue[];
    setAttrValueArray: Function;
}


export const DocumentAnalysisAttributeValue:React.FC<IProps> = ({attrValue,attrValueArray,setAttrValueArray}) => {
    const [editAttrValue,setEditAttrValue] = useState(attrValue);


    useEffect(() => {
        setAttrValueArray([
            ...attrValueArray.map(x => x.idDocumentAnalysisAttributeValue === editAttrValue.idDocumentAnalysisAttributeValue ? editAttrValue : x)
        ])
    },[editAttrValue])


    return(
        <TableCell sx={{border: "solid", m: 0, p: 0}}>
            <CustomeTextField
                attr="Value"
                label=""
                object={editAttrValue}
                setObject={setEditAttrValue}
                type="string"
                rows={3}
            />
        </TableCell>
    )
}
