import React, { useEffect, useState } from "react";
import { ICompanyFormation, ICompanyFormationFullObject } from "../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../Interfaces/ICompanyFormationHasShareHolder";
import { Summary } from "./Childs/Summary";
import { Box, Typography } from "@mui/material";
import { ExportCompanyFormation } from "./ExportCompanyFormation";
import { ShareHolderRepresentationWarning } from "./Childs/ShareHolder/ShareHolderRepresentationWarning";
import { ICompanyFormationAnswer } from "../../Interfaces/ICompanyFormationAnswer";
import {ILegalTransaction, ILegalTransactionFullObject} from "../../Interfaces/ILegalTransaction";


interface IProps {
    companyFormationObject: ICompanyFormationFullObject;
    setCompanyFormationObject: Function;}

export const CompanyFormationMain:React.FC<IProps> = (props) => {
    const [companyFormationObject, setCompanyFormationObject] = useState<ICompanyFormation>(props.companyFormationObject)
    const [shareHolderArray, setShareHolderArray] = useState<IShareHolderFullObject[]>(props.companyFormationObject.ShareHolderArray);
    const [companyFormationShareArray, setCompanyFormationShareArray] = useState<ICompanyFormationShare[]>(props.companyFormationObject.CompanyFormationShareArray);
    const [shareHolderRelation,setShareHolderRelation] = useState<ICompanyFormationHasShareHolder[]>(props.companyFormationObject.CompanyFormationHasShareHolderArray);
    const [companyFormationAnswerArray, setCompanyFormationAnswerArray] = useState<ICompanyFormationAnswer[]>(props.companyFormationObject.CompanyFormationAnswerArray);



    useEffect(() => {
        props.setCompanyFormationObject({
            ...companyFormationObject,
            ShareHolderArray: shareHolderArray,
            CompanyFormationHasShareHolderArray: shareHolderRelation,
            CompanyFormationShareArray: companyFormationShareArray,
            CompanyFormationAnswerArray: companyFormationAnswerArray
        } as ICompanyFormationFullObject)
    },[companyFormationObject,shareHolderArray,companyFormationShareArray,shareHolderRelation,companyFormationAnswerArray])

    return(
        <>

                <Box sx={{float: "right"}}>
                    <ExportCompanyFormation
                        idCompanyFormation={props.companyFormationObject.idCompanyFormation}
                    />
                </Box>


            <ShareHolderRepresentationWarning
                shareHolderArray={shareHolderArray}
                shareHolderRelationArray={shareHolderRelation}
            />

            <Summary
                companyFormationObject={companyFormationObject}
                setCompanyFormationObject={setCompanyFormationObject}
                companyFormationShareArray={companyFormationShareArray}
                setCompanyFormationShareArray={setCompanyFormationShareArray}
                shareHolderArray={shareHolderArray}
                setShareHolderArray={setShareHolderArray}
                shareHolderRelation={shareHolderRelation}
                setShareHolderRelation={setShareHolderRelation}
                companyFormationAnswerArray={companyFormationAnswerArray}
                setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
            />
        </>
    )
}