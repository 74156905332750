import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  Checkbox,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Done, Edit, Cancel } from '@mui/icons-material';
import { IFederalState } from '../../Interfaces/IAemter';
import { useAlert } from '../../context/AlertContext';
import { useFederalStates, useUpdateFederalState } from '../hooks';

interface FederalStateDetailProps {
  federalState: IFederalState;
  onClose: () => void;
}

export const FederalStateDetail: React.FC<FederalStateDetailProps> = ({
  federalState,
  onClose,
}) => {
  const { showAlert } = useAlert();

  const { isPendingUpdateFederalState, updateFederalState } =
    useUpdateFederalState();

  const { refetchFederalStates } = useFederalStates();

  const [isEditingPasswort, setIsEditingPasswort] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [editableFederalState, setEditableFederalState] =
    useState<IFederalState>(federalState);
  const [isEditingUrl, setIsEditingUrl] = useState(false);

  const handleUpdateFederalState = async (
    updatedFederalState: IFederalState
  ) => {
    updatedFederalState.landRegisterNeedLoginFirstInstance =
      updatedFederalState.landRegisterNeedLoginFirstInstance !== undefined
        ? Boolean(updatedFederalState.landRegisterNeedLoginFirstInstance)
        : false;

    updatedFederalState.landRegisterSIData =
      updatedFederalState.landRegisterSIData !== undefined
        ? Boolean(updatedFederalState.landRegisterSIData)
        : false;

    try {
      await updateFederalState(updatedFederalState);

      refetchFederalStates();

      showAlert({
        severity: 'success',
        text: 'Erfolgreich gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Speichern',
      });
    }
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterUser: event.target.value,
    });
  };

  const handleSaveUser = () => {
    setIsEditingUser(false);
  };

  const handleCancelEditUser = () => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterUser: federalState!.landRegisterUser,
    });
    setIsEditingUser(false);
  };

  const handlePasswortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterPassword: event.target.value,
    });
  };

  const handleSavePasswort = () => {
    setIsEditingPasswort(false);
  };

  const handleCancelPasswortEdit = () => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterPassword: federalState!.landRegisterPassword,
    });
    setIsEditingPasswort(false);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterUrl: event.target.value,
    });
  };

  const handleSaveUrl = () => {
    setIsEditingUrl(false);
  };

  const handleCancelUrlEdit = () => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterUrl: federalState!.landRegisterUrl,
    });
    setIsEditingUrl(false);
  };

  const handleSIDataChange = () => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterSIData: !editableFederalState!.landRegisterSIData,
    });
  };

  const handleNeedLoginFirstIntanceChange = () => {
    setEditableFederalState({
      ...editableFederalState!,
      landRegisterNeedLoginFirstInstance:
        !editableFederalState!.landRegisterNeedLoginFirstInstance,
    });
  };

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth='md'>
        <DialogTitle>{federalState.FederalState} Übersicht</DialogTitle>
        <Divider />
        <DialogContent>
          <Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mb={3}
            >
              <Typography variant='h6'>
                Benutzer für erste Anmeldung:
              </Typography>
              {isEditingUser ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <TextField
                    value={editableFederalState.landRegisterUser || ''}
                    onChange={handleUserChange}
                    size='small'
                    fullWidth
                    type='text'
                  />
                  <IconButton onClick={handleSaveUser}>
                    <Done color='success' />
                  </IconButton>
                  <IconButton onClick={handleCancelEditUser}>
                    <Cancel color='error' />
                  </IconButton>
                </Box>
              ) : (
                <Box display='flex' alignItems='center' gap={1}>
                  <Typography>
                    {editableFederalState.landRegisterUser || ''}
                  </Typography>
                  <IconButton onClick={() => setIsEditingUser(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mb={3}
            >
              <Typography variant='h6'>Link zum Grundbuchportal</Typography>
              {isEditingUrl ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <TextField
                    value={editableFederalState.landRegisterUrl || ''}
                    onChange={handleUrlChange}
                    size='small'
                    fullWidth
                    type='url'
                  />
                  <IconButton onClick={handleSaveUrl}>
                    <Done color='success' />
                  </IconButton>
                  <IconButton onClick={handleCancelUrlEdit}>
                    <Cancel color='error' />
                  </IconButton>
                </Box>
              ) : (
                <Box display='flex' alignItems='center' gap={1}>
                  <Typography>
                    {editableFederalState.landRegisterUrl || ''}
                  </Typography>
                  <IconButton onClick={() => setIsEditingUrl(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mb={3}
            >
              <Typography variant='h6'>
                Passwort für erste Anmeldung:
              </Typography>
              {isEditingPasswort ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <TextField
                    value={editableFederalState.landRegisterPassword || ''}
                    onChange={handlePasswortChange}
                    size='small'
                    fullWidth
                    type='password'
                  />
                  <IconButton onClick={handleSavePasswort}>
                    <Done color='success' />
                  </IconButton>
                  <IconButton onClick={handleCancelPasswortEdit}>
                    <Cancel color='error' />
                  </IconButton>
                </Box>
              ) : (
                <Box display='flex' alignItems='center' gap={1}>
                  <Typography>
                    {'●'.repeat(
                      editableFederalState.landRegisterPassword?.length || 8
                    )}
                  </Typography>
                  <IconButton onClick={() => setIsEditingPasswort(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mb={3}
            >
              <Typography variant='h6'>
                Werden Grundbuchportal XJustiz-konforme Dokumente unterstützt?
              </Typography>
              <Checkbox
                checked={editableFederalState.landRegisterSIData || false}
                onChange={handleSIDataChange}
              />
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mb={3}
            >
              <Typography variant='h6'>
                Anmeldung vor Grundbuchportal (i.d.R. SolumWEB) notwendig?
              </Typography>
              <Checkbox
                checked={
                  editableFederalState.landRegisterNeedLoginFirstInstance ||
                  false
                }
                onChange={handleNeedLoginFirstIntanceChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Schließen
          </Button>
          <Button
            onClick={() => handleUpdateFederalState(editableFederalState)}
            variant='contained'
            disabled={isPendingUpdateFederalState}
          >
            {isPendingUpdateFederalState ? (
              <Box display='flex' alignItems='center' gap={1}>
                <span>Speichern</span>
                <CircularProgress size={20} />
              </Box>
            ) : (
              'Speichern'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
