import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert, Box,
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem,
    Radio, RadioGroup, Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Typography
} from "@mui/material";

import ClearIcon from '@mui/icons-material/Clear';
import {IHandelsregister} from "../../Interfaces/IHandelsregister";
import {CustomCircularProgress} from "../CustomCircularProgress";
import {useFetch} from "../../hooks/useFetch";
import {IDistrictCourt} from "../../Interfaces/IDistrictCourt";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import {ILegalTransactionSpecialFunctions} from "../../Interfaces/ILegalTransactionSpecialFunctions";
import {
    ILegalTransaction_has_LegalTransactionSpecialFunction
} from "../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction";


interface IProps {
    openImportFromHandelsregister: boolean;
    setOpenImportFromHandelsregister: Function;

    wasSuccessfullImportedFromhandelsregister: boolean;
    setWasSuccessfullImportedFromhandelsregister: Function;

    handleFieldChangeOnImport?: (index: number, updates: { [key: string]: string }) => void;
    index?: number; // Neue Prop

    isXMLImport: boolean;
    setXMLString?: Function;
}

interface Rechtsform {
    value: string;
    label: string;
}


const bundeslaender = [
    'Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen',
    'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland',
    'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen'
];

const registerArten = ['alle', 'HRA', 'HRB', 'GnR', 'PR', 'VR'];

export const HandelsregisterSearch: React.FC<IProps> = (props) => {
    const [searchString, setSearchString] = useState("");
    const [selectedSearch, setSelectedSearch] = useState({court: '', name: '', state: '', status: ''});
    const [isLoading, setIsLoading] = useState(false)

    const [showNotFoundMessage, setShowNotFoundMessage] = useState(false)
    const [showTimeoutMessage, setShowTimeoutMessage] = useState(false)
    const [showImportSuccesfullMessage, setShowImportSuccesfullMessage] = useState(false)
    const [showWasSuccessfully, setShowWasSuccessfully] = useState(false);

    const [handelsreisterSearchRes, setHandelsreisterSearchRes] = useState<null | undefined | IHandelsregister[]>(null);


    const [companyName, setCompanyName] = useState("")

    //Suchoptionen

    //const [districtCourtArray, setDistrictCourtArray, wasSuccessfullDistrictCourtArray] = useFetch<IDistrictCourt[]>("/districtcourt");
    const [tradeRegisterArray, setTradeResgisterArray] = useState<IDistrictCourt[]>([])

    const [schlagwortOptionen, setSchlagwortOptionen] = React.useState(1);

    const [checkedSuchOptionenAehnlich, setCheckedSuchOptionenAehnlich] = React.useState(false);
    const [checkedSuchOptionenGeloescht, setCheckedSuchOptionenGeloescht] = React.useState(false);
    const [checkedSuchOptionenNurZNneuenRechts, setCheckedSuchOptionenNurZNneuenRechts] = React.useState(false);

    const [form_niederlassung, setForm_niederlassung] = useState("")

    const [iDSelectedCourt, setIDSelectedCourt] = useState<IDistrictCourt>();
    const [searchTerm, setSearchTerm] = useState('');

    const [form_registernummer, setForm_registernummer] = useState("")

    const [form_postleitzahl, setForm_postleitzahl] = useState("")
    const [form_ort, setForm_ort] = useState("")
    const [form_strasse, setForm_strasse] = useState("")

    const [checkedStates, setCheckedStates] = useState<CheckedStates>(
        bundeslaender.reduce((acc, land) => ({...acc, [land]: false}), {})
    );

    const [registerArt, setRegisterArt] = useState('alle');
    const [isCheckedStatesAllowed, setIsCheckedStatesAllowed] = useState(true)
    const [selectedRechtsform, setSelectedRechtsform] = useState<string>('');

    type CheckedStates = {
        [key: string]: boolean;
    };


    useEffect(() => {
        let trueCount = Object.values(checkedStates).filter(value => value).length;
        if (trueCount > 2) {
            setIsCheckedStatesAllowed(false)
        } else {
            setIsCheckedStatesAllowed(true)
        }
    }, [checkedStates]);



    const handleChangeRegisterArt = (event: SelectChangeEvent<string>) => {
        setRegisterArt(event.target.value as string);
    };


    const handleChangeBundeslaender = (event: { target: { name: any; checked: any; }; }) => {
        setCheckedStates({
            ...checkedStates,
            [event.target.name]: event.target.checked,
        });
    };


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        switch (name) {
            case 'Aehnlich':
                setCheckedSuchOptionenAehnlich(checked);
                break;
            case 'Geloescht':
                //setCheckedSuchOptionenGeloescht(checked);
                break;
            case 'NurZNneuenRechts':
                setCheckedSuchOptionenNurZNneuenRechts(checked);
                break;
            default:
                break;
        }
    };

    const handleChangeSchlagwortOptionen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchlagwortOptionen(Number((event.target as HTMLInputElement).value));
    };


    const handleClose = () => {
        props.setOpenImportFromHandelsregister(false)
    };


    const handleImportSuccess = (selectedSearch: any) => {
        if(!props.isXMLImport && props.index !== undefined && props.handleFieldChangeOnImport !== undefined)
        {
            props.handleFieldChangeOnImport(props.index, {
                Function_Field2: selectedSearch.name,
                Function_Field4: selectedSearch.court,
                Res_Field1: getRegisterTypeAndNumber(selectedSearch.court) || ""
            });
            props.setWasSuccessfullImportedFromhandelsregister(true);
            props.setOpenImportFromHandelsregister(false); // Schließt den Dialog nach erfolgreichem Import
        }
        if (props.isXMLImport) {
            let specialFunction = {
                Function_Field2: selectedSearch.name,
                Function_Field4: selectedSearch.court,
            } as ILegalTransaction_has_LegalTransactionSpecialFunction;

            fetch(`/api/legaltransactionspecialfunctions/getSI`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("SessionToken")}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/xml' // Wichtig: Fordere XML als Antwort
                },
                body: JSON.stringify(specialFunction)
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.text(); // Hier XML als Text zurückgeben
                    } else {
                        throw new Error(`ERROR GET: ${res.status}`);
                    }
                })
                .then(xmlText => {
                     // Hier ist der XML-Text
                    // Setze den XML-String in den State, nachdem du den Text erhalten hast
                    if (props.setXMLString !== undefined) {
                        props.setXMLString(xmlText); // Übergib den XML-Text, nicht das Response-Objekt
                        props.setWasSuccessfullImportedFromhandelsregister(true);
                        props.setOpenImportFromHandelsregister(false); // Schließt den Dialog nach erfolgreichem Import
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    };

    function getRegisterTypeAndNumber(courtString: string): string {
        const match = courtString.match(/\b(HR[AB]|GnR|PR|VR) (\d+)\b/);
        if (match) {
            return `${match[1]} ${match[2]}`;
        }
        return "";
    }


    const setShowMessages = (showNotFound: boolean, showTimeout: boolean, showImportSuccessfull: boolean) => {
        setShowNotFoundMessage(showNotFound)
        setShowTimeoutMessage(showTimeout)
        setShowImportSuccesfullMessage(showImportSuccessfull)
    }
    const timeoutFunction = () => {
        async function tryTimeoutFunction() {
            setTimeout(() => {
                if (showImportSuccesfullMessage && showNotFoundMessage) {
                    setShowMessages(false, true, false)
                }
                setIsLoading(false)
            }, 60000);
        }

        tryTimeoutFunction();
    }


    const searchFromHandelsregister = () => {
        timeoutFunction()
        setCompanyName(searchString)

        async function trySearchCompanyFormationFromHandelsregister() {
            const payload = {
                searchString: searchString,
                schlagwortOptionen: schlagwortOptionen,
                suchOptionen: {
                    aehnlich: checkedSuchOptionenAehnlich,
                    geloescht: checkedSuchOptionenGeloescht,
                    nurZNneuenRechts: checkedSuchOptionenNurZNneuenRechts
                },
                niederlassung: form_niederlassung,
                checkedStates: checkedStates,
                registerArt: registerArt,
                form_registernummer: form_registernummer,
                iDSelectedCourt: iDSelectedCourt,
                selectedRechtsform: selectedRechtsform,
                form_postleitzahl: form_postleitzahl,
                form_ort: form_ort,
                form_strasse: form_strasse,
            };


            await fetch("/api/companyformation/searchCompanyFromHandelsregister", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY"
                },
                body: JSON.stringify(payload)
            })
                .then(res => {
                    if (res.status === 200) {
                        setShowMessages(false, false, false)
                        return res.json();
                    }
                    if (res.status === 204) {
                        setIsLoading(false)
                        setShowMessages(true, false, false)
                        return res.json();
                    }
                })
                .then(res => {
                    setShowNotFoundMessage(false)
                    setShowTimeoutMessage(false)
                    setIsLoading(false)
                    setHandelsreisterSearchRes(res);
                })
                .catch(error => {
                })

        }

        trySearchCompanyFormationFromHandelsregister();
    }


    return <>
        <Dialog open={props.openImportFromHandelsregister} onClose={handleClose}
                PaperProps={{
                    style: {minWidth: '800px', minHeight: '100px', paddingTop: '10px'},
                }}>
            <DialogTitle>
                <Grid container>
                    <Grid item sm={11}>
                        Unternehmendaten aus dem Handelsregister importieren
                    </Grid>
                    <Grid item sm={1}>
                        <IconButton onClick={() => handleClose()}><ClearIcon/></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {isLoading ?
                    <>
                        <DialogContentText>
                            Bitte Warten
                        </DialogContentText>
                        <CustomCircularProgress></CustomCircularProgress>
                    </>

                    : (showWasSuccessfully) ?
                        <>
                            <Alert severity="success">
                                Die Gesellschaft <i>{selectedSearch.name}</i> konnte erfolgreich gefunden und importiert
                                werden.
                            </Alert>

                            <Button
                                sx={{float: "right", mt: 2}}
                                variant="contained"
                                onClick={() => props.setWasSuccessfullImportedFromhandelsregister(true)}
                            >Okay</Button>
                        </>
                        : (handelsreisterSearchRes != undefined) ?
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width: 100}}>Auswählen</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Amtsgericht</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {handelsreisterSearchRes.map(x =>
                                            <TableRow>
                                                <TableCell onClick={() => setSelectedSearch(x)}>
                                                    <Radio
                                                        checked={x.name === selectedSearch.name}
                                                    />
                                                </TableCell>
                                                <TableCell>{x.name}</TableCell>
                                                <TableCell>{x.court}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <Box sx={{mt: 1, display: 'flex', flexDirection: 'row'}}>
                                    <Button variant="outlined" onClick={() => {
                                        setSearchString("");
                                        setIsLoading(false)
                                        setShowNotFoundMessage(false)
                                        setShowTimeoutMessage(false)
                                        setHandelsreisterSearchRes(null);
                                    }}>Zurück</Button>
                                    <Box sx={{flex: '1 1 auto'}}/>

                                    <Button variant="contained" disabled={selectedSearch.name === ""} onClick={() => {
                                        //getCompanyFormationFromHandelsregister();
                                        handleImportSuccess(selectedSearch)
                                        setShowNotFoundMessage(false)
                                        setShowTimeoutMessage(false)
                                        setIsLoading(true)
                                    }}>Importieren</Button>
                                </Box>
                            </>
                            :
                            <>
                                {showNotFoundMessage &&
                                    <Alert variant="filled" severity="info">
                                        Es wurde keine Firma "{companyName}" gefunden
                                    </Alert>
                                }
                                {showTimeoutMessage &&
                                    <Alert variant="filled" severity="warning">
                                        Die Anfrage hat zu lange gedauert
                                    </Alert>
                                }
                                {showImportSuccesfullMessage &&
                                    <Alert variant="filled" severity="success">
                                        Firmendaten von "{companyName}" erfolgreich Importiert
                                    </Alert>
                                }


                                <Grid container spacing={1}>

                                    <Grid item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon/>}
                                                aria-controls="bundeslaender-content"
                                                id="bundeslaender"
                                            >
                                                <Typography>Bundesländer</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {bundeslaender.map((land, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={checkedStates[land]}
                                                                        onChange={handleChangeBundeslaender}
                                                                        name={land}
                                                                    />
                                                                }
                                                                label={land}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="searchString"
                                            label={"Firma oder Schlagwörter"}
                                            value={searchString}
                                            fullWidth
                                            required
                                            variant="outlined"
                                            onChange={e => {
                                                setSearchString(e.target.value)
                                            }}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography>Suche nach Einträgen, die</Typography>
                                        </Grid>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={schlagwortOptionen}
                                                onChange={handleChangeSchlagwortOptionen}
                                            >
                                                <FormControlLabel value="1" control={<Radio/>}
                                                                  label="alle Schlagwörter enthalten"/>
                                                <FormControlLabel value="2" control={<Radio/>}
                                                                  label="mindestens ein Schlagwort enthalten"/>
                                                <FormControlLabel value="3" control={<Radio/>}
                                                                  label="den genauen Firmennamen enthalten"/>
                                            </RadioGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Aehnlich"
                                                        checked={checkedSuchOptionenAehnlich}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label="ähnlich lautende Schlagwörter enthalten."
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        Niederlassung / Sitz:
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            margin="dense"
                                            id="niederlassung"
                                            label={"Niederlassung"}
                                            value={form_niederlassung}
                                            variant="outlined"
                                            onChange={e => {
                                                setForm_niederlassung(e.target.value)
                                            }}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        Zusätzliche Suchoptionen:
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/*
                                         <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Geloescht"
                                                    checked={checkedSuchOptionenGeloescht}
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label="Auch gelöschte Firmen finden."
                                        />
                                        */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="NurZNneuenRechts"
                                                    checked={checkedSuchOptionenNurZNneuenRechts}
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label="Nur nach Zweigniederlassungen neuen Rechts suchen."
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon/>}
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                            >
                                                <Typography>Angaben nur zur Hauptniederlassung:</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Aktenzeichen:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel
                                                                id="registerArt-label">Registerart</InputLabel>
                                                            <Select
                                                                labelId="registerArt-label"
                                                                value={registerArt}
                                                                onChange={handleChangeRegisterArt}
                                                                label="Registerart"
                                                            >
                                                                {registerArten.map((art) => (
                                                                    <MenuItem key={art} value={art}>
                                                                        {art}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            autoFocus
                                                            fullWidth
                                                            margin="dense"
                                                            id="Registernummer"
                                                            label={"Registernummer:"}
                                                            value={form_registernummer}
                                                            variant="outlined"
                                                            onChange={e => {
                                                                setForm_registernummer(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>

                                                    {/*
                                                     <Grid item xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="court-select-label">Gericht (leer für
                                                                alle)</InputLabel>
                                                            <Select
                                                                labelId="court-select-label"
                                                                value={iDSelectedCourt}
                                                                onChange={handleChangeTradeRegister}
                                                                label="Gericht"
                                                            >
                                                                {filteredCourts.map((court: IDistrictCourt) => (
                                                                    <MenuItem key={court.idDistrictCourt}
                                                                              value={court.idDistrictCourt}>
                                                                        {court.Court} ({court.FederalState})
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    */}

                                                    {/*
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel
                                                                id="option-select-label">Rechtsform</InputLabel>
                                                            <Select
                                                                labelId="option-select-label"
                                                                value={selectedRechtsform}
                                                                onChange={handleChangeRechtsform}
                                                                label="Rechtsform"
                                                            >
                                                                {rechtsformen.map((option) => (
                                                                    <MenuItem key={option.value}
                                                                              value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    */}

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Adresse:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            autoFocus
                                                            fullWidth
                                                            margin="dense"
                                                            id="Postleitzahl"
                                                            label={"Postleitzahl:"}
                                                            value={form_postleitzahl}
                                                            variant="outlined"
                                                            onChange={e => {
                                                                setForm_postleitzahl(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            autoFocus
                                                            fullWidth
                                                            margin="dense"
                                                            id="Ort"
                                                            label={"Ort:"}
                                                            value={form_ort}
                                                            variant="outlined"
                                                            onChange={e => {
                                                                setForm_ort(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            autoFocus
                                                            fullWidth
                                                            margin="dense"
                                                            id="Straße"
                                                            label={"Straße:"}
                                                            value={form_strasse}
                                                            variant="outlined"
                                                            onChange={e => {
                                                                setForm_strasse(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>


                                </Grid>
                                <Box sx={{mt: 1, display: 'flex', flexDirection: 'row'}}>
                                    <Button variant="outlined" onClick={() => {
                                        setIsLoading(false)
                                        setShowNotFoundMessage(false)
                                        setShowTimeoutMessage(false)
                                        props.setOpenImportFromHandelsregister(false)
                                    }}>Zurück</Button>
                                    <Box sx={{flex: '1 1 auto'}}/>

                                    <Button variant="contained" disabled={searchString === ""} onClick={() => {
                                        setSelectedSearch({court: '', name: '', state: '', status: ''});
                                        searchFromHandelsregister();
                                        setShowNotFoundMessage(false)
                                        setShowTimeoutMessage(false)
                                        setIsLoading(true)
                                    }}>Suche</Button>
                                </Box>
                            </>
                }
            </DialogContent>

        </Dialog>

    </>
}