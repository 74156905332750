import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';
import { LegalRequirementDeadlineRow } from './LegalRequirementDeadlineRow';

interface IProps {
  deadlineArray?: ILegalRequirementDeadline[] | null;
  setDeadline: Function;
  deleteDeadline: Function;
}

export const LegalRequirementDeadlineTable: React.FC<IProps> = props => {
  const sortArrayByWeighting = () => {
    if (props.deadlineArray) {
      return props.deadlineArray.sort((a, b) => {
        return b.Weighting! - a.Weighting!;
      });
    }
    return [];
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Art</TableCell>
            <TableCell>Referenz</TableCell>
            <TableCell>
              <Tooltip title='Bei mehreren eingetroffenen Bedingungen zählt die mit der höchsten Gewichtung. Sollten mehrere Bedingungen die gleiche Gewichtung haben, zählt die zuerst eingetretene Bedingung.'>
                <span>Gewichtung</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title='Wurde die Bedingung bereits ausgelöst?'>
                <span>Status der Bedingung</span>
              </Tooltip>
            </TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortArrayByWeighting().map((x, index) => (
            <LegalRequirementDeadlineRow
              key={`idLegalRequirementTemplateDeadline-${x.idDeadline}`}
              currentDeadline={x}
              setDeadline={(newDeadline: ILegalRequirementDeadline) => {
                props.setDeadline(newDeadline);
              }}
              deleteDeadline={() => props.deleteDeadline(index)}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
