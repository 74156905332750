import React, { useEffect, useState } from "react";
import { IDocumentAnalysisType } from "../../Interfaces/IDocumentAnalysisType";
import { IDocumentAnalysisSection } from "../../Interfaces/IDocumentAnalysisSection";
import { IDocumentAnalysisSubsection } from "../../Interfaces/IDocumentAnalysisSubsection";
import { IDocumentAnalysisAttribute } from "../../Interfaces/IDocumentAnalysisAttribute";
import { IDocumentAnalysisSubsectionEntryFullObject } from "../../Interfaces/IDocumentAnalysisSubsectionEntry";
import { Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { DocumentAnalysisSectionView, getEmptyDocumentAnalysisSubsectionEntryFullObject } from "../DocumentAnalysis/DocumentAnalysisSectionView";
import { IDocumentAnalysisAttributeValue } from "../../Interfaces/IDocumentAnalysisAttributeValue";


interface IProps {
    isEnableHelper: boolean;
    setIsEnableHelper: Function;
    //
    resultArray: string[][];
    selectArray: boolean[][];
    setSelectArray: Function;
    //
    documentAnalysisTypeArray: IDocumentAnalysisType[];
    documentAnalysisSectionArray: IDocumentAnalysisSection[];
    documentAnalysisSubsectionArray: IDocumentAnalysisSubsection[];
    documentAnalysisAttributeArray: IDocumentAnalysisAttribute[];
    //
    sectionEntryArray: IDocumentAnalysisSubsectionEntryFullObject[];
    setSectionEntryArray: Function;
    //
    refreshParent: number;
    setRefreshParent: Function;
}


export const DocumentAssistentValueHelper:React.FC<IProps> = ({
    isEnableHelper, setIsEnableHelper,
    resultArray, selectArray, setSelectArray,
    documentAnalysisTypeArray, documentAnalysisSectionArray, documentAnalysisSubsectionArray, documentAnalysisAttributeArray,
    sectionEntryArray, setSectionEntryArray,
    refreshParent, setRefreshParent
}) => {
    const [isOpen,setIsOpen] = useState(false);
    const [selectedSection,setSelectedSection] = useState<number|null>(null);
    const [selectedSubsection,setSelectedSubsection] = useState<number|null>(null);
    //
    const [selecteSectionObject,setSelecteSectionObject] = useState<IDocumentAnalysisSection|null>(null);
    const [selectedSubsectionObject,setSelectedSubsectionObject] = useState<IDocumentAnalysisSubsection|null>(null);
    //
    const [editSubsectionEntry,setEditSubsectionEntry] = useState<IDocumentAnalysisSubsectionEntryFullObject|null>(null);
    //
    const [filteredStringArray,setFilteredStringArray] = useState<string[]>([]);
    const [selectedIdArray,setSelectedIdArray] = useState<(number|null)[]>([]);
    const [refresh,setRefresh] = useState(0);


    useEffect(() => {
        let tmpArray:string[] = [];
        let emptyStringArray: (number|null)[] = [];
        resultArray.map((res,idx) => {
            res.map((res2,idx2) => {
                if (selectArray[idx][idx2] === true) {
                    tmpArray.push(res2)
                    emptyStringArray.push(null)
                }
            })
        })
        setFilteredStringArray(tmpArray);
        setSelectedIdArray(emptyStringArray);
    },[resultArray,selectArray])


    const handleAdd = () => {
        setSectionEntryArray([
            ...sectionEntryArray,
            editSubsectionEntry
        ])

        setSelectArray([
            ...selectArray.map(l1 => l1.map(l2 => false))
        ])
        setRefreshParent(refreshParent+1);

        handleClose()
    }

    const handleClose = () => {
        setSelectedSection(null);
        setSelectedSubsection(null);
        setEditSubsectionEntry(null);
        //setIsOpen(true);
        setIsEnableHelper(false);
    }

    const handleChangesSubsection = (selectedId:number) => {
        let selectedSubsectionObject = documentAnalysisSubsectionArray.find(x => x.idDocumentAnalysisSubsection === selectedId);
        if (selectedSubsectionObject !== undefined) {
            setSelectedSubsectionObject(selectedSubsectionObject);
            setEditSubsectionEntry(getEmptyDocumentAnalysisSubsectionEntryFullObject(
                selectedSubsectionObject,
                sectionEntryArray,
                documentAnalysisAttributeArray

            ))
        }
    } 

    const handleInsert = () => {
        if (editSubsectionEntry !== null) {
            let tmpArray:IDocumentAnalysisAttributeValue[] = [];

            editSubsectionEntry.DocumentAnalysisAttributeValueArray.map(entryValue => {
                let txt = entryValue.Value;
                selectedIdArray.map((selectedId,idx) => {
                    if (selectedId === entryValue.idDocumentAnalysisAttribute) {
                        txt = txt + filteredStringArray[idx];
                    }
                })
                tmpArray.push({
                    ...entryValue,
                    Value: txt
                })
            })

            setEditSubsectionEntry({
                ...editSubsectionEntry,
                DocumentAnalysisAttributeValueArray: tmpArray
            })
            setRefresh(refresh+1);
        }
    }

    return(
        <>

            <Button sx={{float:"right", ml: 2}} disabled={editSubsectionEntry === null} variant="contained" onClick={handleAdd}>Hinzufügen</Button>
            <Button sx={{float:"right"}} variant="outlined" onClick={handleClose}>Abbruch</Button>
            

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        sx={{mt: 2}}
                        label="Sektion"
                        size="small"
                        fullWidth
                        value={selectedSection === null ? "" : selectedSection}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) =>{
                            let testSection = documentAnalysisSectionArray.find(x => x.idDocumentAnalysisSection === Number(event.target.value));
                            if (testSection !== undefined) {
                                setSelecteSectionObject(testSection);

                            }
                            else {
                                setSelecteSectionObject(null);
                            }
                            setSelectedSubsectionObject(null);
                            setSelectedSection(Number(event.target.value))
                            setSelectedSubsection(null);

                        }}
                        select
                    >
                        {documentAnalysisSectionArray.map(x =>
                            <MenuItem key={`select-section-${x.idDocumentAnalysisSection}`} value={x.idDocumentAnalysisSection}>
                                {x.DocumentAnalysisSection}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={selectedSection !== null}>
                        <TextField
                            sx={{mt: 2}}
                            label="Unterbereich"
                            size="small"
                            fullWidth
                            value={selectedSubsection === null ? "" : selectedSubsection}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) =>{
                                setSelectedSubsection(Number(event.target.value));
                                handleChangesSubsection(Number(event.target.value));
                            }}
                            select
                        >
                            {documentAnalysisSubsectionArray.filter(x => x.idDocumentAnalysisSection === selectedSection).map(x =>
                                <MenuItem key={`select-subsection-${x.idDocumentAnalysisSubsection}`} value={x.idDocumentAnalysisSubsection}>
                                    {x.DocumentAnalysisSubsection}
                                </MenuItem>
                            )}
                        </TextField>
                    </Collapse>
                </Grid>

            </Grid>
            <Box sx={{mt: 2}} />
            <Collapse in={editSubsectionEntry !== null}>
                <Table size="small">
                    <TableBody>
                        {filteredStringArray.map((x,idx) =>
                            <TableRow>
                                <TableCell>{x}</TableCell>
                                <TableCell sx={{width: 300}}>
                                    <TextField
                                        sx={{mt: 2}}
                                        label="Zielbereich"
                                        size="small"
                                        fullWidth
                                        value={selectedIdArray[idx] === null ? "" : selectedIdArray[idx]}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) =>{
                                            let changedArray = [...selectedIdArray];
                                            changedArray[idx] = Number(event.target.value);
                                            setSelectedIdArray(changedArray);
                                        }}
                                        select
                                    >
                                        {documentAnalysisAttributeArray.filter(x => x.idDocumentAnalysisSubsection === selectedSubsection).map(x =>
                                            <MenuItem key={`select-attr-${x.idDocumentAnalysisAttribute}`} value={x.idDocumentAnalysisAttribute}>
                                                {x.DocumentAnalysisAttribute}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </Collapse>

            <Button sx={{mt: 2,float: "right"}} variant="outlined" onClick={handleInsert}>Überführen</Button>

            <Collapse in={ selecteSectionObject !== null && selectedSubsectionObject !== null && editSubsectionEntry !== null}>
                {(selecteSectionObject !== null && selectedSubsectionObject !== null && editSubsectionEntry !== null) &&
                    <DocumentAnalysisSectionView
                        key={`inser-loader-${refresh}`}
                        documentAnalysisSection={selecteSectionObject}
                        documentAnalysisSubsectionArray={[selectedSubsectionObject]}
                        documentAnalysisAttributeArray={documentAnalysisAttributeArray}
                        //
                        sectionEntryArray={[editSubsectionEntry]}
                        setSectionEntryArray={(array:IDocumentAnalysisSubsectionEntryFullObject[]) => setEditSubsectionEntry(array[0])}
                        disableBtn
                    />
                }

            </Collapse>

            <Box>
                
            </Box>
        </>
    )

}