import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  type ICourtAdminstrativeUnitAssignment,
  type ICourt,
  type ICourtRole,
  type IAdminstrativeUnit,
} from '../../Interfaces/IAemter';
import { ClearIcon } from '@mui/x-date-pickers';

interface RoleAssignmentSelectProps {
  adminUnit: IAdminstrativeUnit;
  role: ICourtRole;
  assignment: ICourtAdminstrativeUnitAssignment | undefined;
  courts: ICourt[];
  allAssignments: ICourtAdminstrativeUnitAssignment[];
  onUpdateAssignment: React.Dispatch<{
    type: 'update' | 'create' | 'delete';
    payload: ICourtAdminstrativeUnitAssignment;
  }>;
}

export const RoleAssignmentSelect: React.FC<RoleAssignmentSelectProps> = ({
  adminUnit,
  role,
  assignment,
  courts,
  allAssignments,
  onUpdateAssignment,
}) => {
  const theme = useTheme();

  const selectedCourt =
    assignment && assignment.activeAssignment
      ? courts.find(court => court.idCourt === assignment.idCourt)
      : undefined;

  const handleChange = (event: SelectChangeEvent) => {
    const newSelectedCourt = courts.find(
      court => court.idCourt === Number(event.target.value)
    )!;

    const existingAssignment = allAssignments.find(
      assignment =>
        assignment.idCourtRole === role.idCourtRole &&
        assignment.idCourt === newSelectedCourt.idCourt
    );

    if (!existingAssignment) {
      // In this case, a new assignment needs to be created
      const newAssignment: ICourtAdminstrativeUnitAssignment = {
        idCourtRole: role.idCourtRole,
        idCourt: newSelectedCourt.idCourt,
        CourtRole: role.CourtRole,
        Court: newSelectedCourt.Court,
        FederalState: newSelectedCourt.FederalState,
        idFederalState: newSelectedCourt.idFederalState,
        idAdministrativeUnit: adminUnit.idAdministrativeUnit,
        AdministrativeUnit: adminUnit.AdministrativeUnit,
        idAssignment: -1,
        activeAssignment: true,
      };

      onUpdateAssignment({ type: 'create', payload: newAssignment });
    } else {
      // In this case, the assignment needs to be updated
      const updatedAssignment: ICourtAdminstrativeUnitAssignment = {
        ...existingAssignment,
        idCourt: newSelectedCourt.idCourt,
        Court: newSelectedCourt.Court,
        activeAssignment: true,
      };

      onUpdateAssignment({ type: 'update', payload: updatedAssignment });
    }
  };

  const handleRemoveAssignment = (
    assignment: ICourtAdminstrativeUnitAssignment | undefined
  ) => {
    if (!assignment) return;

    onUpdateAssignment({ type: 'delete', payload: assignment });
  };

  return (
    <Grid item xs={6} sm={4}>
      <InputLabel
        sx={{
          fontSize: theme.typography.fontSize,
          color: theme.palette.grey[600],
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        {role.CourtRole}
      </InputLabel>

      <Select
        disabled={courts.length === 0}
        value={selectedCourt ? selectedCourt.idCourt.toString() : ''}
        size='small'
        fullWidth
        onChange={handleChange}
        displayEmpty
        endAdornment={
          selectedCourt && (
            <InputAdornment position='end' sx={{ mr: 2 }}>
              <IconButton
                onClick={() => handleRemoveAssignment(assignment!)}
                edge='end'
                size='small'
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          )
        }
        renderValue={value => {
          if (!value) {
            if (courts.length === 0) {
              return (
                <Typography
                  sx={{
                    color: theme.palette.grey[500],
                  }}
                >
                  Keine Gerichte verfügbar
                </Typography>
              );
            }
            return (
              <Typography
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                Keine Zuweisung
              </Typography>
            );
          }

          const selectedCourtName = courts.find(
            court => court.idCourt.toString() === value
          )?.Court;

          return selectedCourtName || value;
        }}
      >
        {courts.map(court => (
          <MenuItem key={court.idCourt} value={court.idCourt.toString()}>
            {court.Court}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
