import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { CourtRoleList } from './CourtRoleList';
import type { ICourtRole } from '../../Interfaces/IAemter';
import { AddCourtRoleDialog } from './AddCourtRoleDialog';
import { useState } from 'react';
import { useAlert } from '../../context/AlertContext';
import { useCourtRoles, useDeleteCourtRole } from '../hooks';

export const CourtRolesTab = () => {
  const { showAlert } = useAlert();

  const { courtRoles, isLoadingCourtRoles, refetchCourtRoles } =
    useCourtRoles();

  const { deleteCourtRole } = useDeleteCourtRole();

  const [openAddCourtRoleDialog, setOpenAddCourtRoleDialog] = useState(false);

  const handleDeleteCourtRole = async (courtRole: ICourtRole) => {
    try {
      await deleteCourtRole(courtRole.idCourtRole);

      refetchCourtRoles();

      showAlert({
        text: 'Erfolgreich gelöscht',
      });
    } catch (_) {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Löschen',
      });
    }
  };

  if (isLoadingCourtRoles)
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );

  if (!courtRoles) return <div>Fehler beim Laden der Daten</div>;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={5}
              >
                <Typography variant='h5'>Gerichtsrollen</Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenAddCourtRoleDialog(true)}
                >
                  Neue Rolle hinzufügen
                </Button>
              </Box>
              <CourtRoleList
                courtRoles={courtRoles}
                onDelete={handleDeleteCourtRole}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openAddCourtRoleDialog && (
        <AddCourtRoleDialog
          currentCourtRoles={courtRoles}
          onClose={() => setOpenAddCourtRoleDialog(false)}
        />
      )}
    </>
  );
};
