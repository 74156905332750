import { Edit, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useCallback, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ICourtCourtRole } from '../../../Interfaces/IAemter';
import {
  ILegalRequirement,
  ILegalRequirementFullObject,
} from '../../../Interfaces/ILegalRequirement';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';
import { TextModuleSelector } from '../../../TextModule/TextModuleSelector';
import { CustomEditor } from '../../../core/CustomEditor';
import { AddPrerequisiteDeadline } from './AddPrerequisiteDeadline';
import { LegalRequirementAddCourt } from './LegalRequirementAddCourt';
import { LegalRequirementDeadlineTable } from './LegalRequirementDeadlineTable';

interface IProps {
  currentRequirement: ILegalRequirementFullObject;
  setCurrentRequirement: Function;
  legalRequirements: ILegalRequirement[];
  courtcourtroleArray: ICourtCourtRole[];
  legaltransactionId: number;
}

export const LegalRequirementEdit: React.FC<IProps> = props => {
  // Normale State
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      props.currentRequirement.EmailBody === null
        ? ContentState.createFromText('')
        : ContentState.createFromBlockArray(
            htmlToDraft(props.currentRequirement.EmailBody).contentBlocks
          )
    )
  );

  const [editactualProcessingTime, setEditActualProcessingTime] =
    useState(false);

  const [courtcourtroleDialogOpen, setCourtcourtroleDialogOpen] =
    useState(false);

  const [addPrerequisteDeadlineOpen, setAddPrerequisteDeadlineOpen] =
    useState(false);

  const [editInitialProcessingTime, setEditInitialProcessingTime] =
    useState(false);

  const getCourtCourtRoleCurrentProcessingTime = useCallback(
    (idCourt: number, idCourtRole: number) => {
      if (props.courtcourtroleArray === undefined) return null;
      const courtCourtRole = props.courtcourtroleArray.find(
        x => x.idCourt === idCourt && x.idCourtRole === idCourtRole
      );
      return courtCourtRole?.currentProcessingTime
        ? courtCourtRole.currentProcessingTime
        : courtCourtRole?.initalProcessingTime;
    },
    [props.courtcourtroleArray]
  );

  const handleChangePrerequisteDeadline = useCallback(
    (changedPrequiste: ILegalRequirementDeadline) => {
      const newArray = props.currentRequirement.DeadlineArray
        ? props.currentRequirement.DeadlineArray
        : [];

      if (changedPrequiste.idDeadline === -1) {
        changedPrequiste.idDeadline = Math.floor(Math.random() * 1000000);
        newArray.push(changedPrequiste);
      } else {
        const index = newArray.findIndex(
          x => x.idDeadline === changedPrequiste.idDeadline
        );
        if (index === -1) {
          newArray.push(changedPrequiste);
        } else {
          newArray[index] = changedPrequiste;
        }
      }
      props.setCurrentRequirement({
        ...props.currentRequirement,
        DeadlineArray: newArray,
      });
    },
    [props]
  );

  const handleDeleteDeadline = useCallback(
    (index: number) => {
      const newArray = props.currentRequirement.DeadlineArray
        ? props.currentRequirement.DeadlineArray
        : [];
      newArray.splice(index, 1);
      props.setCurrentRequirement({
        ...props.currentRequirement,
        DeadlineArray: newArray,
      });
    },
    [props]
  );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);

    if (props.currentRequirement) {
      props.setCurrentRequirement({
        ...props.currentRequirement,
        EmailBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Bedingung'
            value={props.currentRequirement.LegalRequirement}
            error={props.currentRequirement.LegalRequirement === ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                LegalRequirement: event.target.value,
              })
            }
            size='small'
            fullWidth
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label='Mitarbeiter kann Dateien hochladen'
            value={
              props.currentRequirement.canUploadFilesEmployees == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                canUploadFilesEmployees: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Mandant kann Dateien hochladen'
            value={
              props.currentRequirement.canUploadFilesClient == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                canUploadFilesClient: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        {/*
                <Grid item sm={6}>
                    <TextField 
                        label="Vorgänger (Bedingung)"
                        value={(props.currentRequirement.Predecessor_idLegalRequirement == undefined) ? "" : props.currentRequirement.Predecessor_idLegalRequirement}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, Predecessor_idLegalRequirement: Number(event.target.value)})}
                        size="small"
                        fullWidth
                        select
                    >
                        {props.legalRequirements.map(x =>
                            <MenuItem 
                                key={`predecessor-idLegalRequirement-${x.idLegalRequirement}`}
                                value={x.idLegalRequirement}
                            >{x.LegalRequirement}</MenuItem>
                        )}
                    </TextField> 
                </Grid>
                */}

        <Grid item sm={12}>
          <Box
            component='fieldset'
            sx={{
              borderColor: '#ccc',
              border: 1,
              borderRadius: 4,
              padding: 2,
              marginTop: 4,
              position: 'relative',
            }}
          >
            <Typography
              component='legend'
              sx={{ fontSize: '0.875rem', padding: '0 8px' }}
            >
              Fristen und Gerichte
            </Typography>
            <Grid container spacing={2} justifyContent='center'>
              {(props.currentRequirement.idCourt === null ||
                props.currentRequirement.idCourt === undefined) &&
                (props.currentRequirement.idCourtRole === null ||
                  props.currentRequirement.idCourtRole === undefined) && (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      {editInitialProcessingTime ? (
                        <>
                          <Tooltip title='Die geschätzte Bearbeitungszeit ist die geschätzte Zeit für den Abschluss dieser Maßnahme unter normalen Umständen. Diese Zeit wird täglich automatisch basierend auf den historischen Werten angepasst. Sollten Sie den Wert verändern, wird die automatische Anpassung deaktiviert.'>
                            <TextField
                              label='Geschätzte Bearbeitungszeit'
                              type='number'
                              value={
                                props.currentRequirement.initialProcessingTime
                                  ? props.currentRequirement
                                      .initialProcessingTime
                                  : ''
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                props.setCurrentRequirement({
                                  ...props.currentRequirement,
                                  initialProcessingTime: Number(
                                    event.target.value
                                  ),
                                  ManualProcessingTime: true,
                                } as ILegalRequirementFullObject)
                              }
                              size='small'
                              sx={{ width: '50%' }}
                            />
                          </Tooltip>
                          <Tooltip title='Bearbeiten beenden'>
                            <IconButton
                              onClick={() => {
                                setEditInitialProcessingTime(false);
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title='Die geschätzte Bearbeitungszeit ist die geschätzte Zeit für den Abschluss dieser Maßnahme unter normalen Umständen. Diese Zeit wird täglich automatisch basierend auf den historischen Werten angepasst. Sollten Sie den Wert verändern, wird die automatische Anpassung deaktiviert.'>
                            <Typography
                              variant='body1'
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Geschätzte Bearbeitungszeit:{' '}
                              {props.currentRequirement
                                .initialProcessingTime !== null ? (
                                <>
                                  {
                                    props.currentRequirement
                                      .initialProcessingTime
                                  }{' '}
                                  Tage
                                </>
                              ) : (
                                'noch nicht festgelegt'
                              )}
                            </Typography>
                          </Tooltip>
                          <Tooltip title='Aktivieren Sie die Funktion, wenn Sie nicht wünschen, dass die geschätze Bearbeitungszeit automatisch angepasst wird.'>
                            <Box display='flex' alignItems={'center'}>
                              <Typography
                                variant='body1'
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Automatische Anpassung deaktiviert
                              </Typography>
                              <Checkbox
                                checked={
                                  props.currentRequirement.ManualProcessingTime
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  props.setCurrentRequirement({
                                    ...props.currentRequirement,
                                    ManualProcessingTime: event.target.checked,
                                  })
                                }
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip title='Setzen Sie die geschätze benötigte Zeit manuell'>
                            <IconButton
                              onClick={() => {
                                setEditInitialProcessingTime(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      {editactualProcessingTime ? (
                        <>
                          <TextField
                            label='Tatsächliche Bearbeitungszeit'
                            type='number'
                            value={
                              props.currentRequirement.actualProcessingTime
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              props.setCurrentRequirement({
                                ...props.currentRequirement,
                                actualProcessingTime: Number(
                                  event.target.value
                                ),
                              })
                            }
                            size='small'
                          />
                          <Tooltip title='Bearbeiten beenden'>
                            <IconButton
                              onClick={() => {
                                setEditActualProcessingTime(false);
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title='Die tatsächlich benötigte Zeit'>
                            <Typography
                              variant='body1'
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Tatsächliche Bearbeitungszeit:{' '}
                              {props.currentRequirement.actualProcessingTime !==
                                null &&
                              props.currentRequirement.actualProcessingTime !==
                                undefined ? (
                                <>
                                  {
                                    props.currentRequirement
                                      .actualProcessingTime
                                  }{' '}
                                  Tage
                                </>
                              ) : (
                                'Die Maßnahme befindet sich noch in Bearbeitung'
                              )}
                            </Typography>
                          </Tooltip>
                          <Tooltip title='Bearbeiten Sie die tatsächlich benötigte Zeit'>
                            <IconButton
                              onClick={() => {
                                setEditActualProcessingTime(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    ></Grid>
                    <Tooltip title='Alternativ können Sie auch ein zuständiges Amt für diese Maßnahme festlegen. Die Bearbeitungszeit wird dann automatisch basierend auf den Daten des zuständigen Amts kaluliert.'>
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setCourtcourtroleDialogOpen(true);
                        }}
                      >
                        Zuständiges Amt festlegen
                      </Button>
                    </Tooltip>
                  </>
                )}
              <Grid item xs={12}>
                <Box>
                  {props.currentRequirement.idCourt &&
                    props.currentRequirement.idCourt !== null &&
                    props.currentRequirement.idCourtRole &&
                    props.currentRequirement.idCourtRole !== null && (
                      <>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Tooltip title='Das Amt, welches für die Bearbeitung dieser Maßnahme zuständig ist.'>
                            <Typography variant='body1'>
                              Amt: {props.currentRequirement.CourtRole}{' '}
                              {props.currentRequirement.Court}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Tooltip title='Die geschätzte Bearbeitungszeit des zuständigen Amts'>
                            <Typography
                              variant='body1'
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Geschätzte Bearbeitungszeit:{' '}
                              {getCourtCourtRoleCurrentProcessingTime(
                                props.currentRequirement.idCourt,
                                props.currentRequirement.idCourtRole
                              ) !== null ? (
                                <>
                                  {getCourtCourtRoleCurrentProcessingTime(
                                    props.currentRequirement.idCourt,
                                    props.currentRequirement.idCourtRole
                                  )}{' '}
                                  Tage
                                </>
                              ) : (
                                'noch nicht festgelegt'
                              )}
                            </Typography>
                          </Tooltip>
                          <Box display={'flex'} alignItems={'center'}>
                            {editactualProcessingTime ? (
                              <>
                                <TextField
                                  label='Tatsächliche Bearbeitungszeit'
                                  type='number'
                                  value={
                                    props.currentRequirement
                                      .actualProcessingTime
                                  }
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    props.setCurrentRequirement({
                                      ...props.currentRequirement,
                                      actualProcessingTime: Number(
                                        event.target.value
                                      ),
                                    })
                                  }
                                  size='small'
                                />
                                <Tooltip title='Bearbeiten beenden'>
                                  <IconButton
                                    onClick={() => {
                                      setEditActualProcessingTime(false);
                                    }}
                                  >
                                    <Save />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title='Die tatsächlich benötigte Zeit'>
                                  <Typography
                                    ml={2}
                                    variant='body1'
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Tatsächliche Bearbeitungszeit:{' '}
                                    {props.currentRequirement
                                      .actualProcessingTime !== null &&
                                    props.currentRequirement
                                      .actualProcessingTime !== undefined ? (
                                      <>
                                        {
                                          props.currentRequirement
                                            .actualProcessingTime
                                        }{' '}
                                        Tage
                                      </>
                                    ) : (
                                      'Die Maßnahme befindet sich noch in Bearbeitung'
                                    )}
                                  </Typography>
                                </Tooltip>
                                <Tooltip title='Bearbeiten Sie die tatsächlich benötigte Zeit'>
                                  <IconButton
                                    onClick={() => {
                                      setEditActualProcessingTime(true);
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </Grid>

                        <Box display='flex' gap={2}>
                          <Tooltip title='Ändern Sie die Zuweisung zu einem anderen Amt'>
                            <Button
                              variant='contained'
                              color='primary'
                              sx={{ mt: 1 }}
                              onClick={() => {
                                setCourtcourtroleDialogOpen(true);
                              }}
                            >
                              Zuweisung ändern
                            </Button>
                          </Tooltip>
                          <Tooltip title='Alternativ können Sie auch die Zuweisung zu einem Amt löschen. Die Bearbeitungszeit wird dann basierend auf historischen Daten der Maßnahme berechnet.'>
                            <Button
                              variant='contained'
                              color='warning'
                              sx={{ mt: 1 }}
                              onClick={() => {
                                props.setCurrentRequirement({
                                  ...props.currentRequirement,
                                  idCourt: null,
                                  idCourtRole: null,
                                  Court: null,
                                  CourtRole: null,
                                });
                              }}
                            >
                              Zuweisung löschen
                            </Button>
                          </Tooltip>
                        </Box>
                      </>
                    )}

                  <Divider>
                    <Typography variant='subtitle1'>
                      Startbedingungen
                    </Typography>
                  </Divider>
                  <Grid item xs={12} display={'flex'} justifyContent={'right'}>
                    <Tooltip title='Bearbeiten Sie die Startbedingungen'>
                      <IconButton
                        onClick={() => setAddPrerequisteDeadlineOpen(true)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <LegalRequirementDeadlineTable
                    deadlineArray={props.currentRequirement.DeadlineArray}
                    setDeadline={handleChangePrerequisteDeadline}
                    deleteDeadline={handleDeleteDeadline}
                  ></LegalRequirementDeadlineTable>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item sm={12}>
          <TextModuleSelector
            key={`transaction-legalrequirementedit`}
            floatRight
            idTextModuleCategory={4}
            txt={props.currentRequirement.InfoText}
            setTxt={(txt: string) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                InfoText: txt,
              })
            }
          />
          <TextField
            label='Infotext'
            value={
              props.currentRequirement.InfoText === null
                ? ''
                : props.currentRequirement.InfoText
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                InfoText: event.target.value === '' ? null : event.target.value,
              })
            }
            size='small'
            fullWidth
            multiline
            rows={3}
          />
        </Grid>

        <Grid item sm={4}>
          <TextField
            label='Auto. E-Mail versenden (wenn erfüllt)'
            value={props.currentRequirement.sendMail == true ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                sendMail: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={8}>
          <TextField
            label='E-Mail Betreff'
            value={props.currentRequirement.EmailSubject}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                EmailSubject: event.target.value,
              })
            }
            size='small'
            fullWidth
          />
        </Grid>

        <CustomEditor
          txt={
            props.currentRequirement.EmailBody === null
              ? ''
              : props.currentRequirement.EmailBody
          }
          setTxt={(txt: string) =>
            props.setCurrentRequirement({
              ...props.currentRequirement,
              EmailBody: txt === '' ? null : txt,
            })
          }
          idTextModuleCategory={3}
          dictonaryIdsArray={[1,2,3]}
        />

        <Grid item sm={6}>
          <TextField
            label='Fehler möglich'
            value={props.currentRequirement.hasError == true ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                hasError: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Freitext bei Fehler erlauben'
            value={
              props.currentRequirement.enableFreeTextError == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                enableFreeTextError: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label='Fehlermeldung'
            value={props.currentRequirement.TextOfError}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                TextOfError: event.target.value,
              })
            }
            size='small'
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <AddPrerequisiteDeadline
        open={addPrerequisteDeadlineOpen}
        currentLegalRequirement={props.currentRequirement}
        setDeadline={handleChangePrerequisteDeadline}
        onClose={() => setAddPrerequisteDeadlineOpen(false)}
        legaltransactionId={props.legaltransactionId}
      ></AddPrerequisiteDeadline>

      <LegalRequirementAddCourt
        legalRequirement={props.currentRequirement}
        courtCourtRoleArray={props.courtcourtroleArray}
        open={courtcourtroleDialogOpen}
        onSave={(newLegalRequirement: ILegalRequirement) => {
          props.setCurrentRequirement(newLegalRequirement);
          setCourtcourtroleDialogOpen(false);
        }}
        onClose={() => setCourtcourtroleDialogOpen(false)}
      ></LegalRequirementAddCourt>
    </Box>
  );
};
