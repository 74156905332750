import React, { useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { IUser } from '../Interfaces/IUser';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  AddCircle,
  CalendarMonth,
  Celebration,
} from '@mui/icons-material';
import { checkDate } from '../Kanban/KanbanBody';
import { ILegalRequirement } from '../Interfaces/ILegalRequirement';
import { ILegalTransactionMeeting } from '../Interfaces/ILegalTransactionMeeting';

interface IProps {
  currentUserLegalArray: ILegalTransaction[];
  currentUserMeetingArray: ILegalTransactionMeeting[];
  currentUserReqArray: ILegalRequirement[];
  currentUserlegalReqDeadline: ILegalRequirement[];
}

export const castNoTimeZone = (currentDateTime: string) => {
  let date = new Date(currentDateTime);
  let userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const getTillDate = (meetingObject: ILegalTransactionMeeting) => {
  let meeting_time = new Date(meetingObject.Meeting_at);
  let returnValie = new Date(
    meeting_time.setMinutes(
      meeting_time.getMinutes() + meetingObject.DurationInMin
    )
  );

  return returnValie.toLocaleTimeString();
};

export const DashboardOwn: React.FC<IProps> = ({
  currentUserLegalArray,
  currentUserMeetingArray,
  currentUserReqArray,
  currentUserlegalReqDeadline,
}) => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item sm={6}>
          <Card variant='outlined' sx={{ mb: 2 }}>
            <CardContent>
              <CalendarMonth sx={{ m: 2, mb: 1, fontSize: 30 }} />
              <br />
              {currentUserMeetingArray.length === 0 ? (
                <i>Zurzeit sind keine Termine vereinbart.</i>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Vorgang</TableCell>
                      <TableCell>Terminbezeichnung</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell>Von</TableCell>
                      <TableCell>Bis</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUserMeetingArray.map(meetingObj => {
                      let legalTransaction = currentUserLegalArray.find(
                        x =>
                          x.idLegalTransaction === meetingObj.idLegalTransaction
                      );

                      if (legalTransaction === undefined) {
                        return (
                          <TableRow>
                            <TableCell colSpan={5}>Fehler</TableCell>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow>
                            <TableCell>
                              <a
                                href={`/legaltransaction/${legalTransaction.idLegalTransaction}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {legalTransaction.Title}
                              </a>
                            </TableCell>
                            <TableCell>
                              {meetingObj.LegalTransactionMeeting}
                            </TableCell>
                            <TableCell>
                                {new Date(meetingObj.Meeting_at).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {new Date(meetingObj.Meeting_at).toLocaleTimeString()}
                            </TableCell>
                            <TableCell>{getTillDate(meetingObj)}</TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              )}
            </CardContent>
          </Card>

          <Card variant='outlined'>
            <CardContent>
              <AccountBalance sx={{ m: 2, mb: 1, fontSize: 30 }} />
              <Typography>
                {currentUserLegalArray.length === 0 ? (
                  <i>Sie haben keine offene Vorgänge.</i>
                ) : (
                  <>
                    Ihnen sind die folgenden offenen Vorgänge zugewiesen:
                    <ul>
                      {currentUserLegalArray.map(x => (
                        <li>
                          <a
                            href={`/legaltransaction/${x.idLegalTransaction}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {x.Title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          {currentUserReqArray.length === 0 ? (
            <Alert sx={{ mb: 2 }} severity='success'>
              <i>Keine Ihrer Maßnahmen benötigt eine Sichtung.</i>
            </Alert>
          ) : (
            <Alert sx={{ mb: 2 }} severity='warning'>
              Maßnahmen in den folgenden Vorgängen benötigen eine Sichtung von
              Ihnen:
              <ul>
                {currentUserReqArray.map(x => (
                  <li>
                    <a
                      href={`/legaltransaction/${x.idLegalTransaction}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {x.Title}
                    </a>
                  </li>
                ))}
              </ul>
            </Alert>
          )}

          {currentUserLegalArray
            .filter(x => x.LastPhaseUpdate_at !== null)
            .every(x => checkDate(String(x.LastPhaseUpdate_at))) ? (
            <Alert severity='success' sx={{ mb: 2 }}>
              <i>Sie haben keine Vorgänge, die länger als zwei Monate in derselben Abwicklungsphase sind.</i>
            </Alert>
          ) : (
            <Alert severity='error' sx={{ mb: 2 }}>
              Folgende Ihrer Vorgänge sind länger als zwei Monate in derselben Abwicklungsphase:
              <ul>
                {currentUserLegalArray
                  .filter(x => x.LastPhaseUpdate_at !== null)
                  .filter(
                    x => checkDate(String(x.LastPhaseUpdate_at)) === false
                  )
                  .map(x => (
                    <li>
                      <a
                        href={`/legaltransaction/${x.idLegalTransaction}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {x.Title}
                      </a>
                    </li>
                  ))}
              </ul>
            </Alert>
          )}
          {currentUserLegalArray.filter(
            x => x.UserInformed_LackOfActivity === true
          ).length === 0 ? (
            <Alert severity='success' sx={{ mb: 2 }}>
              <i>
                Sie haben keine Vorgänge, bei denen Sie aufgrund von Inaktivität
                benachrichtigt wurden.
              </i>
            </Alert>
          ) : (
            <Alert severity='warning' sx={{ mb: 2 }}>
              In folgenden Vorgängen wurde seit längerem keine Aktivität
              festgestellt:
              <ul>
                {currentUserLegalArray
                  .filter(x => x.UserInformed_LackOfActivity === true)
                  .map(x => (
                    <li>
                      <a
                        href={`/legaltransaction/${x.idLegalTransaction}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {x.Title}
                      </a>
                    </li>
                  ))}
              </ul>
            </Alert>
          )}
          {currentUserlegalReqDeadline.length === 0 ? (
            <Alert severity='success' sx={{ mb: 2 }}>
              <i>Sie haben keine Maßnahmen, bei denen die Fristen ablaufen.</i>
            </Alert>
          ) : (
            <Alert severity='warning' sx={{ mb: 2 }}>
              In den folgenden Vorgängen sind die Fristen für mindestens eine
              Maßnahme abgelaufen:
              <ul>
                {currentUserlegalReqDeadline.map(x => (
                  <li>
                    <a
                      href={`/legaltransaction/${x.idLegalTransaction}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {x.Title}
                    </a>
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
};
