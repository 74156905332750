import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

interface IProps {
  show: boolean;
  setShow: Function;
  severity: AlertColor;
  text: string;
}



export const PopupAlert: React.FC<IProps> = (props) => {
  const [localShow, setLocalShow] = useState(props.show);

  useEffect(() => {
    setLocalShow(props.show);
  }, [props.show]);

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setLocalShow(false);
    props.setShow(false);
  };
  return (
    <>
      <Snackbar
        sx={{ mt: 10 }}
        open={props.show}
        autoHideDuration={5000}
        onClose={(event, reason) => handleClose(event, reason)}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.text}
        </Alert>
      </Snackbar>
    </>
  );
};
