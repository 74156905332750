import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { ICourt, IFederalState } from '../../Interfaces/IAemter';
import { isNumber } from '@mui/x-data-grid/internals';
import { IPostcode } from '../../Interfaces/IPostcode';
import { useAlert } from '../../context/AlertContext';
import { useCourts, useCreateCourt } from '../hooks';

interface AddCourtDialogProps {
  courts: ICourt[];
  onClose: () => void;
  federalStates: IFederalState[];
  postcodes: IPostcode[];
}

export const AddCourtDialog: React.FC<AddCourtDialogProps> = ({
  courts,
  onClose,
  federalStates,
  postcodes,
}) => {
  const { showAlert } = useAlert();

  const { refetchCourts } = useCourts();
  const { createCourt, isPendingCreateCourt } = useCreateCourt();

  const [courtName, setCourtName] = useState('');
  const [federalState, setFederalState] = useState<number | ''>('');
  const [xJustizId, setXJustizId] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [postcode, setPostcode] = useState<string | ''>('');
  const [city, setCity] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [streetNr, setStreetNr] = useState<string>('');
  const [streetAditional, setStreetAditional] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [filteredPostCodes, setFilteredPostCodes] = useState<IPostcode[]>([]);
  const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>();
  const [postCodeError, setPostCodeError] = useState(false);
  const [postCodeHelperText, setPostCodeHelperText] = useState('');

  useEffect(() => {
    const courtExists = courts.some(
      court =>
        court.Court.toLowerCase().trim() === courtName.toLowerCase().trim() &&
        court.idFederalState === federalState
    );

    if (courtExists) {
      setError(true);
      setHelperText(
        'Dieser Gerichtsname ist in diesem Bundesland bereits vergeben.'
      );
    } else {
      setError(false);
      setHelperText('');
    }
  }, [courtName, federalState, courts]);

  useEffect(() => {
    let filtered = postcodes;

    // Filter by postcode if entered
    if (postcode !== '') {
      filtered = filtered.filter(p => p.Postcode.trim() === postcode.trim());
    }

    // Filter by city if entered
    setFilteredPostCodes(filtered);
  }, [postcode, city, filterTimeout, postcodes]);

  const checkPostcode = (postcode: string, city: string) => {
    if (postcode === '' && city === '') {
      setFilteredPostCodes([]);
      setPostCodeError(false);
      setPostCodeHelperText('');
      return false;
    }

    let filtered = postcodes;

    // Filter by postcode if entered
    if (postcode !== '') {
      filtered = filtered.filter(p => p.Postcode === postcode);
    }

    // Filter by city if entered
    if (city !== '') {
      filtered = filtered.filter(
        p => p.City.toLowerCase().trim() === city.toLowerCase().trim()
      );
    }

    // If no matching postcodes/cities were found
    if (filtered.length === 0) {
      setPostCodeError(true);
      setPostCodeHelperText('Postleitzahl oder Ort nicht gefunden.');
      return false;
    } else {
      setPostCodeError(false);
      setPostCodeHelperText('');
      return true;
    }
  };

  const handleCreateCourt = async () => {
    //find the city for the postcode in the postcodes array
    if (!checkPostcode(postcode, city)) {
      return;
    }

    if (courtName && federalState !== '' && !error) {
      const newCourt: ICourt = {
        idCourt: -1,
        Court: courtName,
        idFederalState: federalState as number,
        FederalState:
          federalStates.find(state => state.idFederalState === federalState)
            ?.FederalState || '',
        XJustizID: xJustizId,
        PhoneNumber: phoneNumber,
        Email: email,
        Website: website,
        Street: street,
        StreetNr: streetNr,
        StreetAditional: streetAditional,
        idPostcode: filteredPostCodes[0].idPostcode,
      };

      newCourt.activeCourt =
        newCourt.activeCourt !== undefined
          ? Boolean(newCourt.activeCourt)
          : true;

      try {
        await createCourt(newCourt);

        refetchCourts();

        showAlert({
          text: 'Gericht gespeichert',
        });

        onClose();
      } catch (_) {
        showAlert({
          text: 'Fehler beim Speichern des Gerichts',
          severity: 'error',
        });
      }
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Neues Gericht hinzufügen</DialogTitle>
      <Divider />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} py={2}>
          {/* Gerichtsinformationen */}
          <Typography variant='h6'>Gerichtsinformationen</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label='Gerichtsname'
                value={courtName}
                onChange={e => setCourtName(e.target.value)}
                error={error}
                helperText={helperText}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label='XJustiz-ID'
                value={xJustizId}
                onChange={e => setXJustizId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                label='Bundesland'
                value={federalState}
                onChange={e => setFederalState(Number(e.target.value))}
                fullWidth
                SelectProps={{
                  native: true,
                }}
              >
                <option value=''></option>
                {federalStates.map(state => (
                  <option
                    key={state.idFederalState}
                    value={state.idFederalState}
                  >
                    {state.FederalState}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {/* Adressinformationen */}
          <Typography variant='h6' mt={2}>
            Adressinformationen
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                label='Postleitzahl'
                value={postcode}
                onChange={e => {
                  setPostcode(e.target.value);
                  checkPostcode(e.target.value, city);
                }}
                fullWidth
                error={postCodeError}
                helperText={postCodeHelperText}
                type='text'
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={filteredPostCodes.map(postcode => postcode.City)} // Suggestions based on filtered postcodes
                value={city} // Controlled value for the input field
                onInputChange={(event, newInputValue) => {
                  setCity(newInputValue); // Update the city based on input
                  checkPostcode(postcode, newInputValue); // Check for postcode match when input changes
                }}
                renderInput={params => (
                  <TextField
                    required
                    {...params}
                    label='Ort'
                    error={postCodeError}
                    helperText={postCodeHelperText}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                label='Straße'
                value={street}
                onChange={e => setStreet(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                label='Hausnummer'
                value={streetNr}
                onChange={e => setStreetNr(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Adresszusatz'
                value={streetAditional}
                onChange={e => setStreetAditional(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Kontaktinformationen */}
          <Typography variant='h6' mt={2}>
            Kontaktinformationen
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label='Telefonnummer'
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Website'
                value={website}
                onChange={e => setWebsite(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Abbrechen
        </Button>
        <Button
          onClick={() => handleCreateCourt()}
          color='primary'
          variant='contained'
          disabled={
            !xJustizId ||
            !courtName ||
            (isNumber(federalState) && federalState < 1) ||
            !isNumber(federalState) ||
            !postcode ||
            !city ||
            !street ||
            !streetNr ||
            error ||
            postCodeError ||
            isPendingCreateCourt
          }
        >
          {isPendingCreateCourt ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
