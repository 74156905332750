import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IFederalState} from "../../Interfaces/IAemter";


const initialState =  { storeFederalState: [] as IFederalState[] };

const sliceFederalState = createSlice({
    name: "FEDERALSTATEARRAY",
    initialState,
    reducers: {
        setFederalStateArray: (state, action: PayloadAction<IFederalState[]>) => {
            state.storeFederalState = [...action.payload];
        },
        addUpdateFederalStateArray: (state, action: PayloadAction<IFederalState>) => {
            let testObject = state.storeFederalState.find(x => x.idFederalState === action.payload.idFederalState);

            if (testObject) {
                state.storeFederalState = [...state.storeFederalState.map(x => x.idFederalState === action.payload.idFederalState ? action.payload : x)];
            } else {
                state.storeFederalState = [action.payload, ...state.storeFederalState];
            }
        },
    }
})
export const { setFederalStateArray, addUpdateFederalStateArray } = sliceFederalState.actions;
export default sliceFederalState.reducer;