import React, { useEffect, useState } from 'react';
import { RealEstateAddress } from './RealEstateAddress';
import { RealEstateLandRegister } from './RealEstateLandRegister';
import { RealEstatePropertyTypes } from './RealEstatePropertyTypes';
import { RealEstateInventoryOptions } from './RealEstateInventoryOptions';
import { RealEstatePriceUseType } from './RealEstatePriceUseType';
import {
  Box,
  Button,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { getIsValidatedAdress } from './functions_validated';
import { RealEstateManager } from './RealEstateManager';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IRealEstateHasPropertyType } from '../../../../Interfaces/IRealEstateHasPropertyType';
import { IPropertyType } from '../../../../Interfaces/IPropertyType';
import { IRealEstateHasInventoryOption } from '../../../../Interfaces/IRealEstateHasInventoryOption';
import { IInventoryOption } from '../../../../Interfaces/IInventoryOption';
import { IRealEstateUseType } from '../../../../Interfaces/IRealEstateUseType';
import { IPurchaseContractHasPerson } from '../../../../Interfaces/IPerson';
import { NumericFormat } from 'react-number-format';
import { IPurchaseContractRelationType } from '../../../../Interfaces/IPurchaseContractRelationType';

interface IProps {
  idRealEstate: number | null;
  setIdRealEstate: Function;
  realEstateArray: IRealEstate[];
  setRealEstateArray: Function;
  realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
  setRealEstateHasPropertyTypeArray: Function;
  propertyTypeArray: IPropertyType[];
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
  realEstateUseTypeArray: IRealEstateUseType[];
  personArray: IPurchaseContractHasPerson[];
  setPersonArray: Function;
  purchaseContractRelationTypeArray: IPurchaseContractRelationType[];
}

export const searchRealEstate = (
  idRealEstate: number,
  realEstateArray: IRealEstate[],
  forceNew = false
) => {
  let foundObject = realEstateArray.find(x => x.idRealEstate === idRealEstate);
  if (forceNew === false && foundObject !== undefined) {
    return foundObject;
  } else {
    let tmpId = Math.min(...realEstateArray.map(x => x.idRealEstate)) - 1;

    if (tmpId >= 0) {
      tmpId = -1;
    }
    return {
      idRealEstate: tmpId,

      Street: null,
      StreetNr: null,
      StreetAditional: null,
      idPostcode: null,
      // Adresse ODER Latitide als Pflich
      Latitude: null,
      Longitude: null,

      idCourt: -1,
      LandRegister: '',
      SheetLandRegister: '',
      Parcel: '',
      LandParcel: '',
      idPropertyType: -1,
      isBuild: false,

      Price: 100000,
      PriceInventoryOption: null,

      idRealEstateUseType: 1,
      idPurchaseContract: -1,

      Defect: null,
      Particularities: null,
    } as IRealEstate;
  }
};

export const RealEstateEdit: React.FC<IProps> = props => {
  const [currentObject, setCurrentObject] = useState(
    searchRealEstate(-1, props.realEstateArray)
  );
  const [editPropertyArray, setEditPropertyArray] = useState<
    IRealEstateHasPropertyType[]
  >([]);
  const [editInventoryArray, setEditInventoryArray] = useState<
    IRealEstateHasInventoryOption[]
  >([]);
  const [editPersonArray, setEditPersonArray] = useState<
    IRealEstateHasInventoryOption[]
  >([]);

  const [hasWarningPirce, setHasWarningPrice] = useState(false);

  useEffect(() => {
    if (props.idRealEstate !== null) {
      setCurrentObject(
        searchRealEstate(props.idRealEstate, props.realEstateArray)
      );
      setEditPropertyArray([
        ...props.realEstateHasPropertyTypeArray.filter(
          x => x.idRealEstate === props.idRealEstate
        ),
      ]);
      setEditInventoryArray(
        props.realEstateHasInventroyOptionArray.filter(
          x => x.idRealEstate === props.idRealEstate
        )
      );
      //setEditPersonArray(props.per.filter(x => x.idRealEstate === props.idRealEstate));
    }
  }, [props.idRealEstate]);

  const handleClose = () => {
    props.setIdRealEstate(null);
  };

  const handleSave = () => {
    props.setRealEstateArray([
      ...props.realEstateArray.map(x =>
        x.idRealEstate === props.idRealEstate ? currentObject : x
      ),
    ]);

    props.setRealEstateHasPropertyTypeArray([
      ...props.realEstateHasPropertyTypeArray.filter(
        x => x.idRealEstate !== props.idRealEstate
      ),
      ...editPropertyArray,
    ]);

    props.setRealEstateHasInventroyOptionArray([
      ...props.realEstateHasInventroyOptionArray.filter(
        x => x.idRealEstate !== props.idRealEstate
      ),
      ...editInventoryArray,
    ]);

    handleClose();
  };

  return (
    <>
      <RealEstateAddress
        currentObject={currentObject}
        setCurrentObject={setCurrentObject}
        marginTop={0}
      />

      <Typography variant='h5' sx={{ mt: 10, mb: 3 }}>
        Wohn- oder Teileigentum
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Wohn- oder Teileigentum'
            value={currentObject.hasApartment ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentObject({
                ...currentObject,
                hasApartment: event.target.value === 'true',
              })
            }
            fullWidth
            size='small'
            select
          >
            <MenuItem key='hasApartment-true' value='true'>
              Ja
            </MenuItem>
            <MenuItem key='hasApartment-false' value='false'>
              Nein
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <Collapse in={currentObject.hasApartment}>
            <RealEstateManager
              realEstateOnject={currentObject}
              setRealEstateObject={setCurrentObject}
              personArray={props.personArray}
              setPersonArray={props.setPersonArray}
            />
          </Collapse>
        </Grid>
      </Grid>

      <RealEstateLandRegister
        currentObject={currentObject}
        setCurrentObject={setCurrentObject}
        marginTop={10}
      />

      <RealEstatePropertyTypes
        idRealEstate={props.idRealEstate === null ? -1 : props.idRealEstate}
        realEstateHasPropertyTypeArray={editPropertyArray}
        setRealEstateHasPropertyTypeArray={setEditPropertyArray}
        propertyTypeArray={props.propertyTypeArray}
        marginTop={10}
      />

      <Typography variant='h5' sx={{ mt: 10, mb: 3 }}>
        Bauten
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Collapse
            in={
              editPropertyArray.find(x => x.idPropertyType === 1) === undefined
            }
          >
            <TextField
              label='Bebaut'
              value={currentObject.isBuild ? 'true' : 'false'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  isBuild: event.target.value === 'true',
                  BuildWith:
                    event.target.value === 'true'
                      ? currentObject.BuildWith
                      : null,
                })
              }
              fullWidth
              size='small'
              select
            >
              <MenuItem key='isBuild-true' value='true'>
                Ja
              </MenuItem>
              <MenuItem key='isBuild-false' value='false'>
                Nein
              </MenuItem>
            </TextField>
          </Collapse>
        </Grid>

        <Grid item sm={12}>
          <Collapse
            in={
              currentObject.isBuild ||
              editPropertyArray.find(x => x.idPropertyType === 1) !== undefined
            }
          >
            <TextField
              label='Bauten'
              size='small'
              fullWidth
              value={
                currentObject.BuildWith === null ? '' : currentObject.BuildWith
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  BuildWith:
                    event.target.value === '' ? null : event.target.value,
                })
              }
              multiline
              rows={3}
            />
          </Collapse>
        </Grid>
      </Grid>

      <Typography variant='h5' sx={{ mt: 10, mb: 3 }}>
        Teilfläche
      </Typography>

      <TextField
        label='Fläche'
        value={currentObject.hasPartialArea ? 'true' : 'false'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCurrentObject({
            ...currentObject,
            hasPartialArea: event.target.value === 'true',
          })
        }
        fullWidth
        size='small'
        select
      >
        <MenuItem key='hasPartialArea-true' value='true'>
          Teilbereich
        </MenuItem>
        <MenuItem key='hasPartialArea-false' value='false'>
          Ganzes Grundstück
        </MenuItem>
      </TextField>

      <Collapse in={currentObject.hasPartialArea}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={12}>
            <TextField
              label='Vermessen'
              value={currentObject.isMeasured ? 'true' : 'false'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  isMeasured: event.target.value === 'true',
                })
              }
              fullWidth
              size='small'
              select
            >
              <MenuItem key='isMeasured-true' value='true'>
                Ja
              </MenuItem>
              <MenuItem key='isMeasured-false' value='false'>
                Nein
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={6}>
            <Collapse in={currentObject.isMeasured}>
              <NumericFormat
                value={currentObject.Area === null ? '' : currentObject.Area}
                suffix=' qm'
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator='.'
                decimalSeparator=','
                fullWidth
                customInput={TextField}
                onValueChange={(values, sourceInfo) => {
                  if (values.floatValue !== undefined) {
                    setCurrentObject({
                      ...currentObject,
                      Area: values.floatValue,
                    });
                  } else {
                    setCurrentObject({
                      ...currentObject,
                      Area: null,
                    });
                  }
                }}
                allowNegative={false}
                label='Fläche'
                size='small'
                required
              />
            </Collapse>

            <Collapse in={!currentObject.isMeasured}>
              <TextField
                label='Auftraggeber/inn'
                value={
                  currentObject.OrderMeasurement_idPurchaseContractRelationType ===
                  null
                    ? ''
                    : String(
                        currentObject.OrderMeasurement_idPurchaseContractRelationType
                      )
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentObject({
                    ...currentObject,
                    OrderMeasurement_idPurchaseContractRelationType: Number(
                      event.target.value
                    ),
                  })
                }
                size='small'
                fullWidth
                select
                required
              >
                {props.purchaseContractRelationTypeArray.map(x => (
                  <MenuItem
                    key={`OrderMeasurement_idPurchaseContractRelationType-${x.idPurchaseContractRelationType}`}
                    value={x.idPurchaseContractRelationType}
                  >
                    {x.PurchaseContractRelationType}
                  </MenuItem>
                ))}
              </TextField>
            </Collapse>
          </Grid>

          <Grid item sm={6}>
            <TextField
              label='Zahlungspflichtiger'
              value={
                currentObject.PaymentMeasurement_idPurchaseContractRelationType ===
                null
                  ? ''
                  : String(
                      currentObject.PaymentMeasurement_idPurchaseContractRelationType
                    )
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  PaymentMeasurement_idPurchaseContractRelationType: Number(
                    event.target.value
                  ),
                })
              }
              size='small'
              fullWidth
              select
              required
            >
              {props.purchaseContractRelationTypeArray.map(x => (
                <MenuItem
                  key={`PaymentMeasurement_idPurchaseContractRelationType-${x.idPurchaseContractRelationType}`}
                  value={x.idPurchaseContractRelationType}
                >
                  {x.PurchaseContractRelationType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={12}>
            <TextField
              label='Dienstbarkeiten'
              value={currentObject.hasEasement ? 'true' : 'false'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  hasEasement: event.target.value === 'true',
                })
              }
              fullWidth
              size='small'
              select
            >
              <MenuItem key='hasEasement-true' value='true'>
                Ja
              </MenuItem>
              <MenuItem key='hasEasement-false' value='false'>
                Nein
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={12}>
            <Collapse in={currentObject.hasEasement}>
              <TextField
                sx={{ mt: 1 }}
                label='Dienstbarkeiten'
                value={
                  currentObject.EasementText === null
                    ? ''
                    : currentObject.EasementText
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentObject({
                    ...currentObject,
                    EasementText: event.target.value,
                  })
                }
                size='small'
                fullWidth
                multiline
                rows={3}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Collapse>

      <RealEstateInventoryOptions
        idRealEstate={props.idRealEstate === null ? -1 : props.idRealEstate}
        inventoryOptionArray={props.inventoryOptionArray}
        realEstateHasInventroyOptionArray={editInventoryArray}
        setRealEstateHasInventroyOptionArray={setEditInventoryArray}
        marginTop={10}
      />

      <RealEstatePriceUseType
        currentObject={currentObject}
        setCurrentObject={setCurrentObject}
        realEstateUseTypeArray={props.realEstateUseTypeArray}
        realEstateHasInventroyOptionArray={editInventoryArray}
        setHasWarningPrice={setHasWarningPrice}
        marginTop={10}
      />

      <Box sx={{ mt: 10, float: 'right' }}>
        <Button variant='outlined' onClick={handleClose} sx={{ mr: 2 }}>
          Abbruch
        </Button>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={
            !getIsValidatedAdress(currentObject) ||
            editPropertyArray.length === 0 ||
            hasWarningPirce
          }
        >
          Übernehmen
        </Button>
      </Box>
    </>
  );
};
