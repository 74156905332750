import { Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import rollenbezeichnung from "../jsons/rollenbezeichnung.json";
import geschlechtJson from "../jsons/geschlecht.json";
import countryJson from "../jsons/country.json";

interface IProps {
  //personArray: any;
  //setPersonArray: Function;
  currentPerson: any;
  //setEditedJsonObj: Function;
  alleBeteiligungen: any;
  //allePersonen: any;
  setAlleBeteiligungen: Function;
  //setAllePersonen: Function;
}
export const PersonGmbHRow: React.FC<IProps> = (props) => {
  // Funktion zum Umwandeln des Arrays in ein Objekt
  const convertToObject = (datenArray: string[][]): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, name]) => {
      result[code] = name;
    });
    return result;
  };
  const convertToObject2 = (
    datenArray: (string | null)[][]
  ): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, nr, name]) => {
      if (code && name) {
        result[code] = name;
      }
    });
    return result;
  };
  const rollenbezeichnungObjekt = convertToObject(rollenbezeichnung.daten);
  const geschlechtObjekt = convertToObject(geschlechtJson.daten);
  const countryObjekt = convertToObject2(countryJson.daten);
  //
  const rollenbezeichnungMap = new Map(
    rollenbezeichnung.daten.map(([code, wert]) => [code, wert])
  );
  const geschlechtMap = new Map(
    geschlechtJson.daten.map(([code, wert]) => [code, wert])
  );
  const countryMap = new Map(
    countryJson.daten.map(([code, nr, wert]) => [code, wert])
  );
  //
  const valueToKeyRollenbezeichnungMap = new Map(
    Array.from(rollenbezeichnungMap.entries()).map(([key, value]) => [
      value,
      key,
    ])
  );
  const valueToKeyGeschlechtMap = new Map(
    Array.from(geschlechtMap.entries()).map(([key, value]) => [value, key])
  );
  const valueToKeyCountryMap = new Map(
    Array.from(countryMap.entries()).map(([key, value]) => [value, key])
  );
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [currentPerson, setCurrentPerson] = useState(props.currentPerson);
  useEffect(() => {
    setCurrentPerson(props.currentPerson);
  }, [props.currentPerson]);
  useEffect(() => {}, [currentPerson]);
  const [editedPerson, setEditedPerson] = useState(JSON.parse(JSON.stringify(currentPerson)));
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [vorname, setVorname] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:vollerName"]?.["tns:vorname"] || ""
  );
  const [nachname, setNachname] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:vollerName"]?.["tns:nachname"] || ""
  );
  const [bezeichnung, setBezeichnung] = useState(
    currentPerson?.["tns:rolle"]?.["tns:rollenbezeichnung"]?.["code"] || ""
  );
  const [geschlecht, setGeschlecht] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:geschlecht"]?.["code"] || ""
  );
  const [geburtstag, setGeburtstag] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:geburt"]?.["tns:geburtsdatum"] || ""
  );
  const [strasse, setStrasse] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:anschrift"]?.["tns:strasse"] || ""
  );
  const [hausnummer, setHausnummer] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:anschrift"]?.["tns:hausnummer"] || ""
  );
  const [postleitzahl, setPostleitzahl] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:anschrift"]?.["tns:postleitzahl"] || ""
  );
  const [ort, setOrt] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:anschrift"]?.["tns:ort"] || ""
  );
  const [staat, setStaat] = useState(
    currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
      "tns:natuerlichePerson"
    ]?.["tns:anschrift"]?.["tns:staat"]?.["tns:auswahl_staat"]?.["tns:staat"]?.[
      "code"
    ] || ""
  );

  //

  //

  /**
   * All Fuctions
   */
  // handleOpen Function
  const handleOpen = () => {
    setVorname(
      currentPerson["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:vollerName"]?.["tns:vorname"] || ""
    );
    setNachname(
      currentPerson["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:vollerName"]?.["tns:nachname"] || ""
    );
    setBezeichnung(
      currentPerson["tns:rolle"]?.["tns:rollenbezeichnung"]?.["code"] || ""
    );
    setGeschlecht(
      currentPerson["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:geschlecht"]?.["code"] || ""
    );
    setGeburtstag(
      currentPerson["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:geburt"]?.["tns:geburtsdatum"] || ""
    );
    setEditedPerson(JSON.parse(JSON.stringify(currentPerson)));
    setIsOpen(true);
  };
  // remove Function
  const handleRemove = () => {
    if (Array.isArray(props.alleBeteiligungen[0])) {
      const updatedAlleBeteiligten = props.alleBeteiligungen[0];
      const updated = updatedAlleBeteiligten.filter(
        (x: any) =>
          x["tns:rolle"]["tns:rollennummer"] !==
          props.currentPerson["tns:rolle"]["tns:rollennummer"]
      );

      setSaveButtonDisabled(true);
      props.setAlleBeteiligungen([
        ...props.alleBeteiligungen.map((x: any, id: any) =>
          id === 0 ? updated : x
        ),
      ]);
    }
    setIsOpen(false);
  };

  // handle Save function
  const handleSave = () => {
    const updatedAlleBeteiligten = props.alleBeteiligungen[0];
    const updated = updatedAlleBeteiligten.map((x: any) =>
      x["tns:rolle"]["tns:rollennummer"] ===
      currentPerson["tns:rolle"]["tns:rollennummer"]
        ? editedPerson
        : x
    );
    props.setAlleBeteiligungen([
      ...props.alleBeteiligungen.map((x: any, id: any) =>
        id === 0 ? updated : x
      ),
    ]);
    setCurrentPerson(editedPerson);
    setSaveButtonDisabled(true);
    setIsOpen(false);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const handleOnStop = () => {
    setSaveButtonDisabled(true);
    setIsOpen(false);
  }

  //

  //

  /**
   * useEffects
   */

  useEffect(() => {
    if (
      editedPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:vollerName"]
    ) {
      editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
        "tns:natuerlichePerson"
      ]["tns:vollerName"]["tns:vorname"] = vorname;

      editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
        "tns:natuerlichePerson"
      ]["tns:vollerName"]["tns:nachname"] = nachname;

      // Überprüfen, ob "tns:geburt" existiert
      if (
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:geburt"]
      ) {
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:geburt"]["tns:geburtsdatum"] = geburtstag;
      }

      if (
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]
      ) {
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:strasse"] = strasse;
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:hausnummer"] = hausnummer;
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:postleitzahl"] = postleitzahl;
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:ort"] = ort;
      }
      if (
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"] &&
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:staat"] &&
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:staat"]["tns:auswahl_staat"] && 
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:staat"]["tns:auswahl_staat"]["tns:staat"] 
      ) {
        editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
          "tns:natuerlichePerson"
        ]["tns:anschrift"]["tns:staat"]["tns:auswahl_staat"]["tns:staat"][
          "code"
        ] = staat;
      }
    }

    if (
      editedPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
        "tns:natuerlichePerson"
      ]?.["tns:geschlecht"]?.["code"]
    ) {
      editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
        "tns:natuerlichePerson"
      ]["tns:geschlecht"]["code"] = geschlecht;
    }

    if (editedPerson?.["tns:rolle"]?.["tns:rollenbezeichnung"]) {
      editedPerson["tns:rolle"]["tns:rollenbezeichnung"]["code"] = bezeichnung;
    }
  }, [
    vorname,
    nachname,
    bezeichnung,
    geschlecht,
    geburtstag,
    strasse,
    hausnummer,
    postleitzahl,
    ort,
    staat,
  ]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <form ref={formRef}>
            <Grid container spacing={2} mt={2}>
              <Grid item sm={12}>
                <Typography variant="h5">Personendaten</Typography>
              </Grid>
              <Grid item sm={4}>
                <Autocomplete
                  options={Object.values(geschlechtObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                    "tns:natuerlichePerson"
                  ]?.["tns:geschlecht"]?.["code"])}
                  value={geschlechtMap.get(geschlecht)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setGeschlecht(
                        valueToKeyGeschlechtMap.get(newValue) || ""
                      );
                      setSaveButtonDisabled(false);
                    } else {
                      setGeschlecht(""); // Optional: handle the case where no value is selected
                      setSaveButtonDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:geschlecht"]?.["code"])}
                      label="Geschlecht"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Vorname"
                  value={vorname}
                  onChange={(e) => {
                    setVorname(e.target.value);
                    setSaveButtonDisabled(false);
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Nachname"
                  value={nachname}
                  onChange={(e) => {
                    setNachname(e.target.value);
                    setSaveButtonDisabled(false);
                  }}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>
              {editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
                "tns:natuerlichePerson"
              ]["tns:geburt"] && (
                <Grid item sm={12}>
                  <TextField
                    label="Geburtstag"
                    type="date"
                    fullWidth
                    required
                    disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                      "tns:natuerlichePerson"
                    ]?.["tns:geburt"]?.["tns:geburtsdatum"])}
                    InputLabelProps={{ shrink: true }}
                    value={geburtstag}
                    onChange={(e) => {
                      setGeburtstag(e.target.value);
                      setSaveButtonDisabled(false);
                    }}
                  />
                </Grid>
              )}

              {editedPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
                "tns:natuerlichePerson"
              ]["tns:anschrift"] && (
                <>
                  <Grid item sm={4}>
                    <TextField
                      label="Straße"
                      value={strasse}
                      onChange={(e) => {
                        setStrasse(e.target.value);
                        setSaveButtonDisabled(false);
                      }}
                      fullWidth
                      disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:anschrift"]?.["tns:strasse"])}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="Hausnummer"
                      value={hausnummer}
                      onChange={(e) => {
                        setHausnummer(e.target.value);
                        setSaveButtonDisabled(false);
                      }}
                      fullWidth
                      disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:anschrift"]?.["tns:hausnummer"])}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="Postleitzahl"
                      value={postleitzahl}
                      onChange={(e) => {
                        setPostleitzahl(e.target.value);
                        setSaveButtonDisabled(false);
                      }}
                      disabled={!( currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:anschrift"]?.["tns:postleitzahl"])}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      label="Ort"
                      value={ort}
                      onChange={(e) => {
                        setOrt(e.target.value);
                        setSaveButtonDisabled(false);
                      }}
                      fullWidth
                      disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:anschrift"]?.["tns:ort"])}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Autocomplete
                      options={Object.values(countryObjekt)} // Array von Strings für die Optionen
                      getOptionLabel={(option) => option}
                      value={countryMap.get(staat)}
                      disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                        "tns:natuerlichePerson"
                      ]?.["tns:anschrift"]?.["tns:staat"]?.["tns:auswahl_staat"]?.["tns:staat"]?.[
                        "code"
                      ])}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setStaat(valueToKeyCountryMap.get(newValue) || "");
                          setSaveButtonDisabled(false);
                        } else {
                          setStaat(""); // Optional: handle the case where no value is selected
                          setSaveButtonDisabled(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Staat"
                          disabled={!(currentPerson?.["tns:beteiligter"]?.["tns:auswahl_beteiligter"]?.[
                            "tns:natuerlichePerson"
                          ]?.["tns:anschrift"]?.["tns:staat"]?.["tns:auswahl_staat"]?.["tns:staat"]?.[
                            "code"
                          ])}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item sm={12}>
                <Autocomplete
                  options={Object.values(rollenbezeichnungObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  value={rollenbezeichnungMap.get(bezeichnung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setBezeichnung(
                        valueToKeyRollenbezeichnungMap.get(newValue)
                      );
                      setSaveButtonDisabled(false);
                    } else {
                      setBezeichnung(""); // Optional: handle the case where no value is selected
                      setSaveButtonDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Bezeichnung"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleOnStop}>
            Abbruch
          </Button>
          <Button
            variant="contained"
            onClick={handleOnSave}
            disabled={saveButtonDisabled}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow>
        <TableCell>
          {rollenbezeichnungMap.get(
            props.currentPerson?.["tns:rolle"]?.["tns:rollenbezeichnung"]?.["code"]
          )}
        </TableCell>
        <TableCell>
          {
            props.currentPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
              "tns:natuerlichePerson"
            ]["tns:vollerName"]["tns:vorname"]
          }
        </TableCell>
        <TableCell>
          {
            props.currentPerson["tns:beteiligter"]["tns:auswahl_beteiligter"][
              "tns:natuerlichePerson"
            ]["tns:vollerName"]["tns:nachname"]
          }
        </TableCell>
        {/*Aktionen */}
        <TableCell>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleOpen}
          >
            <Edit />
          </IconButton>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
