import React, { useRef, useState } from "react";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { IRequiredFiles } from "../InheritDocument";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../Interfaces/IChildren";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { IInherit } from "../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../Interfaces/IMarriage";

interface IProps {
  inheritObject: IInherit;
  setIsOpenNew: Function;
  parentPersonArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  firstParentId: number | null | undefined;
  secondParentId: number | null | undefined;
}

export const getChildren = (
  isNew: boolean,
  idChildren: number,
  childrenArray: IChildren[]
) => {
  let testObject = childrenArray.find((x) => x.idChildren === idChildren);
  /*  Test Push Git*/
  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...childrenArray.map((x) => x.idChildren)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idChildren: minId,
      Parent1_idPerson: -1,
      Parent2_idPerson: null,
      Children_idPerson: -1,
      Marriage_idMarriage: null,
      isAdopted: false,
      isPutUpForAdoption: false,
    } as IChildren;
  }
};

export const SiblingsAddDialog: React.FC<IProps> = (props) => {
  const [idPersonParrent, setIdPersonParrent] = useState<number | null>(null);
  const [idPersonParrentSecond, setIdPersonParrentSecond] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(
    idPersonParrent === null ? 0 : 1
  );
  const [currentChildren, setCurrentChildren] = useState(
    getChildren(true, -1, props.childrenArray)
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(-1, props.personArray, true)
  );
  const [personHasDocument, setPersonHasDocument] = useState<
    IPersonHasDocument[]
  >([]);
  const [isDeath, setIsDeath] = useState(false);

  const getRequiredFiles = () => {
    if (isDeath) {
      return [
        { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 },
        { Title: "Sterbeurkunde", isOptional: false, idPersonDocumentType: 1 },
      ] as IRequiredFiles[];
    } else {
      return [
        { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 },
      ] as IRequiredFiles[];
    }
  };

  const handleNext = () => {
    if (currentPage === 3 && idPersonParrent !== null) {
      let newChild = {
        ...currentChildren,
        Parent1_idPerson: idPersonParrent,
        Parent2_idPerson: idPersonParrentSecond,
        Children_idPerson: currentPerson.idPerson,
      } as IChildren;
      props.setChildrenArray([...props.childrenArray, newChild]);
      props.setPersonArray([...props.personArray, currentPerson]);
      props.setPersonHasDocument([
        ...props.personHasDocument,
        ...personHasDocument,
      ]);
      props.setIsOpenNew(false);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {

      handleNext();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const parents = [props.firstParentId, props.secondParentId];
  const combinedParents =
    props.firstParentId !== null &&
    props.firstParentId !== undefined &&
    props.secondParentId !== null &&
    props.secondParentId !== undefined
      ? `${props.firstParentId}_${props.secondParentId}`
      : null;

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {}}
        maxWidth={currentPage < 3 ? "sm" : "lg"}
        fullWidth
      >
        <DialogTitle>Neues Geschwisterkind</DialogTitle>
        <DialogContent>
          <form ref={formRef}>
            <Box sx={{ mt: 2 }} />

            <Fade in={currentPage === 0} mountOnEnter unmountOnExit>
              <Box>
                <Typography variant="body1" sx={{ flaot: "center" }}>
                  Bitte wählen Sie das Elternteil/ die Elternteile aus.
                </Typography>
                <TextField
                  sx={{ mt: 2 }}
                  label="Elternteil(e)"
                  select
                  value={
                    idPersonParrent === null
                      ? ""
                      : idPersonParrentSecond === null
                      ? idPersonParrent
                      : `${idPersonParrent}_${idPersonParrentSecond}`
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let splitTest = String(event.target.value).split("_");

                    if (splitTest.length === 2) {
                      setIdPersonParrent(Number(splitTest[0]));
                      setIdPersonParrentSecond(Number(splitTest[1]));
                    } else {
                      setIdPersonParrent(Number(event.target.value));
                      setIdPersonParrentSecond(null);
                    }
                  }}
                  size="small"
                  fullWidth
                  required
                >
                  {combinedParents && (
                    <MenuItem key="combined-parents" value={combinedParents}>
                      {
                        props.personArray.find(
                          (person) => person.idPerson === props.firstParentId
                        )?.FirstName
                      }{" "}
                      {
                        props.personArray.find(
                          (person) => person.idPerson === props.firstParentId
                        )?.LastName
                      }{" "}
                      &{" "}
                      {
                        props.personArray.find(
                          (person) => person.idPerson === props.secondParentId
                        )?.FirstName
                      }{" "}
                      {
                        props.personArray.find(
                          (person) => person.idPerson === props.secondParentId
                        )?.LastName
                      }
                    </MenuItem>
                  )}
                  {parents
                    .filter((parent) => parent !== null && parent !== undefined)
                    .map((parent) => {
                      const person = props.personArray.find(
                        (person) => person.idPerson === parent
                      );
                      if (!person) return null; // Ensure person is not null
                      return (
                        <MenuItem
                          key={`idPerson-${parent}`}
                          value={String(parent)}
                        >
                          {person?.FirstName} {person?.LastName}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Box>
            </Fade>

            <Fade in={currentPage === 1} mountOnEnter unmountOnExit>
              <Box
                display="flex"
                height={80}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body1" sx={{ flaot: "center" }}>
                  Ist das Kind adoptiert?
                  <Box
                    display="flex"
                    alignItems="right"
                    justifyContent="right"
                    sx={{ flaot: "right" }}
                  >
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isAdopted: true,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Ja
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isAdopted: false,
                        });
                        setCurrentPage(2);
                      }}
                    >
                      Nein
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Fade>

            <Fade in={currentPage === 2} mountOnEnter unmountOnExit>
              <Box
                display="flex"
                height={80}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body1" sx={{ flaot: "center" }}>
                  Wurde das Kind zur Adoption freigegeben?
                  <Box
                    display="flex"
                    alignItems="right"
                    justifyContent="right"
                    sx={{ flaot: "right" }}
                  >
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isPutUpForAdoption: true,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Ja
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isPutUpForAdoption: false,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Nein
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Fade>

            <Fade in={currentPage === 3} mountOnEnter unmountOnExit>
              <Box>
                <InheritPerson
                  inheritObject={props.inheritObject}
                  currentPerson={currentPerson}
                  setCurrentPerson={setCurrentPerson}
                  personArray={props.personArray}
                  personHasDocument={personHasDocument}
                  setPersonHasDocument={setPersonHasDocument}
                  title=""
                  showDeathSettings
                  requiredFiles={getRequiredFiles()}
                  setIsDeath={setIsDeath}
                />
              </Box>
            </Fade>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => props.setIsOpenNew(false)}>
            Abbruch
          </Button>
          {!(currentPage === 1) && !(currentPage === 2) && (
            <Button variant="contained" onClick={handleOnSave}>
              {currentPage === 3 ? "Speichern" : "Weiter"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
