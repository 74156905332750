import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDictionaryEntry } from "../../Interfaces/IDictionaryEntry";


const initialState =  { storeDictionaryEntry: [] as IDictionaryEntry [] }; 

const sliceDictionaryEntry = createSlice({
    name: "DICTIONARYENTRYARRAY",
    initialState,
    reducers: {
        setDictionaryEntry: (state, action: PayloadAction<IDictionaryEntry []>) => {
            state.storeDictionaryEntry = [...action.payload];
        },
        addUpdateDictionaryEntray: (state, action: PayloadAction<IDictionaryEntry >) => {
            let testObject = state.storeDictionaryEntry.find(x => x.idDictionaryEntry === action.payload.idDictionaryEntry);
            
            if (testObject) {
                state.storeDictionaryEntry = [...state.storeDictionaryEntry.map(x => x.idDictionaryEntry === action.payload.idDictionaryEntry ? action.payload : x)];
            } else {
                state.storeDictionaryEntry = [action.payload, ...state.storeDictionaryEntry];
            }
        },
    }
})
export const { setDictionaryEntry, addUpdateDictionaryEntray } = sliceDictionaryEntry.actions;
export default sliceDictionaryEntry.reducer;