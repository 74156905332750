import React, { useState } from "react";
import { IMeetingEmailTemplate } from "../../Interfaces/IMeetingEmailTemplate";
import { Box, Button, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { Clear, Delete, Remove } from "@mui/icons-material";
import { CustomEditor } from "../../core/CustomEditor";


interface IProps {
    idMeetingEmailTemplate: number;
    setIdMeetingEmailTemplate: Function;
    isNew: boolean;
    setIsNew: Function;
    meetingEmailTemplateArray: IMeetingEmailTemplate[];
    setMeetingEmailTemplateArray: Function;
}

const getMeetingEmailTemplate = (idMeetingEmailTemplate:number,meetingEmailTemplateArray:IMeetingEmailTemplate[],isNew:boolean) => {
    let testObject = meetingEmailTemplateArray.find(x => x.idMeetingEmailTemplate === idMeetingEmailTemplate);

    if (testObject !== undefined && isNew === false) {
        return testObject;
    } else {
        let tmpId = -1;

        if (meetingEmailTemplateArray.length > 0) {
            let tmpIdArray = Math.min(...meetingEmailTemplateArray.map(x => x.idMeetingEmailTemplate)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray-1;
            }
        }

        return {
            idMeetingEmailTemplate: idMeetingEmailTemplate,
            idLegalTransactionType: -1,
            Title: "",
            Subject: "",
            Body: "",
            allowDataUpload: false,
            sendOnCreation: false,
            DaysBeforeDate: null,
            sendOnCancelling: false,
            sendOnReschedule: false,
            sendWithCalenderFile: false
        } as IMeetingEmailTemplate
    }
}




export const MeetingEmailTemplateEdit:React.FC<IProps> = (props) => {
    const [meetingEmailTemObject,setMeetingEmailTemObject] = useState(getMeetingEmailTemplate(props.idMeetingEmailTemplate,props.meetingEmailTemplateArray,props.isNew));
    

    const handeClose = () => {
        props.setIsNew(false);
        props.setIdMeetingEmailTemplate(null);
    }


    const handleSave = () => {
        let testObject = props.meetingEmailTemplateArray.find(x => x.idMeetingEmailTemplate === meetingEmailTemObject.idMeetingEmailTemplate);

        if (testObject === undefined) {
            props.setMeetingEmailTemplateArray([
                ...props.meetingEmailTemplateArray,
                meetingEmailTemObject
            ])
        }
        else {
            props.setMeetingEmailTemplateArray([
                ...props.meetingEmailTemplateArray.map(x => x.idMeetingEmailTemplate === meetingEmailTemObject.idMeetingEmailTemplate ? meetingEmailTemObject : x)
            ])
        }
        handeClose();
    }

    const handleRemove = () => {
        props.setMeetingEmailTemplateArray([
            ...props.meetingEmailTemplateArray.filter(x => x.idMeetingEmailTemplate !== meetingEmailTemObject.idMeetingEmailTemplate)
        ])
        handeClose()
    }

    
    return(
        <>
            {(props.isNew === false) && <IconButton onClick={handleRemove} sx={{float: "right"}}><Delete/></IconButton>}
            <Box sx={{mt: 1}}/>
            <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={meetingEmailTemObject.Title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, Title: event.target.value})}
                            error={meetingEmailTemObject.Title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <TextField
                            label="Senden bei Terminerstellung"
                            size="small"
                            select
                            value={(meetingEmailTemObject.sendOnCreation) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, sendOnCreation: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="sendOnCreation-true" value="true">Ja</MenuItem>
                            <MenuItem key="sendOnCreation-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            label="Senden bei Terminänderung"
                            size="small"
                            select
                            value={(meetingEmailTemObject.sendOnReschedule) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, sendOnReschedule: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="sendOnReschedule-true" value="true">Ja</MenuItem>
                            <MenuItem key="sendOnReschedule-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            label="Senden bei Terminabsage (Löschung)"
                            size="small"
                            select
                            value={(meetingEmailTemObject.sendOnCancelling) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, sendOnCancelling: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="sendOnCancelling-true" value="true">Ja</MenuItem>
                            <MenuItem key="sendOnCancelling-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item sm={6}>
                        <Box sx={{display: "flex"}}>
                        <TextField
                            label="Senden Tage vor Termin"
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0} }}
                            value={(meetingEmailTemObject.DaysBeforeDate === null ) ? "" : meetingEmailTemObject.DaysBeforeDate}

                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, DaysBeforeDate: (Number(event.target.value) < 0) ? null :  Number(event.target.value)})}
                            fullWidth
                        />
                        <IconButton onClick={() => setMeetingEmailTemObject({...meetingEmailTemObject, DaysBeforeDate: null})}><Clear/></IconButton>
                        </Box>
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            label="Kalenderdatei mitsenden"
                            size="small"
                            select
                            value={(meetingEmailTemObject.sendWithCalenderFile) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, sendWithCalenderFile: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="allowDataUpload-true" value="true">Ja</MenuItem>
                            <MenuItem key="allowDataUpload-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            label="Datumupload erlauben"
                            size="small"
                            select
                            value={(meetingEmailTemObject.allowDataUpload) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, allowDataUpload: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="allowDataUpload-true" value="true">Ja</MenuItem>
                            <MenuItem key="allowDataUpload-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>


                    <Grid item sm={12}>
                        <TextField
                            sx={{mt: 3}}
                            label="Betreff"
                            size="small"
                            value={meetingEmailTemObject.Subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailTemObject({...meetingEmailTemObject, Subject: event.target.value})}
                            error={meetingEmailTemObject.Subject === ""}
                            fullWidth
                        />
                    </Grid>

                    <CustomEditor 
                        txt={meetingEmailTemObject.Body} 
                        setTxt={(txt:string) => setMeetingEmailTemObject({...meetingEmailTemObject, Body: txt})}
                    />

            </Grid>

            <Button variant="contained" sx={{float: "right"}} onClick={handleSave} disabled={meetingEmailTemObject.Subject === "" || meetingEmailTemObject.Title === ""}>Übernehmen</Button>
            <Button variant="outlined" sx={{mr: 2, float: "right"}} onClick={handeClose}>Abbruch</Button>
            
        </>
    )
}