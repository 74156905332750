import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import { IRequiredFiles } from "../InheritDocument";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../Interfaces/IChildren";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../../Interfaces/IMarriage";
import { IInherit } from "../../../../../Interfaces/IInherit";
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from "../../../../../services/familyTree.service";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps {
  idPersonInherit: number;
  index: number;
  setIsOpenNew: Function;
  inheritObject: IInherit;
  currentChildren: IChildren;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray?: IMarriage[];
  setMarriageArray?: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
}

/**
 * childrenRow Function
 * @param props
 */
export const SiblingsChildrenRow: React.FC<IProps> = (props) => {
  /**
   * useStates
   */
  const [value, setValue] = useState(2);
  const [currentChildren, setCurrentChildren] = useState(props.currentChildren);
  const [childrenPerson, setChildrenPerson] = useState(
    getInheritPerson(currentChildren.Children_idPerson, props.personArray)
  );
  const [firstParent, setFirstParent] = useState<IInheritHasPerson>(
    getInheritPerson(currentChildren.Parent1_idPerson, props.personArray)
  );

  const initializeSecondParent = (): IInheritHasPerson | null => {
    if (currentChildren.Parent2_idPerson) {
      return (
        getInheritPerson(currentChildren.Parent2_idPerson, props.personArray) ||
        null
      );
    }
    return null;
  };

  const [secondParent, setSecondParent] = useState<IInheritHasPerson | null>(
    initializeSecondParent
  );
  const [editChildren, setEditChildre] = useState(currentChildren);
  const [editChildrenPerson, setEditChildrenPerson] = useState(childrenPerson);
  const [isDeath, setIsDeath] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Required Files
   */
  const getRequiredFiles = () => {
    if (isDeath) {
      return [
        { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 },
        { Title: "Sterbeurkunde", isOptional: false, idPersonDocumentType: 1 },
      ] as IRequiredFiles[];
    } else {
      return [
        { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 },
      ] as IRequiredFiles[];
    }
  };

  useEffect(() => {
    const childrenPerson2 = getInheritPerson(
      currentChildren.Children_idPerson,
      props.personArray
    );
    const FirstPerson2 = getInheritPerson(
      currentChildren.Parent1_idPerson,
      props.personArray
    );
    if (currentChildren.Parent2_idPerson) {
      const SecondParent2 = getInheritPerson(
        currentChildren.Parent2_idPerson,
        props.personArray
      );
      setSecondParent(SecondParent2);
    }
    setChildrenPerson(childrenPerson2);
    setFirstParent(FirstPerson2);
  }, [
    currentChildren.Children_idPerson,
    currentChildren.Parent1_idPerson,
    currentChildren.Parent2_idPerson,
    props.personArray,
  ]);

  /**
   * Dialog Functions
   */
  const handleOpen = () => {
    setEditChildre(currentChildren);
    setEditChildrenPerson(childrenPerson);
    setIsOpen(true);
  };

  const handleRemoveRecursive = (
    idPerson: number,
    personArray: IInheritHasPerson[],
    childrenArray: IChildren[],
    personHasDocument: IPersonHasDocument[]
  ): [IInheritHasPerson[], IChildren[], IPersonHasDocument[]] => {
    let currentChildren = childrenArray.find(
      (x) => x.Children_idPerson === idPerson
    );

    personHasDocument = [
      ...personHasDocument.filter((x) => x.idPerson !== idPerson),
    ];
    personArray = [...personArray.filter((x) => x.idPerson !== idPerson)];
    childrenArray = [
      ...childrenArray.filter(
        (x) => x.idChildren !== Number(currentChildren?.idChildren)
      ),
    ];

    childrenArray
      .filter((x) => x.Parent1_idPerson === idPerson)
      .map((x) => {
        let [localPersonArray, localChildrenArray, localPersonHasDocument] =
          handleRemoveRecursive(
            x.Children_idPerson,
            personArray,
            childrenArray,
            personHasDocument
          );
        personArray = [...localPersonArray];
        childrenArray = [...localChildrenArray];
        personHasDocument = [...localPersonHasDocument];
      });

    return [personArray, childrenArray, personHasDocument];
  };

  const handleRemove = () => {
    let personArray = [...props.personArray];
    let childrenArray = [...props.childrenArray];
    let personHasDocument = [...props.personHasDocument];

    let [resPersonArray, resChildrenArray, resPersonHasDocument] =
      handleRemoveRecursive(
        childrenPerson.idPerson,
        personArray,
        childrenArray,
        personHasDocument
      );

    props.setPersonHasDocument([...resPersonHasDocument]);

    props.setPersonArray([...resPersonArray]);

    props.setChildrenArray([...resChildrenArray]);
    setIsOpen(false);
  };

  const handleSave = () => {
    let personArray = [...props.personArray];
    personArray = personArray.map((x) =>
      x.idPerson === editChildrenPerson.idPerson ? editChildrenPerson : x
    );

    props.setPersonArray([...personArray]);
    props.setChildrenArray([
      ...props.childrenArray.map((x) =>
        x.idChildren === editChildren.idChildren ? editChildren : x
      ),
    ]);
    setCurrentChildren(editChildren);
    setChildrenPerson(editChildrenPerson);
    setIsOpen(false);
  };

  /**
   * switch between Parent and Child Function
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (formRef.current && formRef.current.checkValidity()) {
      setValue(newValue);
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => {}} maxWidth="lg" fullWidth>
        <DialogContent>
          <form ref={formRef}>
            <Tabs
              sx={{ mt: 5 }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Elternteil 1" {...a11yProps(0)} />
              <Tab label="Elternteil 2" {...a11yProps(1)} />
              <Tab label="Kind" {...a11yProps(2)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              <Typography variant="body1" sx={{ mb: 5 }}>
                Sie können die Informationen zum ersten Elternteil unter
                "Erblasser" bearbeiten.
                <br />
                Diese Darstellung dient der Übersicht und gestattet keine
                Anpassungen des ersten Elternteiles.
              </Typography>
              <InheritPerson
                inheritObject={props.inheritObject}
                personArray={props.personArray}
                key="firstparent"
                disableEdit
                currentPerson={firstParent}
                setCurrentPerson={() => {}}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={() => {}}
                title="Erster Elternteil"
                showDeathSettings
                requiredFiles={
                  [
                    {
                      Title: "Sterbeurkunde",
                      isOptional: false,
                      idPersonDocumentType: 1,
                    },
                  ] as IRequiredFiles[]
                }
              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Typography variant="body1" sx={{ mb: 5 }}>
                Diese Darstellung dient der Übersicht und gestattet keine
                Anpassungen des zweiten Elternteiles.
              </Typography>
              {secondParent ? (
                <InheritPerson
                  inheritObject={props.inheritObject}
                  personArray={props.personArray}
                  key="secondparent"
                  disableEdit
                  currentPerson={secondParent}
                  setCurrentPerson={() => {}}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={() => {}}
                  title="Elternteil 2"
                  requiredFiles={[]}
                />
              ) : (
                <Typography variant="body1">Keine Daten vorhanden</Typography>
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <InheritPerson
                inheritObject={props.inheritObject}
                personArray={props.personArray}
                key="child"
                currentPerson={editChildrenPerson}
                setCurrentPerson={setEditChildrenPerson}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
                title={FamilyTreeService.getGenderNaming(
                  FamilyTreePersonNodeType.CHILD_NODE,
                  editChildrenPerson.idGender
                )}
                showDeathSettings
                requiredFiles={getRequiredFiles()}
                setIsDeath={setIsDeath}
              />
            </CustomTabPanel>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant="contained" onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow
        sx={{
          backgroundColor:
            childrenPerson.idPerson === props.idPersonInherit
              ? "#f2f2f2"
              : undefined,
        }}
      >
        {/*Nr. */}
        <TableCell>{props.index + 1}</TableCell>
        {/*Name */}
        <TableCell>
          {`${childrenPerson.FirstName} ${childrenPerson.LastName}`}
        </TableCell>
        {/*Elternteil 1 */}
        <TableCell>
          {firstParent === null ? (
            <>ERROR</>
          ) : (
            `${firstParent.FirstName} ${firstParent.LastName}`
          )}
        </TableCell>
        {/*Elternteil 2 */}
        <TableCell>
          {secondParent === null ? (
            <>keine Daten vorhanden</>
          ) : (
            `${secondParent.FirstName} ${secondParent.LastName}`
          )}
        </TableCell>

        {/*Aktionen */}
        <TableCell>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleOpen}
          >
            <Edit />
          </IconButton>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
