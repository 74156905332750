import React, { useEffect, useState } from "react";
import { IUser } from "../Interfaces/IUser";
import { Box, Checkbox, Collapse, FormControlLabel, Grid, TextField } from "@mui/material";
import ReactPasswordChecklist from "react-password-checklist";
import md5 from "md5";

interface IProps {
    userObject: IUser;
    enableSetGenPw: boolean;
    setUserObject: Function;
    setIsValid?: Function;

}


export const UserSetPassword:React.FC<IProps> = (props) => {
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [genNewPW,setGenNewPW] = useState(props.enableSetGenPw);

    useEffect(() => {
        (props.setIsValid !== undefined) && props.setIsValid(isValid);
    },[isValid])

    useEffect( () => {
        props.setUserObject({
            ...props.userObject, 
            GenerateNewPassword: genNewPW,
            Password: (password === "" || genNewPW) ? "" : md5(password)
        })
    },[password, genNewPW])


    return(
        <>
            <Box sx={{mt: 5}}/>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Collapse in={!genNewPW}>
                        <TextField
                            type="password"
                            label="Passwort"
                            size="small"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPassword( event.target.value )}}
                            fullWidth
                        />
                    </Collapse>
                </Grid>

                <Grid item sm={12}>
                    <Collapse in={password === "" || genNewPW}>
                        <FormControlLabel 
                            label="Neues Passwort generieren und per E-Mail senden"
                            control={
                                <Checkbox
                                    checked={genNewPW}
                                    onChange={() => setGenNewPW(!genNewPW)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Collapse>
                </Grid>

                
                <Grid item sm={12}>
                    <Collapse in={!genNewPW && password !== ""}>
                        <TextField
                            type="password"
                            label="Passwort wiederholen"
                            size="small"
                            value={passwordAgain}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordAgain( event.target.value )}
                            fullWidth
                        />
                    </Collapse>
                </Grid>

                <Grid item sm={12}>
                    <Collapse in={!genNewPW && password !== ""}>
                        <ReactPasswordChecklist
                            rules={["minLength","specialChar","number","capital","match"]}
                            minLength={12}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => setIsValid(isValid)}
                            messages={{
                                minLength: "Das Passwort hat 12 Zeichen.",
                                specialChar: "Das Passwort hat ein Spezialzeichen.",
                                number: "Das Passwort hat eine Zahl.",
                                capital: "Das Passwort hat ein Großbuchstaben.",
                                match: "Die Passwörter stimmen überein",
                            }}
                        />
                    </Collapse>
                </Grid>
                
            </Grid>
        </>
    )
}