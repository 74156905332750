import { TableCell, TableRow, TextField } from '@mui/material';
import React from 'react';
import { ILegalRequirementTemplateDeadline } from '../Interfaces/ILegalRequirementTemplate_deadlines';
import { ConfirmDelete } from '../core/ConfirmDelete';

interface IProps {
  currentDeadline: ILegalRequirementTemplateDeadline;
  setDeadline: Function;
  deleteDeadline: Function;
}

export const LegalRequirementDeadlineRow: React.FC<IProps> = props => {
  const [deadlineType] = React.useState<'Maßnahme' | 'Phase' | 'Status'>(
    props.currentDeadline.idLegalRequirementTemplate
      ? 'Maßnahme'
      : props.currentDeadline.idLegalPhaseTemplate
        ? 'Phase'
        : 'Status'
  );

  const saveDeadline = (newDeadline: ILegalRequirementTemplateDeadline) => {
    props.setDeadline(newDeadline);
  };

  const handleDelete = () => {
    props.deleteDeadline(props.currentDeadline.idDeadline);
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 20 }}></TableCell>
        <TableCell>{deadlineType}</TableCell>
        <TableCell>
          {deadlineType === 'Maßnahme' ? (
            <>
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalRequirementTemplate}
              </span>{' '}
              bei Status:{' '}
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalRequirementState}
              </span>
            </>
          ) : deadlineType === 'Phase' ? (
            <>
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.idLegalPhaseTemplate}
              </span>
            </>
          ) : (
            props.currentDeadline.idLegalTransactionState
          )}
        </TableCell>
        <TableCell>
          <TextField
            type='number'
            value={props.currentDeadline.Weighting || ''}
            onChange={e =>
              saveDeadline({
                ...props.currentDeadline,
                Weighting:
                  Number(e.target.value) <= 0 ? 1 : Number(e.target.value),
              })
            }
            size='small'
          />
        </TableCell>
        <TableCell>
          <ConfirmDelete
            itemText='die aktulle Bedingung'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
