import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Button, Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { SelectShareHolderDialog } from "./Shares/SelectShareHolderDialog";
import { SharesMainRow } from "./Shares/SharesMainRow";
import { roundFunctionNormal } from "../help_functions";
import { ICompanyFormation } from "../../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../Interfaces/ICompanyFormationHasShareHolder";


export interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    setCompanyFormationShareArray: Function;
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;
    minShareValue: number;
    minShareWarning?: number;
    maxShareWarning?: number;
    setHasWarning: Function;
}


function isInt(value:number|string) {
    return !isNaN(Number(value)) && (function(x) { return (x | 0) === x; })(parseFloat(String(value)))
  }


export const Shares:React.FC<IProps> = (props) => {
    const [totalValue,setTotalValue] = useState(0);
    const [totalValueShares,setTotalValueShares] = useState(0);
    const [hasWarningFullEuroShareHolder,setHasWarningFullShareHolder] = useState(false);
    const [hasWarningFullEuroShare,setHasWarningFullEuroShare] = useState(false);

    const [hasWarning,setHasWarning] = useState(false);
    const [hasSoftWarning,setHasSoftWarning] = useState(false);


    useEffect(() => {
        let idShareHolderArray = props.shareHolderRelation.filter(x => x.idShareHolderType === 1).map(x => x.idShareHolder)
        
        setTotalValue(
            (
                props.shareHolderArray
                .filter(x => idShareHolderArray.indexOf(x.idShareHolder) > -1)
                .map(x => x.Shares)
                .reduce((a,b) => a+b,0)
            )*100
        )

        setHasWarningFullShareHolder(
            (
                props.shareHolderArray
                .filter(x => idShareHolderArray.indexOf(x.idShareHolder) > -1)
                .map(x => roundFunctionNormal(x.Shares * props.companyFormationObject.ShareCapital))
                .filter(x => x % 1 !== 0)
                .length > 0
            )
        )

    },[props.shareHolderArray,props.shareHolderRelation])

    useEffect(() => {
        setTotalValueShares(props.companyFormationShareArray.map(x => x.AmountOfShares*x.ValuePerShare).reduce((a,b) => a+b,0))
        setHasWarningFullEuroShare(props.companyFormationShareArray.filter(x => x.ValuePerShare % 1 !== 0).length > 0)
    },[props.companyFormationShareArray])

    /// useEffect: Setzen der Warnungen
    useEffect(() => {
        let localHasWarning = (
            props.shareHolderRelation.filter(x => x.idShareHolderType === 1).length > 0
            && (
                totalValue !== 100 
                || props.companyFormationObject.ShareCapital < props.minShareValue
                || totalValueShares !== props.companyFormationObject.ShareCapital 
                || hasWarningFullEuroShareHolder 
                || hasWarningFullEuroShare
            )
        )

        props.setHasWarning(localHasWarning);
        setHasWarning(localHasWarning);

        setHasSoftWarning(
            (props.minShareWarning != undefined && props.companyFormationObject.ShareCapital < props.minShareWarning)
            || (props.maxShareWarning != undefined && props.companyFormationObject.ShareCapital >= props.maxShareWarning)
        );
    },[props.companyFormationObject.ShareCapital,totalValue,totalValueShares,hasWarningFullEuroShareHolder,hasWarningFullEuroShare])
    /// ed: useEffect

    const handleChangeShareCapital = (values:any, sourceInfo:any) => {
        let value = values.floatValue;
        let tmpArray = [...props.companyFormationShareArray];

        props.setCompanyFormationObject({
            ...props.companyFormationObject,
            ShareCapital: value
        })
        
        
        tmpArray.map((x,i) => {
            if (tmpArray.filter(y => y.idShareHolder === x.idShareHolder).length === 1) {
                let shareHolder = props.shareHolderArray.find(y => y.idShareHolder === x.idShareHolder);

                if (shareHolder !== undefined) {
                    let preValue = value * shareHolder.Shares;
                    let valuePerShare = preValue/x.AmountOfShares;
                    x.ValuePerShare = valuePerShare
                    tmpArray[i] = x;
                }
            }
        })
        props.setCompanyFormationShareArray([...tmpArray]);
    }



    return(
        <>
            <Typography variant="h4" sx={{mb: 4}}>
                Beteiligte
                <SelectShareHolderDialog
                    idShareHolderType={1}
                    shareHolderArray={props.shareHolderArray}
                    setShareHolderArray={props.setShareHolderArray}
                    shareHolderRelation={props.shareHolderRelation}
                    setShareHolderRelation={props.setShareHolderRelation}
                    //
                    companyFormationObject={props.companyFormationObject}
                    companyFormationShareArray={props.companyFormationShareArray}
                    setCompanyFormationShareArray={props.setCompanyFormationShareArray}
                />
            </Typography>

            <Collapse in={hasSoftWarning || hasWarning}>
                <Alert severity="warning" >
                    <AlertTitle>Warnung(en)</AlertTitle>
                    Es wurde eine oder mehrere Warnungen entdeckt
                    <ul>
                        {(props.companyFormationObject.ShareCapital < props.minShareValue) && <li>Das Stammkapital ist kleiner als {props.minShareValue.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</li>}
                        {(totalValue !== 100) && <li>Gesamteinlage in % entspricht nicht 100 %</li>}
                        {
                            (props.companyFormationObject.ShareCapital == undefined) ? <li>Stammkapital kann nicht bestimmt werden.</li>
                            : (totalValueShares !== props.companyFormationObject.ShareCapital) && <li>Nennbetrag Anteil(e) entsprechen nicht {props.companyFormationObject.ShareCapital.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</li>}
                        {(hasWarningFullEuroShare) && <li>Der Wert pro Anteil darf keine Nachkommastellen enthalten</li>}
                        {(hasWarningFullEuroShareHolder) && <li>Die Gesamteinlage darf keine Nachkommastellen enthalten</li>}

                        {(props.minShareWarning != undefined && props.companyFormationObject.ShareCapital < props.minShareWarning)
                            && <li>Wir empfehlen Ihnen, dass das Stammkapital mindestens {props.minShareWarning.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) } beträgt.</li>
                        }
                        {(props.maxShareWarning != undefined && props.companyFormationObject.ShareCapital >= props.maxShareWarning)
                            && <li>Wir empfehlen Ihnen, dass das Stammkapital maximal {props.maxShareWarning.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) } beträgt.
                                <br/>Vielleicht empfiehlt sich eine andere Geschäftsform?
                            </li>
                        }
                    </ul>
                </Alert>
            </Collapse>

            <Grid container spacing={2} sx={{ mt: 1}}>
                <Grid item sm={4}>
                    <Typography sx={{mt: 2}}>Stammkapital</Typography>
                </Grid>
                <Grid item sm={8}>
                    <NumericFormat
                        value={props.companyFormationObject.ShareCapital}
                        suffix=" €" 
                        fixedDecimalScale
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        decimalSeparator=","
                        fullWidth
                        customInput={TextField 
                        }
                        onValueChange={handleChangeShareCapital}
                        allowNegative={false}
                        label="Stammkapital"
                        size="small"
                    />
                </Grid>
            </Grid>

            <Table sx={{mt: 5}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Gesellschafter</TableCell>
                        <TableCell>Gesamteinlage in %</TableCell>
                        <TableCell>Gesamteinlage in EUR /<br/> Wert pro Anteil</TableCell>
                        <TableCell>Anzahl Anteile</TableCell>
                        <TableCell>Nennbetrag Anteil(e)</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.shareHolderArray
                     .filter(x => props.shareHolderRelation.map(y => y.idShareHolder).indexOf(x.idShareHolder) > -1)
                     .map(x =>
                        <SharesMainRow
                            key={`${props.companyFormationObject.ShareCapital}-shares-idShareHolder-${x.idShareHolder}`}
                            shareHolderObject={x}
                            companyFormationObject={props.companyFormationObject}
                            companyFormationShareArray={props.companyFormationShareArray}
                            setCompanyFormationShareArray={props.setCompanyFormationShareArray}
                            shareHolderArray={props.shareHolderArray}
                            setShareHolderArray={props.setShareHolderArray}
                        />

                    )
                    }

                    <TableRow>
                        <TableCell>
                            <b>Total</b>
                        </TableCell>

                        <TableCell>
                            <Typography textAlign="center" color={(totalValue === 100) ? "info" : "error"}>
                                <b>{totalValue.toFixed(2).replace(".",",") + " %"}</b>
                            </Typography>
                        </TableCell>

                        <TableCell colSpan={2}></TableCell>

                        <TableCell colSpan={2}>
                            <Typography textAlign="center" color={(totalValueShares === props.companyFormationObject.ShareCapital) ? "info" : "error"}>
                                <b>{totalValueShares.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    )
}