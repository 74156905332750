import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { ICourtRole } from '../../Interfaces/IAemter';
import { useAlert } from '../../context/AlertContext';
import { useCourtRoles, useCreateCourtRole } from '../hooks';

interface AddCourtRoleDialogProps {
  currentCourtRoles: ICourtRole[];
  onClose: () => void;
}

export const AddCourtRoleDialog: React.FC<AddCourtRoleDialogProps> = ({
  currentCourtRoles,
  onClose,
}) => {
  const { showAlert } = useAlert();
  const { refetchCourtRoles } = useCourtRoles();
  const { isPendingCreateCourtRole, createCourtRole } = useCreateCourtRole();

  const [courtRoleName, setCourtRoleName] = useState('');

  const isNameDuplicate = currentCourtRoles.some(
    role => role.CourtRole.toLowerCase() === courtRoleName.toLowerCase()
  );

  const handleCreateCourtRole = async (name: string) => {
    if (!name) {
      return;
    }

    const newCourtRole: ICourtRole = {
      idCourtRole: -1,
      CourtRole: name,
      activeRole: true,
    };

    try {
      await createCourtRole(newCourtRole);

      refetchCourtRoles();

      showAlert({
        text: 'Erfolgreich gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Speichern',
      });
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Neue Rolle hinzufügen</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} pt={5} pb={5}>
          <TextField
            label='Rollenbezeichnung'
            value={courtRoleName}
            onChange={e => setCourtRoleName(e.target.value)}
            error={isNameDuplicate}
            helperText={isNameDuplicate ? 'Diese Rolle existiert bereits' : ''}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Abbrechen
        </Button>
        <Button
          onClick={() => handleCreateCourtRole(courtRoleName)}
          variant='contained'
          disabled={
            !courtRoleName || isNameDuplicate || isPendingCreateCourtRole
          }
        >
          {isPendingCreateCourtRole ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
