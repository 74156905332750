import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  CircularProgress,
} from '@mui/material';
import { IAdminstrativeUnit, IFederalState } from '../../Interfaces/IAemter';
import { useAdministrativeUnits, useCreateAdminUnit } from '../hooks';
import { useAlert } from '../../context/AlertContext';

interface AddAdminUnitDialogProps {
  currentAdminUnits: IAdminstrativeUnit[];
  onClose: () => void;
  federalStates: IFederalState[];
}

export const AddAdminUnitDialog: React.FC<AddAdminUnitDialogProps> = ({
  currentAdminUnits,
  onClose,
  federalStates,
}) => {
  const { showAlert } = useAlert();
  const { refetchAdministrativeUnits } = useAdministrativeUnits();
  const { createAdminUnit, isPendingCreateAdminUnit } = useCreateAdminUnit();

  const [adminUnitName, setAdminUnitName] = useState('');
  const [federalState, setFederalState] = useState<IFederalState | undefined>(
    undefined
  );

  const isNameDuplicate = currentAdminUnits.some(
    unit =>
      unit.AdministrativeUnit.toLowerCase() === adminUnitName.toLowerCase() &&
      federalState &&
      unit.idFederalState === federalState.idFederalState
  );

  const handleCreateAdminUnit = async () => {
    if (!adminUnitName || !federalState || isNameDuplicate) {
      return;
    }

    const newAdminUnit: IAdminstrativeUnit = {
      idAdministrativeUnit: -1,
      AdministrativeUnit: adminUnitName,
      idFederalState: federalState.idFederalState,
      FederalState: federalState.FederalState,
      activeUnit: true,
    };

    try {
      await createAdminUnit(newAdminUnit);
      refetchAdministrativeUnits();

      showAlert({
        text: 'Verwaltungseinheit gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        text: 'Fehler beim Speichern der Verwaltungseinheit',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Neue Verwaltungseinheit hinzufügen</DialogTitle>
      <Divider />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} py={3}>
          <TextField
            label='Name der Verwaltungseinheit'
            value={adminUnitName}
            onChange={e => setAdminUnitName(e.target.value)}
            fullWidth
            error={isNameDuplicate}
            helperText={
              isNameDuplicate
                ? 'Name wird bereits von einer anderen Verwaltungseinheit im selben Bundesland verwendet'
                : ''
            }
          />
          <FormControl variant='outlined'>
            <InputLabel id='federal-state-label'>Bundesland wählen</InputLabel>
            <Select
              labelId='federal-state-label'
              label='Bundesland wählen'
              value={federalState?.idFederalState.toString() || ''}
              onChange={e =>
                setFederalState(
                  federalStates.find(
                    state => state.idFederalState === Number(e.target.value)
                  )
                )
              }
            >
              {federalStates.map(state => (
                <MenuItem
                  key={state.idFederalState}
                  value={state.idFederalState}
                >
                  {state.FederalState}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Abbrechen
        </Button>
        <Button
          onClick={handleCreateAdminUnit}
          color='primary'
          variant='contained'
          disabled={
            !adminUnitName ||
            !federalState ||
            isPendingCreateAdminUnit ||
            isNameDuplicate
          }
        >
          {isPendingCreateAdminUnit ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
