import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { AdminUnitsList } from './AdminUnitList';
import { useState } from 'react';
import type { IAdminstrativeUnit } from '../../Interfaces/IAemter';
import { AddAdminUnitDialog } from './AddAdminUnitDialog';
import { AdminUnitDetails } from './AdminUnitDetails';
import { useAlert } from '../../context/AlertContext';
import {
  useAdministrativeUnits,
  useCourtAdministrativeUnitAssignments,
  useCourtCourtRoles,
  useCourtRoles,
  useCourts,
  useDeleteAdministrativeUnit,
  useFederalStates,
} from '../hooks';

export const AdminUnitsTab = () => {
  const { showAlert } = useAlert();

  const {
    administrativeUnits,
    isLoadingAdministrativeUnits,
    refetchAdministrativeUnits,
  } = useAdministrativeUnits();

  const { federalStates, isLoadingFederalStates } = useFederalStates();

  const { courts, isLoadingCourts } = useCourts();

  const {
    courtAdministrativeUnitAssignments,
    isLoadingCourtAdministrativeUnitAssignments,
  } = useCourtAdministrativeUnitAssignments();

  const { courtCourtRoles, isLoadingCourtCourtRoles } = useCourtCourtRoles();
  const { courtRoles, isLoadingCourtRoles } = useCourtRoles();
  const { deleteAdminUnit } = useDeleteAdministrativeUnit();

  const [selectedAdminUnit, setSelectedAdminUnit] =
    useState<IAdminstrativeUnit | null>(null);
  const [openAddAdminUnitDialog, setOpenAddAdminUnitDialog] = useState(false);

  const handleDeleteAdminUnit = async (adminUnit: IAdminstrativeUnit) => {
    try {
      await deleteAdminUnit(adminUnit.idAdministrativeUnit);
      refetchAdministrativeUnits();

      showAlert({
        text: 'Verwaltungseinheit gelöscht',
      });
    } catch (_) {
      showAlert({
        text: 'Fehler beim Löschen der Verwaltungseinheit',
        severity: 'error',
      });
    }
  };

  if (
    isLoadingAdministrativeUnits ||
    isLoadingFederalStates ||
    isLoadingCourtCourtRoles ||
    isLoadingCourtAdministrativeUnitAssignments ||
    isLoadingCourtRoles ||
    isLoadingCourts
  )
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );

  if (
    !administrativeUnits ||
    !federalStates ||
    !courtCourtRoles ||
    !courtAdministrativeUnitAssignments ||
    !courtRoles ||
    !courts
  )
    return <div>Fehler beim Laden der Daten</div>;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={5}
              >
                <Typography variant='h5'>Verwaltungseinheiten</Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenAddAdminUnitDialog(true)}
                >
                  Neue Verwaltungseinheit hinzufügen
                </Button>
              </Box>
              <AdminUnitsList
                adminUnits={administrativeUnits}
                onAdminUnitClick={adminUnit => setSelectedAdminUnit(adminUnit)}
                onDelete={handleDeleteAdminUnit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {openAddAdminUnitDialog && (
        <AddAdminUnitDialog
          currentAdminUnits={administrativeUnits}
          onClose={() => setOpenAddAdminUnitDialog(false)}
          federalStates={federalStates}
        />
      )}

      {selectedAdminUnit && (
        <AdminUnitDetails
          adminstrativeUnit={selectedAdminUnit}
          assignments={courtAdministrativeUnitAssignments.filter(
            assignment =>
              assignment.idAdministrativeUnit ===
              selectedAdminUnit.idAdministrativeUnit
          )}
          courtCourtRoles={courtCourtRoles}
          courtRoles={courtRoles}
          courts={courts.filter(
            court => selectedAdminUnit.FederalState === court.FederalState
          )}
          onClose={() => setSelectedAdminUnit(null)}
          administrativeUnits={administrativeUnits}
        />
      )}
    </>
  );
};
