import React, { useState } from "react";
import {
    Box,
    Button,
    CssBaseline,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, Dashboard, ViewKanban, AccountBalance, Person, BackupTable, StickyNote2, Groups, Gavel, AccessTime, ManageAccounts, CalendarMonth, Group, Mail, Translate, Public, MarkunreadMailbox, Info, Storage } from "@mui/icons-material";
import AvatarePicture from "./AvatarPicture";
import { SupportDialog } from "./SupportDialog";
import { TimeRecordMenuItem } from "../TimeRecord/TimeRecordMenuItem";
import { IConfig } from "../Interfaces/IConfig";
import NotificationComponent from "./NotificationComponent";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import ConstructionIcon from "@mui/icons-material/Construction";
import HandymanIcon from "@mui/icons-material/Handyman";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export interface IProps {
    children?: React.ReactNode;
    configObject: IConfig;
}

export const NavBarV2: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigation = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const [scopeArray, setScopeArray] = React.useState(localStorage.getItem("scope")?.split(","));

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <AppBar position="fixed" open={isSidebarOpen}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            marginRight: 5,
                            ...(isSidebarOpen && { display: "none" }),
                        }}
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                        DiNo | {props.configObject.company_name} | &copy; LeXtorByte
                    </Typography>

                    <Box display="flex" flexGrow={1} />
                    <SupportDialog />
                    <Typography sx={{ mr: 2 }}>{props.configObject.version}</Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Einstellungen öffnen">
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget)}
                                sx={{ p: 0 }}
                            >
                                <AvatarePicture />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={() => setAnchorElUser(null)}
                        >
                            <TimeRecordMenuItem />

                            <MenuItem
                                onClick={() => {
                                    localStorage.removeItem("SessionToken");
                                    window.location.reload();
                                }}
                            >
                                <Typography textAlign="center">Abmelden</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <NotificationComponent />
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                open={isSidebarOpen}
                onMouseOver={() => setIsSidebarOpen(true)}
                onMouseLeave={() => setIsSidebarOpen(false)}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton onClick={() => navigation("/")}>
                        <ListItemIcon>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText>Startseite</ListItemText>
                    </ListItemButton>
                </List>

                <Divider />
                <List>
                    <ListItemButton onClick={() => navigation("/legaltransaction")}>
                        <ListItemIcon>
                            <AccountBalance />
                        </ListItemIcon>
                        <ListItemText>Vorgänge</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => navigation("/clients")}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText>Mandanten</ListItemText>
                    </ListItemButton>
                </List>

                <Divider />
                <List>
                    <ListItemButton onClick={() => navigation("/legaltransactiontypes")}>
                        <ListItemIcon>
                            <BackupTable />
                        </ListItemIcon>
                        <ListItemText>Rechtsgeschäftsarten</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => navigation('/aemter')}>
                        <ListItemIcon>
                            <Gavel />
                        </ListItemIcon>
                        <ListItemText>Ämter</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => navigation("/textmodules")}>
                        <ListItemIcon>
                            <StickyNote2 />
                        </ListItemIcon>
                        <ListItemText>Textbausteine</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => navigation("/contactgroups")}>
                        <ListItemIcon>
                            <Groups />
                        </ListItemIcon>
                        <ListItemText>Mandantengruppen</ListItemText>
                    </ListItemButton>
                </List>




                {(scopeArray?.find(x => x === "timerecording" || x === "admin") !== undefined && props.configObject.Module.find(x => x === "hr") !== undefined) &&
                    <>
                        <Divider />
                        <List>
                            <ListItemButton onClick={() => navigation("/timerecording")}>
                                <ListItemIcon>
                                    <AccessTime />
                                </ListItemIcon>
                                <ListItemText>Zeiterfassung</ListItemText>
                            </ListItemButton>
                        </List>
                        <Divider />
                        <List>
                            <ListItemButton onClick={() => navigation("/daysoffwork")}>
                                <ListItemIcon>
                                    <CalendarMonth />
                                </ListItemIcon>
                                <ListItemText>Fehltage</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigation("/users")}>
                                <ListItemIcon>
                                    <ManageAccounts />
                                </ListItemIcon>
                                <ListItemText>Benutzer</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigation("/userGroups")}>
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText>Gruppen</ListItemText>
                            </ListItemButton>
                        </List>
                    </>
                }


                <Divider />
                <List>
                    <ListItemButton onClick={() => navigation("/tools")}>
                        <ListItemIcon>
                            <ConstructionIcon />
                        </ListItemIcon>
                        <ListItemText>Tools</ListItemText>
                    </ListItemButton>
                </List>

                {(scopeArray?.find(x => x === "systemmails" || x === "admin") !== undefined) &&
                    <>
                        <Divider />
                        <List>
                            <ListItemButton onClick={() => navigation("/systemmails")}>
                                <ListItemIcon>
                                    <Mail />
                                </ListItemIcon>
                                <ListItemText>Systemnachrichten</ListItemText>
                            </ListItemButton>
                        </List>
                    </>
                }

                {(scopeArray?.find(x => x === "admin") !== undefined) &&
                    <>
                        <Divider />
                        <List>
                            <ListItemButton onClick={() => navigation("/admin/fsdirectory")}>
                                <ListItemIcon>
                                    <Storage />
                                </ListItemIcon>
                                <ListItemText>Ordnerverwaltung</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigation("/admin/language")}>
                                <ListItemIcon>
                                    <Translate />
                                </ListItemIcon>
                                <ListItemText>Sprachpakete</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigation("/admin/public")}>
                                <ListItemIcon>
                                    <Public />
                                </ListItemIcon>
                                <ListItemText>Öffentlicher Server</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigation("/admin/globalmail")}>
                                <ListItemIcon>
                                    <MarkunreadMailbox />
                                </ListItemIcon>
                                <ListItemText>Globale E-Mails</ListItemText>
                            </ListItemButton>
                        </List>
                    </>
                }




                <Divider />
                <List>
                    <ListItemButton onClick={() => navigation("/systeminfo")}>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText>Systeminformationen</ListItemText>
                    </ListItemButton>
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {props.children && props.children}
            </Box>
        </Box>
    );
};
