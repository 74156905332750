import { useState } from 'react';
import type { ICourtCourtRole } from '../../Interfaces/IAemter';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Box,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  useCourtCourtRoles,
  useUpdateCourtCourtRoleAssignment,
} from '../hooks';
import { useAlert } from '../../context/AlertContext';

export const EditCourtAssignmentDialog = ({
  assignment,
  onClose,
}: {
  assignment: ICourtCourtRole;
  onClose: () => void;
}) => {
  const { showAlert } = useAlert();
  const { refetchCourtCourtRoles } = useCourtCourtRoles();
  const {
    updateCourtCourtRoleAssignment,
    isPendingUpdateCourtCourtRoleAssignment,
  } = useUpdateCourtCourtRoleAssignment();

  const [processingTime, setProcessingTime] = useState(
    assignment.currentProcessingTime || assignment.initalProcessingTime
  );

  const handleUpdateCourtCourtRoleAssignment = async (
    assignment: ICourtCourtRole
  ) => {
    try {
      assignment.activeCourtCourtRole =
        assignment.activeCourtCourtRole !== undefined
          ? Boolean(assignment.activeCourtCourtRole)
          : true;

      await updateCourtCourtRoleAssignment(assignment);

      refetchCourtCourtRoles();

      showAlert({
        text: 'Zuweisung gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        text: 'Fehler beim Speichern der Zuweisung',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Bearbeitungszeit anpassen</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          size='medium'
          type='number'
          label='Bearbeitungszeit (in Tagen)'
          value={processingTime}
          onChange={e => setProcessingTime(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!processingTime || isPendingUpdateCourtCourtRoleAssignment}
          onClick={() =>
            handleUpdateCourtCourtRoleAssignment({
              ...assignment,
              currentProcessingTime: processingTime,
            })
          }
        >
          {isPendingUpdateCourtCourtRoleAssignment ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
