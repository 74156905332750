import React, { useEffect, useState } from "react";
import { IPurchaseContract, IPurchaseContractFullObject } from "../../Interfaces/IPurchaseContract";
import { PurchaseContractSummary } from "./Dialog/PurchaseContractSummary";
import { IRealEstate } from "../../Interfaces/IRealEstate";
import { IRealEstateHasPropertyType } from "../../Interfaces/IRealEstateHasPropertyType";
import { IRealEstateHasInventoryOption } from "../../Interfaces/IRealEstateHasInventoryOption";
import { IPurchaseContractHasPerson } from "../../Interfaces/IPerson";
import { IDocument } from "../../Interfaces/IDocument";
import { useFetch } from "../../hooks/useFetch";
import { IPropertyType } from "../../Interfaces/IPropertyType";
import { IInventoryOption } from "../../Interfaces/IInventoryOption";
import { IRealEstateUseType } from "../../Interfaces/IRealEstateUseType";
import { Alert, Collapse, MenuItem, TextField, Typography } from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { IPurchaseContractRelationType } from "../../Interfaces/IPurchaseContractRelationType";
import { PurchaseContractPersonOverview } from "./Components/Person/PurchaseContractPersonOverview";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { RealEstateSummary } from "./Components/Summary/RealEstateSummary";


interface IProps {
    purchaseContractObject: IPurchaseContractFullObject;
    setPurchaseContractObject: Function;
}



export const PurchaseContractMain:React.FC<IProps> = (props) => {
    const [propertyTypeArray, setPropertyTypeArray, wasSuccessfullyPropertyTypeArray] = useFetch<IPropertyType[]>("/propertytype");
    const [inventoryOptionArray, setInventoryOption, wasSuccessfullyInventoryOption] = useFetch<IInventoryOption[]>("/inventoryoption");
    const [realEstateUseTypeArray, setRealEstateUseTypeArray, wasSuccessfullyRealEstateUseTypeArray] = useFetch<IRealEstateUseType[]>("/realestateusetype");
    const [purchaseContractRelationTypeArray, setPurchaseContractRelationTypeArray, wasSuccessfullyPurchaseContractRelationTypeArray] = useFetch<IPurchaseContractRelationType[]>("/purchasecontractrelationtype");


    //PurchaseContractRelationType
    const [purchaseContractEdit,setPurchaseContractEdit] = useState<IPurchaseContract>(props.purchaseContractObject);
    const [realEstateArray, setRealEstateArray] = useState<IRealEstate[]>(props.purchaseContractObject.RealEstateArray);
    const [realEstateHasPropertyTypeArray,setRealEstateHasPropertyTypeArray] = useState<IRealEstateHasPropertyType[]>(props.purchaseContractObject.RealEstateHasPropertyTypeArray);
    const [realEstateHasInventroyOptionArray,setRealEstateHasInventroyOptionArray] = useState<IRealEstateHasInventoryOption[]>(props.purchaseContractObject.RealEstateHasInventoryOptionArray);
    const [personArray,setPersonArray] = useState<IPurchaseContractHasPerson[]>(props.purchaseContractObject.PersonArray);
    const [documentArray, setDocumentArray] = useState<IDocument[]>(props.purchaseContractObject.DocumentArray);


    useEffect(() => {
        props.setPurchaseContractObject({
            ...purchaseContractEdit,
            DocumentArray: documentArray,
            PersonArray: personArray,
            RealEstateArray: realEstateArray,
            RealEstateHasInventoryOptionArray: realEstateHasInventroyOptionArray,
            RealEstateHasPropertyTypeArray: realEstateHasPropertyTypeArray,
        } as IPurchaseContractFullObject)
    },[
        purchaseContractEdit,
        realEstateArray,
        realEstateHasPropertyTypeArray,
        realEstateHasInventroyOptionArray,
        personArray,
        documentArray
    ])


    if (
        !wasSuccessfullyPropertyTypeArray
        || !wasSuccessfullyInventoryOption
        || !wasSuccessfullyRealEstateUseTypeArray
        || !wasSuccessfullyPurchaseContractRelationTypeArray
    ) { return <Alert severity="error">Es ist ein Fehler aufgetreten!</Alert> }
    else if (
        propertyTypeArray === undefined
        || inventoryOptionArray === undefined
        || realEstateUseTypeArray === undefined
        || purchaseContractRelationTypeArray === undefined
    ) { return <CustomCircularProgress/> } 
    else {
        return(
            <>
                <RealEstateSummary 
                    realEstateArray={realEstateArray}
                    setRealEstateArray={setRealEstateArray}
                    realEstateHasPropertyTypeArray={realEstateHasPropertyTypeArray}
                    setRealEstateHasPropertyTypeArray={setRealEstateHasPropertyTypeArray}
                    realEstateHasInventroyOptionArray={realEstateHasInventroyOptionArray}
                    setRealEstateHasInventroyOptionArray={setRealEstateHasInventroyOptionArray}
                    propertyTypeArray={propertyTypeArray}
                    inventoryOptionArray={inventoryOptionArray}
                    purchaseContractRelationTypeArray={purchaseContractRelationTypeArray}
                    realEstateUseTypeArray={realEstateUseTypeArray}
                    personArray={personArray}
                    setPersonArray={setPersonArray}
                />

                <PurchaseContractPersonOverview
                    idPruchaseContractRelationType={1}
                    personArray={personArray}
                    setPersonArray={setPersonArray}
                    title="Verkäufer/innen"
                />
                <PurchaseContractPersonOverview
                    idPruchaseContractRelationType={5}
                    personArray={personArray}
                    setPersonArray={setPersonArray}
                    title="Käufer/innen"
                />

                <DocumentOverview
                    allowRemove
                    allowUpload
                    documentArray={documentArray}
                    setDocumentArray={setDocumentArray}
                    title="Bereitgestellte Daten"
                    titleVariant="h6"
                    marginTop={5}
                />

                <Typography variant="h6" sx={{mt: 5, mb: 2}}>Maklerklausel</Typography>
                <TextField
                    label="Maklerklausel"
                    value={(purchaseContractEdit.withThirdPartyClause) ? "true" : "false"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setPurchaseContractEdit({
                            ...purchaseContractEdit,
                            withThirdPartyClause: (event.target.value === "true"),
                            ThirdPartyClauseText: null
                        })
                    }
                    fullWidth
                    size="small"
                    select
                >
                    <MenuItem key="withThirdPartyClause-true" value="true">Ja</MenuItem>
                    <MenuItem key="withThirdPartyClause-false" value="false">Nein</MenuItem>
                </TextField>
                <Collapse in={purchaseContractEdit.withThirdPartyClause}>
                    <TextField
                        sx={{mt: 2}}
                        label="Maklerklausel"
                        value={(purchaseContractEdit.ThirdPartyClauseText === null) ? "" : purchaseContractEdit.ThirdPartyClauseText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setPurchaseContractEdit({
                                ...purchaseContractEdit,
                                ThirdPartyClauseText: (event.target.value === "") ? null : event.target.value
                            })
                        }
                        fullWidth
                        size="small"
                        multiline
                        rows={5}
                    />
                </Collapse>
            </>
        )
    }
}