import { useState } from 'react';
import { IAdminstrativeUnit } from '../../Interfaces/IAemter';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Box,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useAlert } from '../../context/AlertContext';
import { useAdministrativeUnits, useUpdateAdminUnit } from '../hooks';

export const UpdateAdminUnitDialog = ({
  adminUnit,
  currentAdminUnits,
  onClose,
}: {
  adminUnit: IAdminstrativeUnit;
  currentAdminUnits: IAdminstrativeUnit[];
  onClose: () => void;
}) => {
  const { showAlert } = useAlert();
  const { updateAdminUnit, isPendingUpdateAdminUnit } = useUpdateAdminUnit();
  const { refetchAdministrativeUnits } = useAdministrativeUnits();

  const [adminUnitName, setAdminUnitName] = useState(
    adminUnit.AdministrativeUnit
  );

  const handleUpdateAdminUnit = async () => {
    const updatedAdminUnit = {
      ...adminUnit,
      AdministrativeUnit: adminUnitName,
      activeUnit: true,
    };

    try {
      await updateAdminUnit(updatedAdminUnit);

      refetchAdministrativeUnits();

      showAlert({
        text: 'Verwaltungseinheit gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        text: 'Fehler beim Speichern der Verwaltungseinheit',
        severity: 'error',
      });
    }
  };

  const isCurrentName = adminUnitName === adminUnit.AdministrativeUnit;

  const isNameDuplicate =
    !isCurrentName &&
    currentAdminUnits.some(
      unit =>
        unit.AdministrativeUnit.toLowerCase() === adminUnitName.toLowerCase() &&
        unit.idFederalState === adminUnit.idFederalState
    );

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Verwaltungseinheit bearbeiten</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          size='medium'
          label='Name der Verwaltungseinheit'
          value={adminUnitName}
          onChange={e => setAdminUnitName(e.target.value)}
          fullWidth
          error={isNameDuplicate}
          helperText={
            isNameDuplicate
              ? 'Name wird bereits von einer anderen Verwaltungseinheit im selben Bundesland verwendet'
              : ''
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            !adminUnitName ||
            isCurrentName ||
            isNameDuplicate ||
            isPendingUpdateAdminUnit
          }
          onClick={handleUpdateAdminUnit}
        >
          {isPendingUpdateAdminUnit ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Speichern</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Speichern'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
