import React from "react";
import { IDocumentAnalysisSection } from "../../Interfaces/IDocumentAnalysisSection";
import { IDocumentAnalysisSubsection } from "../../Interfaces/IDocumentAnalysisSubsection";
import { IDocumentAnalysisAttribute } from "../../Interfaces/IDocumentAnalysisAttribute";
import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IDocumentAnalysisSubsectionEntryFullObject } from "../../Interfaces/IDocumentAnalysisSubsectionEntry";
import { Add } from "@mui/icons-material";
import { IDocumentAnalysisAttributeValue } from "../../Interfaces/IDocumentAnalysisAttributeValue";
import { DocumentAnalysisSubsectionEntry } from "./DocumentAnalysisSubsectionEntry";


interface IProps {
    documentAnalysisSection: IDocumentAnalysisSection;
    documentAnalysisSubsectionArray: IDocumentAnalysisSubsection[];
    documentAnalysisAttributeArray: IDocumentAnalysisAttribute[];
    //
    sectionEntryArray: IDocumentAnalysisSubsectionEntryFullObject[];
    setSectionEntryArray: Function;
    //
    disableBtn?: boolean;
}

export const getEmptyDocumentAnalysisSubsectionEntryFullObject = (section:IDocumentAnalysisSubsection,sectionEntryArray:IDocumentAnalysisSubsectionEntryFullObject[],documentAnalysisAttributeArray:IDocumentAnalysisAttribute[]) => {
    let tmpId = -1;
    let tmpArray:IDocumentAnalysisAttributeValue[] = [];

    if (sectionEntryArray.length > 0) {
        let tmpIdArray = Math.min(...sectionEntryArray.map(x => x.idDocumentAnalysisSubsectionEntry)) -1;

        if (tmpIdArray <= tmpId) {
            tmpId = tmpIdArray - 1;
        }
    }

    documentAnalysisAttributeArray
        .filter(x => x.idDocumentAnalysisSubsection === section.idDocumentAnalysisSubsection)
        .map( (attr, idx )=> {
            let newObject:IDocumentAnalysisAttributeValue = {
                idDocumentAnalysisAttribute: attr.idDocumentAnalysisAttribute,
                idDocumentAnalysisSubsectionEntry: tmpId,
                idDocumentAnalysisAttributeValue: (idx*-1)-1,
                Value: ""
            } 
            tmpArray.push(newObject)
    })


    let newObject:IDocumentAnalysisSubsectionEntryFullObject = {
        idDocumentAnalysisSubsection: section.idDocumentAnalysisSubsection,
        idDocumentAnalysisSubsectionEntry: tmpId,
        DocumentAnalysisAttributeValueArray: tmpArray,
        isDeleted: false
    }
    return newObject;

}



export const DocumentAnalysisSectionView:React.FC<IProps> = ({
    documentAnalysisSection, documentAnalysisSubsectionArray, documentAnalysisAttributeArray ,
    sectionEntryArray, setSectionEntryArray,
    disableBtn
}) => {

    const addSectionEntry = (subsection:IDocumentAnalysisSubsection) => {
        let newObject = getEmptyDocumentAnalysisSubsectionEntryFullObject(subsection,sectionEntryArray,documentAnalysisAttributeArray);
        setSectionEntryArray([
            ...sectionEntryArray,
            newObject
        ])
    }


    return(
        <>
            <Card variant="outlined" sx={{mt: 5}}>
                <CardContent>
                    <Typography variant="h6">{documentAnalysisSection.DocumentAnalysisSection}</Typography>
                        {documentAnalysisSubsectionArray
                            .filter(x => x.idDocumentAnalysisSection === documentAnalysisSection.idDocumentAnalysisSection)
                            .map(subsection => 
                                <Table size="small" sx={{mt: 1}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{border: "solid"}}
                                                colSpan={2+ documentAnalysisAttributeArray.filter(x => x.idDocumentAnalysisSubsection === subsection.idDocumentAnalysisSubsection).length}
                                            >
                                                {subsection.DocumentAnalysisSubsection}
                                                {(disableBtn === undefined || disableBtn === false) &&
                                                    <IconButton size="small" onClick={() => addSectionEntry(subsection)}><Add/></IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{border: "solid"}}>
                                            <TableCell sx={{border: "solid"}}>Gestr.</TableCell>
                                            {documentAnalysisAttributeArray
                                                .filter(x => x.idDocumentAnalysisSubsection === subsection.idDocumentAnalysisSubsection)
                                                .map(attr =>
                                                    <TableCell sx={{border: "solid"}}>{attr.DocumentAnalysisAttribute}</TableCell>
                                                )}
                                            <TableCell sx={{border: "solid"}}>Aktionen</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sectionEntryArray
                                            .filter(x => x.idDocumentAnalysisSubsection === subsection.idDocumentAnalysisSubsection)
                                            .map(entryValue => 
                                                <DocumentAnalysisSubsectionEntry
                                                    key={`subsectionentry-${entryValue.idDocumentAnalysisSubsectionEntry}`}
                                                    sectionEntry={entryValue}
                                                    sectionEntryArray={sectionEntryArray}
                                                    setSectionEntryArray={setSectionEntryArray}
                                                    disableBtn={disableBtn}
                                                />
                                        )}
                                    </TableBody>
                                </Table>
                        )}

                </CardContent>
            </Card>
        </>
    )
}
