import {
  Box,
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  useTheme,
  DialogActions,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { ICourt, ICourtRole, ICourtCourtRole } from '../../Interfaces/IAemter';
import {
  useCourtCourtRoles,
  useCreateCourtCourtRoleAssignments,
} from '../hooks';
import { useAlert } from '../../context/AlertContext';

interface CourtAssignmentDialogProps {
  court: ICourt;
  courtRoles: ICourtRole[];
  existingAssignments: ICourtCourtRole[];
  onClose: () => void;
}

export const CourtAssignmentDialog: React.FC<CourtAssignmentDialogProps> = ({
  court,
  courtRoles,
  existingAssignments,
  onClose,
}) => {
  const theme = useTheme();

  const { refetchCourtCourtRoles } = useCourtCourtRoles();
  const {
    createCourtCourtRoleAssignments,
    isPendingCreateCourtCourtRoleAssignments,
  } = useCreateCourtCourtRoleAssignments();
  const { showAlert } = useAlert();

  const [selectedCourtCourtRoles, setSelectedCourtCourtRoles] = useState<
    ICourtCourtRole[]
  >([]);
  const [processingTimes, setProcessingTimes] = useState<{
    [key: number]: number;
  }>({});

  const handleAddCourtCourtRoleAssignments = async (
    newAssignments: ICourtCourtRole[]
  ) => {
    try {
      await createCourtCourtRoleAssignments(newAssignments);

      refetchCourtCourtRoles();

      showAlert({
        text: 'Zuweisungen gespeichert',
      });

      onClose();
    } catch (_) {
      showAlert({
        text: 'Fehler beim Hinzufügen der Zuweisungen',
        severity: 'error',
      });
    }
  };

  const handleCourtRoleChange = (roleId: number) => {
    if (
      selectedCourtCourtRoles.some(
        assignment => assignment.idCourtRole === roleId
      )
    ) {
      setSelectedCourtCourtRoles(prev =>
        prev.filter(assignment => assignment.idCourtRole !== roleId)
      );
    } else {
      setSelectedCourtCourtRoles((prev: ICourtCourtRole[]) => [
        ...prev,
        {
          idCourt: court.idCourt,
          idCourtRole: roleId,
          Court: court.Court,
          CourtRole:
            courtRoles.find(role => role.idCourtRole === roleId)?.CourtRole ||
            '',
          initalProcessingTime: processingTimes[roleId] || 0,
          currentProcessingTime: processingTimes[roleId] || 0,
        } as ICourtCourtRole,
      ]);
    }
  };

  const handleProcessingTimeChange = (roleId: number, time: number) => {
    setProcessingTimes(prev => ({
      ...prev,
      [roleId]: time,
    }));

    setSelectedCourtCourtRoles(prev =>
      prev.map(assignment =>
        assignment.idCourtRole === roleId
          ? { ...assignment, initalProcessingTime: time }
          : assignment
      )
    );
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>Neue Zuweisung für Gericht: {court.Court}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <FormControl fullWidth margin='normal'>
            <Typography variant='h6'>Gerichtsrollen</Typography>
            <Box display='flex' flexDirection='column' gap={1}>
              {courtRoles.map(role => {
                if (
                  existingAssignments.some(
                    assignment => assignment.idCourtRole === role.idCourtRole
                  )
                )
                  return null;
                const isSelected = selectedCourtCourtRoles.some(
                  assignment => assignment.idCourtRole === role.idCourtRole
                );
                return (
                  <Box
                    key={role.idCourtRole}
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{
                      backgroundColor: isSelected
                        ? theme.palette.grey[100]
                        : 'transparent',
                      borderRadius: '8px',
                      px: 2,
                      py: 1,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={() =>
                            handleCourtRoleChange(role.idCourtRole)
                          }
                        />
                      }
                      label={role.CourtRole}
                    />
                    <TextField
                      label='Bearbeitungszeit (in Tagen)'
                      type='number'
                      value={processingTimes[role.idCourtRole] || ''}
                      onChange={e =>
                        handleProcessingTimeChange(
                          role.idCourtRole,
                          parseInt(e.target.value)
                        )
                      }
                      disabled={!isSelected}
                      size='small'
                      sx={{ minWidth: 240 }}
                    />
                  </Box>
                );
              })}
            </Box>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Schließen
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            handleAddCourtCourtRoleAssignments(selectedCourtCourtRoles)
          }
          disabled={
            selectedCourtCourtRoles.length === 0 ||
            selectedCourtCourtRoles.some(
              assignment => assignment.initalProcessingTime <= 0
            ) ||
            isPendingCreateCourtCourtRoleAssignments
          }
        >
          {isPendingCreateCourtCourtRoleAssignments ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Zuweisung hinzufügen</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Zuweisung hinzufügen'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
