import React, {useEffect, useState} from "react";
import {Alert, Grid, IconButton, MenuItem, TextField, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {
    ILegalTransaction_has_LegalTransactionSpecialFunction
} from "../../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction";
import {ChangeLegalPhaseCondition} from "../ChangeLegalPhaseCondition";
import {ILegalPhaseFullObject} from "../../../Interfaces/ILegalPhase";
import {ILegalRequirementState} from "../../../Interfaces/ILegalRequirementState";
import {CustomeTextField} from "../../../generic/CustomeTextField";
import {IResult} from "../../DocumentDirectory/LandregisterSerach";
import {useSelector} from "react-redux";
import {State} from "../../../redux/mainReducer";

interface IProps {
    idLegalTransaction: number;
    legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
    setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    legalReqState: ILegalRequirementState[];

    deleteSpecialFunction: Function;
    handleFieldChangeOnImport: (index: number, updates: { [key: string]: string }) => void;
}

interface IPayload {
    idDistrictCourt: null | number;
    idLandMarking: null | number;
    Court: null | string;
    Marking: null | string;
    SearchString: null | string;
    DownloadArray: IResult[];
}

export const LandRegisterCheck: React.FC<IProps> = (props) => {
    const federalStateArray = useSelector((state: State) => state.federalStateArray.storeFederalState);
    const districtCourtArray = useSelector((state: State) => state.districtCourtArray.storeDistrictCourt);
    const landMarkingArray = useSelector((state: State) => state.landMarkingArray.storeLandMarking);
    const [hasError, setHasError] = useState(false);
    const [isResultLoading, setIsResultLoading] = useState(false);
    const [resultArray, setResultArray] = useState<IResult[] | null>(null);

    const initializePayloadArray = () => {
        return props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(item => {
            const districtCourt = districtCourtArray.find(dc => dc.Court === item.Function_Field4);
            const landMarking = landMarkingArray.find(lm => lm.LandMarking === item.Function_Field5);

            return {
                idDistrictCourt: districtCourt ? districtCourt.idDistrictCourt : null,
                idLandMarking: landMarking ? landMarking.idLandMarking : null,
                Court: item.Function_Field4 || null,
                Marking: item.Function_Field5 || null,
                SearchString: null,
                DownloadArray: []
            };
        });
    };

    const [payloadArray, setPayloadArray] = useState<IPayload[]>(initializePayloadArray);

    const handleChange = (index: number, field: string, value: string) => {
        const updatedArray = props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map((item, i) => {
            if (item.idSF === 4 && i === index) {
                const updatedItem = {...item, [field]: value};
                updatedItem.Function_Field3 = "1";
                return updatedItem;
            }
            return item;
        });
        props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(updatedArray);
    };

    const handlePayloadChange = (index: number, updatedPayload: Partial<IPayload>) => {
        const updatedPayloadArray = [...payloadArray];
        updatedPayloadArray[index] = {...updatedPayloadArray[index], ...updatedPayload};
        setPayloadArray(updatedPayloadArray);
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Typography variant="h5">Grundbuch Nachverfolgung</Typography>
            </Grid>

            {hasError && (
                <Grid item sm={12}>
                    <Alert severity="error">Ein oder mehrere Einträge haben leere Pflichtfelder.</Alert>
                </Grid>
            )}

            {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map((item, index) => (
                item.idSF === 4 && (
                    <React.Fragment key={index}>
                        {item.Function_Field3 === "0" && (
                            <Grid item sm={12}>
                                <Alert severity="warning">Keine Nachverfolgung aktiviert</Alert>
                            </Grid>
                        )}
                        {item.Function_Field3 === "1" && (
                            <Grid item sm={12}>
                                <Alert severity="info">Nachverfolgung aktiviert, noch nicht eingetragen</Alert>
                            </Grid>
                        )}
                        {item.Function_Field3 === "2" && (
                            <Grid item sm={12}>
                                <Alert severity="success">Eintragung erfolgt</Alert>
                            </Grid>
                        )}

                        <Grid item sm={2}>
                            <TextField
                                label="Datum"
                                size="small"
                                type="date"
                                fullWidth
                                required={true}
                                InputLabelProps={{shrink: true}}
                                value={item.Function_Field1}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(index, 'Function_Field1', event.target.value)
                                }
                            />
                        </Grid>

                        <Grid item xs={9}>
                            <CustomeTextField
                                label="Blattnummer"
                                attr="Function_Field2"
                                object={item} // Blattnummer aus item.Function_Field2 initialisieren
                                required
                                type="string"
                                null
                                setObject={(value: string) => handleChange(index, 'Function_Field2', value)}
                                onChangeHandler={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(index, 'Function_Field2', event.target.value)}
                            />
                        </Grid>

                        <Grid item sm={1}>
                            <IconButton onClick={() => props.deleteSpecialFunction(index)}>
                                <Delete/>
                            </IconButton>
                        </Grid>

                        <Grid item xs={6}>
                            <CustomeTextField
                                label="Gericht"
                                attr="idDistrictCourt"
                                object={payloadArray[index]}
                                disabled={isResultLoading || (resultArray !== null && resultArray.length !== 0)}
                                setObject={(payload: IPayload) => {
                                    let testDistrictObject = districtCourtArray.find(x => x.idDistrictCourt === payload.idDistrictCourt);
                                    if (testDistrictObject !== undefined) {
                                        handlePayloadChange(index, {
                                            idDistrictCourt: testDistrictObject["idDistrictCourt"],
                                            Court: testDistrictObject["Court"],
                                            idLandMarking: null,
                                            Marking: null
                                        });
                                        handleChange(index, 'Function_Field4', testDistrictObject["Court"]);
                                    } else if (payload.idDistrictCourt === null) {
                                        handlePayloadChange(index, {
                                            idDistrictCourt: null,
                                            Court: null,
                                            idLandMarking: null,
                                            Marking: null,
                                        });
                                        handleChange(index, 'Function_Field4', "");
                                    }
                                }}
                                type="select"
                                null
                                required
                            >
                                {districtCourtArray
                                    .filter(x => x.isLandRegister)
                                    .map(x =>
                                        <MenuItem key={`districtcourt-${x.idDistrictCourt}`} value={x.idDistrictCourt}>
                                            {x.Court}
                                        </MenuItem>
                                    )}
                            </CustomeTextField>
                        </Grid>

                        <Grid item xs={5}>
                            <CustomeTextField
                                label="Gemarkung"
                                attr="idLandMarking"
                                object={payloadArray[index]}
                                disabled={isResultLoading || (resultArray !== null && resultArray.length !== 0)}
                                required
                                setObject={(payload: IPayload) => {
                                    let testLandMarking = landMarkingArray.find(x => x.idLandMarking === payload.idLandMarking);
                                    if (testLandMarking !== undefined) {
                                        let testDistrictObject = districtCourtArray.find(x => x.idDistrictCourt === testLandMarking?.idDistrictCourt);
                                        if (testDistrictObject !== undefined) {
                                            handlePayloadChange(index, {
                                                idDistrictCourt: testDistrictObject["idDistrictCourt"],
                                                Court: testDistrictObject["Court"],
                                                idLandMarking: testLandMarking.idLandMarking,
                                                Marking: testLandMarking.LandMarking
                                            });
                                            handleChange(index, 'Function_Field5', testLandMarking.LandMarking);
                                        }
                                    } else if (payload.idLandMarking === null) {
                                        handlePayloadChange(index, {
                                            idLandMarking: null,
                                            Marking: null,
                                        });
                                        handleChange(index, 'Function_Field5', "");
                                    }
                                }}
                                type="select"
                                null
                            >
                                {landMarkingArray
                                    .filter(x => payloadArray[index].idDistrictCourt === null || x.idDistrictCourt === payloadArray[index].idDistrictCourt)
                                    .sort((a, b) => a.LandMarking > b.LandMarking ? 1 : -1)
                                    .map(x =>
                                        <MenuItem key={`landmarking-${x.idLandMarking}`} value={x.idLandMarking}>
                                            {x.LandMarking} {payloadArray[index].idDistrictCourt === null && <> ({districtCourtArray.find(y => y.idDistrictCourt === x.idDistrictCourt)?.Court})</>}
                                        </MenuItem>
                                    )}
                            </CustomeTextField>
                        </Grid>

                        <Grid item sm={12}>
                            <ChangeLegalPhaseCondition
                                idLegalTransaction={props.idLegalTransaction}
                                legalTransaction_has_LegalTransactionSpecialFunctionArray={props.legalTransaction_has_LegalTransactionSpecialFunctionArray}
                                setLegalTransaction_has_LegalTransactionSpecialFunctionArray={props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray}
                                legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                                legalReqState={props.legalReqState}
                                specialFunction={item}
                            />
                        </Grid>
                    </React.Fragment>
                )
            ))}
        </Grid>
    );
};
