import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { XMLParser } from "fast-xml-parser";
import währung2 from "../jsons/währung.json";
import vertretungsregelungJson from "../jsons/vertretungsregelung.json";
import { PersonGmbHRow } from "./PersonGmbHRow";
import { Add } from "@mui/icons-material";
import { PersonAddDialog } from "./PersonAddDialog";
import { PersonWithhafteinlagenRow } from "./PersonWithHafteinlagenRow";
import { VertretungsBerechtigteRow } from "./VertretungsBerechtigteRow";
import { ICompanyFormationFullObject } from "../../Interfaces/ICompanyFormation";

interface IProps {
  jsonObj: any;
  findElementByKey: Function;
  findAllElementsByKey: Function;
  setJsonObj: Function;
  rechtsForm: string;
  companyFormationFullObject: ICompanyFormationFullObject;
  setCompanyFormationFullObject: Function;
  createShareHolderArray: Function;
  createAuthorizedRepresentativesArray: Function;
  createBondingDepositsArray: Function;
}

export const GmbH: React.FC<IProps> = (props) => {
  /**
   * XML Parser
   */
  // parser Options
  const parserOptions = {
    ignoreAttributes: false,
    parseTagValue: false,
  };

  // Erstelle eine Instanz von XMLParser
  const parser = new XMLParser(parserOptions);
  /**
   * Consts
   */
  const addVertretungsberechtigterXmlContent = ` <tns:ref.rollennummer>1</tns:ref.rollennummer>
                    <tns:besondereVertretungsregelung>
                        <tns:auswahl_vertretungsbefugnis>
                            <tns:vertretungsbefugnis listVersionID="2.1" listURI="urn:xoev-de:xjustiz:codeliste:reg.besondere-vertretungsregelung">
                                <code>001</code>
                            </tns:vertretungsbefugnis>
                        </tns:auswahl_vertretungsbefugnis>
                    </tns:besondereVertretungsregelung>`;
  const addXmlContent = `
                        <tns:ref.rollennummer></tns:ref.rollennummer>
                        <tns:hafteinlage>
                            <tns:zahl></tns:zahl>
                            <tns:waehrung listVersionID="1.0" listURI="urn:xoev-de:bund:kba:codeliste:waehrung">
                                <code></code>
                            </tns:waehrung>
                        </tns:hafteinlage>
                   `;
  const [firmennameConst, setFirmennameConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:bezeichnung`]?.[`tns:bezeichnung.aktuell`] ||
      ""
  );
  const [amtsgerichtConst, setAmtsgerichtConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[1]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:bezeichnung`]?.[`tns:bezeichnung.aktuell`] ||
      ""
  );
  //
  const [straßeConst, setStraßenConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:strasse`] || ""
  );
  const [straßeAmtsgerichtConst, setStraßenAmtsgerichtConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[1]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:strasse`] || ""
  );
  //
  const [hausnummerConst, setHausnummerConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:hausnummer`] || ""
  );
  const [hausnummerAmtsgerichtConst, setHausnummerAmtsgerichtConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[1]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:hausnummer`] || ""
  );
  //
  const [postleitzahlConst, setPostleitzahlConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:postleitzahl`] || ""
  );
  const [postleitzahlAmtsgerichtConst, setPostleitzahlAmtsgerichtConst] =
    useState(
      props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
        `tns:verfahrensdaten`
      ]?.[`tns:beteiligung`]?.[1]?.[`tns:beteiligter`]?.[
        `tns:auswahl_beteiligter`
      ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:postleitzahl`] || ""
    );
  //
  const [ortConst, setOrtConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:ort`] || ""
  );
  const [ortAmtsgerichtConst, setOrtAmtsgerichtConst] = useState(
    props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
      `tns:verfahrensdaten`
    ]?.[`tns:beteiligung`]?.[1]?.[`tns:beteiligter`]?.[
      `tns:auswahl_beteiligter`
    ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:ort`] || ""
  );

  /**
   * Functions to set Usestates
   */

  // Funktion um das Stammkapital im JSON zu finden
  const getStammkapital = (jsonObj: any) => {
    const capitalObj = props.findElementByKey(jsonObj, "tns:stammkapital");
    return capitalObj?.["tns:zahl"] || "";
  };
  //
  const getGrundkapital = (jsonObj: any) => {
    const grundCaptialObj = props.findElementByKey(jsonObj, "tns:grundkapital");
    return grundCaptialObj?.["tns:hoehe"]?.["tns:zahl"] || "";
  };
  //
  const getWährung = (jsonObj: any) => {
    const capitalObj = props.findElementByKey(jsonObj, "tns:stammkapital");
    return capitalObj?.["tns:waehrung"]["code"] || "";
  };
  //
  const getGrundkapitalWährung = (jsonObj: any) => {
    const capitalObj = props.findElementByKey(jsonObj, "tns:grundkapital");
    return capitalObj?.["tns:hoehe"]["tns:waehrung"]["code"] || "";
  };
  //
  const getGegenstandEinesUnternehmens = (jsonObj: any) => {
    const gegenstandEinesUnternehmensObj = props.findElementByKey(
      jsonObj,
      "tns:basisdatenRegister"
    );
    return gegenstandEinesUnternehmensObj?.["tns:gegenstand"] || "";
  };

  // Funktion zum Umwandeln des Arrays in ein Objekt
  const convertToObject = (datenArray: string[][]): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, name]) => {
      result[code] = name;
    });
    return result;
  };

  const währungsObjekt = convertToObject(währung2.daten);
  const vertretungsObjekt = convertToObject(vertretungsregelungJson.daten);
  const währungsMap = new Map(
    währung2.daten.map(([code, wert]) => [code, wert])
  );
  const vertretungMap = new Map(
    vertretungsregelungJson.daten.map(([code, wert]) => [code, wert])
  );
  // Umgekehrte Map erstellen
  const valueToKeyWährungsMap = new Map(
    Array.from(währungsMap.entries()).map(([key, value]) => [value, key])
  );
  const valueToKeyVertretungsregelungMap = new Map(
    Array.from(vertretungMap.entries()).map(([key, value]) => [value, key])
  );

  /**
   * All useStates
   */
  //
  const [editedJsonObj, setEditedJsonObj] = useState(props.jsonObj);
  //
  const [stammkapital, setStammkapital] = useState<string>(
    getStammkapital(props.jsonObj)
  );
  //
  const [grundkapital, setGrundkapital] = useState<string>(
    getGrundkapital(props.jsonObj)
  );
  const [währung, setWährung] = useState(getWährung(props.jsonObj));
  const [grundkapitalWährung, setGrundkapitalWährung] = useState(
    getGrundkapitalWährung(props.jsonObj)
  );
  //
  const [firmenname, setFirmenname] = useState<string>(firmennameConst);
  const [amtsgericht, setAmtsgericht] = useState<string>(amtsgerichtConst);
  //
  const [straße, setStraße] = useState<string>(straßeConst);
  const [straßeAmtsgericht, setStraßeAmtsgericht] = useState<string>(
    straßeAmtsgerichtConst
  );
  //
  const [hausnummer, setHausnummer] = useState<string>(hausnummerConst);
  const [hausnummerAmtsgericht, setHausnummerAmtsgericht] = useState<string>(
    hausnummerAmtsgerichtConst
  );
  //
  const [postleitzahl, setPostleitzahl] = useState<string>(postleitzahlConst);
  const [postleitzahlAmtsgericht, setPostleitzahlAmtsgericht] =
    useState<string>(postleitzahlAmtsgerichtConst);
  //
  const [ort, setOrt] = useState<string>(ortConst);
  const [ortAmtsgericht, setOrtAmtsgericht] =
    useState<string>(ortAmtsgerichtConst);
  //
  const [gegenstandEinesUnternehmens, setGegenstandEinesUnternehmens] =
    useState<string | null>(getGegenstandEinesUnternehmens(props.jsonObj));
  //
  const [isStammkapitalDisabled, setIsStammkapitalDisabled] = useState(true);
  //
  const [isFirmendatenDisabled, setIsFirmendatenDisabled] = useState(true);
  //
  const [isAmtsgerichtdatenDisabled, setIsAmtsgerichtdatenDisabled] =
    useState(true);
  //
  const [firmennamen, setFirmennamen] = useState(
    props.findAllElementsByKey(editedJsonObj, "tns:bezeichnung")
  );
  //
  const [anschrift, setAnschrift] = useState(
    props.findAllElementsByKey(editedJsonObj, "tns:anschrift")
  );
  //
  const [sitz, setSitz] = useState(
    props.findAllElementsByKey(editedJsonObj, "tns:sitz")
  );
  //
  const [alleBeteiligungen, setAlleBeteiligungen] = useState(
    props.findAllElementsByKey(editedJsonObj, "tns:beteiligung")
  );
  //
  const [zusatzKG, setZusatzKG] = useState(
    props.findElementByKey(editedJsonObj, "tns:zusatzKG")
  );
  const [openPersonDialog, setOpenPersonDialog] = useState(false);
  const [openPersonWithHafteinlageDialog, setOpenPersonWithHafteinlageDialog] =
    useState(false);

  const [vertretung, setVertretung] = useState(
    props.findElementByKey(editedJsonObj, "tns:vertretung")
  );
  const [
    allgemeineVertretungsregelungFreiText,
    setAllgemeineVertretungsregelungFreiText,
  ] = useState(
    vertretung?.["tns:allgemeineVertretungsregelung"]?.[
      "tns:auswahl_vertretungsbefugnis"
    ]?.["tns:vertretungsbefugnisFreitext"] || ""
  );
  const [allgemeineVertretungsregelung, setAllgemeineVertretungsregelung] =
    useState(
      vertretung?.["tns:allgemeineVertretungsregelung"]?.[
        "tns:auswahl_vertretungsbefugnis"
      ]?.["tns:vertretungsbefugnis"]?.["code"] || ""
    );
  const [isVertretungDisabled, setIsVertretungDisabled] = useState(true);

  /**
   * All Functions
   */
  // Abschnitt Firmendaten
  const updateFirmendaten = () => {
    let gegenstand = props.findElementByKey(
      editedJsonObj,
      "tns:basisdatenRegister"
    );
    firmennamen
      .filter((x: any) => x?.["tns:bezeichnung.aktuell"] === firmennameConst)
      .forEach((obj: any) => {
        if (obj["tns:bezeichnung.aktuell"]) {
          obj["tns:bezeichnung.aktuell"] = firmenname; // Wrap the value as a text node
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:strasse"] === straßeConst)
      .forEach((obj: any) => {
        if (obj["tns:strasse"]) {
          obj["tns:strasse"] = straße;
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:hausnummer"] === hausnummerConst)
      .forEach((obj: any) => {
        if (obj["tns:hausnummer"]) {
          obj["tns:hausnummer"] = hausnummer;
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:postleitzahl"] === postleitzahlConst)
      .forEach((obj: any) => {
        if (obj["tns:postleitzahl"]) {
          obj["tns:postleitzahl"] = postleitzahl;
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:ort"] === ortConst)
      .forEach((obj: any) => {
        if (obj["tns:ort"]) {
          obj["tns:ort"] = ort;
        }
      });
    sitz
      .filter((x: any) => x?.["tns:ort"] === ortConst)
      .forEach((obj: any) => {
        if (obj["tns:ort"]) {
          obj["tns:ort"] = ort;
        }
      });
    if (gegenstand) {
      gegenstand["tns:gegenstand"] = gegenstandEinesUnternehmens;
    }
    setEditedJsonObj({ ...editedJsonObj });
  };

  const updateAmtsgerichtdaten = () => {
    firmennamen
      .filter((x: any) => x?.["tns:bezeichnung.aktuell"] === amtsgerichtConst)
      .forEach((obj: any) => {
        if (obj["tns:bezeichnung.aktuell"]) {
          obj["tns:bezeichnung.aktuell"] = amtsgericht; // Wrap the value as a text node
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:strasse"] === straßeConst)
      .forEach((obj: any) => {
        if (obj["tns:strasse"]) {
          obj["tns:strasse"] = straße;
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:hausnummer"] === hausnummerAmtsgerichtConst)
      .forEach((obj: any) => {
        if (obj["tns:hausnummer"]) {
          obj["tns:hausnummer"] = hausnummerAmtsgericht;
        }
      });
    anschrift
      .filter(
        (x: any) => x?.["tns:postleitzahl"] === postleitzahlAmtsgerichtConst
      )
      .forEach((obj: any) => {
        if (obj["tns:postleitzahl"]) {
          obj["tns:postleitzahl"] = postleitzahlAmtsgericht;
        }
      });
    anschrift
      .filter((x: any) => x?.["tns:ort"] === ortAmtsgerichtConst)
      .forEach((obj: any) => {
        if (obj["tns:ort"]) {
          obj["tns:ort"] = ortAmtsgericht;
        }
      });
    setEditedJsonObj({ ...editedJsonObj });
  };

  const handleFirmendatenSave = () => {
    updateFirmendaten();
  };

  const handleAmtsgerichtdatenSave = () => {
    updateAmtsgerichtdaten();
  };

  const handleVertretungSave = () => {
    if (
      vertretung?.["tns:allgemeineVertretungsregelung"]?.[
        "tns:auswahl_vertretungsbefugnis"
      ]?.["tns:vertretungsbefugnisFreitext"]
    ) {
      vertretung["tns:allgemeineVertretungsregelung"][
        "tns:auswahl_vertretungsbefugnis"
      ]["tns:vertretungsbefugnisFreitext"] =
        allgemeineVertretungsregelungFreiText;
    }

    if (
      vertretung?.["tns:allgemeineVertretungsregelung"]?.[
        "tns:auswahl_vertretungsbefugnis"
      ]?.["tns:vertretungsbefugnis"]?.["code"] || vertretung?.["tns:allgemeineVertretungsregelung"]?.[
        "tns:auswahl_vertretungsbefugnis"
      ]?.["tns:vertretungsbefugnis"]
    ) {
      vertretung["tns:allgemeineVertretungsregelung"][
        "tns:auswahl_vertretungsbefugnis"
      ]["tns:vertretungsbefugnis"]["code"] = allgemeineVertretungsregelung;
    }

    setEditedJsonObj({ ...editedJsonObj });
    setIsVertretungDisabled(true);
  };

  const handleFirmendatenStop = () => {
    setFirmenname(firmennameConst);
    setStraße(straßeConst);
    setHausnummer(hausnummerConst);
    setPostleitzahl(postleitzahlConst);
    setOrt(ortConst);
    setGegenstandEinesUnternehmens(
      getGegenstandEinesUnternehmens(props.jsonObj)
    );
    setEditedJsonObj(props.jsonObj); // Setzt das bearbeitete JSON-Objekt zurück
    setIsFirmendatenDisabled(true);
  };

  const handleAmtsgerichtdatenStop = () => {
    setAmtsgericht(amtsgerichtConst);
    setStraßeAmtsgericht(straßeAmtsgerichtConst);
    setHausnummerAmtsgericht(hausnummerAmtsgerichtConst);
    setPostleitzahlAmtsgericht(postleitzahlAmtsgerichtConst);
    setOrtAmtsgericht(ortAmtsgerichtConst);
    setEditedJsonObj(props.jsonObj);
    setIsAmtsgerichtdatenDisabled(true);
  };

  const handleVertretungsregelungStop = () => {
    if (allgemeineVertretungsregelung !== "") {
      setAllgemeineVertretungsregelung(
        props.findElementByKey(editedJsonObj, "tns:vertretung")?.[
          "tns:allgemeineVertretungsregelung"
        ]?.["tns:auswahl_vertretungsbefugnis"]?.["tns:vertretungsbefugnis"]?.[
          "code"
        ]
      );
      setIsVertretungDisabled(true);
    }
    if (allgemeineVertretungsregelungFreiText !== "") {
      setAllgemeineVertretungsregelungFreiText(
        props.findElementByKey(editedJsonObj, "tns:vertretung")?.[
          "tns:allgemeineVertretungsregelung"
        ]?.["tns:auswahl_vertretungsbefugnis"]?.[
          "tns:vertretungsbefugnisFreitext"
        ]
      );
      setIsVertretungDisabled(true);
    }
    setEditedJsonObj({ ...props.jsonObj });
  };

  // Abschnitt Stammkapital
  const updateStammkapital = (jsonObj: any, value: string, währung: string) => {
    const capitalObj = props.findElementByKey(jsonObj, "tns:stammkapital");

    if (capitalObj) {
      capitalObj["tns:zahl"] = value;
      capitalObj["tns:waehrung"]["code"] = währung;
    }
    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      ShareCapital: parseFloat(stammkapital),
    });
    setEditedJsonObj({ ...jsonObj });
  };

  const updateGrundkapital = (jsonObj: any, value: string, währung: string) => {
    const grundkapitalObj = props.findElementByKey(jsonObj, "tns:grundkapital");

    if (grundkapitalObj && grundkapitalObj["tns:hoehe"]) {
      grundkapitalObj["tns:hoehe"]["tns:zahl"] = value;
      grundkapitalObj["tns:hoehe"]["tns:waehrung"]["code"] = währung;
    }
    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      ShareCapital: parseFloat(grundkapital),
    });
    setEditedJsonObj({ ...jsonObj });
  };

  const handleStammkaptialSave = () => {
    updateStammkapital(editedJsonObj, stammkapital, währung);
  };

  const handleStammkapitalStop = () => {
    setStammkapital(getStammkapital(editedJsonObj));
    setWährung(getWährung(editedJsonObj));
    setEditedJsonObj(props.jsonObj);
    setIsStammkapitalDisabled(true);
  };

  const handleGrundkapitalSave = () => {
    updateGrundkapital(editedJsonObj, grundkapital, grundkapitalWährung);
  };

  const handleGrundkapitalStop = () => {
    setGrundkapital(getGrundkapital(editedJsonObj));
    setGrundkapitalWährung(getGrundkapitalWährung(editedJsonObj));
    setEditedJsonObj(props.jsonObj);
    setIsStammkapitalDisabled(true);
  };

  // remove Function
  const handleRemoveRollennummer = (id: string) => {
    if (Array.isArray(zusatzKG["tns:datenKommanditist"])) {
      const updatedZusatzKG = zusatzKG["tns:datenKommanditist"];
      const updated = updatedZusatzKG.filter(
        (x: any) => x["tns:ref.rollennummer"] !== id
      );
      // Ein neues Objekt erstellen, das das aktualisierte Array enthält
      const newZusatzKG = {
        ...zusatzKG, // Die anderen Eigenschaften von zusatzKG beibehalten
        "tns:datenKommanditist": updated, // Das aktualisierte Array setzen
      };
      // Setzen des aktualisierten Objekts
      setZusatzKG(newZusatzKG);
    }
  };

  const addVertretungsberechtigter = () => {
    const addXmlContent = parser.parse(addVertretungsberechtigterXmlContent);
    const currentVertretungsberechtigte = vertretung?.["tns:vertretungsberechtigte"];

    let updated;

    if (Array.isArray(currentVertretungsberechtigte)) {
        // Wenn es ein Array ist, fügen wir das neue Element einfach hinzu
        updated = [...currentVertretungsberechtigte, addXmlContent];
    } else if (currentVertretungsberechtigte) {
        // Wenn es kein Array ist, aber ein Element vorhanden ist, machen wir es zu einem Array
        updated = [currentVertretungsberechtigte, addXmlContent];
    } else {
        // Wenn kein Element vorhanden ist (null oder undefined), erstellen wir ein Array mit dem neuen Element
        updated = [addXmlContent];
    }

    setVertretung({ ...vertretung, "tns:vertretungsberechtigte": updated });
};


  //

  //

  /**
   * useEffects
   */
  useEffect(() => {
    props.setJsonObj(editedJsonObj);
    setIsFirmendatenDisabled(true);
    setIsStammkapitalDisabled(true);
    setIsAmtsgerichtdatenDisabled(true);
    setFirmennameConst(firmenname);
    setAmtsgerichtConst(amtsgericht);
    setStraßenConst(straße);
    setStraßenAmtsgerichtConst(straßeAmtsgericht);
    setHausnummerConst(hausnummer);
    setHausnummerAmtsgerichtConst(hausnummerAmtsgericht);
    setPostleitzahlConst(postleitzahl);
    setPostleitzahlAmtsgerichtConst(postleitzahlAmtsgericht);
    setOrtConst(ort);
    setOrtAmtsgerichtConst(ortAmtsgericht);
    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      CompanyName: firmenname,
      Street: straße,
      StreetNr: hausnummer,
      PurposeOfTheCompany: gegenstandEinesUnternehmens,
      Postcode: postleitzahl,
      City: ort,
    });
  }, [editedJsonObj]);

  useEffect(() => {
    const updatedJsonObj = { ...editedJsonObj }; // Eine Kopie des JSON-Objekts erstellen
    const allKommandist = alleBeteiligungen[0].filter(
      (x: any) => x["tns:rolle"]?.["tns:rollenbezeichnung"]?.["code"] === "275"
    );
    const allKommandistRollennummer = allKommandist.map(
      (x: any) => x["tns:rolle"]?.["tns:rollennummer"]
    );
    const alreadyInZusatzKg = Array.isArray(zusatzKG?.["tns:datenKommanditist"])
      ? zusatzKG["tns:datenKommanditist"].map(
          (x: any) => x["tns:ref.rollennummer"]
        )
      : zusatzKG?.["tns:datenKommanditist"]
      ? [zusatzKG["tns:datenKommanditist"]?.["tns:ref.rollennummer"]]
      : [];

    const haveToRemoveRollennummer = alreadyInZusatzKg?.filter(
      (x: any) => !allKommandistRollennummer.includes(x)
    );
    const haveToAddRollennummer = allKommandistRollennummer.filter(
      (x: any) => !alreadyInZusatzKg.includes(x)
    );
    if (haveToRemoveRollennummer?.length === 1) {
      handleRemoveRollennummer(haveToRemoveRollennummer[0]);
    }

    if (haveToAddRollennummer?.length === 1) {
      const parsedXmlContent = parser.parse(addXmlContent);
      parsedXmlContent["tns:ref.rollennummer"] = haveToAddRollennummer[0];
      if (Array.isArray(zusatzKG["tns:datenKommanditist"])) {
        const updatedZusatzKG = zusatzKG["tns:datenKommanditist"];
        const updated = [...updatedZusatzKG, parsedXmlContent];
        // Ein neues Objekt erstellen, das das aktualisierte Array enthält
        const newZusatzKG = {
          ...zusatzKG, // Die anderen Eigenschaften von zusatzKG beibehalten
          "tns:datenKommanditist": updated, // Das aktualisierte Array setzen
        };
        // Setzen des aktualisierten Objekts
        setZusatzKG(newZusatzKG);
      }
    }

    const verfahrensdaten =
      updatedJsonObj?.["tns:nachricht.reg.0400003"]?.["tns:grunddaten"]?.[
        "tns:verfahrensdaten"
      ];
    if (verfahrensdaten) {
      verfahrensdaten["tns:beteiligung"] = alleBeteiligungen[0];
    }

    const shareHolderArray = props.createShareHolderArray(
      alleBeteiligungen?.[0]?.filter(
        (x: any) =>
          x["tns:beteiligter"]["tns:auswahl_beteiligter"][
            "tns:natuerlichePerson"
          ]
      ) || []
    );

    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      ShareHolderArray: shareHolderArray,
    });

    setEditedJsonObj(updatedJsonObj);
  }, [alleBeteiligungen]);

  useEffect(() => {
    const updatedJsonObj = { ...editedJsonObj }; // Eine Kopie des JSON-Objekts erstellen
    const zusatzKG2 =
      updatedJsonObj?.["tns:nachricht.reg.0400003"]?.[
        "tns:fachdatenRegister"
      ]?.["tns:auswahl_zusatzangaben"]?.["tns:personengesellschaft"];
    if (zusatzKG2) {
      zusatzKG2["tns:zusatzKG"] = zusatzKG;
    }

    const bondingDepositsArray = props.createBondingDepositsArray(
      zusatzKG?.["tns:datenKommanditist"] || []
    );
    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      BondingDeposits: bondingDepositsArray,
    });

    setEditedJsonObj(updatedJsonObj);
  }, [zusatzKG]);

  useEffect(() => {
    const updatedJsonObj = { ...editedJsonObj }; // Eine Kopie des JSON-Objekts erstellen
    let vertretung2 =
      updatedJsonObj?.["tns:nachricht.reg.0400003"]?.[
        "tns:fachdatenRegister"
      ]?.["tns:basisdatenRegister"];
    if (vertretung2) {
      vertretung2["tns:vertretung"] = vertretung;
    }
    const authorizedRepresentativesArray =
      props.createAuthorizedRepresentativesArray(
        vertretung?.["tns:vertretungsberechtigte"] || []
      );
    props.setCompanyFormationFullObject({
      ...props.companyFormationFullObject,
      AuthorizedRepresentativesArray: authorizedRepresentativesArray,
    });

    setEditedJsonObj(updatedJsonObj);
  }, [vertretung]);

  return (
    <Box mt={6}>
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography>Firmendaten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                label="Firmenname"
                value={firmenname}
                onChange={(e) => {
                  setFirmenname(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Straße"
                value={straße}
                onChange={(e) => {
                  setStraße(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Nr."
                value={hausnummer}
                onChange={(e) => {
                  setHausnummer(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Postleitzahl"
                value={postleitzahl}
                onChange={(e) => {
                  setPostleitzahl(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Ort"
                value={ort}
                onChange={(e) => {
                  setOrt(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="Gegenstand des Unternehmens"
                value={gegenstandEinesUnternehmens}
                onChange={(e) => {
                  setGegenstandEinesUnternehmens(e.target.value);
                  setIsFirmendatenDisabled(false);
                }}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ justifyContent: "flex-end", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={handleFirmendatenStop}
              disabled={isFirmendatenDisabled}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleFirmendatenSave}
              variant="contained"
              disabled={isFirmendatenDisabled}
            >
              Speichern
            </Button>
          </DialogActions>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography>Amtsgericht</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                label="Amtsgericht"
                value={amtsgericht}
                onChange={(e) => {
                  setAmtsgericht(e.target.value);
                  setIsAmtsgerichtdatenDisabled(false);
                }}
                fullWidth
                disabled={
                  !props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[
                    `tns:grunddaten`
                  ]?.[`tns:verfahrensdaten`]?.[`tns:beteiligung`]?.[1]?.[
                    `tns:beteiligter`
                  ]?.[`tns:auswahl_beteiligter`]?.[`tns:organisation`]?.[
                    `tns:bezeichnung`
                  ]?.[`tns:bezeichnung.aktuell`]
                }
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Straße"
                value={straßeAmtsgericht}
                onChange={(e) => {
                  setStraßeAmtsgericht(e.target.value);
                  setIsAmtsgerichtdatenDisabled(false);
                }}
                fullWidth
                disabled={
                  !props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[
                    `tns:grunddaten`
                  ]?.[`tns:verfahrensdaten`]?.[`tns:beteiligung`]?.[1]?.[
                    `tns:beteiligter`
                  ]?.[`tns:auswahl_beteiligter`]?.[`tns:organisation`]?.[
                    `tns:bezeichnung`
                  ]?.[`tns:bezeichnung.aktuell`]
                }
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Nr."
                value={hausnummerAmtsgericht}
                onChange={(e) => {
                  setHausnummerAmtsgericht(e.target.value);
                  setIsAmtsgerichtdatenDisabled(false);
                }}
                fullWidth
                disabled={
                  !props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[
                    `tns:grunddaten`
                  ]?.[`tns:verfahrensdaten`]?.[`tns:beteiligung`]?.[1]?.[
                    `tns:beteiligter`
                  ]?.[`tns:auswahl_beteiligter`]?.[`tns:organisation`]?.[
                    `tns:bezeichnung`
                  ]?.[`tns:bezeichnung.aktuell`]
                }
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Postleitzahl"
                value={postleitzahlAmtsgericht}
                onChange={(e) => {
                  setPostleitzahlAmtsgericht(e.target.value);
                  setIsAmtsgerichtdatenDisabled(false);
                }}
                fullWidth
                disabled={
                  !props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[
                    `tns:grunddaten`
                  ]?.[`tns:verfahrensdaten`]?.[`tns:beteiligung`]?.[1]?.[
                    `tns:beteiligter`
                  ]?.[`tns:auswahl_beteiligter`]?.[`tns:organisation`]?.[
                    `tns:bezeichnung`
                  ]?.[`tns:bezeichnung.aktuell`]
                }
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Ort"
                value={ortAmtsgericht}
                onChange={(e) => {
                  setOrtAmtsgericht(e.target.value);
                  setIsAmtsgerichtdatenDisabled(false);
                }}
                fullWidth
                disabled={
                  !props.jsonObj?.[`tns:nachricht.reg.0400003`]?.[
                    `tns:grunddaten`
                  ]?.[`tns:verfahrensdaten`]?.[`tns:beteiligung`]?.[1]?.[
                    `tns:beteiligter`
                  ]?.[`tns:auswahl_beteiligter`]?.[`tns:organisation`]?.[
                    `tns:bezeichnung`
                  ]?.[`tns:bezeichnung.aktuell`]
                }
                variant="outlined"
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ justifyContent: "flex-end", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={handleAmtsgerichtdatenStop}
              disabled={isAmtsgerichtdatenDisabled}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleAmtsgerichtdatenSave}
              variant="contained"
              disabled={isAmtsgerichtdatenDisabled}
            >
              Speichern
            </Button>
          </DialogActions>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography>Personen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {openPersonDialog && (
            <PersonAddDialog
              setOpenPersonDialog={setOpenPersonDialog}
              setAlleBeteiligungen={setAlleBeteiligungen}
              alleBeteiligungen={alleBeteiligungen}
            />
          )}
          <Typography variant="h6">Auflistung genannter Personen</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "25%" }}>Bezeichnung</TableCell>
                <TableCell sx={{ width: "25%" }}>Vorname</TableCell>
                <TableCell sx={{ width: "25%" }}>Nachname</TableCell>
                <TableCell sx={{ width: "25%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alleBeteiligungen[0]
                .filter(
                  (x: any) =>
                    x["tns:beteiligter"]["tns:auswahl_beteiligter"][
                      "tns:natuerlichePerson"
                    ]
                )
                .map((x: any) => (
                  <PersonGmbHRow
                    currentPerson={x}
                    alleBeteiligungen={alleBeteiligungen}
                    setAlleBeteiligungen={setAlleBeteiligungen}
                  />
                ))}
            </TableBody>
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenPersonDialog(true);
                }}
                sx={{
                  float: "left",
                  "& .MuiButton-startIcon": {
                    marginRight: "2px",
                  },
                }}
                startIcon={
                  <Add
                    sx={{
                      fontSize: 22,
                      position: "relative",
                      bottom: 1,
                      marginRight: "0px",
                    }}
                  />
                }
              >
                Person
              </Button>
            </Box>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography>Vertretung</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {(vertretung?.["tns:allgemeineVertretungsregelung"]?.[
              "tns:auswahl_vertretungsbefugnis"
            ]?.["tns:vertretungsbefugnisFreitext"]) && (
              <Grid item sm={12}>
                <TextField
                  label="Allgemeine Vertretungsregelung Freitext"
                  value={allgemeineVertretungsregelungFreiText}
                  onChange={(e) => {
                    setAllgemeineVertretungsregelungFreiText(e.target.value);
                    setIsVertretungDisabled(false);
                  }}
                  rows={3}
                  fullWidth
                  variant="outlined"
                  multiline
                />
              </Grid>
            )}
            {(vertretung?.["tns:allgemeineVertretungsregelung"]?.[
              "tns:auswahl_vertretungsbefugnis"
            ]?.["tns:vertretungsbefugnis"]?.["code"] || vertretung?.["tns:allgemeineVertretungsregelung"]?.[
              "tns:auswahl_vertretungsbefugnis"
            ]?.["tns:vertretungsbefugnis"]) && (
              <Grid item sm={12}>
                <Autocomplete
                  key={allgemeineVertretungsregelung}
                  options={Object.values(vertretungsObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  value={vertretungMap.get(allgemeineVertretungsregelung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setAllgemeineVertretungsregelung(
                        valueToKeyVertretungsregelungMap.get(newValue)
                      );
                      setIsVertretungDisabled(false);
                    } else {
                      setAllgemeineVertretungsregelung(""); // Optional: handle the case where no value is selected
                      setIsVertretungDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Allgemeine Vertretungsregelung"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <DialogActions sx={{ justifyContent: "flex-end", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={handleVertretungsregelungStop}
              disabled={isVertretungDisabled}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleVertretungSave}
              variant="contained"
              disabled={isVertretungDisabled}
            >
              Speichern
            </Button>
          </DialogActions>
          <Box mt={6}>
            <Typography variant="h6">
              Auflistung der Vertretungsberechtigten
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "25%" }}>Bezeichnung</TableCell>
                  <TableCell sx={{ width: "25%" }}>Name</TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    Vertretungsregelung
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(vertretung?.["tns:vertretungsberechtigte"]) ? (
                  vertretung?.["tns:vertretungsberechtigte"].map((x: any) => (
                    <VertretungsBerechtigteRow
                      currentPerson={x}
                      vertretung={vertretung}
                      setVertretung={setVertretung}
                      alleBeteiligungen={alleBeteiligungen}
                    />
                  ))
                ) : (
                  <VertretungsBerechtigteRow
                    currentPerson={vertretung?.["tns:vertretungsberechtigte"]}
                    vertretung={vertretung}
                    setVertretung={setVertretung}
                    alleBeteiligungen={alleBeteiligungen}
                  />
                )}
              </TableBody>

              <Box mt={2}>
                <Button
                  variant="contained"
                  onClick={addVertretungsberechtigter}
                  sx={{
                    float: "left",
                    "& .MuiButton-startIcon": {
                      marginRight: "2px",
                    },
                  }}
                  startIcon={
                    <Add
                      sx={{
                        fontSize: 22,
                        position: "relative",
                        bottom: 1,
                        marginRight: "0px",
                      }}
                    />
                  }
                >
                  Vertretungsberechtigter
                </Button>
              </Box>
            </Table>
          </Box>
        </AccordionDetails>
      </Accordion>

      {props.rechtsForm === "AG" && (
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Grundkapital</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  label="Grundkapital"
                  value={grundkapital}
                  onChange={(e) => {
                    setGrundkapital(e.target.value);
                    setIsStammkapitalDisabled(false);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12}>
                <Autocomplete
                  options={Object.values(währungsObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  value={währungsMap.get(grundkapitalWährung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setGrundkapitalWährung(
                        valueToKeyWährungsMap.get(newValue)
                      );
                      setIsStammkapitalDisabled(false);
                    } else {
                      setGrundkapitalWährung(""); // Optional: handle the case where no value is selected
                      setIsStammkapitalDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Währung"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>

            <DialogActions sx={{ justifyContent: "flex-end", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={handleGrundkapitalStop}
                disabled={isStammkapitalDisabled}
              >
                Abbrechen
              </Button>
              <Button
                onClick={handleGrundkapitalSave}
                variant="contained"
                disabled={isStammkapitalDisabled}
              >
                Speichern
              </Button>
            </DialogActions>
          </AccordionDetails>
        </Accordion>
      )}

      {props.rechtsForm === "KG" && (
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Hafteinlagen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6">
              Auflistung der Personen mit Hafteinlagen
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Bezeichnung</TableCell>
                  <TableCell sx={{ width: "20%" }}>Name</TableCell>
                  <TableCell sx={{ width: "20%" }}>Hafteinlage</TableCell>
                  <TableCell sx={{ width: "20%" }}>Währung</TableCell>
                  <TableCell sx={{ width: "20%" }}>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(zusatzKG["tns:datenKommanditist"]) &&
                  zusatzKG["tns:datenKommanditist"]?.map((x: any) => (
                    <PersonWithhafteinlagenRow
                      currentPersonWithHafteinlagen={x}
                      alleBeteiligungen={alleBeteiligungen}
                      zusatzKG={zusatzKG}
                      setZusatzKG={setZusatzKG}
                    />
                  ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}

      {props.rechtsForm === "GmbH" && (
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Stammkapital</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  label="Stammkapital"
                  value={stammkapital}
                  onChange={(e) => {
                    setStammkapital(e.target.value);
                    setIsStammkapitalDisabled(false);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12}>
                <Autocomplete
                  options={Object.values(währungsObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={(option) => option}
                  value={währungsMap.get(währung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setWährung(valueToKeyWährungsMap.get(newValue));
                      setIsStammkapitalDisabled(false);
                    } else {
                      setWährung(""); // Optional: handle the case where no value is selected
                      setIsStammkapitalDisabled(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Währung"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>

            <DialogActions sx={{ justifyContent: "flex-end", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={handleStammkapitalStop}
                disabled={isStammkapitalDisabled}
              >
                Abbrechen
              </Button>
              <Button
                onClick={handleStammkaptialSave}
                variant="contained"
                disabled={isStammkapitalDisabled}
              >
                Speichern
              </Button>
            </DialogActions>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
