import React, { useState } from 'react';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../Interfaces/ILegalRequirementTemplate';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowDownward, ArrowUpward, Edit } from '@mui/icons-material';
import { LegalRequirementEdit } from './LegalRequirementEdit';
import { ConfirmDelete } from '../core/ConfirmDelete';
import {
  ILegalPhaseTemplate,
  ILegalPhaseTemplateFullObject,
} from '../Interfaces/ILegalPhaseTemplate';

interface IProps {
  currentRequirement: ILegalRequirementTemplateFullObject;
  legalRequirementArray: ILegalRequirementTemplateFullObject[];
  setLegalRequirementArray: Function;
  legalPhaseTemplateArray?: ILegalPhaseTemplateFullObject[];
}

export const LegalRequirementRow: React.FC<IProps> = props => {
  const [currentRequirementEdit, setCurrentRequirementEdit] = useState(
    props.currentRequirement
  );
  //
  const [isOpenEdit, setIsOpenEdit] = useState(
    props.currentRequirement.idLegalRequirementTemplate < 0 &&
      props.currentRequirement.LegalRequirementTemplate === ''
  );

  const saveReq = () => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.map(x =>
        x.idLegalRequirementTemplate ===
        currentRequirementEdit.idLegalRequirementTemplate
          ? currentRequirementEdit
          : x
      ),
    ]);
    setIsOpenEdit(false);
  };

  const isFirstDisabled = () => {
    let currentIndex: number = props.legalRequirementArray
      .map(x => x.idLegalRequirementTemplate)
      .indexOf(props.currentRequirement.idLegalRequirementTemplate);
    return currentIndex === 0;
  };
  const isLastDisabled = () => {
    let currentIndex: number = props.legalRequirementArray
      .map(x => x.idLegalRequirementTemplate)
      .indexOf(props.currentRequirement.idLegalRequirementTemplate);
    return currentIndex === props.legalRequirementArray.length - 1;
  };

  /*
    const getUp = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let
            
            toChangeObject.Position = currentRequirementEdit.Position;
            currentObject.Position = currentRequirementEdit.Position-1;
            //copyOfArray.map(x => x.idLegalRequirementTemplate === toChangeObject!.idLegalRequirementTemplate ? toChangeObject : x );
            copyOfArray.map(x => 
                x.idLegalRequirementTemplate === currentObject!.idLegalRequirementTemplate ? currentObject :
                (x.idLegalRequirementTemplate === toChangeObject?.idLegalRequirementTemplate) ? toChangeObject : x );
            
            props.setLegalRequirementArray(copyOfArray)
        }
        
        
    }
    */

  const getUp = () => {
    let copyOfArray = [...props.legalRequirementArray];
    let targetPositionn = currentRequirementEdit.Position - 1;
    let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
    let currentObject = { ...currentRequirementEdit };

    if (toChangeObject !== undefined) {
      let currentIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirementTemplate)
        .indexOf(currentObject.idLegalRequirementTemplate);
      let targetIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirementTemplate)
        .indexOf(toChangeObject.idLegalRequirementTemplate);

      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position - 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position + 1;

      props.setLegalRequirementArray(copyOfArray);
    }
  };

  const getDown = () => {
    let copyOfArray = [...props.legalRequirementArray];
    let targetPositionn = currentRequirementEdit.Position + 1;
    let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
    let currentObject = { ...currentRequirementEdit };

    if (toChangeObject !== undefined) {
      let currentIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirementTemplate)
        .indexOf(currentObject.idLegalRequirementTemplate);
      let targetIndex = props.legalRequirementArray
        .map(x => x.idLegalRequirementTemplate)
        .indexOf(toChangeObject.idLegalRequirementTemplate);

      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position + 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position - 1;

      props.setLegalRequirementArray(copyOfArray);
    }
  };

  const handleDelete = () => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.filter(
        x =>
          x.idLegalRequirementTemplate !==
          props.currentRequirement.idLegalRequirementTemplate
      ),
    ]);
  };

  return (
    <>
      <Dialog open={isOpenEdit} onClose={() => {}} maxWidth='lg' fullWidth>
        <DialogTitle>Voraussetzung anpassen</DialogTitle>
        <DialogContent>
          <LegalRequirementEdit
            currentRequirement={currentRequirementEdit}
            setCurrentRequirement={setCurrentRequirementEdit}
            legalRequirements={props.legalRequirementArray}
            legalPhaseTemplateArray={props.legalPhaseTemplateArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenEdit(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={saveReq}
            disabled={currentRequirementEdit.LegalRequirementTemplate === ''}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell sx={{ width: 20 }}></TableCell>
        <TableCell>
          {props.currentRequirement.LegalRequirementTemplate}
        </TableCell>
        <TableCell>
          {props.legalRequirementArray
            .filter(
              x =>
                x.idLegalRequirementTemplate ===
                currentRequirementEdit.Predecessor_idLegalRequirementTemplate
            )
            .map(x => (
              <Typography sx={{ mr: 3 }} variant='caption'>
                {x.LegalRequirementTemplate}
              </Typography>
            ))}
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ float: 'right' }}
            size='small'
            disabled={isLastDisabled()}
            onClick={getDown}
          >
            <ArrowDownward />
          </IconButton>

          <IconButton
            sx={{ float: 'right' }}
            size='small'
            disabled={isFirstDisabled()}
            onClick={getUp}
          >
            <ArrowUpward />
          </IconButton>
        </TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesClient ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesEmployees ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.sendMail ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          <IconButton
            size='small'
            onClick={() => {
              setCurrentRequirementEdit(props.currentRequirement);
              setIsOpenEdit(true);
            }}
          >
            <Edit />
          </IconButton>
          <ConfirmDelete
            itemText='die aktulle Bedingung'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
