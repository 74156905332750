import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Box,
    Grid,
    Divider,
    CircularProgress,
    Tabs,
    Tab,
    Card,
} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import {
    ICourt,
    ICourtRole,
    ICourtCourtRole,
    type ICourtAdminstrativeUnitAssignment,
} from '../../Interfaces/IAemter';
import {CourtAssignmentDialog} from './CourtAssignmentDialog';
import {useAlert} from '../../context/AlertContext';
import {EditCourtAssignmentDialog} from './EditCourtAssignmentDialog';
import {
    useCourtAdministrativeUnitAssignments,
    useCourtCourtRoles,
    useCourts,
    useDeleteCourtAdminUnitAssignment,
    useDeleteCourtCourtRoleAssignment,
    useUpdateCourt,
} from '../hooks';

interface CourtDetailProps {
    court: ICourt;
    assignments: ICourtCourtRole[];
    courtAdmininistrativeUnitAssignments: ICourtAdminstrativeUnitAssignment[];
    courtRoles: ICourtRole[];
    onClose: () => void;
}

export const CourtDetail: React.FC<CourtDetailProps> = ({
                                                            court,
                                                            assignments,
                                                            courtAdmininistrativeUnitAssignments,
                                                            courtRoles,
                                                            onClose,
                                                        }) => {
    const {showAlert} = useAlert();

    const {refetchCourtCourtRoles} = useCourtCourtRoles();
    const {refetchCourtAdministrativeUnitAssignments} =
        useCourtAdministrativeUnitAssignments();
    const {deleteCourtAdminstrativeUnitAssignment} =
        useDeleteCourtAdminUnitAssignment();
    const {deleteCourtCourtRoleAssignment} =
        useDeleteCourtCourtRoleAssignment();
    const {refetchCourts} = useCourts();
    const {updateCourt, isPendingUpdateCourt} = useUpdateCourt();

    const [editableCourt, setEditableCourt] = useState<ICourt>(court);
    const [editableAssignment, setEditableAssignment] =
        useState<ICourtCourtRole | null>(null);
    const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    // Handle input change for each field in the court details
    const handleInputChange =
        (field: keyof ICourt) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setEditableCourt({...editableCourt, [field]: event.target.value});
        };

    const handleOpenAssignmentDialog = () => {
        setOpenAssignmentDialog(true);
    };

    const handleCloseAssignmentDialog = () => {
        setOpenAssignmentDialog(false);
    };

    const handleDeleteCourtAdminstrativeUnitAssignment = async (
        assignment: ICourtAdminstrativeUnitAssignment
    ) => {
        try {
            await deleteCourtAdminstrativeUnitAssignment(assignment);

            refetchCourtAdministrativeUnitAssignments();

            showAlert({
                text: 'Zuweisung erfolgreich gelöscht',
            });
        } catch (_) {
            showAlert({
                text: 'Fehler beim Löschen der Zuweisung',
                severity: 'error',
            });
        }
    };

    const handleDeleteCourtCourtRoleAssignment = async (
        assignment: ICourtCourtRole
    ) => {
        try {
            await deleteCourtCourtRoleAssignment(assignment);

            refetchCourtCourtRoles();

            showAlert({
                text: 'Zuweisung gelöscht',
            });
        } catch (_) {
            showAlert({
                text: 'Fehler beim Löschen der Zuweisung',
                severity: 'error',
            });
        }
    };

    const handleUpdateCourt = async (updatedCourt: ICourt) => {
        updatedCourt.activeCourt =
            updatedCourt.activeCourt !== undefined
                ? Boolean(updatedCourt.activeCourt)
                : true;

        try {
            await updateCourt(updatedCourt);

            refetchCourts();

            showAlert({
                text: 'Gericht gespeichert',
            });

            onClose();
        } catch (_) {
            showAlert({
                text: 'Fehler beim Speichern des Gerichts',
                severity: 'error',
            });
        }
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth='md'>
            <DialogTitle>Gerichtsübersicht</DialogTitle>
            <Divider/>

            <DialogContent>
                <Box display='flex' flexDirection='column' gap={2}>
                    <Typography variant='h6' gutterBottom>
                        Gerichtsinformationen
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required={true}
                                label='Gerichtsname'
                                value={editableCourt.Court}
                                onChange={handleInputChange('Court')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label='XJustiz-ID'
                                value={editableCourt.XJustizID}
                                onChange={handleInputChange('XJustizID')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label='Telefonnummer'
                                value={editableCourt.PhoneNumber}
                                onChange={handleInputChange('PhoneNumber')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required={true}
                                label='Email'
                                value={editableCourt.Email}
                                onChange={handleInputChange('Email')}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                required={true}
                                label='Straße'
                                value={editableCourt.Street}
                                onChange={handleInputChange('Street')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required={true}
                                label='Hausnummer'
                                value={editableCourt.StreetNr}
                                onChange={handleInputChange('StreetNr')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label='Adresszusatz'
                                value={editableCourt.StreetAditional}
                                onChange={handleInputChange('StreetAditional')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label='Website'
                                value={editableCourt.Website}
                                onChange={handleInputChange('Website')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Card>
                        <Tabs
                            value={tabIndex}
                            onChange={(_, newValue) => setTabIndex(newValue)}
                        >
                            <Tab key='adminUnits' label='Verwaltungseinheiten'/>
                            <Tab key='roles' label='Rollen'/>
                        </Tabs>
                        <Box>
                            {tabIndex === 0 && (
                                <Box p={2}>
                                    {courtAdmininistrativeUnitAssignments.length !== 0 ? (
                                        <List>
                                            {courtAdmininistrativeUnitAssignments.map(
                                                (assignment, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={assignment.AdministrativeUnit}
                                                            secondary={`Rolle: ${assignment.CourtRole}`}
                                                        />

                                                        <IconButton
                                                            onClick={() =>
                                                                handleDeleteCourtAdminstrativeUnitAssignment(
                                                                    assignment
                                                                )
                                                            }
                                                        >
                                                            <Delete/>
                                                        </IconButton>
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    ) : (
                                        <Typography>
                                            Keine Verwaltungseinheiten zugewiesen
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            {tabIndex === 1 && (
                                <Box p={2}>
                                    <Box display='flex' justifyContent='end'>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={handleOpenAssignmentDialog}
                                        >
                                            Neue Zuweisung
                                        </Button>
                                    </Box>
                                    {assignments.length !== 0 ? (
                                        <List>
                                            {assignments.map((assignment, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={`${assignment.CourtRole}`}
                                                        secondary={`Aktuelle Bearbeitungszeit: ${assignment.currentProcessingTime || assignment.initalProcessingTime} Tage`}
                                                    />
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditableAssignment(assignment);
                                                        }}
                                                    >
                                                        <Edit/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDeleteCourtCourtRoleAssignment(assignment)
                                                        }
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <Typography>Keine Rollen zugewiesen</Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Card>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    Abbrechen
                </Button>
                <Button
                    onClick={() => handleUpdateCourt(editableCourt)}
                    color='primary'
                    variant='contained'
                    disabled={isPendingUpdateCourt}
                >
                    {isPendingUpdateCourt ? (
                        <Box display='flex' alignItems='center' gap={1}>
                            <span>Speichern</span>
                            <CircularProgress size={20}/>
                        </Box>
                    ) : (
                        'Speichern'
                    )}
                </Button>
            </DialogActions>

            {openAssignmentDialog && (
                <CourtAssignmentDialog
                    court={editableCourt}
                    courtRoles={courtRoles}
                    existingAssignments={assignments}
                    onClose={handleCloseAssignmentDialog}
                />
            )}
            {editableAssignment && (
                <EditCourtAssignmentDialog
                    onClose={() => setEditableAssignment(null)}
                    assignment={editableAssignment}
                />
            )}
        </Dialog>
    );
};
