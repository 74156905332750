import React, {useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {IContact, IContactFullObject} from "../Interfaces/IContact";
import {ClientBaseData} from "./ClientBaseData";
import {deleteFetch, uploadFetch, useFetch} from "../hooks/useFetch";
import {ICompany} from "../Interfaces/ICompany";
import {CustomCircularProgress} from "../generic/CustomCircularProgress";
import {ShowWasSaved} from "../core/ShowWasSaved";
import {ILegalTransaction} from "../Interfaces/ILegalTransaction";
import {ClientsLegalTransactionOverview} from "./ClientsLegalTransactionOverview";
import {Delete} from "@mui/icons-material";
import {getIsEmail} from "../core/generic_helper";
import {ClientEmailWarning} from "./ClientEmailWarning";

interface IProps {
    idContact: number | null;
    setidContact: Function;
    contactArray: IContact[];
    setContactArray: Function;
    //
    isLoading: boolean;
    setIsLoading: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
    handleAfterRemove: Function;
    //
    handleClose?: Function;

}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const getEmptyContact = () => {
    return {
        idCompany: -1,
        idContact: -1,
        idGender: 3,
        idAcademicTitle: 1,
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: null,
        Birthday: null,
        passwordResetRequired: true,
        loginPossible: true,
        Birthplace: null,
        isReceivingEmailEnabled: false,
        last_login_at: null
    } as IContact;
}

export const getEmptyCompany = () => {
    return {
        idCompany: -1,
        idBusinessForm: 1,
        Company: "",
        Street: "",
        StreetNr: "",
        StreetAditional: null,
        idPostcode: null,
        PhoneNumber: null,
        Website: null,
        RegisterCourt: null,
        RegisterNumber: null,
        SalesTaxNumber: null,
        TaxNumber: null
    } as ICompany
}


////////////////////////////////////////////////////////////////////////////////////
export const ClientsEdit: React.FC<IProps> = (props) => {
    ////////////////////////////////////////////
    //ContactFullObject
    const [currentContactFullObject, setCurrentContactFullObject, wasSuccessfullyFullObject] = (useFetch<IContactFullObject>("/contact/fullObject/", props.idContact));

    const [contactObject, setContactObject] = useState<IContact | null>(Number(props.idContact) > 0 ? null : getEmptyContact());
    const [companyObject, setCompanyObject] = useState<ICompany | null>(Number(props.idContact) > 0 ? null : getEmptyCompany());
    const [legalTransactionArray, setLegalTransactionArray] = useState<ILegalTransaction[]>([]);
    //
    const [hasDuplicateEmailError, setHasDuplicateEmailError] = useState(false);
    //
    const [wasSuccessfullyLoadContact, setWasSuccessfullyLoadContact] = useState(true);
    //
    const [isOpenConfirmPwReset, setIsOpenConfirmPwReset] = useState(false);
    // 
    const [isOpenDelete, setIsOpenDelete] = useState(false);


    useEffect(() => {
        if (currentContactFullObject !== undefined) {
            setContactObject(currentContactFullObject);
            setCompanyObject(currentContactFullObject.CompanyObject);
            setLegalTransactionArray(currentContactFullObject.LegalTransactionArray);
        }
    }, [currentContactFullObject])
    ////////////////////////////////////////////


    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleSaveWrapper = (currentObject: IContactFullObject) => {
        if (props.idContact != null) {
            if (props.idContact < 0) {
                props.setContactArray([
                    currentObject,
                    ...props.contactArray

                ])
            } else {
                props.setContactArray([
                    ...props.contactArray.map(x => x.idContact === props.idContact ? currentObject : x)
                ])
            }

            if (contactObject?.resetPassword) {
                setIsOpenConfirmPwReset(true);
            } else {
                props.setidContact(null);
            }
        }
    }


    const handleUpload = () => {
        if (companyObject !== null && contactObject !== null) {
            let tmpObject: IContactFullObject = {
                ...contactObject,
                CompanyObject: companyObject,
                Birthday: (contactObject.Birthday == undefined || contactObject.Birthday == "") ? null : new Date(contactObject.Birthday).toISOString().split("T")[0],
                loginPossible: (Number(contactObject.loginPossible) == 0) ? false : true,
                isReceivingEmailEnabled: (Number(contactObject.isReceivingEmailEnabled) == 0) ? false : true,
                passwordResetRequired: (Number(contactObject.passwordResetRequired) == 0) ? false : true,
                LegalTransactionArray: legalTransactionArray
            }
            uploadFetch(`/contact/fullObject`,
                (tmpObject.idContact < 0),
                tmpObject,
                handleSaveWrapper,
                props.setWasSavedSuccesfully,
                props.setWasSaved,
                props.setIsLoading
            );
        }
    }

    const handleDelete = () => {
        if (props.idContact !== null) {
            deleteFetch("contact", props.idContact, props.handleAfterRemove)
        }
    }

    useEffect(() => {
        //Set current object when props.currentObject
        if (contactObject != undefined && contactObject != undefined) {
            setContactObject(contactObject)
        }
    }, [currentContactFullObject]);

    if (props.idContact === null) {
        return <>Bitte Mandant auswählen!</>
    } else if (companyObject == null || contactObject == null) {
        return <>
            <CustomCircularProgress/>
        </>
    }
    return (
        <>
            <Dialog open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    Sind Sie sich sicher, dass Sie den aktuellen Mandanten löschen wollen?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenDelete(false)}>Abbruch</Button>
                    <Button onClick={handleDelete}>Löschen</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenConfirmPwReset}
                onClose={() => props.setidContact(null)}
            >
                <DialogTitle>Neues Passwort generieren</DialogTitle>
                <DialogContent>
                    Das Passwort wurde für {currentContactFullObject?.LastName} zurückgesetzt.
                    <br/> Der Mandant erhält zeitnah eine E-Mail unter der
                    E-Mail-Adresse {currentContactFullObject?.Email}.
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => props.setidContact(null)}>Schließen</Button>
                </DialogActions>
            </Dialog>

            <DialogContent>
                {currentContactFullObject && (<Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                <h1>
                                    {currentContactFullObject?.FirstName} {currentContactFullObject?.LastName}

                                    {(props.idContact > 0 && currentContactFullObject !== undefined) &&
                                        <IconButton sx={{float: "right"}}
                                                    onClick={() => setIsOpenDelete(true)}><Delete/></IconButton>
                                    }
                                </h1>
                            </Typography>

                        </Grid>
                    </Grid>
                </Box>)}


                {(props.isLoading) && <><LinearProgress sx={{mt: 1}}/></>}

                <ClientEmailWarning
                    currentContact={contactObject}
                    contactArray={props.contactArray}
                    hasDuplicateEmailError={hasDuplicateEmailError}
                    setHasDuplicateEmailError={setHasDuplicateEmailError}
                />

                {contactObject.last_login_at !== null && (<Typography sx={{mb: 2, ml: 5, display: "flex"}}>
                    Letzter Login:
                    <Box sx={{ml: 5}}>
                        {(contactObject.last_login_at === null)
                            ? <>----</>
                            : new Date(contactObject.last_login_at).toLocaleString()
                        }
                    </Box>

                </Typography>)}


                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Stammdaten" {...a11yProps(0)} />
                            <Tab label="Rechtsgeschäfte" {...a11yProps(1)} />

                            {/*
                            
                            <Tab label="Dokumente" {...a11yProps(2)} /> 
                        */}
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <ClientBaseData
                            currentContactObject={contactObject}
                            setCurrentContactObject={setContactObject}
                            companyObject={companyObject}
                            setCompanyObject={setCompanyObject}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ClientsLegalTransactionOverview
                            legalTransactionArray={legalTransactionArray}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        Item Three
                    </CustomTabPanel>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => (props.handleClose === undefined) ? props.setidContact(null) : props.handleClose()}
                >Abbrechen</Button>
                <Button
                    variant="contained"
                    disabled={
                        props.isLoading
                        || contactObject.FirstName === ""
                        || contactObject.LastName === ""
                        || contactObject.Email === "" || !getIsEmail(contactObject.Email)
                        || hasDuplicateEmailError
                    }
                    onClick={() => {
                        handleUpload()
                    }}
                >Speichern</Button>
            </DialogActions>
        </>
    )
}
