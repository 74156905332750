import { CourtsList } from './CourtList';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { ICourt } from '../../Interfaces/IAemter';
import { AddCourtDialog } from './AddCourtDialog';
import { CourtDetail } from './CourtDetails';
import { useState } from 'react';
import { useAlert } from '../../context/AlertContext';
import {
  useCourtAdministrativeUnitAssignments,
  useCourtCourtRoles,
  useCourtRoles,
  useCourts,
  useDeleteCourt,
  useFederalStates,
  usePostCodes,
} from '../hooks';

export const CourtsTab = () => {
  const { showAlert } = useAlert();

  const { courts, isLoadingCourts, refetchCourts } = useCourts();
  const { postcodes, isLoadingPostcodes } = usePostCodes();
  const { federalStates, isLoadingFederalStates } = useFederalStates();
  const { courtRoles, isLoadingCourtRoles } = useCourtRoles();
  const { courtCourtRoles, isLoadingCourtCourtRoles } = useCourtCourtRoles();
  const {
    courtAdministrativeUnitAssignments,
    isLoadingCourtAdministrativeUnitAssignments,
  } = useCourtAdministrativeUnitAssignments();

  const { deleteCourt } = useDeleteCourt();

  const [selectedCourt, setSelectedCourt] = useState<ICourt | null>(null);
  const [openAddCourtDialog, setOpenAddCourtDialog] = useState(false);

  const handleDeleteCourt = async (court: ICourt) => {
    try {
      await deleteCourt(court.idCourt);

      refetchCourts();

      showAlert({
        text: 'Gericht gelöscht',
      });
    } catch (_) {
      showAlert({
        text: 'Fehler beim Löschen des Gerichts',
        severity: 'error',
      });
    }
  };

  if (
    isLoadingCourts ||
    isLoadingPostcodes ||
    isLoadingFederalStates ||
    isLoadingCourtRoles ||
    isLoadingCourtCourtRoles ||
    isLoadingCourtAdministrativeUnitAssignments
  )
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );

  if (
    !courts ||
    !postcodes ||
    !federalStates ||
    !courtRoles ||
    !courtCourtRoles ||
    !courtAdministrativeUnitAssignments
  )
    return <div>Fehler beim Laden der Daten</div>;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={5}
              >
                <Typography variant='h5'>Gerichte</Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenAddCourtDialog(true)}
                >
                  Neues Gericht hinzufügen
                </Button>
              </Box>
              <CourtsList
                courts={courts}
                onCourtClick={court => setSelectedCourt(court)}
                onDelete={handleDeleteCourt}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openAddCourtDialog && (
        <AddCourtDialog
          courts={courts}
          onClose={() => setOpenAddCourtDialog(false)}
          federalStates={federalStates}
          postcodes={postcodes}
        />
      )}
      {selectedCourt && (
        <CourtDetail
          court={selectedCourt}
          assignments={courtCourtRoles.filter(
            a => a.idCourt === selectedCourt.idCourt
          )}
          courtAdmininistrativeUnitAssignments={courtAdministrativeUnitAssignments.filter(
            a => a.idCourt === selectedCourt.idCourt
          )}
          courtRoles={courtRoles}
          onClose={() => setSelectedCourt(null)}
        />
      )}
    </>
  );
};
