import { Autocomplete, ListItem, ListItemText, TextField } from '@mui/material';
import { IUser } from '../../Interfaces/IUser';
import { IUserGroup } from '../../Interfaces/IUserGroup';
import { Person } from '@mui/icons-material';
import { useState } from 'react';

interface IProps {
  currentUserId: number | string | null;
  userArray: IUser[];
  userGroupArray: IUserGroup[];
  reassignUser(
    value: number | null,
    userType: 'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung' | 'Notar',
    index?: number
  ): void;
  index?: number;
  title?: string | null;
  isNotary?: boolean;
}

export const UserReassignSingle: React.FC<IProps> = ({
  index,
  userArray,
  title,
  reassignUser,
  currentUserId,
  userGroupArray,
  isNotary,
}) => {
  // Dynamisch je nach Notar-Flag die Optionen generieren
  const combinedOptions = isNotary
    ? userArray
        .filter(x => x.isNotary) // Nur Notare anzeigen
        .map(x => ({
          type: 'Notar',
          id: x.idUser,
          name: `${x.FirstName} ${x.LastName}`,
        }))
    : [
        ...userArray
          .filter(x => !x.isNotary) // Mitarbeiter, die keine Notare sind
          .map(x => ({
            type: 'Mitarbeiter',
            id: x.idUser,
            name: `${x.FirstName} ${x.LastName}`,
          })),
        ...userGroupArray.map(group => ({
          type: 'Gruppe',
          id: group.idUserGroup,
          name: group.UserGroup,
        })),
        {
          type: 'Keine Zuweisung',
          id: null,
          name: 'Keine Zuweisung',
        },
      ];

  const [selectedType, setSelectedType] = useState<
    'user' | 'group' | 'none' | 'notary'
  >(
    currentUserId === null
      ? 'none'
      : userArray.find(x => x.idUser === currentUserId && x.isNotary)
        ? 'notary'
        : userArray.find(x => x.idUser === currentUserId)
          ? 'user'
          : 'group'
  );

  return (
    <>
      <Autocomplete
        options={combinedOptions}
        groupBy={option => option.type}
        clearIcon={null}
        clearOnEscape={false}
        clearOnBlur={false}
        getOptionLabel={option => option.name}
        style={{ width: '100%' }}
        renderInput={params => (
          <TextField
            {...params}
            label={
              selectedType === 'notary' ? (
                <span>
                  <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zugewiesener Notar
                </span>
              ) : selectedType === 'user' || selectedType === 'none' ? (
                <span>
                  <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zugewiesener Mitarbeiter
                </span>
              ) : selectedType === 'group' ? (
                <span>
                  <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zugewiesene Gruppe
                </span>
              ) : (
                <span>
                  <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zuweisung vornehmen
                </span>
              )
            }
            size='small'
            variant='outlined'
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={option.name} />
          </ListItem>
        )}
        onChange={(event, newValue) => {
          if (newValue) {
            if (newValue.type === 'Notar') {
              reassignUser(newValue.id, 'Notar', index);
              setSelectedType('notary');
            } else if (newValue.type === 'Mitarbeiter') {
              reassignUser(newValue.id, 'Mitarbeiter', index);
              setSelectedType('user');
            } else if (newValue.type === 'Gruppe') {
              reassignUser(newValue.id, 'Gruppe', index);
              setSelectedType('group');
            } else if (newValue.type === 'Keine Zuweisung') {
              reassignUser(null, 'Keine Zuweisung', index);
              setSelectedType('none');
            }
          } else {
            reassignUser(
              null,
              selectedType === 'notary' ? 'Notar' : 'Mitarbeiter',
              index
            );
            setSelectedType('none');
          }
        }}
        value={
          selectedType === 'notary' && currentUserId !== null
            ? combinedOptions.find(
                option => option.type === 'Notar' && option.id === currentUserId
              )
            : selectedType === 'user' && currentUserId !== null
              ? combinedOptions.find(
                  option =>
                    option.type === 'Mitarbeiter' && option.id === currentUserId
                )
              : selectedType === 'group' && currentUserId !== null
                ? combinedOptions.find(
                    option =>
                      option.type === 'Gruppe' && option.id === currentUserId
                  )
                : selectedType === 'none'
                  ? combinedOptions.find(
                      option => option.type === 'Keine Zuweisung'
                    )
                  : null
        }
      />
    </>
  );
};
