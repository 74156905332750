import { TableCell, TableRow, TextField } from '@mui/material';
import React from 'react';
import { ConfirmDelete } from '../../../core/ConfirmDelete';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';

interface IProps {
  currentDeadline: ILegalRequirementDeadline;
  setDeadline: Function;
  deleteDeadline: Function;
}

export const LegalRequirementDeadlineRow: React.FC<IProps> = props => {
  const [deadlineType] = React.useState<
    'Maßnahme' | 'Phase' | 'Status' | 'Startdatum'
  >(
    props.currentDeadline.idLegalRequirement
      ? 'Maßnahme'
      : props.currentDeadline.idLegalPhase
        ? 'Phase'
        : props.currentDeadline.idLegalTransactionState
          ? 'Status'
          : 'Startdatum'
  );

  const saveDeadline = (newDeadline: ILegalRequirementDeadline) => {
    props.setDeadline(newDeadline);
  };

  const handleDelete = () => {
    props.deleteDeadline(props.currentDeadline.idDeadline);
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 20 }}></TableCell>
        <TableCell>{deadlineType}</TableCell>
        <TableCell>
          {deadlineType === 'Maßnahme' ? (
            <>
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalRequirement}
              </span>{' '}
              bei Status:{' '}
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalRequirementState}
              </span>
              {' in Vorgang '}
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalTransactionTitle}
              </span>
            </>
          ) : deadlineType === 'Phase' ? (
            <>
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalPhase}
              </span>
              {' in Vorgang '}
              <span style={{ color: '#4a4a4a', fontStyle: 'italic' }}>
                {props.currentDeadline.LegalTransactionTitle}
              </span>
            </>
          ) : deadlineType === 'Status' ? (
            props.currentDeadline.idLegalTransactionState
          ) : (
            new Date(props.currentDeadline.Startdate!).toLocaleDateString()
          )}
        </TableCell>
        <TableCell>
          <TextField
            type='number'
            value={props.currentDeadline.Weighting || ''}
            onChange={e =>
              saveDeadline({
                ...props.currentDeadline,
                Weighting:
                  Number(e.target.value) <= 0 ? 1 : Number(e.target.value),
              })
            }
            size='small'
          />
        </TableCell>
        <TableCell>
          {props.currentDeadline.DeadlineTriggeredAt
            ? 'Die Bedingung wurde am ' +
              new Date(
                props.currentDeadline.DeadlineTriggeredAt
              ).toLocaleDateString() +
              ' erfüllt'
            : 'Die Bedingung wurde noch nicht erfüllt'}
        </TableCell>
        <TableCell>
          <ConfirmDelete
            itemText='die aktulle Bedingung'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
