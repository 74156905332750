import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';

interface IProps {
  itemText: string;
  functionToDelete: Function;
}

export const ConfirmDelete: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Löschungsbestätigung</DialogTitle>
        <DialogContent>
          Sind Sie sicher, dass Sie {props.itemText} löschen wollen?
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              props.functionToDelete();
              setIsOpen(false);
            }}
          >
            Löschen
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => setIsOpen(false)}
          >
            Abbruch
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip title={props.itemText + 'löschen'}>
        <IconButton size='small' onClick={() => setIsOpen(true)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
};
