import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material';
import { ArrowDownward, ArrowUpward, Edit } from '@mui/icons-material';
import { ConfirmDelete } from '../../../../core/ConfirmDelete';
import { ILegalPhaseTemplateFullObject } from '../../../../Interfaces/ILegalPhaseTemplate';
import { ILegalRequirementTemplateFullObject } from '../../../../Interfaces/ILegalRequirementTemplate';
import { LegalRequirementEdit } from '../LegalRequirementEdit';

interface IProps {
  currentRequirement: ILegalRequirementTemplateFullObject;

  handleChoosenRequirementChange: Function;
  isChecked: boolean;
}

export const LegalRequirementRow: React.FC<IProps> = props => {
  const [currentRequirementEdit, setCurrentRequirementEdit] = useState(
    props.currentRequirement
  );

  const handleCheckRequirement = (checked: boolean) => {
    props.handleChoosenRequirementChange(props.currentRequirement, checked);
  };
  //

  /*
    const getUp = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let
            
            toChangeObject.Position = currentRequirementEdit.Position;
            currentObject.Position = currentRequirementEdit.Position-1;
            //copyOfArray.map(x => x.idLegalRequirementTemplate === toChangeObject!.idLegalRequirementTemplate ? toChangeObject : x );
            copyOfArray.map(x => 
                x.idLegalRequirementTemplate === currentObject!.idLegalRequirementTemplate ? currentObject :
                (x.idLegalRequirementTemplate === toChangeObject?.idLegalRequirementTemplate) ? toChangeObject : x );
            
            props.setLegalRequirementArray(copyOfArray)
        }
        
        
    }
    */

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 20 }}></TableCell>
        <TableCell>
          <Checkbox
            onChange={e => handleCheckRequirement(e.target.checked)}
            checked={props.isChecked}
          ></Checkbox>
          {props.currentRequirement.LegalRequirementTemplate}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesClient ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.canUploadFilesEmployees ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell>
          {props.currentRequirement.sendMail ? 'Ja' : 'Nein'}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  );
};
