import { Box, Button, Dialog, DialogContent, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getFetch, useFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { JSONTree } from "react-json-tree";
import { Cached, Publish, Search } from "@mui/icons-material";
import { ILegalTransactionType } from "../../Interfaces/ILegalTransactionType";
import { IDialogLegalTransactionTypeMapping } from "../../Interfaces/IDialogLegalTransactionTypeMapping";
import { DialogMappingEntry } from "./Childs/DialogMappingEntry";


export const PublicServer:React.FC = () => {
    const [corruptedArray,setCorruptedArray, wasSuccessFullyCorruptedArray] = useFetch<any[]>("/system/sync/corrupted");
    const [stateObject,setStateObject, wasSuccessFullyStateObject] = useFetch<{State: string}>("/system/sync/state");
    const [legalTransactionTypeArray, setLegalTransactionType, wasSuccessfullyLegalTransactionType] = useFetch<ILegalTransactionType[]>("/legaltransactiontype");
    const [dialogMapping, setDialogMapping, wasSuccessfullyMapping] = useFetch<IDialogLegalTransactionTypeMapping[]>("/dialoglegaltransactiontypemapping");
    //
    const [isOpenCorrupted,setIsOpenCorrupted] = useState(false);

    const reloadState = () => {
        getFetch("/system/sync/state",null,setStateObject)
    }

    const syncAll = () => {
        getFetch("/system/sync/all",null,setStateObject)
    }

    if (
        !wasSuccessFullyCorruptedArray
        || !wasSuccessFullyStateObject
        || !wasSuccessfullyLegalTransactionType
        || !wasSuccessfullyMapping
    ) { return <>Error!</> }
    else if (
        corruptedArray === undefined
        || stateObject === undefined
        || legalTransactionTypeArray === undefined
        || dialogMapping === undefined
    ) { return <CustomCircularProgress/> }
    else {
        return(
            <>

                <Dialog
                    open={isOpenCorrupted}
                    onClose={() => setIsOpenCorrupted(false)}
                    maxWidth="lg" fullWidth
                >
                    <DialogContent>
                        <JSONTree data={corruptedArray} />
                    </DialogContent>
                </Dialog>


                <Box sx={{m: 2, mt: 0}}>
                    <Typography variant="h4">
                        Öffentlicher Server
                    </Typography>

                    <Box sx={{mt: 5}} />
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{mb: 2}}>
                                        Synchronisation zum öffentlichen Server
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Die folgenden Einträge zeigen den Status der synchronisation zum öffentlichen Server. Der Eintrag "Volle Synchronisation" sollte den Status "Ready" zeigen.
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <b>Fehlerhafte Synchronisationen zum Server</b>
                                </Grid>
                                <Grid item xs={4}>
                                    {corruptedArray.length}
                                </Grid>
                                <Grid item xs={4}>
                                    <Button onClick={() => setIsOpenCorrupted(true)}>Betrachten</Button>
                                </Grid>

                                <Grid item xs={4}>
                                    <b>Volle Synchronisation</b>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Status"
                                        value={stateObject.State}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <Button variant="outlined" onClick={() => {setStateObject(undefined); reloadState()}}>Prüfen</Button>
                                    <Button variant="outlined" onClick={() => {setStateObject(undefined); syncAll()}}>Alles hochladen</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{mb: 2}}>
                                        Synchronisation vom öffentlichen Server
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        In der folgenden Tabelle kann festgelegt werden, welche Art von Rechtsgeschäft dem Vorgang aus dem jeweiligen Dialog zugeordnet werden soll.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dialog</TableCell>
                                                <TableCell>Rechtsgeschäftsart</TableCell>
                                                <TableCell>Aktionen</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {dialogMapping.map(x =>
                                            <DialogMappingEntry
                                                key={`dialogmapping-${x.idDialogLegalTransactionTypeMapping}`}
                                                currentMapping={x}
                                                dialogMappingArray={dialogMapping}
                                                legalTransactionTypeArray={legalTransactionTypeArray}
                                                setDialogMappingArray={setDialogMapping}
                                            />
                                        )}
                                        </TableBody>
                                    </Table>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </Box>
            </>
        )
    }

}