import React, { useCallback, useEffect, useState } from 'react';
import { IUser, IUserFullObject } from '../Interfaces/IUser';
import { IRole } from '../Interfaces/IRole';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { getFetch, uploadFetch, useFetch } from '../hooks/useFetch';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';
import { UserCoreEdit } from './UserCoreEdit';
import { UserBaseInformation } from './Childs/UserBaseInformation';
import { IEmploymentContract } from '../Interfaces/IEmploymentContract';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { EmploymentContractOverview } from './Childs/EmploymentContractOverview';
import { DayOffWorkUserSummary } from '../DayOffWork/Childs/DayOffWorkUserSummary';
import { IDayOffWork } from '../Interfaces/IDayOffWork';
import { IDayOffWorkCategory } from '../Interfaces/IDayOffWorkCategory';
import { DayOffWorkOverviewTable } from '../DayOffWork/DayOffWorkOverviewTable';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import { PopupAlert } from '../core/PopupAlert';
import { UserReassign } from './UserReassign';
import { UserAccounts } from './UserAccounts';
import { ITopic } from '../Interfaces/ITopic';
import { NotificationPreferences } from './Childs/NotificationPreferences';
import { IUserGroup, IUserGroupFullObject } from '../Interfaces/IUserGroup';
import { IUserHasUserGroup } from '../Interfaces/IUserHasUserGroup';
if (process.env.NODE_ENV === 'development') {
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IProps {
  idUser: number;
  setIdUser: Function;
  userArray: IUser[];
  userGroupArray: IUserGroup[];
  setUserArray: Function;
  roleArray: IRole[];
  setWasSaved: Function;
  setWasSavedSuccesfully: Function;
  dayOffWorkCategoryArray: IDayOffWorkCategory[];
}

const genEmptyUser = () => {
  return {
    Email: '',
    FirstName: '',
    idRole: 3,
    idUser: -1,
    isBlocked: false,
    isNotary: false,
    LastName: '',
    Login: '',
    GenerateNewPassword: true,
    idGender: 1,
    // Erweiterung MA
    Birthplace: null,
    Birthdate: null,
    Street: null,
    StreetNr: null,
    idPostcode: null,
    IBAN: null,
    idBank: null,
    InsuranceNumber: null,
    TaxIdentificationNumber: null,
    idHealthInsurance: null,
  } as IUser;
};

export const UserFullEdit: React.FC<IProps> = props => {
  const [userFullObject, setUserFullObject, wasSuccessfully] =
    useFetch<IUserFullObject>('/user/', props.idUser);
  //const [userObject, setUserObject] = useState(genEmptyUser());
  const [employmentContractArray, setEmploymentContractArray] = useState<
    IEmploymentContract[]
  >([]);
  const [dayOffArray, setDayOffArray] = useState<IDayOffWork[]>([]);
  const [userEdit, setUserEdit] = useState(genEmptyUser());
  const [
    userGroupsUserIsIn,
    setUserGroupsUserIsIn,
    wasSuccessfullyUserGroupsUserIsIn,
  ] = useFetch<IUserGroupFullObject[]>('/getUserGroupsForUser/', props.idUser);
  //
  const genderArray = useSelector(
    (state: State) => state.genderArray.storeGender
  );
  //
  const [isPwValidated, setIsPwValidated] = useState(false);
  //
  const [value, setValue] = useState(0);

  const [currentUser, setCurrentUser] = useState<IUser>();

  //Notwendig für User deaktivieren und Notar oder nicht Notar ändern
  const [legalTransactionArray, setLegalTransactionArray] = useState<
    ILegalTransaction[]
  >([]);
  const [showUserReassignDialog, setShowUserReassignDialog] = useState(false);
  const [showAlertUserReassign, setShowAlertUserReassign] = useState(false);
  const [alertText, setAlertText] = useState<string>('');
  const [roleChange, setRoleChange] = useState<boolean>(false);
  const [userDeactivated, setUserDeactivated] = useState<boolean>(false);
  const [uploadledLegalTransactions, setUploadledLegalTransactions] =
    useState<number>(0);
  const idCurrentUser = Number(localStorage.getItem('idUser'));
  const [topics, setTopics, wasSuccessfullyTopics] =
    useFetch<ITopic[]>('/topic');
  const [notificationPreferences, setNotificationPreferences] = useState<{
    [key: number]: boolean;
  }>({});
  const [showAtLogin, setShowAtLogin] = useState<boolean>(false);
  //
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingSaveTransaction, setIsLoadingSaveTransaction] =
    useState(false);

  const calcNotifcationOption = useCallback((options: number[]): number => {
    let abonnements = 0;
    for (const option of options) {
      abonnements |= 1 << (option - 1);
    }
    return abonnements;
  }, []);

  const calcNotifcationOptionArray = useCallback(
    (notificationBitNumber: number): number[] => {
      const optionen: number[] = [];
      let bitPosition = 0;

      while (notificationBitNumber !== 0) {
        if ((notificationBitNumber & 1) !== 0) {
          optionen.push(bitPosition + 1);
        }
        notificationBitNumber >>= 1;
        bitPosition++;
      }

      return optionen;
    },
    []
  );

  useEffect(() => {
    if (wasSuccessfullyTopics && userFullObject !== undefined) {
      if (
        userFullObject.NotifcationOptionBitmask !== null &&
        userFullObject.NotifcationOptionBitmask !== undefined
      ) {
        setNotificationPreferences(
          calcNotifcationOptionArray(
            userFullObject.NotifcationOptionBitmask
          ).reduce(
            (acc, cur) => {
              acc[cur] = true;
              return acc;
            },
            {} as { [key: number]: boolean }
          )
        );
      }
      if (
        userFullObject.ShowNotificationsAtLogin !== null &&
        userFullObject.ShowNotificationsAtLogin !== undefined
      ) {
        setShowAtLogin(
          userFullObject.ShowNotificationsAtLogin !== null
            ? userFullObject.ShowNotificationsAtLogin
            : false
        );
      }
    }
  }, [wasSuccessfullyTopics, userFullObject, calcNotifcationOptionArray]);

  useEffect(() => {
    if (props.userArray !== undefined) {
      setCurrentUser(props.userArray.find(x => x.idUser === idCurrentUser));
    }
  }, [props.userArray, idCurrentUser]);

  useEffect(() => {
    if (userFullObject !== undefined) {
      setUserEdit(userFullObject);
      setEmploymentContractArray(userFullObject.EmploymentContractArray);
      setDayOffArray(userFullObject.DayOffWorkArray);
    }
  }, [userFullObject]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //Initialisiere den Userchange, indem die dem Nutzer zugeordneten Vorgänge geladen werden
  const initateUserChange = () => {
    //prüfe, ob Vorgänge bereits geladen wurden
    if (legalTransactionArray.length === 0) {
      getFetch<ILegalTransaction[]>(
        `/legaltransaction/getByUser/`,
        props.idUser,
        (legaltransactions: ILegalTransaction[]) => {
          if (legaltransactions.length === 0) {
            //wenn Nutzer keine Vorgänge hat, speichere direkt
            handleSave();
          } else {
            //wenn Nutzer Vorgänge hat, zeige UserChangeDialog
            setLegalTransactionArray(legaltransactions);
            setShowUserReassignDialog(true);
          }
        },
        (successfull: boolean) => {
          if (!successfull) {
            //Wenn Fehler beim Laden der Vorgänge, zeige Fehlermeldung
            setAlertText(
              'Fehler beim Laden der dem Nutzer zugeordneten Vorgänge.'
            );
            setShowAlertUserReassign(true);
          }
        }
      );
    } else {
      setShowUserReassignDialog(true);
    }
  };

  //Wird aufgerufen, nachdem der UserChangeDialog geschlossen wurde (durch Speichern oder Abbrechen)
  const handleUserChange = (isAborted: boolean) => {
    if (isAborted) {
      //wenn UserChangeDialog abgebrochen wurde, breche Speichern ab und zeige Fehlermeldung
      setAlertText(
        'Bitte weisen Sie vor dem Speichern alle Vorgänge einem anderen Nutzer zu.'
      );
      setShowAlertUserReassign(true);
      setShowUserReassignDialog(false);
    } else {
      //wenn UserChangeDialog gespeichert wurde, speichere Vorgänge
      setShowAlertUserReassign(false);
      setShowUserReassignDialog(false);
      handleSaveLegalTransaction();
    }
  };

  const isDisabled = () => {
    if (userEdit !== undefined) {
      return (
        userEdit.FirstName === '' ||
        userEdit.LastName === '' ||
        userEdit.Email === '' ||
        userEdit.Login === '' ||
        (!isPwValidated &&
          userEdit.Password !== undefined &&
          userEdit.Password !== '' &&
          (userEdit.GenerateNewPassword === undefined ||
            !userEdit.GenerateNewPassword))
        //|| (!isPwValidated && userEdit.GenerateNewPassword !== undefined && !userEdit.GenerateNewPassword)
      );
    } else {
      return true;
    }
  };

  const wrapperSaved = (localObject: IUser) => {
    if (props.idUser < 0) {
      props.setUserArray([...props.userArray, localObject]);
    } else {
      props.setUserArray([
        ...props.userArray.map(x =>
          x.idUser === localObject.idUser ? localObject : x
        ),
      ]);
    }
    props.setIdUser(null);
  };

  //Speichert die LegalTransactions, welche durch den UserChangeDialog übergeben wurden
  const handleSaveLegalTransaction = () => {
    if (legalTransactionArray.length > 0) {
      legalTransactionArray.forEach(legalTransaction => {
        if (legalTransaction.Employee_idUser === -1) {
          legalTransaction.Employee_idUser = null;
        }
        if (legalTransaction.idUserGroup === -1) {
          legalTransaction.idUserGroup = null;
        }
        //remove the usergroup key from the object
        legalTransaction.UserInformed_LackOfActivity = Boolean(
          legalTransaction.UserInformed_LackOfActivity
        );
        legalTransaction.LastActivity_at = null;

        uploadFetch(
          '/legaltransaction',
          false,
          legalTransaction,
          () => {},
          handleSavedLegalTransaction, //notwendig, da mehrere Vorgänge gespeichert werden müssen und handleSave erst nachdem alle Vorgänge erfolgreich gespeichert wurden aufgerufen werden darf
          () => {},
          setIsLoadingSaveTransaction
        );
      });
    }
  };

  const handleSavedLegalTransaction = (uploaded: boolean) => {
    if (!uploaded) {
      //Wenn Vorgang nicht gespeichert werden konnte, zeige Fehlermeldung
      setAlertText('Fehler beim Speichern der übergebenen Vorgänge.');
      setShowAlertUserReassign(true);
    } else {
      //Wenn Vorgang gespeichert wurde, erhöhe Zähler der gespeicherten Vorgänge
      setUploadledLegalTransactions(uploadledLegalTransactions => {
        const newUploadedLegalTransactions = uploadledLegalTransactions + 1;
        checkIfAllTransactionsSaved(newUploadedLegalTransactions); //da state nicht sofort aktualisiert wird, braucht es eine Zwischenfunktion
        return newUploadedLegalTransactions;
      });
    }
  };

  const handleSave = () => {
    if (userEdit !== undefined) {
      let uploadObeject: IUserFullObject = {
        ...userEdit,
        isBlocked: Boolean(userEdit.isBlocked),
        isNotary: Boolean(userEdit.isNotary),
        isUserEditEnabled: Boolean(userEdit.isNotary),
        ShowNotificationsAtLogin: Boolean(userEdit.ShowNotificationsAtLogin),
        Password: userEdit.Password === undefined ? '' : userEdit.Password,
        EmploymentContractArray: employmentContractArray,
        DayOffWorkArray: dayOffArray,
      };
      if (notificationPreferences !== undefined) {
        let notificationBitmask = calcNotifcationOption(
          Object.keys(notificationPreferences)
            .map(x => Number(x))
            .filter(x => notificationPreferences[x])
        );
        if (notificationBitmask !== userEdit.NotifcationOptionBitmask) {
          console.log(notificationBitmask);
          uploadObeject.NotifcationOptionBitmask = notificationBitmask;
        } else {
          //remove from uploadObject
          uploadObeject.NotifcationOptionBitmask = undefined;
        }
      }
      if (showAtLogin !== userEdit.ShowNotificationsAtLogin) {
        uploadObeject.ShowNotificationsAtLogin =
          showAtLogin == null ? false : Boolean(showAtLogin);
      } else {
        //remove from uploadObject
        uploadObeject.ShowNotificationsAtLogin = undefined;
      }
      console.log(uploadObeject);
      uploadFetch(
        `/user`,
        uploadObeject.idUser < 0,
        uploadObeject,
        wrapperSaved,
        props.setWasSavedSuccesfully,
        props.setWasSaved,
        setIsLoadingSave
      );
    }
  };

  //Prüft, ob alle Vorgänge erfolgreich gespeichert wurden
  const checkIfAllTransactionsSaved = (count: number) => {
    if (count === legalTransactionArray.length) {
      handleSave();
    }
  };

  if (
    !wasSuccessfully ||
    !wasSuccessfullyUserGroupsUserIsIn ||
    !wasSuccessfullyTopics
  ) {
    return <Alert severity='error'>Benutzer kann nicht geladen werden!</Alert>;
  } else if (
    (props.idUser !== -1 && userFullObject === undefined) ||
    topics === undefined ||
    (props.idUser !== -1 && userGroupsUserIsIn === undefined)
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <PopupAlert
          show={showAlertUserReassign}
          setShow={setShowAlertUserReassign}
          severity='error'
          text={alertText}
        />

        <Typography variant='h5'>
          {props.idUser < 0 || userFullObject === undefined
            ? 'Neuer Benutzer'
            : `${userFullObject?.FirstName} ${userFullObject?.LastName}`}
        </Typography>

        {(isLoadingSave || isLoadingSaveTransaction) && (
          <>
            <LinearProgress />
          </>
        )}

        <UserCoreEdit
          roleArray={props.roleArray}
          userEdit={userEdit}
          setUserEdit={setUserEdit}
          setIsPwValidated={setIsPwValidated}
          userArray={props.userArray}
          setRoleChange={() => setRoleChange(!roleChange)}
          setUserDeactivated={setUserDeactivated}
          roleChange={roleChange}
          currentUserId={currentUser?.idUser}
          userGroups={userGroupsUserIsIn || []}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Stammdaten' {...a11yProps(0)} />
            <Tab label='Zugänge' {...a11yProps(1)} />
            <Tab label='Benachrichtigungen' {...a11yProps(2)} />

            {/*
                        <Tab label="Arbeitsverträge" {...a11yProps(3)} />
                        <Tab label="Fehltage" {...a11yProps(4)} />
                        <Tab label="Dokumente" {...a11yProps(5)} />
                        */}
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <UserBaseInformation userEdit={userEdit} setUserEdit={setUserEdit} />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <UserAccounts userEdit={userEdit} setUserEdit={setUserEdit} />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <NotificationPreferences
            topics={topics}
            preferences={notificationPreferences}
            setPreferences={setNotificationPreferences}
            showAtLogin={showAtLogin}
            setShowAtLogin={setShowAtLogin}
          />
        </CustomTabPanel>

        {/*
                <CustomTabPanel value={value} index={3}>
                    <EmploymentContractOverview
                        employmentContractArray={employmentContractArray}
                        setEmploymentContractArray={setEmploymentContractArray}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={4}>
                    <>
                        <DayOffWorkOverviewTable
                            userArray={[userEdit]}
                            employmentContractArray={employmentContractArray}
                            dayOffWorkCategoryArray={props.dayOffWorkCategoryArray}
                            dayOffWorkArray={dayOffArray}
                            setDayOffWorkArray={setDayOffArray}
                        />
                        <DayOffWorkUserSummary
                            key={`dayoffworkusersummary-${props.idUser}`}
                            idUser={props.idUser}
                            employmentContractArray={employmentContractArray}
                            dayOffWorkArray={dayOffArray}
                            dayOffWorkCategoryArray={props.dayOffWorkCategoryArray}
                        />
                    </>

                </CustomTabPanel>
                */}

        <Button
          onClick={() => {
            if (userEdit !== undefined) {
              if (roleChange || userDeactivated) {
                initateUserChange();
              } else {
                handleSave();
              }
            }
          }}
          sx={{ float: 'right', ml: 2 }}
          variant='contained'
          disabled={isDisabled() || isLoadingSave}
        >
          Speichern
        </Button>
        <Button
          disabled={isLoadingSave}
          onClick={() => props.setIdUser(null)}
          sx={{ float: 'right' }}
          variant='outlined'
        >
          Abbruch
        </Button>

        <Dialog open={showUserReassignDialog} fullWidth maxWidth='md'>
          <DialogTitle>
            Der Benutzer ist noch Vorgängen als{' '}
            {roleChange
              ? !userEdit.isNotary
                ? 'Notar'
                : 'Mitarbeiter'
              : userEdit.isNotary
                ? 'Notar'
                : 'Mitarbeiter'}{' '}
            zugeordnet. Bitte ordnen Sie diese neu zu, um fortzufahren.
          </DialogTitle>
          <UserReassign
            userId={userEdit.idUser}
            setLegalTransactionArray={setLegalTransactionArray}
            usergroupArray={[...props.userGroupArray]}
            userArray={[...props.userArray]
              .filter(
                user =>
                  user.idUser !== userEdit.idUser &&
                  Boolean(user.isBlocked) !== true &&
                  (roleChange
                    ? Boolean(user.isNotary) !== Boolean(userEdit.isNotary)
                    : Boolean(user.isNotary) === Boolean(userEdit.isNotary))
              )
              .sort((a, b) => a.idUser - b.idUser)}
            legalTransactionArray={legalTransactionArray}
            isNotary={roleChange ? !userEdit.isNotary : userEdit.isNotary}
            isRoleChange={roleChange}
          />
          <DialogActions>
            <Button onClick={() => handleUserChange(true)} color='primary'>
              Abbrechen
            </Button>
            <Button
              onClick={() => handleUserChange(false)}
              color='primary'
              disabled={legalTransactionArray.some(
                x =>
                  x.Notary_idUser === null ||
                  x.Notary_idUser === userEdit.idUser ||
                  x.Employee_idUser === userEdit.idUser
              )}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};
